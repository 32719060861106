import React from "react";
import CMS from "../../../components/CMS/Why";

const index = () => {
  return (
    <>
      <CMS />
    </>
  );
};

export default index;
