import instance from "../../Config/axios";
import { ParseError } from "../../Config/utils";
const GetCMSHome = async () => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  return instance
    .get("/home/cms")
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetCMSLogosHome = async () => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  return instance
    .get("/home/logo/")
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetFAQHome = async (type) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  return instance
    .get("home/faq/" + type)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetSiteInfoHome = async () => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  return instance
    .get("home/site/")
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetCMSTimelineListingHome = async () => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  return instance
    .get("home/timeline/")
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetCMSTeamListingHome = async (type) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  return instance
    .get("home/team/" + type)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetCMSSliderListingHome = async () => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  return instance
    .get("home/slider/")
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetICOInfoHome = async () => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  return instance
    .get("home/ico/")
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export {
  GetCMSHome,
  GetCMSLogosHome,
  GetFAQHome,
  GetSiteInfoHome,
  GetCMSTimelineListingHome,
  GetCMSTeamListingHome,
  GetCMSSliderListingHome,
  GetICOInfoHome,
};
