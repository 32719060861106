import { useState, useEffect } from "react";
import { createRows, rowsPerPage, UserProfileTableHeadings } from "./helpers";
import { Table, TableContainer } from "@material-ui/core";
import { Pagination } from "@mui/material";
import TableHeader from "./TableHeader";
import TableRow from "./Tablerow";
import DataNotFound from "../Comps-Utils/DataNotFoundTable";
import { getComparator, stableSort } from "../../helpers/Sorting";
import { getCount, paginate } from "../../helpers/Paginate";
const UserProfileTable = (props) => {
  const { users, loading } = props;
  const [rowData, setRowData] = useState([]);
  //pagination of table
  const [page, setPage] = useState(1);
  const pagination = paginate(page, rowsPerPage);
  // sorting states
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("userId");
  //Use Effect To set RowData
  useEffect(() => {
    setRowData(users);
  }, [users]);
  //set rows
  const rows = createRows(rowData.slice(pagination.start, pagination.end));
  //Change Page pagination Handler
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //Sorting Handler
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  return (
    <>
      <div className="block block-rounded block-bordered mt-3">
        <div className="block-header block-header-default">
          <h3 className="block-title">User Profiles</h3>
        </div>
        <TableContainer>
          <Table className="table m-0">
            <TableHeader
              Columns={UserProfileTableHeadings}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <tbody>
              {rows.length === 0 && loading === false ? (
                <DataNotFound />
              ) : (
                stableSort(rows, getComparator(order, orderBy)).map(
                  (row, index) => {
                    return (
                      <TableRow
                        // key={row.id}
                        row={row}
                        index={index}
                      />
                    );
                  }
                )
              )}
            </tbody>
          </Table>
        </TableContainer>
        <div className="divider" />
        <div className="pagination-css px-md-4 py-md-3 px-2 py-1 d-flex justify-content-center">
          <Pagination
            count={getCount(rowData.length || 0, rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </>
  );
};
export default UserProfileTable;
