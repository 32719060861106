import * as Yup from "yup";
import moment from "moment";
export const createRows = (rowData) => {
  const rows = rowData.map((i, index) => {
    let temp = moment(i.deadline).format("MMMM DD YYYY");
    let today = moment().format("MMMM DD YYYY");
    return {
      id: i._id,
      title: i.title,
      deadline: moment(i.deadline).format("MM DD YYYY"),
      achived: moment(temp).diff(today, "months") >= -1 ? "Comming" : "Passed",
      // achived:moment(temp).diff(today,"months") ,
      status: i.status,
    };
  });
  return rows;
};
export const rowsPerPage = 10;
export const timelineListingTableHeadings = [
  {
    id: "title",
    label: "Title",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "deadline",
    label: "Deadline",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "achived",
    label: "Achived",
    sort: true,
    class: "bg-body-light text-center",
  },
  {
    id: "status",
    label: "Status",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "actions",
    label: "Actions",
    sort: true,
    class: "bg-body-light text-center",
  },
];
export const timelineInitialValues = {
  title: "",
  deadline: "",
  status: false,
};
export const TimelineSchema = Yup.object().shape({
  title: Yup.string().required("Title is required!"),
  deadline: Yup.string().required("Deadline is required!"),
});
export const TimelineFormFields = [
  {
    field: "Title",
    fieldName: "title",
    place: "Enter Title",
    type: "text",
  },
  {
    field: "Deadline",
    fieldName: "deadline",
    place: "Enter Deadline",
    type: "date",
  },
  {
    field: "Status",
    fieldName: "status",
    place: "",
    type: "radio",
  },
];
export const setInitialValues = (updatedRow) => {
  const Values = {
    id: updatedRow.id ? updatedRow.id : "",
    title: updatedRow.title ? updatedRow.title : "",
    status: updatedRow.title ? updatedRow.status : "",
    deadline: updatedRow.deadline
      ? moment(updatedRow.deadline).format("yyyy-MM-DD")
      : "",
  };
  return Values;
};
