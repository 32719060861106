import React from "react";
import MainSideBar from "./SideBarMain";
const SidePanel = (props) => {
  const { sidebarMain } = props;

  return (
    <>
      <MainSideBar
        key={"SideBarMainAdmin" + Math.random().toString(36).substring(7)}
        sidebarMain={sidebarMain}
      />
    </>
  );
};
export default SidePanel;
