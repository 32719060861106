import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TableHeader = (props) => {
  const {
    order,
    orderBy,
    onRequestSort,
    Columns,
    handleSelectAllClick,
    numSelected,
    rowCountAll,
    tableHeading,
    deleteRow,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <thead
      colSpan="5"
      className="thead-light text-capitalize font-size-sm font-weight-bold border-top"
    >
      <tr>
        {tableHeading === "AirDrops" ? (
          <>
            <th className="bg-body-light" padding="checkbox ff">
              <Checkbox
                className="checkbox-tiny"
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCountAll}
                checked={rowCountAll > 0 && numSelected === rowCountAll}
                onChange={handleSelectAllClick}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
              />
            </th>
          </>
        ) : null}

        {/* {tableHeading === "AirDrops" ? (
          <>
            <th className="bg-body-light" padding="checkbox">
              <FontAwesomeIcon
                icon={["fas", "times"]}
                className="font-size-xs"
              />
            </th>
          </>
        ) : null} */}

        {Columns.map((headCell, index) => (
          <th
            id={"table-header" + index}
            key={headCell.label}
            className={headCell.class}
            sortdirection={orderBy === headCell.id ? order : "false"}
          >
            {headCell.sort ? (
              <TableSortLabel
                id={"sort-table" + index}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              <>
                <div className="d-flex">
                  <span>{headCell.label}</span>
                  {headCell &&
                  headCell.component &&
                  headCell.component === "permissions" ? (
                    <span
                      onClick={() => {
                        deleteRow(headCell.label);
                      }}
                      className="ml-2 cursor-puri del-btn"
                    >
                      {" "}
                      {headCell &&
                      headCell.component &&
                      headCell.component === "permissions" ? (
                        <FontAwesomeIcon
                          icon={["fas", "close"]}
                          className="font-size-sm"
                        />
                      ) : null}{" "}
                    </span>
                  ) : null}
                </div>
              </>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};
export default TableHeader;
