import React, { useState } from "react";
import SidePanel from "../components/Layout/SideBar";
import SidePanelMini from "../components/Layout/SideBarMini";
import Header from "../components/Layout/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
export const LayoutContext = React.createContext({});

const PresentationLayout = (props) => {
  const { children, sidebarMain } = props;
  const matches = useMediaQuery("(max-width:1000px)");
  const [open, setOpen] = useState(false);
  return (
    <div className="">
      {/* <div className='bg-primary col-10 ml-auto'>
                <Header />
            </div> */}
      <div className="row_0_m">
        {open || matches === false ? (
          <div className="col-2 bg-dark admin-sidenav">
            <SidePanel
              key={"sidePanel" + Math.random().toString(36).substring(7)}
              sidebarMain={sidebarMain}
            />
          </div>
        ) : (
          <div
            className="col-1-mini bg-dark"
            style={{ height: "100vh", padding: "0" }}
          >
            <SidePanelMini />
          </div>
        )}
        <div
          className={
            open || matches === false
              ? "col-12 admin-sidevar-content"
              : "col-11-mini"
          }
          style={{ padding: "0" }}
        >
          <div className="bg-dark">
            <div
              className={
                open || matches === false ? "mini-px" : "col-12 mobileView"
              }
            >
              <LayoutContext.Provider value={{ open, setOpen }}>
                <Header />
              </LayoutContext.Provider>
            </div>
          </div>

          {children}
        </div>{" "}
      </div>
    </div>
  );
};
export default PresentationLayout;
