/* eslint-disable */
import * as Yup from "yup";
export const PermissionsInitialValues = {
  role: "",
};
export const DashboardPermissionsFormFields = [
  {
    field: "Status",
    fieldName: "dashboardstatus",
    place: "Select Status",
    type: "radio",
  },
];
export const PermissionsSchema = Yup.object().shape({
  role: Yup.string().required("Please Enter Role!"),
});
export const PermissionsFormFields = [
  {
    field: "Role",
    fieldName: "role",
    place: "Enter Role",
    type: "text",
  },
];
export const setInitialValues = (Current, role) => {
  return {
    role: role,
    dashboardstatus:
      Current && Current.sideBar && Current.sideBar.dashboard.status
        ? Current.sideBar.dashboard.status
        : "",
  };
};
export const createRows = (rowData) => {
  let temp = [];
  rowData.map((data, index) => {
    Object.values(data && data.sideBar ? data.sideBar : []).map((item) => {
      temp.push(item);
    });
  });
  const rows = temp.map((i, index) => {
    return {
      role: i && i.role ? i.role : "",
      type: i && i.name ? i.name : "",
      viewKyc: i && i.viewKyc ? i.viewKyc : false,
      editKyc: i && i.editKyc ? i.editKyc : false,
      status: i && i.status ? i.status : "",
      userStatus: i && i.userStatus ? i.userStatus : false,
      kycStatus: i && i.kycStatus ? i.kycStatus : false,
      deleteKyc: i && i.deleteKyc ? i.deleteKyc : false,
      addKyc: i && i.deleteKyc ? i.deleteKyc : false,
    };
  });
  const result = Array.from(new Set(rows.map((s) => s.type))).map((lab) => {
    return {
      type: lab,
      role: rows.filter((s) => s.type === lab).map((edition) => edition.role),
      userStatus: rows
        .filter((s) => s.type === lab)
        .map((edition) => edition.userStatus),
      data: rows.filter((s) => s.type === lab).map((edition) => edition.status),
      kycStatus: rows
        .filter((s) => s.type === lab)
        .map((edition) => edition.kycStatus),
      viewKyc: rows
        .filter((s) => s.type === lab)
        .map((edition) => edition.viewKyc),
      editKyc: rows
        .filter((s) => s.type === lab)
        .map((edition) => edition.editKyc),
      deleteKyc: rows
        .filter((s) => s.type === lab)
        .map((edition) => edition.deleteKyc),
      addKyc: rows
        .filter((s) => s.type === lab)
        .map((edition) => edition.addKyc),
    };
  });
  const finalRows = result.map((r, index) => {
    let dummyObject = {};
    r.role.map((roleMap, index) => {
      dummyObject = {
        ...dummyObject,
        [roleMap]: r.data && r.data[index] ? r.data[index] : false,
      };
    });
    let dummyObject1 = {};
    r.role.map((roleMap, index) => {
      dummyObject1 = {
        ...dummyObject1,
        [roleMap]:
          r.userStatus && r.userStatus[index] ? r.userStatus[index] : false,
      };
    });
    let dummyObject2 = {};
    r.role.map((roleMap, index) => {
      dummyObject2 = {
        ...dummyObject2,
        [roleMap]:
          r.kycStatus && r.kycStatus[index] ? r.kycStatus[index] : false,
      };
    });
    let dummyObject3 = {};
    r.role.map((roleMap, index) => {
      dummyObject3 = {
        ...dummyObject3,
        [roleMap]: r.viewKyc && r.viewKyc[index] ? r.viewKyc[index] : false,
      };
    });
    let dummyObject4 = {};
    r.role.map((roleMap, index) => {
      dummyObject4 = {
        ...dummyObject4,
        [roleMap]: r.editKyc && r.editKyc[index] ? r.editKyc[index] : false,
      };
    });
    let dummyObject5 = {};
    r.role.map((roleMap, index) => {
      dummyObject5 = {
        ...dummyObject5,
        [roleMap]:
          r.deleteKyc && r.deleteKyc[index] ? r.deleteKyc[index] : false,
      };
    });
    let dummyObject6 = {};
    r.role.map((roleMap, index) => {
      dummyObject6 = {
        ...dummyObject6,
        [roleMap]: r.addKyc && r.addKyc[index] ? r.addKyc[index] : false,
      };
    });
    return {
      type: r && r.type ? r.type : "",
      sideBar: dummyObject,
      userStatus: dummyObject1,
      kycStatus: dummyObject2,
      viewKyc: dummyObject3,
      editKyc: dummyObject4,
      deleteKyc: dummyObject5,
      addKyc: dummyObject6,
      // mangerSideBarStatus: r && r.data && r.data[0] ? r.data[0] : "",
      // advisorSideBarStatus: r && r.data && r.data[1] ? r.data[1] : "",
      // leadershipSideBarStatus: r && r.data && r.data[2] ? r.data[2] : "",
      // developerSideBarStatus: r && r.data && r.data[3] ? r.data[3] : "",
      // mangerKycUserStatus:
      //   r && r.userStatus && r.userStatus[0] ? r.userStatus[0] : "",
      // advisorKycUserStatus:
      //   r && r.userStatus && r.userStatus[1] ? r.userStatus[1] : "",
      // leadershipKycUserStatus:
      //   r && r.userStatus && r.userStatus[2] ? r.userStatus[2] : "",
      // developerKycUserStatus:
      //   r && r.userStatus && r.userStatus[3] ? r.userStatus[3] : "",
    };
  });
  return finalRows;
};
export const rowsPerPage = 10;
export const CreatePermissionsTableHeadings = (data) => {
  let header = [
    {
      id: "name",
      label: "Type",
      sort: false,
      class: "bg-body-light",
    },
  ];
  data.map((role) => {
    header.push({
      id: role.role,
      label: role.role,
      sort: false,
      class: "bg-body-light",
      component: "permissions",
    });
  });
  return header;
};
// [
//   // {
//   //   id: "id",
//   //   label: "#",
//   //   sort: true,
//   //   class: "bg-body-light",
//   // },
//   {
//     id: "name",
//     label: "Type",
//     sort: true,
//     class: "bg-body-light",
//   },
//   {
//     id: "sideBarStatus",
//     label: "Manager SideBar",
//     sort: true,
//     class: "bg-body-light",
//   },
//   {
//     id: "sideBarStatus",
//     label: "Advisor SideBar",
//     sort: true,
//     class: "bg-body-light",
//   },
//   {
//     id: "sideBarStatus",
//     label: "Leadership SideBar",
//     sort: true,
//     class: "bg-body-light",
//   },
//   {
//     id: "sideBarStatus",
//     label: "Developer SideBar",
//     sort: true,
//     class: "bg-body-light",
//   },
//   // {
//   //   id: "payableToken",
//   //   label: "Payable Token",
//   //   sort: true,
//   //   class: "bg-body-light",
//   // },
//   // {
//   //   id: "status",
//   //   label: "Status",
//   //   class: "bg-body-light",
//   // },
//   // {
//   //   id: "time",
//   //   label: "Time",
//   //   class: "bg-body-light",
//   // },
// ];
