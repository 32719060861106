import Button from "@mui/material/Button";
import { useState, useContext, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "./Tabs";
// import { UserInitialValues, KycGeneralSchema } from "./helpers";
import Alert from "../Alert";
import useStyles from "../../assets/CustomeMaterialStyleSheet";
import CloseIcon from "@mui/icons-material/Close";
import StepperWizard from "./Wizard";
import { AddUser, UpdateKycUser, getKycDocs } from "../../Services/Admin/Kyc";
import { ToasterContext } from "../../App";
import PerfectScrollbar from "react-perfect-scrollbar";

const Body = (props) => {
  const {
    actionState,
    updatedRow,
    setUpdatedRow,
    Refetch,
    handleClose,
    setActionState,
  } = props;
  const classes = useStyles();
  const [alert, setAlert] = useState({ visible: false });
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [upload, setUpload] = useState("");
  const [upload1, setUpload1] = useState("");
  const [docType, setDocsType] = useState("");

  // Solve KYC image Bug RCN-144
  const [passportImg, setPasspostImg] = useState(null);
  const [cnicFront, setCnicFront] = useState(null);
  const [cnicBack, setCnicBack] = useState(null);
  const [drivingImg, setDrivingImg] = useState(null);
  // end RCN-144

  const [kycDocs, setkycDocs] = useState("");

  useEffect(() => {
    if (actionState === "edit") {
      getKycDocs(updatedRow.id).then((res) => {
        if (res.status === 200) {
          setkycDocs(res.data);
        } else {
          setTostify({
            ...tostify,
            visible: true,
            key: Math.random().toString(36).substring(7),
            type: res && res.status === 200 ? "success" : "error",
            message: res.message,
          });
        }
      });
    }
    // eslint-disable-next-line
  }, [actionState]);
  const KycAddUser = (data, actions) => {
    AddUser(data).then((res) => {
      if (res.status === 200) {
        Refetch();
        handleClose();
      }
      actions.setSubmitting(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const KycUpdateUser = (data, actions) => {
    UpdateKycUser(data).then((res) => {
      if (res.status === 200) {
        Refetch();
        handleClose();
        // setTostify({
        //   ...tostify,
        //   visible: true,
        //   key: Math.random().toString(36).substring(7),
        //   type: res && res.status === 200 ? "success" : "error",
        //   message: res.message,
        // });
      }
      actions.setSubmitting(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  // bug-kyc submit check
  const SubmitForm = (data, actions, actionState, upload, upload1) => {
    if (actionState === "edit") {
      KycUpdateUser(data, actions);
    } else {
      data.type = docType;
      KycAddUser(data, actions);
    }
  };
  return (
    <>
      <Alert setAlert={setAlert} {...alert} />
      <div className="table-kyc-modal">
        <AppBar className={classes.header60} id="modal-header ">
          <div
            id="add-user-header"
            className="d-flex align-items-center justify-content-between add-user-css"
          >
            <div>
              <h5 className="user-header-h5 mb-2">Manage Users</h5>
            </div>
            <Button
              id="coin-modal-close"
              className="btn-neutral mx-2 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center text-white border-radius-15px"
              onClick={() => {
                handleClose();
                setUpdatedRow({});
                setActionState("new");
              }}
            >
              <CloseIcon />
            </Button>
          </div>
        </AppBar>
      </div>
      <div className="table-kyc-container">
        <div
          className={`modal-wrapper ${classes.paper60}`}
          id="manage-users-modal"
        >
          <PerfectScrollbar
            options={{
              wheelPropagation: true,
            }}
          >
            <div className="d-flex justify-content-between">
              <div className="mx-auto w100">
                {actionState === "new" ? (
                  <StepperWizard
                    setUpdatedRow={setUpdatedRow}
                    updatedRow={updatedRow}
                    actionState={actionState}
                    SubmitForm={SubmitForm}
                    upload={upload}
                    upload1={upload1}
                    setUpload1={setUpload1}
                    setUpload={setUpload}
                    setDocsType={setDocsType}
                    passportImg={passportImg}
                    setPasspostImg={setPasspostImg}
                    cnicFront={cnicFront}
                    setCnicFront={setCnicFront}
                    cnicBack={cnicBack}
                    setCnicBack={setCnicBack}
                    drivingImg={drivingImg}
                    setDrivingImg={setDrivingImg}
                  />
                ) : (
                  <Tabs
                    Refetch={Refetch}
                    handleClose={handleClose}
                    setUpdatedRow={setUpdatedRow}
                    updatedRow={updatedRow}
                    actionState={actionState}
                    setActionState={setActionState}
                    kycDocs={kycDocs}
                  />
                )}
              </div>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </>
  );
};
export default Body;
