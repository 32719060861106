import { withStyles, makeStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";
export const useStyles = makeStyles((theme) => {
  return {
    // stepper style
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    stepper_main_cont: {
      padding: "5px 0px !important",
      background: "initial !important",
      color: "unset",
    },
    // stepper style end

    fieldContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(0.8),
      marginBottom: theme.spacing(0.8),
      width: "100%",
      border: "none",
      "&:focus": {
        "&:before": {
          borderBottom: "none",
        },
        "&:after": {
          borderBottom: "none",
        },
      },
      "& .MuiFormHelperText-root": {},
      "& .MuiInput-underline:before": {},
      "& .MuiInputLabel-root": {
        fontWeight: "900",
      },
    },
    bg: {
      padding: 20,
    },
    helperText: {},
    label_input: {
      fontWeight: "900",
    },
    input_field: {
      borderRadius: 4,
      border: "none",
      fontSize: 14,
      width: "100%",
    },
    selectField: {
      "&:before": {},
      "&:after": {},
    },
    selectFieldContainer: {
      marginTop: theme.spacing(0.8),
      marginBottom: theme.spacing(0.8),
    },
    promp: {
      fontSize: 12,
      textAlign: "center",
    },
    form_title: {
      fontSize: 18,
      fontWeight: "700",
      marginBottom: 12,
      marginTop: 10,
      letterSpacing: 1,
      alignSelf: "baseline",
    },
    switch_title: {
      fontSize: 20,
      marginBottom: 0,
      marginTop: 10,
      letterSpacing: 1,
      alignSelf: "baseline",
    },
    pre_tag: {
      margin: 0,
      fontFamily: "Roboto",
      display: "flex",
      alignItems: "center",
      fontSize: 16,
      fontWeight: "900",
    },

    buttons_cont: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    buttons_sub_cont: {
      marginLeft: "auto",
    },
    teamtextField: {
      width: "55%",
      margin: 0,
    },
    teamSelectField: {
      width: "30%",
    },
    iconSize: {
      fontSize: 20,
    },
  };
});

export const QontoConnector = withStyles(() => {
  return {
    alternativeLabel: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    active: {
      "& $line": {
        borderColor: "#f64b1c",
      },
    },
    completed: {
      "& $line": {
        borderColor: "#f64b1c",
      },
    },
    line: {
      borderColor: "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  };
})(StepConnector);

export const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: "#eaeaf0",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#eaeaf0",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

export const useColorlibStepIconStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: "#F5F6FA",
      zIndex: 1,
      border: "1px solid #f64b1c",
      color: "#f64b1c",
      borderColor: "#f64b1c",
      cursor: "pointer",
      width: 50,
      height: 50,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        width: 37,
        height: 37,
      },
    },
    active: {
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
      backgroundColor: "#f64b1c",
      color: "#fff",
    },
    completed: {
      backgroundColor: "#f64b1c",
      color: "#fff",
    },
  };
});

export const useQontoStepIconStyles = makeStyles((theme) => {
  return {
    root: {
      color: "#eaeaf0",
      display: "flex",
      height: 22,
      alignItems: "center",
    },
    active: {
      color: "#f64b1c",
    },
    completed: {
      color: "#f64b1c",
      zIndex: 1,
      fontSize: 18,
    },
  };
});
