import * as Yup from "yup";
export const createRows = (rowData) => {
  const rows = rowData.map((i, index) => {
    return {
      id: i._id,
      title: i.head1,
      head1: i.head1,
      description1: i.description1,
      head2: i.head2,
      subhead1: i.subhead1,
      subhead2: i.subhead2,
      description2: i.description2,
      status: i.status,
    };
  });
  return rows;
};
export const rowsPerPage = 10;
export const FaqsSliderListingTableHeadings = [
  {
    id: "titile",
    label: "Title",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "status",
    label: "Status",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "actions",
    label: "Actions",
    sort: true,
    class: "bg-body-light",
  },
];
export const sliderInitialValues = {
  head1: "",
  head2: "",
  description1: "",
  description2: "",
  subhead1: "",
  subhead2: "",
  status: false,
};
export const SliderSchema = Yup.object().shape({
  head1: Yup.string().required("Heading 1 is required!"),
  head2: Yup.string().required("Heading 2 is required!"),
  subhead1: Yup.string().required("Sub Heading 1 is required!"),
  description1: Yup.string().required("Description 1 is required!"),
  description2: Yup.string().required("Description 2 is required!"),
  subhead2: Yup.string().required("Sub Heading 2 is required!"),
});
export const SliderFormFields = [
  {
    field: "Heading 1",
    fieldName: "head1",
    place: "Enter Heading 1",
    type: "text",
  },
  {
    field: "Sub Heading 1",
    fieldName: "subhead1",
    place: "Enter Sub Heading 1",
    type: "text",
  },
  {
    field: "Description 1",
    fieldName: "description1",
    place: "Enter Description 1",
    type: "description",
  },
  {
    field: "Heading 2",
    fieldName: "head2",
    place: "Enter Heading 2",
    type: "text",
  },
  {
    field: "Sub Heading 2",
    fieldName: "subhead2",
    place: "Enter Sub Heading 2",
    type: "text",
  },
  {
    field: "Description 2",
    fieldName: "description2",
    place: "Enter Description 2",
    type: "description",
  },
  {
    field: "Status",
    fieldName: "status",
    place: "",
    type: "radio",
  },
];
export const setInitialValues = (updatedRow) => {
  const Values = {
    id: updatedRow.id ? updatedRow.id : "",
    head1: updatedRow.head1 ? updatedRow.head1 : "",
    head2: updatedRow.head2 ? updatedRow.head2 : "",
    description1: updatedRow.description1 ? updatedRow.description1 : "",
    description2: updatedRow.description2 ? updatedRow.description2 : "",
    subhead1: updatedRow.subhead1 ? updatedRow.subhead1 : "",
    subhead2: updatedRow.subhead2 ? updatedRow.subhead2 : "",
    status: updatedRow.title ? updatedRow.status : "",
  };
  return Values;
};
