import moment from "moment";
import {
  ETHEREUM_S,
  MMMM_Do_YYYY,
  //  TX_NOT_SUBMIT,
  h_mm_ss_a,
} from "../../helpers/constants";
import ActionGroup from "../Comps-Utils/ActionsButtonGroup";
import {
  TEXT_LEFT,
  TEXT_DARK,
  D_NONE__D_SM_TABLE_cell,
} from "../../helpers/CLASSES";

function numberWithCommas(x) {
  return parseFloat(x).toLocaleString(undefined, { maximumFractionDigits: 20 });
}

const TableRow = (props) => {
  const { row, index, transactionSubmitModalShowHandle } = props;
  return (
    <>
      <tr key={row.id + index} id={ETHEREUM_S + "-tr-" + index}>
        <td className={TEXT_LEFT}>
          <span>👤 {row.name}</span>
          <span style={{ fontSize: "10px", textTransform: "initial" }}>
            📧 {row.email}
          </span>
        </td>
        <td className={TEXT_LEFT}>
          <span className="expected_amount">
            <b>💸 Expected</b>: {numberWithCommas(row.expectedAmount)}{" "}
            {row.paymentMethod}
          </span>
          <span className="received_amount">
            <b>💰 Received</b>: {numberWithCommas(row.actualamount)}{" "}
            {row.paymentMethod}
          </span>
        </td>
        <td className={TEXT_LEFT}>
          <span>
            ⭕ {numberWithCommas(row.payableToken)} {row.tokens}
          </span>
          <span className={D_NONE__D_SM_TABLE_cell}>
            <span
              className={TEXT_DARK}
              style={{ fontSize: "10px", textTransform: "initial" }}
            >
              {" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {row.saleType}
            </span>
          </span>
        </td>
        {/* <td className={TEXT_LEFT}>
          <span className={D_NONE__D_SM_TABLE_cell}>
            <span className={TEXT_DARK}>{row.saleType}</span>
          </span>
        </td> */}

        {/* <td className={TEXT_LEFT}>
          <span className={D_NONE__D_SM_TABLE_cell}>
            <span className={TEXT_DARK}>{row.actualamount}</span>
          </span>
        </td>
        <td className={TEXT_LEFT}>
          <span className={D_NONE__D_SM_TABLE_cell}>
            <span className={TEXT_DARK}>{row.expectedamount}</span>
          </span>S
        </td> */}
        {/* <td className={TEXT_LEFT}>
          <span className={D_NONE__D_SM_TABLE_cell}>
            <span className={TEXT_DARK}>👛 {row.recevingAddress}</span>
          </span>
        </td> */}
        <td className={TEXT_LEFT}>
          <span>🕛 {moment(row.updatedAt).format(MMMM_Do_YYYY)}</span>
          <span>
            {" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            {moment(row.updatedAt).format(h_mm_ss_a)}
          </span>
        </td>
        <td className={TEXT_LEFT}>
          <span className={D_NONE__D_SM_TABLE_cell}>
            <span
              style={{
                background: row.statusBGColor,
                padding: "7px 10px",
                borderRadius: "20px",
                color: row.statusColor,
              }}
            >
              {row.status}
            </span>
          </span>
        </td>
        <td className="text-left table-btn-group">
          <>
            <ActionGroup
              disTransactionsubmit={row.statusCode == 2 ? true : false}
              row={row}
              transactionSubmitModalShowHandle={
                transactionSubmitModalShowHandle
              }
            />
          </>
        </td>
      </tr>
    </>
  );
};
export default TableRow;
