import { useState, useEffect, useContext, useCallback } from "react";
import {
  GetTransactionsByType,
  ApplyFilterTransaction,
} from "../../Services/Admin/Dashboard";
import { createRows, BitcoinTableHeadings } from "./helpers";
import TableRows from "./tablerow";
import Loader from "../Comps-Utils/ClimbingLoader";
import BitcoinTable from "../Comps-Utils/GenericTable";
import { ToasterContext } from "../../App";
import {
  SUCCESS,
  ERROR,
  BITCOIN_SEARCH_PLACEHOLDER,
  BITCOIN_TABLE_HEADING,
  BTC_C,
} from "../../helpers/constants";

import StaticDateRangePickerDemo from "../Filters/date";
import moment from "moment";
import RangeSlider from "../Filters/range";

import Tags from "../Filters/filters";
import { ClipLoader } from "react-spinners";
const Bitcoin = () => {
  const [filterValues, setFilterValues] = useState({
    type: "BTC",
    datefrom: "2022-01-01",
    dateto: moment(new Date()).format("YYYY-MM-DD"),
    lowprice: 0,
    highprice: 1000000,
    status: [2, 3, 4, 5],
  });
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [tostify, setTostify] = useContext(ToasterContext);
  const GetTransactionsService = useCallback(
    (type) => {
      setLoading(true);
      GetTransactionsByType(type).then((res) => {
        if (res.status === 200) {
          setTransactions(res.data);
          setLoading(false);
        } else {
          setLoading(false);
          setTostify({
            ...tostify,
            visible: true,
            key: Math.random().toString(36).substring(7),
            type: res && res.status === 200 ? { SUCCESS } : { ERROR },
            message: res.message,
          });
        }
      });
    },
    [tostify, setTostify]
  );
  useEffect(() => {
    GetTransactionsService(BTC_C);
    // eslint-disable-next-line
  }, []);
  const SetFilterData = (data, type) => {
    if (type === "date") {
      setFilterValues({
        ...filterValues,
        datefrom: moment(data[0]).format("YYYY-MM-DD"),
        dateto: moment(data[1]).format("YYYY-MM-DD"),
      });
    }
    if (type === "range") {
      setFilterValues({
        ...filterValues,
        lowprice: data[0],
        highprice: data[1],
      });
    }

    if (type === "status") {
      if (data.includes("6") || data.length === 0) {
        data = ["2", "3", "4", "5"];
      }
      setFilterValues({ ...filterValues, status: data });
    }
  };
  const GetFilteredData = () => {
    setLoadingFilter(true);
    ApplyFilterTransaction(filterValues).then((res) => {
      setLoadingFilter(false);
      setTransactions(res.data);
    });
  };
  return (
    <>
      <div className="mx-auto w95">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="filter-div row">
              <div className="col-lg-3 col-sm-6 col-12 mb-3">
                <h5>Filter Tx Status</h5>
                <div className="tag-filter">
                  <Tags
                    className="tag-filter-dropdown"
                    SetFilterData={SetFilterData}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 mb-3">
                <h5>Tx Date Range</h5>
                <div className="filter-input">
                  <StaticDateRangePickerDemo SetFilterData={SetFilterData} />
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 mb-3">
                <h5 className="mb-0">Tokens Quantity</h5>
                <div className="p-2 range-bar">
                  <RangeSlider SetFilterData={SetFilterData} />
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 mb-3">
                <button
                  className="btn btn-primary mt-btn filter-loader"
                  onClick={GetFilteredData}
                >
                  {loadingFilter ? (
                    <ClipLoader color="white" className="loader" />
                  ) : (
                    <span>Apply Filters</span>
                  )}
                </button>
              </div>
            </div>
            <div className="mt-3">
              <BitcoinTable
                tableHeading={BITCOIN_TABLE_HEADING}
                searchPlaceHolder={BITCOIN_SEARCH_PLACEHOLDER}
                loading={loading}
                data={transactions}
                TableRow={TableRows}
                createRows={createRows}
                headings={BitcoinTableHeadings}
                tostify={tostify}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Bitcoin;
