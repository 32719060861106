/* eslint-disable */
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export const makePaths = (rights) => {
  let pathNames = [];
  Object.values(rights).map((item) => {
    if (item.status === true) {
      pathNames.push({
        pathName: "/" + item.name,
        key: "/" + item.name,
        component: capitalizeFirstLetter(item.name),
      });
    }
  });
  return pathNames;
};
