import { useState, useEffect, useContext } from "react";
import Card from "@mui/material/Card";
import Alert from "../../../Alert";
import TableRows from "./Tablerow";
import Loader from "../../../Comps-Utils/ClimbingLoader";
import SliderTable from "../../../Comps-Utils/GenericTable";
import {
  GetCMSSliderListing,
  DeleteCMSSlider,
  UpdateStatusCMSSlider,
  AddCMSSliderListing,
  UpdateCMSSliderListing,
} from "../../../../Services/Admin/CMS/Slider";
import { ToasterContext } from "../../../../App";
import {
  sliderInitialValues,
  SliderSchema,
  setInitialValues,
  SliderFormFields,
  createRows,
  FaqsSliderListingTableHeadings,
} from "./helpers";
const SliderListing = () => {
  const [general, setGeneral] = useState([]);
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [id, setId] = useState();
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ visible: false });
  const [updatedRow, setUpdatedRow] = useState({});
  const [actionState, setActionState] = useState("new");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setActionState("new");
    setUpdatedRow({});
  };
  const GetCMSSliderLisitngService = () => {
    GetCMSSliderListing().then((res) => {
      if (res.status === 200) {
        setGeneral(res.data);
        setId(res.data[0]._id);
        setLoading(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const DeleteCMSSliderService = (id) => {
    DeleteCMSSlider(id).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const UpdateStatusCMSSliderService = (id, status) => {
    UpdateStatusCMSSlider(id, status).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
      }

      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };

  const AddCMSSliderLisitngService = (data) => {
    AddCMSSliderListing(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
        handleClose();
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const UpdateCMSSliderLisitngService = (data) => {
    UpdateCMSSliderListing(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
        handleClose();
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  useEffect(() => {
    GetCMSSliderLisitngService();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Alert setAlert={setAlert} {...alert} />;
      <Card className="w97-4 ml-3">
        {loading ? (
          <Loader />
        ) : (
          <SliderTable
            //states
            isModal={true}
            modalHeading={"Slider"}
            tableHeading={"Manage Sliders"}
            loading={loading}
            data={general}
            id={id}
            actionState={actionState}
            open={open}
            updatedRow={updatedRow}
            //setters
            setUpdatedRow={setUpdatedRow}
            setActionState={setActionState}
            setAlert={setAlert}
            setLoading={setLoading}
            setGeneral={setGeneral}
            //Handlers
            handleOpen={handleOpen}
            handleClose={handleClose}
            //Table Row Components
            TableRow={TableRows}
            //Helpers States &  Function
            createRows={createRows}
            headings={FaqsSliderListingTableHeadings}
            initialValues={sliderInitialValues}
            schema={SliderSchema}
            setInitialValues={setInitialValues}
            formFields={SliderFormFields}
            //Services
            updateKycStatus={UpdateStatusCMSSliderService}
            deleteRow={DeleteCMSSliderService}
            add={AddCMSSliderLisitngService}
            update={UpdateCMSSliderLisitngService}
            tostify={tostify}
          />
        )}
      </Card>
    </>
  );
};
export default SliderListing;
