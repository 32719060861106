import React from "react";
import Users from "../../../components/UsersProfileTable";
const UsersPage = () => {
  return (
    <>
      <Users />
    </>
  );
};

export default UsersPage;
