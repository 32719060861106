import Card from "@mui/material/Card";
import "./assets/css/vendor.bundle.css";
// import "./HomePage.module.css";
// import "./assets/css/style-zinnia.css";
import { useState, useEffect, useContext } from "react";
import HomePageSkelton from "../../components/Skeltons/HomePageSkelton";
import { ToasterContext } from "../../App";
import Header from "./Header";
import About from "./About";
import Why from "./Why";
import Benefits from "./Slider";
import TokenSale from "./Tokenomics";
import Tokens from "./Tokens/Tokens.js";
import Resources from "./Resources/Resources.js";
import Roadmap from "./Roadmap.js";
// import TokenForecast from "./TokenAllocation";
// import Documents from "./Documents/index";
// import Services from "./Servicess";
import Team from "./Teams";
// import Timeline from "./Timeline";
// import Partners from "./Partners";
// import FAQS from "./FAQS/index";
// import Contact from "./ContactUs";
import Footer from "./Footer";
// import "./assets/css/style-zinnia.css";
import { GetCMS } from "../../Services/Admin/CMS/Banner";
import {
  GetCMSHome,
  GetFAQHome,
  GetCMSLogosHome,
  GetSiteInfoHome,
  GetCMSTimelineListingHome,
  GetCMSTeamListingHome,
  GetCMSSliderListingHome,
  GetICOInfoHome,
} from "../../Services/HomePage";

const HomePage = () => {
  const [banner, setBanner] = useState([]);
  const [about, setAbout] = useState([]);
  const [icoInfo, setIcoInfo] = useState([]);
  const [slider, setSlider] = useState([]);
  // const [services, setServices] = useState([]);
  // const [logos, setLogos] = useState([]);
  // const [tokens, setTokens] = useState([]);
  const [why, setWhy] = useState([]);
  const [sliderListing, setSliderListing] = useState([]);
  // const [timeline, setTimeline] = useState([]);
  // const [timelineListing, setTimelineListing] = useState([]);
  const [team, setTeam] = useState([]);
  const [advisorListing, setAdvisorListing] = useState([]);
  const [leadershipListing, setLeaderhipListing] = useState([]);
  // const [faq, setFaq] = useState([]);
  // const [faqGeneral, setFaqGeneral] = useState([]);
  // const [faqKyc, setFaqKyc] = useState([]);
  const [documents, setDocuments] = useState({});
  // const [contact, setContact] = useState([]);
  // const [faqToken, setFaqToken] = useState([]);
  const [tokenomics, setTokenomics] = useState([]);
  const [siteInfo, setSiteInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const GetWholeCMS = () => {
    GetCMS().then((res) => {
      if (res.status === 200) {
        setDocuments(res?.data[0]?.document);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const GetSiteService = () => {
    GetSiteInfoHome().then((res) => {
      if (res.status === 200) {
        setSiteInfo(res.data[0]);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const GetCMSFAQDataService = (type) => {
    GetFAQHome("general").then((res) => {
      if (res.status === 200) {
        // setFaqGeneral(res.data);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
    GetFAQHome("kyc").then((res) => {
      if (res.status === 200) {
        // setFaqKyc(res.data);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
    GetFAQHome("token").then((res) => {
      if (res.status === 200) {
        // setFaqToken(res.data);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const GetCMSTimelineLisitngService = () => {
    GetCMSTimelineListingHome().then((res) => {
      if (res.status === 200) {
        // setTimelineListing(res.data);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const GetCMSTeamAdvisorLisitngService = () => {
    GetCMSTeamListingHome("advisor").then((res) => {
      if (res.status === 200) {
        setAdvisorListing(res.data);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const GetCMSTeamLeaderShipLisitngService = () => {
    GetCMSTeamListingHome("leadership").then((res) => {
      if (res.status === 200) {
        setLeaderhipListing(res.data);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const GetCMSSliderLisitngService = () => {
    GetCMSSliderListingHome().then((res) => {
      if (res.status === 200) {
        setSliderListing(res.data);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const GetCMSLogoService = () => {
    GetCMSLogosHome().then((res) => {
      if (res.status === 200) {
        // setLogos(res.data);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const GetCMSDataService = () => {
    setLoading(true);
    GetCMSHome().then((res) => {
      //
      if (res.status === 200) {
        setBanner(res.data[0].banner);
        setAbout(res.data[0].aboutico);
        setSlider(res.data[0].slider);
        setWhy(res.data[0].cmscommon);
        // setServices(res.data[0].services);
        // setTimeline(res.data[0].roadmap);
        setTeam(res.data[0].team);
        // setFaq(res.data[0].commonfaq);
        setTokenomics(res.data[0].tokens);
        // setTokens(res.data[0].tokens);
        // setDocuments(res.data[0].document);
        // setContact(res.data[0].contactus);
        setLoading(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const GetIcoService = () => {
    GetICOInfoHome().then((res) => {
      if (res.status === 200) {
        setIcoInfo(res.data[0]);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  useEffect(() => {
    GetCMSDataService();
    GetCMSLogoService();
    GetIcoService();
    GetWholeCMS();
    GetCMSSliderLisitngService();
    GetCMSTimelineLisitngService();
    GetCMSTeamAdvisorLisitngService();
    GetCMSTeamLeaderShipLisitngService();
    GetCMSFAQDataService();
    GetSiteService();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Card>
        {loading ? (
          <HomePageSkelton />
        ) : (
          <div className="nk-body body-wider mode-onepage">
            <div className="nk-wrap">
              <Header banner={banner} siteInfo={siteInfo} icoInfo={icoInfo} />
              <main className="nk-pages">
                <About about={about} />
                {/* <Partners logos={logos} /> */}
                <Benefits slider={slider} sliderListing={sliderListing} />
                <Why why={why} />
                {/* <Services servicesData={services} /> */}
                <TokenSale tokenomics={tokenomics} />
                {/* <TokenForecast /> */}
                {/* <Documents documents={documents} /> */}
                <Tokens />
                <Resources documents={documents} />
                <Roadmap />
                {/* <Timeline
                  timeline={timeline}
                  timelineListing={timelineListing}
                /> */}
                <Team
                  team={team}
                  advisorListing={advisorListing}
                  leadershipListing={leadershipListing}
                />
                {/* <FAQS
                  faq={faq}
                  general={faqGeneral}
                  kyc={faqKyc}
                  token={faqToken}
                />
                <Contact contact={contact} setTostify={setTostify} /> */}
              </main>
              <Footer />
            </div>
          </div>
        )}
      </Card>
    </>
  );
};
export default HomePage;
