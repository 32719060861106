import * as Yup from "yup";
export const DocumentsInitialValues = {
  mainhead: "",
  description: "",
};
export const CMSDocumentsFormFields = [
  {
    field: "Main Heading",
    fieldName: "mainhead",
    place: "Enter Main heading",
    type: "text",
  },
  {
    field: "Description",
    fieldName: "description",
    place: "Enter Description",
    type: "description",
  },
  {
    field: "White Paper (url)",
    fieldName: "whitepaper",
    place: "Paste white paper url here",
    type: "text",
  },
  {
    field: "One Pager (url)",
    fieldName: "onepage",
    place: "Paste one page url here",
    type: "text",
  },
];
export const DocumentsSchema = Yup.object().shape({
  mainhead: Yup.string().required("Heading is required!"),
  description: Yup.string().required("Description is required!"),
  whitepaper: Yup.string().required("url is required!"),
  onepage: Yup.string().required("url is required!"),
});
export const getInitialValues = (CurrentUser, id) => {
  return {
    id: id ? id : "",
    mainhead: CurrentUser.mainhead,
    description: CurrentUser.description,
    whitepaper: CurrentUser.whitepaper,
    onepage: CurrentUser.onepage,
  };
};
