import GFX from "../assets/images/referral-image.svg";
import { sanitize } from "dompurify";
const Why = (props) => {
  const { why } = props;
  return (
    <section className="section bg-white" id="why">
      {/* <div className="ui-shape ui-shape-s1"></div> */}
      <div className="container">
        <div className="nk-block nk-block-about pt-lg-5">
          <div className="row align-items-center gutter-vr-30px">
            <div className="col-lg-5 text-center text-lg-start">
              <div className="nk-block-text">
                <div className="oneball mb-4">
                  <h2
                    className="title title-semibold text-default"
                    data-delay=".1"
                  >
                    {why.mainhead}
                  </h2>
                </div>
                <div className="tc-dark" data-delay=".2">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitize(why.description),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div
                className="nk-block-img nk-block-img-s2 text-center text-lg-start betts-img "
                data-delay=".5"
              >
                <img src={GFX} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Why;
