import React, { lazy, Suspense, useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
// import { ClimbingBoxLoader } from "react-spinners";
import AlertToaster from "../components/Comps-Utils/Toast";
import { ThemeProvider } from "@material-ui/styles";
import MuiTheme from "../theme";
import { makePaths } from "./helpers";
import GenericRoutes from "./genericRoutes";
// import "../assets/css/dashmix.css";
import "../assets/css/dashmix.css";
export const LayoutContext = React.createContext({});
const Homepage = lazy(() => import("../UserDashboard/HomePage/HomePage"));

const AdminRoutes = (props) => {
  const { setTostify, tostify, rights } = props;
  const [sidebar, setSidebar] = useState([]);
  const location = useLocation();
  useEffect(() => {
    if (rights.sideBar) {
      let temp = makePaths(rights.sideBar);
      setSidebar(temp);
    }
  }, [rights]);
  useEffect(() => {
    tostify.dismissAll();
    setTostify({ ...tostify, visible: false, key: "", type: "", message: "" });
    // eslint-disable-next-line
  }, [location.pathname]);
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };
  const pageTransition = {
    type: "tween",
    ease: "linear",
    duration: 0.3,
  };
  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
        // setShowTostify(true)
      };
    }, []);
    return (
      <>
        <AnimatePresence>
          {
            show
            // && (
            //   <motion.div
            //     key="loading"
            //     initial={{ opacity: 0 }}
            //     animate={{ opacity: 1 }}
            //     exit={{ opacity: 0 }}
            //     transition={{ duration: 0.4 }}
            //   >
            //     <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
            //       <div className="d-flex align-items-center flex-column px-4">
            //         <ClimbingBoxLoader color={"#f64b1c"} loading={true} />
            //       </div>
            //       <div className="text-muted font-size-xl text-center pt-3">
            //         Please wait
            //       </div>
            //     </div>
            //   </motion.div>
            // )
          }
        </AnimatePresence>
      </>
    );
  };
  const TostifyWait = () => {
    const [showTostify, setShowTostify] = useState(false);
    useEffect(() => {
      let timeout = "";
      if (location.pathname === "/dashboard") {
        // eslint-disable-next-line no-use-before-define
        // eslint-disable-next-line
        let timeout = setTimeout(() => setShowTostify(true), 300);
      } else {
        // eslint-disable-next-line no-use-before-define
        // eslint-disable-next-line
        let timeout = setTimeout(() => setShowTostify(true), 300);
      }
      return () => {
        clearTimeout(timeout);
      };
    }, []);
    return (
      <>
        {showTostify && (
          <motion.div
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <AlertToaster setTostify={setTostify} {...tostify} />
          </motion.div>
        )}
      </>
    );
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <TostifyWait />
          <Switch>
            <Redirect exact from="/" to="/home" />
            <Route path={["/home"]}>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}
                  style={{ height: "100%" }}
                >
                  <Route path="/home" component={Homepage} />
                </motion.div>
              </Switch>
            </Route>

            {/* <Route path={["/dashboard"]}>
              <PresentationLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                    style={{ height: "100%" }}
                  >
                    <Route path="/dashboard" component={Dashboard} />
                  </motion.div>
                </Switch>
              </PresentationLayout>
            </Route> */}

            {sidebar && sidebar.length > 0 ? (
              <GenericRoutes
                sidebar={sidebar}
                location={location}
                pageVariants={pageVariants}
                pageTransition={pageTransition}
                rights={rights}
              />
            ) : null}
            <Route render={() => <Redirect to="/dashboard" />} />
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};
export default AdminRoutes;
