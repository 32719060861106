import * as React from "react";
import ModalTransactions from "./ModalTransactions";
import { useState, useEffect, useContext } from "react";
// import { Form, FormControl } from 'react-bootstrap'
import { FetchTransaction } from "../../Services/User/Tokens/token";
import Clip from "../../components/Comps-Utils/ClipLoader";
import { ToasterContext } from "../../App";
import moment from "moment";

import { ICOTokenName } from "../../Services/User/web3Calls/contractCall";
import { useLocation } from "react-router";
const Transactions = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalTrans, setModalTrans] = useState(0);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const transid = location?.state?.transid ? location.state.transid : "";
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const handleModal = (e, index) => {
    setModalTrans(index);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [transactionsObj, setTransactionObj] = useState([
    {
      txnno: "-",
      tokens: "-",
      expectedamount: "-",
      actualamount: "",
      from: "-",
      date: "-",
      status: "-",
      saletype: "-",
      paymentMethod: "-",
      bonus: "0.0",
      payableToken: 0,
      _id: "",
    },
  ]);
  useEffect(() => {
    fetchTokenName();
  }, []);

  const [rewardToken, setRewardToken] = useState("-");
  const fetchTokenName = async () => {
    const tokenSymbol = await ICOTokenName();
    setRewardToken(tokenSymbol);
  };
  useEffect(() => {
    fetchTransactions();
    // eslint-disable-next-line
  }, []);

  const fetchTransactions = async () => {
    setLoading(true);
    const trans = await FetchTransaction();
    if (trans.status === 210) {
      setLoading(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: "error",
        message: trans.message,
      });
    } else {
      setLoading(false);
      setTransactionObj(trans.data);
      if (transid) {
        let index = trans.data.findIndex((el) => el._id === transid);
        setModalTrans(index);
        setShowModal(true);
      }
    }
  };

  return (
    <>
      <div className="user-content">
        <div className="user-panel">
          <h2 className="user-panel-title">Transactions</h2>
          <table className="data-table tranx-table dataTable table-responsive tax-table">
            <thead>
              <tr>
                <th className="tranx-status"></th>
                <th className="tranx-no">
                  <span>TNX NO</span>
                </th>
                <th className="tranx-token text-center">
                  <span>Tokens</span>
                </th>
                <th className="tranx-amount">
                  <span>Amount</span>
                </th>
                <th className="tranx-from">
                  <span>From</span>
                </th>
                <th className="tranx-action"></th>
              </tr>
            </thead>

            {loading ? (
              <tr>
                <td
                  style={{ textAlign: "center", paddingTop: "20px" }}
                  colspan="6"
                >
                  <Clip color={"#f64b1c"} />
                </td>
              </tr>
            ) : (
              <>
                <tbody>
                  {transactionsObj.length > 0 ? (
                    <>
                      {transactionsObj.map((item, index) => {
                        return (
                          <tr className="odd">
                            <td className="tranx-status tranx-status-approved">
                              <span className="d-none">Approved</span>
                              <em className="ti ti-check"></em>
                            </td>
                            <td className="tranx-no">
                              <span className="mb-1">
                                {item.txnno ? item.txnno.substring(0, 5) : ""}
                                ....
                                {item.txnno ? item.txnno.substring(60, 65) : ""}
                              </span>
                              <span className="blue-span">
                                {moment(item.date).format("MMMM Do YYYY")}
                                <br></br>
                                {moment(item.date).format("h:mm:ss a")}
                              </span>
                            </td>
                            <td className="tranx-token text-center">
                              <span className="mb-1">{item.tokens}</span>
                              {rewardToken}
                            </td>
                            <td className="tranx-amount">
                              <span className="mb-1 elipse">
                                {item.actualamount}
                              </span>
                              {item.network == "USDT"
                                ? item.network
                                : item.paymentMethod}{" "}
                            </td>
                            <td className="tranx-from">
                              <span className="mb-1">
                                {item.from ? item.from.substring(0, 4) : ""}...
                                {item.from ? item.from.substring(38, 42) : ""}
                              </span>
                              <span className="blue-span">
                                {moment(item.date).format("MMMM Do YYYY")}
                                <br></br>
                                {moment(item.date).format("h:mm:ss a")}
                              </span>
                            </td>
                            <td className="tranx-action tranx-status">
                              <span onClick={(e) => handleModal(e, index)}>
                                <em className="ti ti-more-alt"></em>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td
                          style={{ textAlign: "center", paddingTop: "20px" }}
                          colspan="6"
                        >
                          <p>No Transactions Found</p>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </>
            )}
          </table>
        </div>
      </div>

      {showModal ? (
        <ModalTransactions
          data={showModal}
          callbackFunc={handleCloseModal}
          transactionsObj={transactionsObj[modalTrans]}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Transactions;
