import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { makeGraphData } from "../helpers";
import { Card } from "@material-ui/core";
import { ClimbingBoxLoader } from "react-spinners";
import {
  TRANSACTIONS,
  DAI_C,
  USDT_c,
  BUSD_C,
  TUSD_C,
  BTC_C,
  ETH_C,
  TRANSACTIONS_DATA,
  LINE_S,
} from "../../../helpers/constants";
const TransactionsGraph = (props) => {
  const { transactionData } = props;
  const [dates, setDates] = useState([]);
  const [filterData, setFilterData] = useState();
  const [dai, setDai] = useState();
  const [busd, setBusd] = useState();
  const [ust, setUst] = useState();
  const [tusd, setTusd] = useState();
  const [btc, setBtc] = useState();
  const [eth, setEth] = useState();
  //  const filterData =[30,40,60,80,90, 40, 25, 50, 49, 21, 70, 70, 51,30, 40, 25, 50, 49, 21, 70, 51]
  const [options, setOptions] = useState();
  useEffect(() => {
    makeGraphData(
      setDates,
      setOptions,
      transactionData,
      setFilterData,
      setDai,
      setBusd,
      setUst,
      setTusd,
      setBtc,
      setEth
    );
  }, [transactionData]);
  const series = [
    {
      name: TRANSACTIONS,
      data: filterData,
    },
    {
      name: DAI_C,
      data: dai,
    },
    {
      name: BUSD_C,
      data: busd,
    },
    {
      name: USDT_c,
      data: ust,
    },
    {
      name: TUSD_C,
      data: tusd,
    },
    {
      name: BTC_C,
      data: btc,
    },
    {
      name: ETH_C,
      data: eth,
    },
  ];
  return (
    <>
      <div className="p-0 transaction-report-graph">
        <Card>
          <div className="px-2 pb-0 pt-3">
            {options ? (
              <Chart
                dates={dates}
                options={options}
                series={series}
                filename={TRANSACTIONS_DATA}
                height="350px"
                type={LINE_S}
              />
            ) : (
              <div className="m-0 w-100 h-100 pb-5 d-flex justify-content-center align-items-center">
                <ClimbingBoxLoader color={"var(--first)"} />
              </div>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};
export default TransactionsGraph;
