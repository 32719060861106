import { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import CircularProgress from "@material-ui/core/CircularProgress";
import GeneralForm from "./GeneralForm";
import KycForm from "./KycForm";
import SettingsForm from "./SettingsForm";

const MainForm = (props) => {
  const {
    gotoNext,
    // loading,
    isSubmitting,
    setFieldValue,
    handleSubmit,
    validateForm,
    handleNext,
    handleBack,
    actionState,
    upload,
    upload1,
    setUpload1,
    setUpload,
    setDocsType,
    passportImg,
    setPasspostImg,
    cnicFront,
    setCnicFront,
    cnicBack,
    setCnicBack,
    drivingImg,
    setDrivingImg,
  } = props;
  const [docType, setDocType] = useState("");

  const handleNextForm = () => {
    if (gotoNext === 0) {
      // check validation of the form
      validateForm().then((res) => {
        if (res && Object.keys(res).length === 0) {
          handleNext();
        } else {
          handleSubmit();
        }
      });
    } else if (docType === "passport" && passportImg !== "" && gotoNext === 1) {
      handleNext();
    } else if (
      docType === "cnic" &&
      cnicFront !== "" &&
      cnicBack !== "" &&
      gotoNext === 1
    ) {
      handleNext();
    } else if (docType === "license" && drivingImg !== "" && gotoNext === 1) {
      handleNext();
    }
  };
  useEffect(() => {
    // handleNextForm();
    setDocsType(docType);
    // eslint-disable-next-line
  }, [docType]);
  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off">
        {gotoNext === 0 && <GeneralForm actionState={actionState} {...props} />}
        {gotoNext === 1 && (
          <KycForm
            setFieldValue={setFieldValue}
            actionState={actionState}
            upload={upload}
            setDocType={setDocType}
            docType={docType}
            upload1={upload1}
            setUpload1={setUpload1}
            setUpload={setUpload}
            passportImg={passportImg}
            setPasspostImg={setPasspostImg}
            cnicFront={cnicFront}
            setCnicFront={setCnicFront}
            cnicBack={cnicBack}
            setCnicBack={setCnicBack}
            drivingImg={drivingImg}
            setDrivingImg={setDrivingImg}
            {...props}
          />
        )}
        {(gotoNext === 2 || gotoNext === 3) && (
          <SettingsForm actionState={actionState} {...props} />
        )}
        <div
          className={
            gotoNext === 0
              ? "w-100 d-flex justify-content-between align-items-center mt-n4 "
              : "w-100 d-flex justify-content-between align-items-center my-3"
          }
        >
          <div className="">
            {gotoNext > 0 && (
              <Button
                type="button"
                // disabled={loading}
                onClick={handleBack}
                className="bg-primary font-weight-bold text-white font-size-sm m-0 w-auto"
              >
                {<FaArrowLeft />}&nbsp;Previous
              </Button>
            )}
          </div>
          <div className="ml-auto">
            {/* {!(gotoNext === 0 || gotoNext === 2) ? (
              <Button
                id="skip-and-register"
                // disabled={loading || !values.consent}
                type="button"
                onClick={() => handleSubmit()}
                className="btn-purple font-weight-bold font-size-sm m-0 w-auto mr-2">
                {!loading ? (
                  'Skip and Register'
                ) : (
                  <CircularProgress color="secondary" size={24} />
                )}
              </Button>
            ) : (
              ''
            )} */}
            {gotoNext === 2 ? (
              <Button
                id="register-btn"
                // disabled={loading || !values.consent}
                disabled={isSubmitting}
                type="button"
                onClick={() => handleSubmit()}
                className="bg-primary font-weight-bold text-white font-size-sm m-0 w-auto"
              >
                {!isSubmitting ? (
                  "Register"
                ) : (
                  <CircularProgress color="secondary" size={24} />
                )}
              </Button>
            ) : (
              <Button
                id="next-step"
                type="button"
                // disabled={loading || !values.consent}
                disabled={
                  (gotoNext === 1 && !(passportImg || drivingImg)) ||
                  (cnicFront && !cnicBack) ||
                  (!cnicFront && cnicBack)
                    ? true
                    : false
                }
                className={
                  gotoNext === 0
                    ? " bg-primary font-weight-bold text-white font-size-sm m-0 w-auto mr-2"
                    : " bg-primary font-weight-bold text-white font-size-sm m-0 w-auto"
                }
                onClick={() => handleNextForm()}
              >
                <FaArrowRight />
                &nbsp;Next
              </Button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};
export default MainForm;
