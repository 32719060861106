export const createRows = (rowData) => {
  const rows = rowData.map((i, index) => {
    return {
      userId: index + 1,
      name: i.username,
      email: i.email,
      address1: i.address1,
      address2: i.address2,
      city: i.city,
      country: i.country,
    };
  });
  return rows;
};
export const rowsPerPage = 10;
export const UserProfileTableHeadings = [
  {
    id: "id",
    label: "User Id",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "name",
    label: "User Name",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "userEmail",
    label: "User Email",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "address",
    label: "Address",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "city",
    label: "City",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "country",
    label: "Country",
    class: "bg-body-light",
  },
];
