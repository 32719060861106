import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { green } from "@mui/material/colors";
const label = { inputProps: { "aria-label": "controlled" } };
export default function IconCheckboxes(props) {
  const { check, id, subtype, role, service } = props;
  const [checked, setChecked] = useState(check ? check : false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    service(id, subtype, event.target.checked, role);
  };
  return (
    <div className="d-flex ml-4">
      <Checkbox
        {...label}
        checked={checked}
        onChange={handleChange}
        icon={<CancelOutlinedIcon color="error" />}
        checkedIcon={<CheckCircleOutlinedIcon />}
        sx={{
          color: green[800],
          "&.Mui-checked": {
            color: green[600],
            "&:hover": {
              color: "orange",
              backgroundColor: "white",
            },
          },
        }}
      />
    </div>
  );
}
