import { useState, useEffect, useContext } from "react";
import Card from "@mui/material/Card";
import Alert from "../../../Alert";
import TableRows from "./Tablerow";
import Loader from "../../../Comps-Utils/ClimbingLoader";
import AdvisorListingTable from "../../../Comps-Utils/GenericTable";
import {
  GetCMSTeamAdvisorListing,
  AddCMSTeamAdvisorLisitng,
  UpdateCMSTeamAdvisorListing,
  DeleteCMSTeamLisiting,
  UpdateCMSTeamStatus,
} from "../../../../Services/Admin/CMS/Team";
import { ToasterContext } from "../../../../App";
import {
  teamAdvisorsInitialValues,
  TeamAdvisorSchema,
  setInitialValues,
  TeamAdvisorFormFields,
  createRows,
  AdvisorListingTableHeadings,
} from "./helpers";
const SliderListing = () => {
  const [general, setGeneral] = useState([]);
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [id, setId] = useState();
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ visible: false });
  const [updatedRow, setUpdatedRow] = useState({});
  const [actionState, setActionState] = useState("new");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setActionState("new");
    setUpdatedRow({});
  };
  const GetCMSTeamAdvisorLisitngService = () => {
    GetCMSTeamAdvisorListing("advisor").then((res) => {
      if (res.status === 200) {
        setGeneral(res.data);
        setId(res.data[0]?._id);
        setLoading(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const DeleteCMSTeamAdvisorService = (id) => {
    DeleteCMSTeamLisiting(id).then((res) => {
      if (res.status === 200 && res.code === 200) {
        GetCMSTeamAdvisorLisitngService();
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const UpdateStatusTeamAdvisorService = (id, status, type) => {
    UpdateCMSTeamStatus(id, status, type).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const AddCMSTeamAdvisorLisitngService = (data) => {
    AddCMSTeamAdvisorLisitng(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);

        handleClose();
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const UpdateCMSTeamAdvisorLisitngService = (data) => {
    UpdateCMSTeamAdvisorListing(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
        handleClose();
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  useEffect(() => {
    GetCMSTeamAdvisorLisitngService();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Alert setAlert={setAlert} {...alert} />;
      <Card className="w97-4 ml-3">
        {loading ? (
          <Loader />
        ) : (
          <AdvisorListingTable
            //states
            isModal={true}
            modalHeading={"Advisory board"}
            tableHeading={"Manage Advisory board"}
            loading={loading}
            data={general}
            id={id}
            actionState={actionState}
            open={open}
            updatedRow={updatedRow}
            //setters
            setUpdatedRow={setUpdatedRow}
            setActionState={setActionState}
            setAlert={setAlert}
            setLoading={setLoading}
            setGeneral={setGeneral}
            //Handlers
            handleOpen={handleOpen}
            handleClose={handleClose}
            //Table Row Components
            TableRow={TableRows}
            //Helpers States &  Function
            createRows={createRows}
            headings={AdvisorListingTableHeadings}
            initialValues={teamAdvisorsInitialValues}
            schema={TeamAdvisorSchema}
            setInitialValues={setInitialValues}
            formFields={TeamAdvisorFormFields}
            //Services
            updateKycStatus={UpdateStatusTeamAdvisorService}
            deleteRow={DeleteCMSTeamAdvisorService}
            add={AddCMSTeamAdvisorLisitngService}
            update={UpdateCMSTeamAdvisorLisitngService}
            tostify={tostify}
          />
        )}
      </Card>
    </>
  );
};
export default SliderListing;
