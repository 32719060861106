import { createTheme } from "@material-ui/core";
// const light = {
//     palette: {
//       mode: "light",
//     },
//   };

//   const dark = {
//     palette: {
//       mode: "dark",
//     },
//   };

const MuiTheme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#f64b1c",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#11cb5f",
    },
  },
});
export default MuiTheme;
