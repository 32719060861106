import moment from "moment";
//search binding
export const searchBinding = (setSearchBar, setRowData, data, place) => {
  setSearchBar({
    placeholder: place,
    onSearch: (searchedVal) => OnSearch(searchedVal, setRowData, data),
  });
};
//Search Handler
export const OnSearch = (searchedVal, setRowData, data) => {
  if (searchedVal.length > 0) {
    const filteredRows = data.filter((row) => {
      let match = false;
      // match name
      if (!match && row.name) {
        match = row.name.toLowerCase().includes(searchedVal.toLowerCase());
      }
      // match userName
      if (!match && row.username) {
        match = row.username.toLowerCase().includes(searchedVal.toLowerCase());
      }
      // match firstname
      if (!match && row.firstname) {
        match = row.firstname.toLowerCase().includes(searchedVal.toLowerCase());
      }
      // match lastname
      if (!match && row.lastname) {
        match = row.lastname.toLowerCase().includes(searchedVal.toLowerCase());
      }
      // match useremail
      if (!match && row.useremail) {
        match = row.useremail.toLowerCase().includes(searchedVal.toLowerCase());
      }
      // match useremail
      if (!match && row.email) {
        match = row.email.toLowerCase().includes(searchedVal.toLowerCase());
      }
      // match designation
      if (!match && row.designation) {
        match = row.designation
          .toLowerCase()
          .includes(searchedVal.toLowerCase());
      }
      // match slider heading
      if (!match && row.head1) {
        match = row.head1.toLowerCase().includes(searchedVal.toLowerCase());
      }
      //   // match emailStatus
      //   if (!match && row.emailStatus) {
      //     match = row.emailStatus.toLowerCase().includes(searchedVal.toLowerCase());
      //   }
      // match kycstatus
      if (!match && row.kycstatus) {
        match = row.kycstatus.toLowerCase().includes(searchedVal.toLowerCase());
      }
      // match paymentMethod
      if (!match && row.paymentMethod) {
        match = row.paymentMethod
          .toLowerCase()
          .includes(searchedVal.toLowerCase());
      }
      // match title
      if (!match && row.title) {
        match = row.title.toLowerCase().includes(searchedVal.toLowerCase());
      }
      // match title
      // if (!match && row.status.toString().length > 0) {
      //   match = row.status
      //     .toString()
      //     .toLowerCase()
      //     .includes(searchedVal.toLowerCase());
      // }
      // match dealine year
      if (!match && row.deadline) {
        match = moment(row.deadline)
          .format("MMMM DD YYYY")
          .toString()
          .toLowerCase()
          .includes(searchedVal.toLowerCase());
      }
      // match dealine month
      if (!match && row.deadline) {
        match = moment(row.deadline)
          .format("MMMM")
          .toString()
          .toLowerCase()
          .includes(searchedVal.toLowerCase());
      }
      //match wallet address
      //   if (!match && row.payableToken) {
      //     match = row.payableToken
      //       .toLowerCase()
      //       .includes(searchedVal.toLowerCase());
      //   }
      return match;
    });
    setRowData(filteredRows);
  } else {
    setRowData(data);
  }
};
