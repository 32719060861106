import { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import { Link } from "react-router-dom";
export default function SkeletonColor() {
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return (
    <>
      {/* <Box
        sx={{
          bgcolor: "#121212",
          p: 8,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Skeleton
          sx={{ bgcolor: "grey.900" }}
          variant="rect"
          width={210}
          height={118}
        />
      </Box> */}
      <div className="nk-body body-wider mode-onepage">
        <div className="nk-wrap">
          <header
            className={
              offset > 0
                ? "nk-header page-header is-transparent is-sticky is-shrink has-fixed"
                : "nk-header page-header is-transparent is-sticky is-shrink"
            }
            id="header"
          >
            <div className="header-main">
              <div className="header-container header-container-s1">
                <div className="header-wrap">
                  <div className="header-logo logo ">
                    <Skeleton variant="circular" width={40} height={40} />
                  </div>
                  <div className="header-nav-toggle">
                    <Skeleton variant="text" />
                  </div>
                  <div className="header-navbar header-navbar-s1">
                    <nav
                      className="header-menu justify-content-between"
                      id="header-menu"
                    >
                      <ul className="menu  remove-animation">
                        <li className="menu-item">
                          <span className="menu-link nav-link">
                            {" "}
                            <Skeleton width={50} height={40} variant="text" />
                          </span>
                        </li>
                        <li className="menu-item">
                          <span className="menu-link nav-link">
                            <Skeleton width={50} height={40} variant="text" />
                          </span>
                        </li>
                        <li className="menu-item">
                          <span className="menu-link nav-link">
                            <Skeleton width={50} height={40} variant="text" />
                          </span>
                        </li>
                        <li className="menu-item">
                          <span className="menu-link nav-link">
                            <Skeleton width={50} height={40} variant="text" />
                          </span>
                        </li>
                        <li className="menu-item">
                          <span className="menu-link nav-link">
                            <Skeleton width={50} height={40} variant="text" />
                          </span>
                        </li>
                        <li className="menu-item">
                          <span className="menu-link nav-link">
                            <Skeleton width={50} height={40} variant="text" />
                          </span>
                        </li>
                        <li className="menu-item">
                          <span className="menu-link nav-link">
                            <Skeleton width={50} height={40} variant="text" />
                          </span>
                        </li>
                        <li className="menu-item">
                          <span className="menu-link nav-link">
                            <Skeleton width={50} height={40} variant="text" />
                          </span>
                        </li>
                      </ul>
                      <ul
                        className="menu-btns align-items-center  remove-animation"
                        data-delay=".85"
                      >
                        {sessionStorage.getItem("token") ? (
                          <li>
                            <div className="btn btn-md btn-round btn-outline btn-auto">
                              <Skeleton width={80} height={40} variant="text" />
                            </div>
                          </li>
                        ) : (
                          <>
                            <li>
                              <Link
                                to={"/login"}
                                className="btn btn-md btn-round btn-outline btn-auto"
                              >
                                <span>Login</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={"/signup"}
                                className="btn btn-md btn-round btn-outline btn-auto"
                              >
                                <span>Register</span>
                              </Link>
                            </li>
                          </>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="header-banner bg-theme-grad-alt" id="banner">
              <div className="header-banner bg-theme-grad-alt" id="banner">
                <div className="nk-banner has-ovm has-mask">
                  <div className="banner banner-fs banner-single banner-with-token-box-s1">
                    <div className="ui-shape ui-shape-header ui-shape-light"></div>
                    <div className="banner-wrap">
                      <div className="container">
                        <div className="row align-items-center justify-content-center">
                          <div className="col-sm-10 col-md-10 col-xl-9">
                            <div className="banner-caption tc-light text-center">
                              <div className="cpn-title " data-delay="1.25">
                                <h1 className="title title-xl-s2 title-semibold">
                                  <span className="d-flex justify-content-center">
                                    <Skeleton
                                      width={700}
                                      height={100}
                                      variant="text"
                                    />
                                  </span>
                                  <span className="d-flex justify-content-center">
                                    <Skeleton
                                      width={400}
                                      height={100}
                                      variant="text"
                                    />
                                  </span>
                                </h1>{" "}
                              </div>
                              <div className="cpn-text cpn-text-center">
                                <span className="d-flex justify-content-center">
                                  <Skeleton
                                    width={600}
                                    height={50}
                                    variant="text"
                                  />
                                </span>
                              </div>
                              <div className="cpn-btns">
                                <ul
                                  className="btn-grp justify-content-center "
                                  data-delay="1.45"
                                >
                                  <li>
                                    <Link
                                      to={"/signup"}
                                      className="btn btn-md btn-round btn-with-icon btn-light"
                                    >
                                      <span>
                                        <Skeleton
                                          width={200}
                                          height={30}
                                          variant="text"
                                        />
                                      </span>
                                      <em className="icon fas fa-angle-double-right"></em>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="gap-2x"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="banner-social-wrap text-center "
                        data-animate="fadeIn"
                        data-delay="1.55"
                      >
                        <ul className="banner-social banner-social-vertical">
                          <li>
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={20}
                            />
                          </li>
                          <li>
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={20}
                            />
                          </li>
                          <li>
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={20}
                            />
                          </li>
                          <li>
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={20}
                            />
                          </li>
                          <li>
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={20}
                            />
                          </li>
                          <li>
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={20}
                            />
                          </li>
                          <li>
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={20}
                            />
                          </li>
                          <li>
                            <Skeleton
                              variant="circular"
                              width={20}
                              height={20}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="nk-ovm mask-b mask-contain-bottom"></div>
                </div>
                <div
                  id="particles-bg"
                  className="particles-container particles-bg"
                ></div>
              </div>
              {/* <PreSale icoInfo={icoInfo} /> */}
              <div
                id="particles-bg"
                className="particles-container particles-bg"
              ></div>
            </div>
          </header>
        </div>
      </div>
    </>
  );
}
