export const AdminSideBarSubItems = [
  {
    title: "Dashboard",
    url: "/dashboard",
    match: "/dashboard",
    icon: "fa-solid fa-table-columns",
  },

  // {
  //     title: "Timeline",
  //     url: '/manage_team',
  //     icon:"si-clock"
  //   } ,
  //   {
  //     title: "CMS",
  //     url: '/cms',
  //     icon:"si-menu"
  //   } ,
  {
    title: "Profile",
    url: "",
    match: "/profile",
    icon: "fa-solid fa-user",
  },
  {
    title: "Admins",
    url: "/admins",
    match: "/admins",
    icon: "fa-solid fa-people-group",
  },
  {
    title: "Permissions",
    url: "/permissions",
    match: "/permissions",
    icon: "fa-solid fa-list-check",
  },
  {
    title: "Kyc",
    url: "/kyc",
    match: "/kyc",
    icon: "fa-solid fa-address-card",
  },
  {
    title: "Bitcoin Transactions",
    url: "/bitcoin",
    match: "/bitcoin",
    icon: "fa-brands fa-bitcoin",
  },
  {
    title: "Ethereum Transactions",
    url: "/ethereum",
    match: "/ethereum",
    icon: "fa-brands fa-ethereum",
  },
  {
    title: "Stable Transactions",
    url: "/stable",
    match: "/stable",
    icon: "fa-solid fa-coins",
  },
  {
    title: "Email Templates",
    url: "/emailTemplates",
    match: "/emailTemplates",
    icon: "fa-solid fa-envelope",
  },
  {
    title: "Airdrop",
    url: "/airdrop",
    match: "/airdrop",
    icon: "fas fa-parachute-box",
  },
  {
    title: "Credit Card",
    url: "/creditcard",
    match: "/creditcard",
    icon: "fa-solid fa-coins",
  },
  {
    title: "CMS",
    url: "/cms",
    match: "/cms",
    icon: "fa-solid fa-list-check",
  },
  {
    title: "ICO Settings",
    url: "/icosettings",
    match: "/icosettings",
    icon: "fa-solid fa-gear",
  },
  {
    title: "Site Settings",
    url: "/sitesettings",
    match: "/sitesettings",
    icon: "fa-solid fa-gears",
  },
  {
    title: "Presale",
    url: "/presaleuser",
    match: "/presaleuser",
    icon: "fa-solid fa-shield",
  },
  // {
  //     title: "FAQ'S",
  //     url: '/faqs',
  //     icon:"si-question"
  // },
  // {
  //     title: "Logout",
  //     url: '/logout',
  //     icon:"si-logout"
  // },
];

export const AdminSideBarProfileSubMenu = [
  {
    title: "Admin Profile",
    url: "/profile",
    icon: "fa-solid fa-id-badge",
  },
  // {
  //   title: "Users",
  //   url: "/users",
  //   icon: "si-users",
  // },
  {
    title: "Password",
    url: "/password",
    icon: "fa-solid fa-shield",
  },
];

export const AdminSideBarCMSSubMenu = [
  {
    title: "Banner",
    url: "/banner",
    icon: "fa-brands fa-bimobject",
  },
  {
    title: "Logos",
    url: "/logos",
    icon: "fa-solid fa-icons",
  },
  {
    title: "Why Is BETTS ICO",
    url: "/why",
    icon: "fa-solid fa-circle-question",
  },
  {
    title: "About ICO",
    url: "/aboutico",
    icon: "fa-solid fa-circle-info",
  },
  {
    title: "Services",
    url: "/services",
    icon: "fab fa-stripe-s",
  },
  {
    title: "Timeline",
    url: "/timeline",
    icon: "fa-solid fa-timeline",
  },
  {
    title: "Tokens",
    url: "/tokens",
    icon: "fa-solid fa-t",
  },
  {
    title: "Slider",
    url: "/slider",
    icon: "fa-solid fa-sliders",
  },
  {
    title: "Manage Team",
    url: "/team",
    icon: "fa-solid fa-people-group",
  },
  {
    title: "FAQS",
    url: "/faqs",
    icon: "fa-solid fa-clipboard-question",
  },
  {
    title: "Contact Us",
    url: "/contactus",
    icon: "fa-solid fa-headset",
  },
  {
    title: "Our Documents",
    url: "/documents",
    icon: "fa-solid fa-file-lines",
  },
];

export const AdminSideBarCMSSubMenuFaqs = [
  {
    title: "FAQS",
    url: "/faqs",
    icon: "fa-solid fa-clipboard-question",
  },
  {
    title: "General Listing",
    url: "/general",
    icon: "fa-solid fa-table-list",
  },
  {
    title: "Pre Ico Listing",
    url: "/preico",
    icon: "fa-solid fa-table-list",
  },
  {
    title: "Tokens Listing",
    url: "/tokenlisting",
    icon: "fa-solid fa-table-list",
  },
];

export const AdminSideBarCMSSubMenuSlider = [
  {
    title: "Slider",
    url: "/slider",
    icon: "fa-solid fa-sliders",
  },
  {
    title: "Slider Listing",
    url: "/listing",
    icon: "fa-solid fa-table-list",
  },
];

export const AdminSideBarCMSSubMenuTimeline = [
  {
    title: "Timeline",
    url: "/timeline",
    icon: "fa-solid fa-timeline",
  },
  {
    title: "Timeline Listing",
    url: "/timelinelisting",
    icon: "fa-solid fa-table-list",
  },
];

export const AdminSideBarCMSSubMenuManageTeam = [
  {
    title: "Team",
    url: "/team",
    icon: "fa-solid fa-people-group",
  },
  {
    title: "Leadership Listing",
    url: "/leadership",
    icon: "fa-solid fa-table-list",
  },
  {
    title: "Advisor Board Listing",
    url: "/advisor",
    icon: "fa-solid fa-table-list",
  },
];
