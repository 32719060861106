import Skeleton from "@material-ui/lab/Skeleton";
import { Table, TableContainer } from "@material-ui/core";
const SkeltonTable = (props) => {
  const { header, rows } = props;
  return (
    <div className="mt-3 block block-rounded block-bordered">
      <div className="block-header block-header-default bg-zinnia-grad-alt">
        <h5 className="block-title">
          <span className="">
            {" "}
            <Skeleton height={50} width={200} />
          </span>
        </h5>
        <div className="mr-1 circle d-flex ">
          <Skeleton
            className="circle mr-1"
            variant="circle"
            width={25}
            height={25}
          />
        </div>
        <div className="mr-1 circle d-flex ">
          <Skeleton
            className="circle mr-1"
            variant="circle"
            width={25}
            height={25}
          />
        </div>
      </div>
      <TableContainer>
        <Table className="table m-0">
          <thead
            colSpan={"5"}
            className="thead-light text-capitalize font-size-sm font-weight-bold border-top"
          >
            <tr
              key={
                "skelton-table-tr-" + Math.random().toString(36).substring(7)
              }
            >
              {header.map((i, index) => {
                return (
                  <th
                    key={
                      "skelton-table-th" +
                      index +
                      Math.random().toString(36).substring(7)
                    }
                  >
                    <div className="d-flex">
                      <Skeleton
                        style={{ background: "#fab7b7" }}
                        variant="rect"
                        height={50}
                        width={150}
                      />
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {rows.map((i, index) => {
              return (
                <tr
                  key={
                    "skelton-table-tr-" +
                    Math.random().toString(36).substring(7) +
                    index
                  }
                >
                  <td className="text-left ">
                    <Skeleton height={50} width={150} />
                  </td>
                  <td className="text-left ">
                    <Skeleton height={50} width={150} />
                  </td>
                  <td className="text-left ">
                    <Skeleton height={50} width={150} />
                  </td>
                  <td className="text-left ">
                    <Skeleton height={50} width={150} />
                  </td>
                  <td className="text-left ">
                    <Skeleton height={50} width={150} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>
      <div className="divider" />
      <div className=" px-4 py-3 d-flex justify-content-center">
        <div className="mr-1 circle d-flex ">
          <Skeleton
            className="circle mr-1"
            variant="circle"
            width={25}
            height={25}
          />
        </div>
        <div className="mr-1 circle d-flex ">
          <Skeleton className="mr-1" variant="circle" width={25} height={25} />
        </div>
        <div className="mr-1 circle d-flex ">
          <Skeleton className="mr-1" variant="circle" width={25} height={25} />
        </div>
        <div className="mr-1 circle d-flex ">
          <Skeleton className="mr-1" variant="circle" width={25} height={25} />
        </div>
        <div className="mr-1 circle d-flex ">
          <Skeleton
            className="circle mr-1"
            variant="circle"
            width={25}
            height={25}
          />
        </div>
      </div>
    </div>
  );
};
export default SkeltonTable;
