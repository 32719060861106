import React from "react";
import KYC from "../../../components/Kyc";
const KYCPage = () => {
  return (
    <>
      <KYC />
    </>
  );
};

export default KYCPage;
