import ChangePassword from "./password/change_password";
import Enable2FA from "./enable2FA.jsx";

function Security() {
  return (
    <>
      <div className="user-content">
        <div className="user-panel">
          <h2 className="user-panel-title">Security Settings</h2>
          <p>
            You can control your password and account-access setting and toos
            that let you safe, protect your account.
          </p>
          <ul className="nav nav-tabs nav-tabs-line" role="tablist">
            {/* <li className="nav-item">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                href="#security"
              >
                Security
              </a>
            </li> */}
            <li className="nav-item">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                href="#changepassword"
              >
                Change Password
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#factor2">
                Two Factor Authentication
              </a>
            </li>
          </ul>
          <div className="tab-content" id="security-opt-tab">
            <div className="tab-pane fade" id="security">
              <h5 className="user-panel-subtitle">Genaral Security Options</h5>
              <div className="gaps-1x"></div>
              <ul className="notification-list">
                <li className="justify-content-start guttar-16px">
                  <div>
                    <input
                      className="input-switch"
                      type="checkbox"
                      id="activitylog"
                    />
                    <label htmlFor="activitylog"></label>
                  </div>
                  <div>
                    <span>Save my Activities Log</span>
                  </div>
                </li>
                <li className="justify-content-start guttar-16px">
                  <div>
                    <input
                      className="input-switch"
                      type="checkbox"
                      id="passchange"
                    />
                    <label htmlFor="passchange"></label>
                  </div>
                  <div>
                    <span>Confirm me through email before password change</span>
                  </div>
                </li>
                <li className="justify-content-start guttar-16px">
                  <div>
                    <input
                      className="input-switch"
                      type="checkbox"
                      id="tokwith"
                    />
                    <label htmlFor="tokwith"></label>
                  </div>
                  <div>
                    <span>Ask me password before token withdraw</span>
                  </div>
                </li>
              </ul>
              <div className="gaps-2x"></div>
              <div className="sap"></div>
              <div className="gaps-4x"></div>
              <div className="note note-plane note-danger">
                <em className="fas fa-info-circle"></em>
                <p>
                  Important! In case of loss of access to the mobile
                  application, you can regain it using mobile number that
                  specified in your profile. If you donot save that, we will not
                  able to help you to regain.
                </p>
              </div>
              <div className="gaps-4x"></div>
              <div className="d-flex justify-content-between align-items-center">
                <span></span>
                <span className="color-success">
                  <em className="ti ti-check-box"></em> Update Settings
                </span>
              </div>
            </div>
            <div className="tab-pane fade  active show" id="changepassword">
              <ChangePassword />
            </div>
            <div className="tab-pane fade" id="factor2">
              <Enable2FA />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Security;
