import React from "react";
import CMS from "../../../components/CMS/FAQS/General";
const CMSPage = () => {
  return (
    <>
      <CMS />
    </>
  );
};
export default CMSPage;
