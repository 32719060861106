import { API } from "../apiConfig";
import { ParseError, getAuthHeader } from "../../../Config/utils";
import { TokenErr } from "../../../Config/tokenErr";

export const GetPreSaleUsers = async () => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  let response = API.get("presale/", headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
          code: response.code,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};

export const AddPreSaleUsers = async (data) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  let response = API.post("presale/add", data, headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.msg,
            code: response.code,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};
export const DeletePreSaleUsers = async (data) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  let response = API.put("presale/delete", data, headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.msg,
            code: response.code,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};
