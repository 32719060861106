import React from "react";
import Faqs from "../../../components/CMS/FAQS/Faqs";
const CMSPage = () => {
  return (
    <>
      <Faqs />
    </>
  );
};
export default CMSPage;
