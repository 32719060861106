import axios from "axios";

let API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
let BlockcypherAPIBitcoin = axios.create({
  baseURL: process.env.REACT_APP_BITCOIN_API_URL,
});

let APIEthereumBaseURL = axios.create({
  baseURL: process.env.REACT_APP_ETH_API_URL,
});

let Config = (token = sessionStorage.getItem("token")) => {
  return {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
};

let id = localStorage.getItem("id");

export { API, Config, id, BlockcypherAPIBitcoin, APIEthereumBaseURL };
