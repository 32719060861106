import React from "react";
import { Modal } from "react-bootstrap";
// import { Link } from "react-router-dom";

function WalletCancelModal({
  showWalletDisconnectModal,
  onCloseWalletDisconnectModal,
}) {
  return (
    <div>
      <Modal
        show={showWalletDisconnectModal}
        backdrop="static"
        onHide={onCloseWalletDisconnectModal}
        className="bitcoin-modal d-flex align-items-center auto-height-modal"
      >
        <Modal.Header className="align-items-baseline" closeButton>
          <h4 className=" text-center mb-0">Wallet Disconnected</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-warning">
            <p>Your wallet has been disconnected as per your selection</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default WalletCancelModal;
