import React from "react";
import IcoSettings from "../../../components/IcoSettings";
const IcoSettingsPage = () => {
  return (
    <>
      <IcoSettings />
    </>
  );
};

export default IcoSettingsPage;
