import React, { useEffect, useState, useContext } from "react";
import { FetchUserFaqs } from "../Services/User/User/user.js";
import { ToasterContext } from "../App";

const Faqs = () => {
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [faqs, setFaqs] = useState([
    {
      title: "ICO",
      description: "what is ico?",
      type: "general",
    },
  ]);
  useEffect(() => {
    fetchFaq();

    // eslint-disable-next-line
  }, []);

  const fetchFaq = async () => {
    const faq = await FetchUserFaqs();
    if (faq.code === 200) {
      setFaqs(faq.message);
    } else if (faq.code === 300) {
      setFaqs({
        ...faqs,
        title: "No faq Available ",
        description: "",
        type: "",
      });
    } else {
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: "error",
        message: "Network/Server Error",
      });
    }
  };
  return (
    <>
      <div className="user-content">
        <div className="user-panel">
          <h2 className="user-panel-title">Frequently Asked Questions</h2>
          <div id="faqList">
            <h4 className="color-dark">General</h4>
            <div className="accordion-simple" id="faqList-1">
              {faqs.map((item, index) => {
                return (
                  <>
                    {item.type.toLowerCase() === "general" ? (
                      <div className="accordion-item">
                        <h6
                          className="accordion-heading collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse-1-${index}`}
                        >
                          {" "}
                          {item.title}{" "}
                        </h6>
                        <div
                          id={`collapse-1-${index}`}
                          className="collapse"
                          data-bs-parent="#faqList"
                        >
                          <div className="accordion-content">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </div>
            <h4 className="color-dark">KYC Application</h4>
            {faqs.map((item, index) => {
              return (
                <>
                  {item.type.toLowerCase() === "kyc" ? (
                    <div className="accordion-item">
                      <h6
                        className="accordion-heading collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse-1-${index}`}
                      >
                        {" "}
                        {item.title}{" "}
                      </h6>
                      <div
                        id={`collapse-1-${index}`}
                        className="collapse"
                        data-bs-parent="#faqList"
                      >
                        <div className="accordion-content">
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
            <h4 className="color-dark">Contribution</h4>
            {faqs.map((item, index) => {
              return (
                <>
                  {item.type.toLowerCase() === "contribution" ? (
                    <div className="accordion-item">
                      <h6
                        className="accordion-heading collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse-1-${index}`}
                      >
                        {" "}
                        {item.title}{" "}
                      </h6>
                      <div
                        id={`collapse-1-${index}`}
                        className="collapse"
                        data-bs-parent="#faqList"
                      >
                        <div className="accordion-content">
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faqs;
