import Card from "@mui/material/Card";
import { useState, useEffect, useContext } from "react";
import GenericForm from "../../Comps-Utils/GenericForm";
import { GetCMS } from "../../../Services/Admin/CMS/Banner";
import Loader from "../../Comps-Utils/ClimbingLoader";
import { ToasterContext } from "../../../App";
import {
  DocumentsInitialValues,
  DocumentsSchema,
  CMSDocumentsFormFields,
  getInitialValues,
} from "./helpers";
import { UpdateCMSDocument } from "../../../Services/Admin/CMS/Document";
const CMS = () => {
  const [general, setGeneral] = useState([]);
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [id, setId] = useState();
  const [loading, setLoading] = useState(true);
  const [reset, setReset] = useState(false);
  const GetCMSDataService = () => {
    setLoading(true);
    GetCMS().then((res) => {
      if (res.status === 200) {
        setGeneral(res.data[0].document);
        setId(res.data[0]._id);
        setLoading(false);
        setReset(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const updateService = async (data, actions) => {
    UpdateCMSDocument(data, id).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data.document);
        setReset(true);
      }
      actions.setSubmitting(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  useEffect(() => {
    GetCMSDataService();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Card className="mx-auto w95">
        {loading ? (
          <Loader />
        ) : (
          <>
            <GenericForm
              isModal={false}
              heading={"CMS Common - Documents"}
              general={general}
              id={id}
              reset={reset}
              refresh={GetCMSDataService}
              getValues={getInitialValues}
              initialValues={DocumentsInitialValues}
              schema={DocumentsSchema}
              formFields={CMSDocumentsFormFields}
              update={updateService}
              tostify={tostify}
            />
          </>
        )}
      </Card>
    </>
  );
};
export default CMS;
