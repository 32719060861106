/* eslint-disable */
import { useEffect, useState, useContext, useCallback } from "react";
import {
  createRows,
  AdminsFormFields,
  setAdminInitialValues,
  AdminsSchema,
  AdminsTableHeadings,
  setInitialValues,
} from "./helpers";
import AdminsTable from "../Comps-Utils/GenericTable";
import TableRows from "./TableRow";
import { ToasterContext } from "../../App";
import {
  GetAdminsProfiles,
  AddAdmin,
  updateAdminStatus,
  updateAdmin,
  deleteAdmin,
  GetRoleList,
} from "../../Services/Admin/Admins";
import DialogBox from "../Comps-Utils/DialougeBox";
import { DialogMessage } from "../../helpers/dialouge";
import SkeltonTable from "../Comps-Utils/Skeltons/TableSkelton";
import {
  NEW_S,
  SUCCESS_S,
  ERROR_S,
  ACTIVATE_ADMIN,
  DEACTIVATE_ADMIN,
  PLEASE_CONFIRM_ACTIVATE_ADMIN,
  ARE_YOU_SURE_YOU_WANT,
  DELETE_ADMIN,
  ADMIN,
  ADMIN_TABLE_HEADING,
  ADMIN_SEARCH_PLACEHOLDER,
  PLEASE_CONFIRM_DELETE_ADMIN,
} from "../../helpers/constants";
import { MX_AUTO__W95, MT_3 } from "../../helpers/CLASSES";
const Admins = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [initialValuesIndex, setInitialValuesIndex] = useState();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [dialog, setDialog] = useState({ visible: false });
  const [updatedRow, setUpdatedRow] = useState({});
  const [actionState, setActionState] = useState(NEW_S);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setActionState(NEW_S);
    setUpdatedRow({});
  };
  const GetKycUsersService = useCallback(() => {
    GetAdminsProfiles().then((res) => {
      if (res.status === 200) {
        setUsers(res.data);
        setLoading(false);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? SUCCESS_S : ERROR_S,
          message: res.message,
        });
      }
    });
  }, []);
  const AddAdminService = (data, actions) => {
    AddAdmin(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setUsers(res.data);
        handleClose();
      }
      actions.setSubmitting(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? SUCCESS_S : ERROR_S,
        message: res.message,
      });
    });
  };
  const UpdateAdminService = (data, actions) => {
    tostify.dismissAll();
    updateAdmin(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setUsers(res.data);
        handleClose();
      }
      actions.setSubmitting(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? SUCCESS_S : ERROR_S,
        message: res.message,
      });
    });
  };
  //User Status Update Confirmation Handler
  const handleUserUpdateStatusConfirmation = useCallback((id, status) => {
    DialogMessage(
      setDialog,
      "",
      status ? ACTIVATE_ADMIN : DEACTIVATE_ADMIN,
      status ? PLEASE_CONFIRM_ACTIVATE_ADMIN : ARE_YOU_SURE_YOU_WANT,
      () => UpdateUserStatusService(id, status)
    );
  }, []);
  //User Status Update Confirmation Handler
  const handleAdminDeleteStatusConfirmation = useCallback((data) => {
    DialogMessage(
      setDialog,
      "",
      DELETE_ADMIN,
      PLEASE_CONFIRM_DELETE_ADMIN,
      () => DeleteAdminService(data)
    );
  }, []);
  const UpdateUserStatusService = useCallback((id, status) => {
    tostify.dismissAll();
    updateAdminStatus(id, status).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setUsers(res.data);
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? SUCCESS_S : ERROR_S,
        message: res.message,
      });
    });
  }, []);
  const DeleteAdminService = (data) => {
    deleteAdmin(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setUsers(res.data);
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? SUCCESS_S : ERROR_S,
        message: res.message,
      });
    });
  };
  const GetRoleListService = useCallback(() => {
    GetRoleList().then((res) => {
      if ((res.status === 200) & (res.code === 200)) {
        setRoles(res.data);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? SUCCESS_S : ERROR_S,
          message: res.message,
        });
      }
    });
  }, []);
  useEffect(() => {
    GetKycUsersService();
    GetRoleListService();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (roles.length > 0) {
      setInitialValuesIndex(setAdminInitialValues(roles));
    }
    // eslint-disable-next-line
  }, [roles]);
  return (
    <>
      <DialogBox {...dialog} />
      <div className={MX_AUTO__W95}>
        {loading ? (
          <SkeltonTable header={[0, 1, 2, 3, 4]} rows={[0, 1, 2]} />
        ) : (
          <>
            <div className={MT_3}>
              <AdminsTable
                //states
                isModal={true}
                modalHeading={ADMIN}
                tableHeading={ADMIN_TABLE_HEADING}
                searchPlaceHolder={ADMIN_SEARCH_PLACEHOLDER}
                loading={loading}
                data={users}
                actionState={actionState}
                open={open}
                updatedRow={updatedRow}
                tostify={tostify}
                //setters
                setUpdatedRow={setUpdatedRow}
                setLoading={setLoading}
                setActionState={setActionState}
                setGeneral={setUsers}
                //Handlers
                handleOpen={handleOpen}
                handleClose={handleClose}
                //Table Row Components
                TableRow={TableRows}
                //Helpers States &  Function
                createRows={createRows}
                headings={AdminsTableHeadings}
                initialValues={initialValuesIndex}
                schema={AdminsSchema}
                setInitialValues={setInitialValues}
                formFields={AdminsFormFields}
                //services
                deleteRow={handleAdminDeleteStatusConfirmation}
                add={AddAdminService}
                updateUserStatus={handleUserUpdateStatusConfirmation}
                update={UpdateAdminService}
                //extra only for admins
                roles={roles}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Admins;
