import React from "react";
import Bitcoin from "../../../components/Bitcoin";
const BitcoinPage = () => {
  return (
    <>
      <Bitcoin />
    </>
  );
};

export default BitcoinPage;
