import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
function valuetext(value) {
  return `${value}°C`;
}

export default function RangeSlider({ SetFilterData }) {
  const [value, setValue] = React.useState([0, 10000]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    SetFilterData(newValue, "range");
  };
  const Reset = () => {
    setValue([0, 10000]);
    SetFilterData([0, 10000], "range");
  };

  return (
    <Box sx={{ width: 300 }}>
      <Slider
        getAriaLabel={() => "Token range"}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        min={0}
        max={10000}
        getAriaValueText={valuetext}
      />
      <button onClick={Reset} className="btn  btn-secondary">
        <RestartAltIcon />
      </button>
    </Box>
  );
}
