import { useState, useEffect, useCallback } from "react";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
// import { FcSearch } from "react-icons/fc";
// import { GiCancel } from "react-icons/gi";
import Grow from "@mui/material/Grow";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { FaSearch } from "react-icons/fa";

const Search = (props) => {
  const {
    // searchText,
    setSearchText,
    searchBar,
    setResponsiveSearch,
  } = props;
  const [open, setOpen] = useState(false);
  const [searchTextValue, setSearchTextValue] = useState("");
  const handleOpen = () => {
    setOpen(true);
    setResponsiveSearch(true);
  };
  // const handleClose = () => {
  //   setOpen(false);
  //   setSearchText("");
  // };
  const [headerSearchHover, setHeaderSearchHover] = useState(false);
  const toggleHeaderSearchHover = () => {
    setHeaderSearchHover(true);
  };
  const toggleHeaderSearchBlure = () => {
    if (searchTextValue) {
      setHeaderSearchHover(true);
    } else {
      setHeaderSearchHover(false);
    }
  };
  useEffect(() => {
    setSearchTextValue("");
    setHeaderSearchHover(false);
    // eslint-disable-next-line
  }, [searchBar]);
  // accept search value and call onSearch function on the base of search value
  const requestSearch = useCallback(
    (e) => {
      if (e.target) {
        setSearchTextValue(e.target.value);
        if (e.key === "Enter") {
          searchBar.onSearch(e.target.value);
          setSearchText(searchTextValue);
        }
      }
    },
    // eslint-disable-next-line
    [searchTextValue]
  );
  const requestSearchIcon = () => {
    searchBar.onSearch(searchTextValue);
    setSearchText(searchTextValue);
  };
  return (
    <>
      <Grow
        className="wrap-search-input"
        in={open}
        style={{ transformOrigin: "0 0 0" }}
        {...(open ? { timeout: 1000 } : {})}
      >
        <div id="header-search-wrapper-div" className="search-box">
          <div
            id="header-search-wrapper-sub-div"
            className="d-flex search-wrap"
          >
            <label
              className={clsx(
                "icon-wrapper",
                headerSearchHover ? "icon-wrapper-open" : "icon-wrapper-close"
              )}
              htmlFor="header-search-input"
            ></label>
            <TextField
              fullWidth
              size="small"
              onFocus={toggleHeaderSearchHover}
              onBlur={toggleHeaderSearchBlure}
              id="header-search-input"
              name="header-search-input"
              type="search"
              placeholder={searchBar.placeholder}
              value={searchTextValue}
              onChange={requestSearch}
              onKeyDown={requestSearch}
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaSearch
                      size={25}
                      className="mr-3 custom-icon"
                      onClick={requestSearchIcon}
                    />
                  </InputAdornment>
                ),
              }}
            />
            {/* <IconButton
              onClick={handleClose}
              color="primary"
              aria-label="add to shopping cart"
            >
              <GiCancel size={20} className="mr-0" color="red" />
            </IconButton> */}
          </div>
        </div>
      </Grow>
      <Grow
        in={!open}
        style={{ transformOrigin: "0 0 0" }}
        {...(!open ? { timeout: 1000 } : {})}
      >
        <IconButton
          onClick={handleOpen}
          color="primary"
          aria-label="add to shopping cart custom-icon"
        >
          <FaSearch size={25} className="mr-3 custom-icon" />
        </IconButton>
      </Grow>
    </>
  );
};
export default Search;
