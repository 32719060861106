export const getCount = (count, limit) => {
  return count && limit && Math.ceil(count / limit) > 1
    ? Math.ceil(count / limit)
    : 1;
};
export const paginate = (page, rowsPerPage) => {
  let start = page === 1 ? 0 : (page - 1) * rowsPerPage;
  let end = page * rowsPerPage;
  return { start, end };
};
