import { FaEthereum } from "react-icons/fa";
import { FaBitcoin } from "react-icons/fa";
import { IoMdPerson } from "react-icons/io";
import { Icon } from "@iconify/react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const KEYBOARD_ARROW_UP = () => {
  return <KeyboardArrowUpIcon />;
};
export const KEYBOARD_ARROW_DOWN = () => {
  return <KeyboardArrowDownIcon />;
};

export const ETHEREUM_ICON_WHITE_35 = () => {
  return <FaEthereum fontSize={35} color={"#ffffff"} />;
};

export const BITCOIN_ICON_WHITE_35 = () => {
  return <FaBitcoin fontSize={35} color={"#ffffff"} />;
};

export const PERSON_ICON_WHITE_35 = () => {
  return <IoMdPerson fontSize={35} color={"#ffffff"} />;
};

export const DAI_ICON_WHITE_35 = () => {
  return (
    <Icon
      icon="cryptocurrency:dai"
      color="#ffffff"
      style={{ fontSize: "35px" }}
    />
  );
};
export const TUSD_ICON_WHITE_35 = () => {
  return (
    <Icon
      icon="cryptocurrency:tusd"
      color="#ffffff"
      style={{ fontSize: "35px" }}
    />
  );
};

export const USDT_ICON_WHITE_35 = () => {
  return (
    <Icon
      icon="cryptocurrency:usdt"
      color="#ffffff"
      style={{ fontSize: "35px" }}
    />
  );
};

export const BUSD_ICON_WHITE_35 = () => {
  return (
    <Icon
      icon="cryptocurrency:emc2"
      color="#ffffff"
      style={{ fontSize: "35px" }}
    />
  );
};

export const CREDIT_ICON_WHITE_35 = () => {
  return (
    <Icon
      icon="material-symbols:credit-card"
      color="#ffffff"
      style={{ fontSize: "35px" }}
    />
  );
};
