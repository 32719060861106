import React from "react";

// import Clip from '../../components/Comps-Utils/ClipLoader'
import { Modal } from "react-bootstrap";
import { BounceLoader } from "react-spinners";
function ModalLoading() {
  return (
    <div>
      <Modal
        show={true}
        className="d-flex align-items-center transaction-modal"
      >
        <Modal.Header className="d-flex flex-column text-center justify-content-center align-items-center h-75">
          <h3 className="mt-4 mb-4">We are Almost There</h3>
          <BounceLoader size={60} color={"#f64b31"} className="mb-4" />
          <h5 className="mt-4 mb-4">
            Transaction Processing will take some time{" "}
          </h5>
        </Modal.Header>
      </Modal>
    </div>
  );
}

export default ModalLoading;
