import React from "react";
import { sanitize } from "dompurify";
import { Link } from "react-router-dom";
const Tokens = (props) => {
  const { description } = props;
  return (
    <>
      <section className="bg-white section ov-h" id="tokens">
        <div className="container">
          <div className="token-circle">
            <div className="section-head text-center wide-auto-sm">
              <h4 className="title title-semibold">
                Tokens and how they are used
              </h4>
              <div className="mb-3" data-delay=".2">
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitize(description),
                  }}
                />
                <p>
                  You can earn tokens by engaging with our clients and spend
                  tokens to unlock clients you want to work at
                </p>
              </div>
              <Link
                to={"/signup"}
                className="zinnia-btn btn-md btn-round btn-with-icon btn-light btn-shade mb-lg-0 mb-5"
              >
                <span>Join Pre-Sale</span>
                <em className="icon fas fa-angle-double-right"></em>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-lg-0 mb-md-5 mb-4">
              <div className="rcv-tokens">
                <h4 className="title title-bold title-s4">RECEIVING TOKENS</h4>
                <div className="row">
                  <div className="col-6">
                    <div className="token-box">
                      <span className="detail-title">Talent Network</span>
                      <ul className="circle-tick">
                        <li>Signing up</li>
                        <li>Interviewing</li>
                        <li>Getting hired</li>
                        <li>Refferng your network</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="token-box">
                      <span className="detail-title">Client</span>
                      <ul className="circle-tick">
                        <li>Signing up for Connect</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="spend-tokens">
                <h4 className="title title-bold title-s4 text-white">
                  SPENDIND TOKENS
                </h4>
                <div className="row">
                  <div className="col-6">
                    <div className="token-box">
                      <span className="detail-title">Talent Network</span>
                      <ul className="circle-tick">
                        <li>Unlock Company Hiring</li>
                        <li>Pay for Trainnings</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="token-box">
                      <span className="detail-title">Client</span>
                      <ul className="circle-tick">
                        <li>Incentive candidates to respond</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Tokens;
