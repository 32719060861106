const SubmitBtn = (props) => {
  const { text, disable, click } = props;
  return (
    <>
      <div className="items-push">
        <div className="col-lg-7">
          <button
            disabled={disable}
            onClick={click}
            type="file"
            className="btn btn-primary"
          >
            {text}
          </button>
        </div>
      </div>
    </>
  );
};
export default SubmitBtn;
