import instance from "../../../Config/axios";

export const RegisterUserApi = (data) => {
  let response = instance.post(`/users/register`, data);
  return response;
};

export const LoginUserApi = async (data) => {
  let response = await instance.post(`/users/login`, data);
  return response;
};

export const ResetPasswordApi = async (data) => {
  let response = await instance.post(`/users/forget`, data);
  return response;
};

export const ForgetPasswordApi = async (data) => {
  let response = await instance.put(`/users/reset-password`, data);
  return response;
};

export const ConfirmUserApi = async (id) => {
  let response = await instance.get(`/users/confirm-email/${id}`);
  return response;
};
