import React, {
  // lazy,
  useEffect,
  useState,
} from "react";
import { Switch, Route } from "react-router-dom";
import { motion } from "framer-motion";
import PresentationLayout from "../Layouts/PresentationLayout";
import CreditCard from "../components/CreditCard/creditcard";
import StableCoins from "../components/Stable";
import Kyc from "../Pages/Admin/Kyc";
import Admins from "../Pages/Admin/Admins";
import Dashboard from "../Pages/Admin/Dashboard";
import EmailTemplatesPage from "../Pages/Admin/EmailTemplates";
import Profile from "../Pages/Admin/Profile";
import Users from "../Pages/Admin/Users";
import Icosettings from "../Pages/Admin/IcoSettings";
import Sitesettings from "../Pages/Admin/SiteSettings";
import PasswordChange from "../Pages/Admin/PasswordChange.js";
import Banner from "../Pages/Admin/CMSBanner";
import Faqs from "../Pages/Admin/Faqs";
import General from "../Pages/Admin/GeneralListing";
import Preico from "../Pages/Admin/PreIcoPage";
import Tokenlisitng from "../Pages/Admin/TokenListing";
import Slider from "../Pages/Admin/Slider";
import Listing from "../Pages/Admin/SliderListing";
import Aboutico from "../Pages/Admin/CMSAboutico";
import Services from "../Pages/Admin/CMSServices";
import Tokens from "../Pages/Admin/CMSTokens";
import Contactus from "../Pages/Admin/CMSContactUs";
import Documents from "../Pages/Admin/CMSOurDocuments";
import Why from "../Pages/Admin/CMSWhy";
import Team from "../Pages/Admin/CMSTeam";
import Leadership from "../Pages/Admin/CMSLeadershipListing";
import Advisor from "../Pages/Admin/CMSAdvisorListing";
import Logos from "../Pages/Admin/CMSLogos";
import Timeline from "../Pages/Admin/CMSTimeline";
import Timelinelisting from "../Pages/Admin/CMSTimelineListing";
import Bitcoin from "../Pages/Admin/Bitcoin";
import Ethereum from "../Pages/Admin/Ethereum";
import Airdrop from "../Pages/Admin/Airdrop";
import PermissionsPage from "../Pages/Admin/Permissions";
import PreSalePage from "../Pages/Admin/PreSale";
import { makePaths } from "../components/Layout/SideBar/helpers";
import { AdminSideBarSubItems } from "../helpers/adminSideMenuOptions";
import useSaleSetter from "../CustomHooks/Sale";
export const LayoutContext = React.createContext({});

const GenericRoutes = (props) => {
  const { sidebar, location, pageVariants, pageTransition, rights } = props;
  // eslint-disable-next-line
  const sale = useSaleSetter();
  const [sidebarMain, setSidebarMain] = useState([]);
  useEffect(() => {
    if (rights.sideBar) {
      // eslint-disable-next-line
      let temp = makePaths(
        rights.sideBar,
        AdminSideBarSubItems,
        setSidebarMain
      );
      // setSidebar(temp);
    }
  }, [rights]);
  return (
    <Route
      path={[
        window.location.pathname,
        ...sidebar.map((route) => route.pathName),
      ]}
    >
      <PresentationLayout sidebarMain={sidebarMain}>
        <Switch location={location} key={location.pathname}>
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            style={{ height: "100%" }}
          >
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/admins" component={Admins} />
            <Route path="/permissions" component={PermissionsPage} />
            <Route path="/kyc" component={Kyc} />
            <Route path="/bitcoin" component={Bitcoin} />
            <Route path="/ethereum" component={Ethereum} />
            <Route path="/stable" component={StableCoins} />
            <Route path="/creditcard" component={CreditCard} />
            <Route path="/emailTemplates" component={EmailTemplatesPage} />
            <Route path="/airdrop" component={Airdrop} />
            <Route path="/profile" component={Profile} />
            <Route path="/users" component={Users} />
            <Route path="/icosettings" component={Icosettings} />
            <Route path="/sitesettings" component={Sitesettings} />
            <Route path="/password" component={PasswordChange} />
            <Route path="/banner" component={Banner} />
            <Route path="/logos" component={Logos} />
            <Route path="/faqs" component={Faqs} />
            <Route path="/preico" component={Preico} />
            <Route path="/tokenlisting" component={Tokenlisitng} />
            <Route path="/slider" component={Slider} />
            <Route path="/listing" component={Listing} />
            <Route path="/general" component={General} />
            <Route path="/aboutico" component={Aboutico} />
            <Route path="/services" component={Services} />
            <Route path="/tokens" component={Tokens} />
            <Route path="/contactus" component={Contactus} />
            <Route path="/documents" component={Documents} />
            <Route path="/why" component={Why} />
            <Route path="/team" component={Team} />
            <Route path="/leadership" component={Leadership} />
            <Route path="/advisor" component={Advisor} />
            <Route path="/timeline" component={Timeline} />
            <Route path="/timelinelisting" component={Timelinelisting} />
            <Route path="/presaleuser" component={PreSalePage} />
          </motion.div>
        </Switch>
      </PresentationLayout>
    </Route>
  );
};
export default GenericRoutes;
