import moment from "moment";
import { DASHBOARD_S, MMMM_Do_YYYY, h_mm_ss_a } from "../../helpers/constants";

function numberWithCommas(x) {
  return parseFloat(x).toLocaleString(undefined, { maximumFractionDigits: 20 });
}

const TableRow = (props) => {
  const { row, index } = props;
  return (
    <>
      <tr key={row.id + index} id={DASHBOARD_S + "-tr-" + index}>
        <td className="px-4 text-left text-capitalize">
          <span>{row.serial}</span>
        </td>
        <td className="text-left ">
          <span>{row.name}</span>
          <span style={{ fontSize: "10px", textTransform: "initial" }}>
            {row.email}
          </span>
        </td>
        <td className="text-left">
          <span>
            {row.invested} {row.paymentMethod}
          </span>
        </td>
        <td className="text-left">
          <span>
            {numberWithCommas(row.payableToken)} {row.tokens}
          </span>
        </td>
        <td className="text-left">
          <span className="d-none d-sm-table-cell">
            <span
              className={row.status === "Sent" ? "text-green" : "text-warning"}
            >
              {row.status}
            </span>
          </span>
        </td>
        <td className="text-left">
          <span>{moment(row.updatedAt).format(MMMM_Do_YYYY)}</span>
          <span> {moment(row.updatedAt).format(h_mm_ss_a)}</span>
        </td>
      </tr>
    </>
  );
};
export default TableRow;
