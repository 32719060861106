import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

export default function Tags({ SetFilterData }) {
  const fixedOptions = [top100Films[6]];
  const [value, setValue] = React.useState([top100Films[0]]);
  const handleChange = (newValue) => {
    let items = [...newValue];
    let dummy = [];
    for (let i = 0; i < items.length; i++) {
      dummy.push(items[i].status);
    }
    setValue([
      ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
    ]);

    SetFilterData(dummy, "status");
  };
  return (
    <Stack spacing={2} sx={{ width: 400 }}>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={top100Films}
        getOptionLabel={(option) => option.title}
        onChange={(event, newValue) => handleChange(newValue)}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Sale Status"
            placeholder="Status Type"
          />
        )}
      />
    </Stack>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { title: "ALL", status: "ALL" },
  { title: "DAI", status: "DAI" },
  { title: "BUSD", status: "BUSD" },
  { title: "USDT", status: "USDT" },
  { title: "TUSD", status: "TUSD" },
];
