import React from "react";
import Permissions from "../../../components/Permissions";

const PermissionsPage = () => {
  return (
    <>
      <Permissions />
    </>
  );
};
export default PermissionsPage;
