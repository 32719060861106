import React from "react";
import CMS from "../../../components/CMS/Banner";
const CMSPage = () => {
  return (
    <>
      <CMS />
    </>
  );
};
export default CMSPage;
