import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import ProfileForm from "./profileForm";
const Profile = () => {
  const [CurrentUser, setUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    // eslint-disable-next-line
  }, [localStorage.getItem("user")]);

  return (
    <>
      <Card className="mx-auto w95">
        <ProfileForm
          key={"profile-form" + Math.random().toString(36).substring(7)}
          adminInfo={CurrentUser}
          setUser={setUser}
        />
      </Card>
    </>
  );
};
export default Profile;
