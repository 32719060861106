import React from "react";
// import { Link } from 'react-router-dom'

const Payment = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <iframe
        src={
          "https://widget.onramper.com?color=F0491B&apiKey=pk_test_x5M_5fdXzn1fxK04seu0JgFjGsu7CH8lOvS9xZWzuSM0"
        }
        height="595px"
        title="Onramper widget"
        frameborder="0"
        allow="accelerometer;
         autoplay; camera; gyroscope; payment"
        style={{ boxShadow: "1px 1px 1px 1px rgba(0,0,0,0.1)", width: "50%" }}
      ></iframe>
    </div>
  );
};

export default Payment;
