import Slider from "react-slick";
import SliderItem from "./SliderItem";
import { portfolioTestimonalHome } from "./helpers";
const AutoPlaySlider = (props) => {
  const { sliderListing } = props;
  return (
    <div className="nk-block block-partners w-100">
      <Slider
        {...portfolioTestimonalHome}
        className="slider-arrows-outside slider-arrows-dark"
      >
        {sliderListing && sliderListing.length !== 0
          ? sliderListing.map((row, index) => {
              return <SliderItem row={row} key={index} />;
            })
          : null}
      </Slider>
    </div>
  );
};
export default AutoPlaySlider;
