import * as Yup from "yup";
export const createRows = (rowData) => {
  const rows = rowData.map((i, index) => {
    return {
      id: i._id,
      title: i.title,
      type: i.type,
      body: i.body,
      template: i.template,
    };
  });
  return rows;
};
export const rowsPerPage = 2;

export const EmailListingTableHeadings = [
  {
    id: "title",
    label: "Title",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "type",
    label: "Type",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "actions",
    label: "Actions",
    sort: true,
    class: "bg-body-light text-center",
  },
];
export const emailInitialValues = {
  title: "",
  type: "",
  body: "",
};
export const EmailSchema = Yup.object().shape({
  title: Yup.string().required("Title is required!"),
  type: Yup.string().required("Type is required!"),
  body: Yup.string().required("Email Body is required!"),
});
export const EmailFormFields = [
  {
    field: "Title",
    fieldName: "title",
    place: "Enter Title",
    type: "text",
  },
  {
    field: "Type",
    fieldName: "type",
    place: "Enter Type",
    type: "text",
  },
  {
    field: "Body",
    fieldName: "body",
    place: "Make your Email Body",
    type: "editor",
  },
];
export const setInitialValues = (updatedRow) => {
  const Values = {
    id: updatedRow.id ? updatedRow.id : "",
    title: updatedRow.title ? updatedRow.title : "",
    type: updatedRow.type ? updatedRow.type : "",
    body: updatedRow.body ? updatedRow.body : "",
  };
  return Values;
};
