import Skeleton from "@material-ui/lab/Skeleton";
const PlusMinusSkeltonFieldPayment = (props) => {
  const { mintFields } = props;
  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="w57">
          <span className="d-flex">
            {" "}
            <Skeleton height={66} width={200} />
          </span>
        </div>
      </div>

      {mintFields.map((i) => {
        return (
          <div className="d-flex justify-content-between">
            <Skeleton height={66} width={50} />

            <Skeleton height={66} width={800} />

            <Skeleton height={66} width={50} />
          </div>
        );
      })}
    </>
  );
};
export default PlusMinusSkeltonFieldPayment;
