import { useState } from "react";
import ActionGroup from "../Comps-Utils/ActionsButtonGroup";
import Modal from "@mui/material/Modal";
import TemplateViewer from "./TemplateViewer";
const TableRow = (props) => {
  const { row, index, setActionState, handleOpen, setUpdatedRow, deleteRow } =
    props;
  const [templateOpen, setTemplateOpen] = useState(false);
  const handleTemplateOpen = () => setTemplateOpen(true);
  const handleTemplateClose = () => setTemplateOpen(false);
  return (
    <>
      <Modal
        open={templateOpen}
        onClose={handleTemplateClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <TemplateViewer
            handleTemplateClose={handleTemplateClose}
            template={row.template}
          />
        </div>
      </Modal>
      <tr key={row.id + index} id={"user-profile-tr-" + index}>
        <td className="px-4 text-left">
          <span>{row.title}</span>
        </td>
        <td className="px-4 text-left">
          <span>{row.type}</span>
        </td>
        <td className="text-center table-btn-group">
          <ActionGroup
            handleOpen={handleOpen}
            handleTemplateOpen={handleTemplateOpen}
            setUpdatedRow={setUpdatedRow}
            setActionState={setActionState}
            row={row}
            deleteRow={deleteRow}
          />
        </td>
      </tr>
    </>
  );
};
export default TableRow;
