import TextField50 from "../Comps-Utils/Textfield50";
import TextFiledLabelRequired from "../Comps-Utils/TextfieldLabelRequired";
import { ConnectedFocusError } from "focus-formik-error";
import { SettingsFormFields } from "./helpers";
// import { ErrorMessage } from "formik";
const GeneralForm = (props) => {
  const { touched, errors, values, handleChange } = props;
  return (
    <>
      <div className="mb-3">
        <ConnectedFocusError />
        <div className="row items-push">
          {SettingsFormFields.map((item, index) => {
            return (
              <>
                <div className="d-flex">
                  <TextFiledLabelRequired
                    widthclass="col-lg-4"
                    field={item.field}
                  />
                  <TextField50
                    widthclass="w100"
                    fieldName={item.fieldName}
                    handleChange={handleChange}
                    values={values}
                    touched={touched}
                    errors={errors}
                    place={item.place}
                    type={item.type}
                  />
                </div>
                <div className="gaps-2x"></div>
              </>
            );
          })}
        </div>
        <div className="gaps-2x"></div>
      </div>
    </>
  );
};
export default GeneralForm;
