//Single Class
export const MT_3 = "mt-3 ";
export const D_FLEX = "d-flex";
export const TEXT_LEFT = "text-left";
export const TEXT_WHITE = "text-white";
export const TEXT_SUCCESS = "text-success";
export const TEXT_DARK = "text-dark";
export const TEXT_CAPITALIZE = "text-capitalize";
export const PX_4 = "px-4";
export const ML_2 = "ml-2";
export const TEXT_PRIMARY = "text-primary";

//Joint Classes
export const TEXT_LEFT__TEXT_CAPITALIZE = "text-left text-capitalize";
export const MXAUTO_W95 = "mx-auto w95";
export const MX_AUTO__W95 = "mx-auto w95";
export const D_NONE__D_SM_TABLE_cell = "d-none d-sm-table-cell";
export const THREAD_LIGHT__TEXT_CAPITALIZE__FONT_SIZE_SM__FONT_WEIGHT_BOLD__BORDER_TOP =
  "thead-light text-capitalize font-size-sm font-weight-bold border-top";

// TRANSACTION TABLE STATUS COLORS
export const TX_NOT_SUBMIT_BGCOLOR = "rgb(221 15 20 / 20%)";
export const HASH_SUBMIT_BGCOLOR = "rgb(159 157 3 / 82%)";
export const TX_VERIFIED_BGCOLOR = "rgb(6 113 237 / 42%)";
export const TX_AIRDROP_BGCOLOR = "rgb(0 234 67 / 28%)";

export const TX_NOT_SUBMIT_COLOR = "rgb(237 38 6 / 84%)";
export const HASH_SUBMIT_COLOR = "rgb(159 157 3 / 82%)";
export const TX_VERIFIED_COLOR = "rgb(6 113 237 / 81%)";
export const TX_AIRDROP_COLOR = "rgb(13 171 58 / 76%)";
