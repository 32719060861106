import React from "react";
import Admins from "../../../components/Admins";
const AdminsPage = () => {
  return (
    <>
      <Admins />
    </>
  );
};
export default AdminsPage;
