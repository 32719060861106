import Slider from "./ProblemsSlider";
import { sanitize } from "dompurify";
const Benifits = (props) => {
  const { slider, sliderListing } = props;
  return (
    <section className="section bg-light-alt" id="benefits">
      <div className="ui-shape ui-shape-s2 benifit-ball-mobile"></div>
      <div className="container">
        <div className="section-head section-head-s8 text-center">
          <h2
            className="title title-semibold text-default max-100"
            data-delay=".1"
          >
            {slider.mainhead}
          </h2>
          <h2 className="title title-semibold text-default " data-delay=".1">
            {slider.description}
          </h2>
          <div className="" data-delay=".2">
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(slider.text),
              }}
            />
          </div>
        </div>
        <Slider sliderListing={sliderListing} />
        {/* <div className="nk-block mgt-m30">
          <div
            className="feature-carousel-s1 has-carousel has-carousel-overlap carousel-nav-center carousel-nav-s1 owl-carousel"
            data-items="1"
            data-navs="true"
            data-loop="true"
            data-animate-out="fadeOut"
          >
            <div className="feature">
              <div className="row g-0">
                <div className="col-md-6 col-sm-12 animate-left delay-5ms">
                  <div className="feature-s10 feature-s10-left round bg-white">
                    <h2 className="title title-bold title-s4">Problem</h2>
                    <h5 className="title-sm">Crypto Investors</h5>
                    <ul className="list list-dot list-dot-s2">
                      <li>
                        What is the crypto tech? Lorem ipsum dolor sit amet,
                        consectetur.
                      </li>
                      <li>Natural Language Understanding</li>
                      <li>
                        How to network with other investors / like-minded
                        people?
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 animate-right delay-7ms">
                  <div className="feature-s10 feature-s10-right round bg-theme tc-light">
                    <h2 className="title title-bold title-s4">Solution</h2>
                    <h5 className="title-sm">Centralize Network</h5>
                    <ul className="list list-dot list-dot-s2">
                      <li>
                        What is the crypto tech? Lorem ipsum dolor sit amet,
                        consectetur.
                      </li>
                      <li>Natural Language Understanding</li>
                      <li>
                        How to network with other investors / like-minded
                        people?
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <Slider {...settings}>
          <div>
            <h3>zzzzzzzz</h3>
          </div>
          <div>
            <h3>2</h3>
          </div>
          <div>
            <h3>3</h3>
          </div>
        </Slider> */}
      </div>
    </section>
  );
};
export default Benifits;
