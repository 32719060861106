import moment from "moment";
export const portfolioTestimonalHome = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  pauseOnHover: false,
  easing: "linear",
  arrows: true,
  //   responsive: [
  //     {
  //       breakpoint: 1200,
  //       settings: { slidesToShow: 1, slidesToScroll: 1 },
  //     },
  //     {
  //       breakpoint: 900,
  //       settings: { slidesToShow: 1, slidesToScroll: 1 },
  //     },
  //   ],
};
export const createStakeRows = (stakes) => {
  return stakes.map((i) => {
    return {
      id: i.id,
      name: i.name,
      icon: i.icon,
      chain: i.chain,
      compounding: i.compounding,
      price: i.price,
      apy: i.apy,
      description: i.description,
      updatedAt: moment(parseInt(i.updatedAt))
        .format("YYYY-MM-DD HH:mm:ss")
        .toString(),
    };
  });
};
