import { useEffect, useState, useContext } from "react";
import UserProfileTable from "./UserTable";
import { GetUserProfiles } from "../../Services/Admin/Users";
import Loader from "../Comps-Utils/ClimbingLoader";
import Alert from "../Alert";
import { ToasterContext } from "../../App";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ visible: false });
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const GetUserProfileService = () => {
    GetUserProfiles().then((res) => {
      if (res.status === 200) {
        setUsers(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  useEffect(() => {
    GetUserProfileService();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Alert setAlert={setAlert} {...alert} />
      <div className="mx-auto w95">
        {loading ? (
          <Loader />
        ) : (
          <UserProfileTable users={users} loading={loading} />
        )}
      </div>
    </>
  );
};
export default Users;
