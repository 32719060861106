import { useState, useEffect, useContext, useRef } from "react";
import { ClipLoader } from "react-spinners";
import {
  AIRDROP_DETAILS,
  TOTAL_AMOUNT,
  TOTAL_ADDRESSES,
  SELECTED_ADDRESSES,
} from "../../helpers/constants";
import { ToasterContext } from "../../App";
import {
  ManualDepositTokens,
  GetPreAndPublicDates,
  getReferralCount,
} from "../../Services/User/web3Calls/contractCall";
import {
  AirDropTokens,
  GetReferralTransactionInfo,
} from "../../Services/User/Tokens/token";
// import web3 from "web3";
import { useSigner, useProvider } from "wagmi";
import { GetAllTransactionsAirDrop } from "../../Services/Admin/Dashboard";
import { DialogMessage } from "../../helpers/dialouge";
import DialogBox from "../Comps-Utils/DialougeBox";

function AirdropDetail({ data, selected, setTransactions }) {
  const [filterData, setFilterData] = useState([]);
  const provider = useProvider();
  const { data: signerData } = useSigner();
  const [tostify, setTostify] = useContext(ToasterContext);
  // eslint-disable-next-line
  const [filterAddress, setFilterAddress] = useState([]);
  const [loading, setLoading] = useState(false);
  const [airdropVal, setAirdropVal] = useState(0);
  const [dialog, setDialog] = useState({ visible: false });
  // eslint-disable-next-line
  const airdropEmail = useRef(false);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [selected]);

  // useEffect(() => {
  //   handleAirDrop()
  // }, [airdropEmail])
  const fetchData = () => {
    var filter = [];
    var address = [];
    for (let i = 0; i < selected.length; i++) {
      let temp = data.filter((d) => d._id === selected[i]);
      if (temp.length > 0) {
        filter.push(...temp);
        address.push(temp[0].recevingaddress);
      }
    }
    setFilterData(filter);
    setFilterAddress([...new Set(address)]);
  };
  useEffect(() => {
    if (filterData.length > 0) {
      var sum = 0;
      // eslint-disable-next-line
      filterData.map((f) => {
        var payabletoken =
          f.actualamount === ""
            ? 0
            : (
                (parseFloat(f.biddingtokenprice) /
                  parseFloat(f.claimtokenprice) ===
                0
                  ? 1
                  : parseFloat(f.claimtokenprice)) *
                  parseFloat(f.actualamount) +
                parseFloat(f.bonus)
              ).toFixed(9);
        sum = sum + parseFloat(payabletoken);
      });
      setAirdropVal(sum);
    } else {
      setAirdropVal(0);
    }
  }, [filterData]);

  const SendAirdropDetails = () => {
    // setLoading(true);
    DialogMessage(
      setDialog,
      "",
      <>
        <p className="text-primary">Airdrop Email</p>
      </>,
      <>
        <p>
          Send individual email to all users that were part of this airdrop?
        </p>
      </>,
      () => {
        handleAirDrop(true);
      },
      () => {
        handleAirDrop(false);
      }
    );
  };

  const handleAirDrop = async (sendEmail) => {
    if (filterData.length > 0) {
      let recevingAddress = [];
      let recevingAmount = [];
      let transaction = [];
      let saleType = [];
      let public3M = [];
      let public6M = [];
      let referAddress = [];
      let referralCountArr = [];
      let referralEmail = [];
      // let amou = 1;
      console.log("filterData", filterData);
      setLoading(true);
      for (let i = 0; i < filterData.length; i++) {
        const {
          recevingaddress,
          _id,
          biddingtokenprice,
          actualamount,
          // bonus,
          userid,
          claimtokenprice,
          saletype,
        } = filterData[i];
        let amount = parseFloat(
          parseFloat(actualamount) * parseFloat(biddingtokenprice)
        );

        // let amount = parseFloat(
        //   (parseFloat(biddingtokenprice) / parseFloat(claimtokenprice)) *
        //     parseFloat(actualamount) +
        //     parseFloat(bonus)
        // );
        const currentTime = new Date().valueOf() / 1000;

        amount = amount * claimtokenprice;
        let amount2 = (amount * 10 ** 7).toFixed(0);
        recevingAddress.push(recevingaddress);
        recevingAmount.push(amount2);
        console.log("recevingAmount", recevingAmount);

        const dates = await GetPreAndPublicDates();
        const weekSeconds = 604800;
        const week2BonusEnd =
          parseInt(dates.publicsalestart) + parseInt(weekSeconds * 2);
        let isPublicSale = saletype === "Public-Sale" ? true : false;

        if (isPublicSale) {
          if (currentTime <= week2BonusEnd) {
            saleType.push(false);
            public3M.push(true);
            public6M.push(false);
          } else {
            saleType.push(false);
            public3M.push(false);
            public6M.push(true);
          }
        } else {
          saleType.push(true);
          public3M.push(false);
          public6M.push(false);
        }

        const referralAddress = await GetReferralTransactionInfo(userid);
        referAddress.push(referralAddress.data);
        const referralCount = await getReferralCount(referralAddress.data);
        referralCountArr.push(referralCount);
        transaction.push(_id);
        referralEmail.push(false);
      }

      ManualDepositTokens(
        recevingAddress,
        recevingAmount,
        saleType,
        public3M,
        public6M,
        referAddress,
        signerData,
        provider
      )
        .then(async (res) => {
          setLoading(false);

          // change these transaction status so that these will not appear in future airdrops
          // const airdropDb = await AirDropTokens({ transaction: transaction });
          for (let i = 0; i < transaction.length; i++) {
            const referralCount = await getReferralCount(referAddress[i]);
            if (referralCountArr[i] < referralCount) {
              referralEmail[i] = true;
            }
          }
          let data = {
            transaction: transaction,
            transactionHash: res.transactionHash,
            airdropEmail: sendEmail,
            referralEmail: referralEmail,
          };
          await AirDropTokens(data);
          const getbackData = await GetAllTransactionsAirDrop();
          setTransactions(getbackData.data);
          setLoading(false);
          setTostify({
            ...tostify,
            visible: true,
            key: Math.random().toString(36).substring(7),
            type: "success",
            message: "Successfully Airdropped",
          });
        })
        .catch((err) => {
          setLoading(false);
          setTostify({
            ...tostify,
            visible: true,
            key: Math.random().toString(36).substring(7),
            type: "error",
            message: "Error : " + err?.reason,
          });
        });
    }
  };

  return (
    <>
      <DialogBox {...dialog} />
      <div className="card card-airdrop">
        <div
          className="card-header d-flex justify-content-center"
          style={{
            color: "white",
            background:
              "linear-gradient(to right, rgb(50 72 88), rgba(255,0,0,1))",
          }}
        >
          <h4 className="text-white text-center"> {AIRDROP_DETAILS}</h4>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            {TOTAL_AMOUNT} :<span className="text-primary"> {airdropVal} </span>
          </li>

          <li className="list-group-item">
            {SELECTED_ADDRESSES}:{" "}
            <span className="text-primary"> {filterData.length} </span>
          </li>

          <li className="list-group-item">
            {TOTAL_ADDRESSES}:
            <span className="text-primary"> {data.length} </span>
          </li>
        </ul>
        <div className="card-body d-flex justify-content-center">
          <button
            className={
              filterData.length > 0
                ? "btn btn-primary d-flex justify-content-center"
                : "btn btn-primary disabled"
            }
            onClick={SendAirdropDetails}
          >
            {loading ? <ClipLoader color={"white"} /> : <span>Airdrop</span>}
          </button>
        </div>
      </div>
    </>
  );
}
export default AirdropDetail;
