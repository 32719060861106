import { useState, useEffect, useContext } from "react";
import {
  GetTransactionsByType,
  ApplyStableCoinFilterTransaction,
} from "../../Services/Admin/Dashboard";
import { createRows, StableTableHeadings } from "./helpers";
import TableRows from "./tablerow";
import Loader from "../Comps-Utils/ClimbingLoader";
import StableTable from "../Comps-Utils/GenericTable";
import { ToasterContext } from "../../App";
import {
  STABLE_TABLE_HEADING,
  STABLE,
  SUCCESS,
  ERROR,
  STABLE_SEARCH_PLACEHOLDER,
} from "../../helpers/constants";
import StaticDateRangePickerDemo from "../Filters/date";
import moment from "moment";
import RangeSlider from "../Filters/range";

import Tags from "../Filters/filterStabletokens";
import { ClipLoader } from "react-spinners";
const StableCoins = () => {
  const [filterValues, setFilterValues] = useState({
    type: ["ALL", "DAI", "USDT", "TUSD", "BUSD"],
    datefrom: "2022-01-01",
    dateto: moment(new Date()).format("YYYY-MM-DD"),
    lowprice: 0,
    highprice: 1000000,
  });
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tostify, setTostify] = useContext(ToasterContext);
  const [transactions, setTransactions] = useState([]);
  const GetTransactionsService = (type) => {
    setLoading(true);
    GetTransactionsByType(type).then((res) => {
      if (res.status === 200) {
        setTransactions(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? { SUCCESS } : { ERROR },
          message: res.message,
        });
      }
    });
  };
  useEffect(() => {
    GetTransactionsService(STABLE);
    // eslint-disable-next-line
  }, []);
  const SetFilterData = (data, type) => {
    if (type === "date") {
      setFilterValues({
        ...filterValues,
        datefrom: moment(data[0]).format("YYYY-MM-DD"),
        dateto: moment(data[1]).format("YYYY-MM-DD"),
      });
    }
    if (type === "range") {
      setFilterValues({
        ...filterValues,
        lowprice: data[0],
        highprice: data[1],
      });
    }

    if (type === "status") {
      setFilterValues({ ...filterValues, type: data });
    }
  };
  const GetFilteredData = () => {
    setLoadingFilter(true);
    ApplyStableCoinFilterTransaction(filterValues).then((res) => {
      setLoadingFilter(false);
      setTransactions(res.data);
    });
  };
  return (
    <>
      <div className="mx-auto w95">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="filter-div row">
              <div className="col-lg-3 col-sm-6 col-12 mb-3">
                <h5>Filter Tx Status</h5>
                <div className="tag-filter">
                  <Tags SetFilterData={SetFilterData} />
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 mb-3">
                <h5>Tx Date Range</h5>
                <div className="filter-input">
                  <StaticDateRangePickerDemo SetFilterData={SetFilterData} />
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 mb-3">
                <h5 className="mb-0">Tokens Quantity</h5>
                <div className="p-2 range-bar">
                  <RangeSlider SetFilterData={SetFilterData} />
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 mb-3">
                <button
                  className="btn btn-primary mt-btn filter-loader"
                  onClick={GetFilteredData}
                >
                  {loadingFilter ? (
                    <ClipLoader color="white" className="loader" />
                  ) : (
                    <span>Apply Filters</span>
                  )}
                </button>
              </div>
            </div>
            <div className="mt-3">
              <StableTable
                tableHeading={STABLE_TABLE_HEADING}
                searchPlaceHolder={STABLE_SEARCH_PLACEHOLDER}
                loading={loading}
                data={transactions}
                TableRow={TableRows}
                createRows={createRows}
                headings={StableTableHeadings}
                tostify={tostify}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default StableCoins;
