import React from "react";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { useQontoStepIconStyles } from "./style";
import { Check } from "@material-ui/icons";
import clsx from "clsx";

export const StepperLabels = (props) => {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <ArrowDropUpIcon />
      )}
    </div>
  );
};

export const getSteps = () => {
  return ["Personal Details", "Verify Identity", "Security"];
};
