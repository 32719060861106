import { useState, useEffect } from "react";
import Btnloader from "../Comps-Utils/ClipLoader";
// import TextField50 from "../Comps-Utils/Textfield50";
// import TextFiledLabel from "../Comps-Utils/TextfieldLabelRequired";
// import { ConnectedFocusError } from "focus-formik-error";
// import { GeneralFormFields } from "./helpers";
// import Uploader from "../Comps-Utils/ProfileUploaderField";
import Uploader from "./upload";
// import { Card, Button } from "@material-ui/core";
import { Collapse } from "@material-ui/core";
const KycForm = (props) => {
  const {
    // upload,
    setUpload,
    // upload1,
    setUpload1,
    handleSubmit,
    setFieldValue,
    actionState,
    isSubmitting,
    setDocType,
    passportImg,
    setPasspostImg,
    cnicFront,
    setCnicFront,
    cnicBack,
    setCnicBack,
    drivingImg,
    setDrivingImg,
    // docType,
    kycDocs,
  } = props;
  const [openPassport, setOpenPassport] = useState(true);
  const [openCnic, setOpenCnic] = useState(false);
  const [openDriver, setOpenDriver] = useState(false);
  const [openCnicError, setOpenCnicError] = useState(false);

  useEffect(() => {
    // setFieldValue("upload", upload);
    // setFieldValue("upload1", upload1);
    setDocType("passport");
    if (openPassport) {
      setDocType("passport");
    }
    if (openCnic) {
      setDocType("cnic");
    }
    if (openDriver) {
      setDocType("license");
    }
    // if (openPassport) {
    //   setFieldValue("type", "passport");
    // }
    // if (openDriver) {
    //   setFieldValue("type", "license");
    // }
    // eslint-disable-next-line
  }, [openPassport, openDriver, openCnic]);

  // useEffect(() => {
  //   setFieldValue("upload1", upload1);

  //   // if (openCnic) {
  //   //   setFieldValue("type", "cnic");
  //   // }
  // }, [upload1, setFieldValue, openCnic]);
  // useEffect(() => {
  //   if (upload !== "" && upload !== "") {
  //     setDocType("cnic");
  //   } else {
  //     setDocType("");
  //   }
  // }, [openCnic]);

  useEffect(() => {
    if (passportImg) {
      setFieldValue("upload", passportImg);
      setFieldValue("passport", passportImg);
      setUpload(passportImg);
    }
    if (cnicFront) {
      setFieldValue("upload", cnicFront);
      setFieldValue("cnicFront", cnicFront);
      setUpload(cnicFront);
    }
    if (drivingImg) {
      setFieldValue("upload", drivingImg);
      setFieldValue("license", drivingImg);
      setUpload(drivingImg);
    }
    if ((cnicFront && !cnicBack) || (!cnicFront && cnicBack)) {
      setOpenCnicError(true);
    } else {
      setOpenCnicError(false);
    }
    // eslint-disable-next-line
  }, [passportImg, cnicFront, drivingImg]);

  useEffect(() => {
    if (cnicBack) {
      setFieldValue("upload1", cnicBack);
      setFieldValue("cnicBack", cnicBack);
      setUpload1(cnicBack);
    }
    if ((cnicFront && !cnicBack) || (!cnicFront && cnicBack)) {
      setOpenCnicError(true);
    } else {
      setOpenCnicError(false);
    }
    // eslint-disable-next-line
  }, [cnicBack]);
  return (
    <>
      <div className="from-step-item fff">
        <div className="from-step-content">
          <div className="note note-md note-info note-plane">
            <em className="fas fa-info-circle"></em>
            <p>Please upload any of the following personal documents.</p>
            {openCnicError && (
              <span className="error1">
                National ID Front and Back side are required.
              </span>
            )}
          </div>
          <div className="gaps-2x"></div>
          <ul className="nav nav-tabs nav-tabs-bordered" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                onClick={() => {
                  setOpenPassport(true);
                  setOpenCnic(false);
                  setOpenDriver(false);
                  // setUpload("");
                  // setUpload1("");
                }}
                href="#passport"
              >
                <div className="nav-tabs-icon">
                  <img src="images/icon-passport.png" alt="icon" />
                  <img src="images/icon-passport-color.png" alt="icon" />
                </div>
                <span>Passport</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                onClick={() => {
                  setOpenPassport(false);
                  setOpenCnic(true);
                  setOpenDriver(false);
                  // setUpload("");
                  // setUpload1("");
                }}
                href="#national-card"
              >
                <div className="nav-tabs-icon">
                  <img src="images/icon-national-id.png" alt="icon" />
                  <img src="images/icon-national-id-color.png" alt="icon" />
                </div>
                <span>National Card</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#driver-licence"
                onClick={() => {
                  setOpenPassport(false);
                  setOpenCnic(false);
                  setOpenDriver(true);
                  // setUpload("");
                  // setUpload1("");
                }}
              >
                <div className="nav-tabs-icon">
                  <img src="images/icon-licence.png" alt="icon" />
                  <img src="images/icon-licence-color.png" alt="icon" />
                </div>
                <span>Driver’s License</span>
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <Collapse in={openPassport} timeout="auto" unmountOnExit>
              <div className="tab-pane fade show active" id="passport">
                <h5 className="kyc-upload-title">
                  To avoid delays when verifying account, Please make sure
                  bellow:
                </h5>
                <ul className="kyc-upload-list">
                  <li>Chosen credential must not be expired.</li>
                  <li>
                    Document should be in good condition and clearly visible.
                  </li>
                  <li>Make sure that there is no light glare on the card.</li>
                </ul>
                <div className="gaps-4x"></div>
                <span className="upload-title">
                  Upload Here Your Passport Copy
                </span>
                <div className="row align-items-center">
                  <div className="col-8">
                    <div className="upload-box">
                      <div className="upload-zone">
                        <div className="dz-message" data-dz-message>
                          {/* <span className="dz-message-text">
                            Drag and drop file
                          </span>
                          <span className="dz-message-or">or</span> */}
                          <label className="custom-file-upload tab-upload-btn">
                            <Uploader
                              upload={passportImg}
                              setUpload={setPasspostImg}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="kyc-upload-img">
                      <img
                        src={
                          passportImg
                            ? URL.createObjectURL(passportImg)
                            : kycDocs && kycDocs.passport.imageurl
                            ? kycDocs.passport.imageurl
                            : "images/vector-id-front.png"
                        }
                        alt="vector"
                      />
                    </div>
                  </div>
                </div>
                <div className="gaps-1x"></div>
              </div>
            </Collapse>
            <Collapse in={openCnic} timeout="auto" unmountOnExit>
              <>
                <h5 className="kyc-upload-title">
                  To avoid delays when verifying account, Please make sure
                  bellow:
                </h5>
                <ul className="kyc-upload-list">
                  <li>Chosen credential must not be expired.</li>
                  <li>
                    Document should be in good condition and clearly visible.
                  </li>
                  <li>Make sure that there is no light glare on the card.</li>
                </ul>
                <div className="gaps-4x"></div>
                <span className="upload-title">
                  Upload Here Your National id Front Side
                </span>
                <div className="row align-items-center">
                  <div className="col-8">
                    <div className="upload-box">
                      <div className="upload-zone">
                        <div className="dz-message" data-dz-message>
                          {/* <span className="dz-message-text">
                            Drag and drop file
                          </span>
                          <span className="dz-message-or">or</span>
                          <button className="btn btn-primary">SELECT</button> */}
                          <label className="custom-file-upload tab-upload-btn">
                            <Uploader
                              upload={cnicFront}
                              setUpload={setCnicFront}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="kyc-upload-img">
                      <img
                        src={
                          cnicFront
                            ? URL.createObjectURL(cnicFront)
                            : kycDocs && kycDocs.nationalId.frontimage
                            ? kycDocs.nationalId.frontimage
                            : "images/vector-id-front.png"
                        }
                        alt="vector"
                      />
                    </div>
                  </div>
                </div>
                <div className="gaps-3x"></div>
                <span className="upload-title">
                  Upload Here Your National id Back Side of ID
                </span>
                <div className="row align-items-center">
                  <div className="col-8">
                    <div className="upload-box">
                      <div className="upload-zone">
                        <div className="dz-message" data-dz-message>
                          {/* <span className="dz-message-text">
                            Drag and drop file
                          </span>
                          <span className="dz-message-or">or</span>
                          <button className="btn btn-primary">SELECT</button> */}
                          <label className="custom-file-upload tab-upload-btn">
                            <Uploader
                              upload={cnicBack}
                              setUpload={setCnicBack}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="kyc-upload-img">
                      <img
                        src={
                          cnicBack
                            ? URL.createObjectURL(cnicBack)
                            : kycDocs && kycDocs.nationalId.backimage
                            ? kycDocs.nationalId.backimage
                            : "images/vector-id-front.png"
                        }
                        alt="vector"
                      />
                    </div>
                  </div>
                </div>
                <div className="gaps-1x"></div>
              </>
            </Collapse>
            <Collapse in={openDriver} timeout="auto" unmountOnExit>
              <>
                <h5 className="kyc-upload-title">
                  To avoid delays when verifying account, Please make sure
                  bellow:
                </h5>
                <ul className="kyc-upload-list">
                  <li>Chosen credential must not be expired.</li>
                  <li>
                    Document should be in good condition and clearly visible.
                  </li>
                  <li>Make sure that there is no light glare on the card.</li>
                </ul>
                <div className="gaps-4x"></div>
                <span className="upload-title">
                  Upload Here Your Driving Licence Copy
                </span>
                <div className="row align-items-center">
                  <div className="col-8">
                    <div className="upload-box">
                      <div className="upload-zone">
                        <div className="dz-message" data-dz-message>
                          {/* <span className="dz-message-text">
                            Drag and drop file
                          </span> */}
                          {/* <span className="dz-message-or">or</span> */}
                          <label className="custom-file-upload tab-upload-btn">
                            <Uploader
                              upload={drivingImg}
                              setUpload={setDrivingImg}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="kyc-upload-img">
                      <img
                        src={
                          drivingImg
                            ? URL.createObjectURL(drivingImg)
                            : kycDocs && kycDocs.license.imageurl
                            ? kycDocs.license.imageurl
                            : "images/vector-id-front.png"
                        }
                        alt="vector"
                      />
                    </div>
                  </div>
                </div>
                <div className="gaps-1x"></div>
              </>
            </Collapse>
          </div>
          <div className="gaps-2x"></div>
          {actionState === "edit" ? (
            <div className="d-flex justify-content-end">
              <button
                disabled={
                  isSubmitting ||
                  (openPassport && !passportImg) ||
                  (openDriver && !drivingImg) ||
                  (openCnic && !cnicFront) ||
                  (openCnic && !cnicBack) ||
                  openCnicError
                }
                onClick={handleSubmit}
                type="button"
                className="btn btn-primary ml-1 p-1"
              >
                {isSubmitting ? (
                  <span className="ml-3">
                    <Btnloader />{" "}
                  </span>
                ) : (
                  "Save me"
                )}
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default KycForm;
