import React, { useState, useEffect } from "react";
import { Table, TableContainer } from "@material-ui/core";
import { Pagination } from "@mui/material";
import TableHeader from "../../../helpers/TableHeader";
import DataNotFound from "../DataNotFoundTable";
import { getComparator, stableSort } from "../../../helpers/Sorting";
import { getCount, paginate } from "../../../helpers/Paginate";
import Search from "../SeacrchField";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "@mui/material/Modal";
import { searchBinding } from "./helpers";
import useMediaQuery from "@mui/material/useMediaQuery";

import GenericModalBody from "../AddEditFormModal";
import { Button } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { GetReferralTransactionInfo } from "../../../Services/User/Tokens/token";
import DialogBox from "../DialougeBox";
import { DialogMessage } from "../../../helpers/dialouge";
const GenericTable = (props) => {
  const {
    setLoading,
    setGeneral,
    setAlert,
    add,
    update,
    initialValues,
    schema,
    setInitialValues,
    formFields,
    updatedRow,
    setUpdatedRow,
    actionState,
    setActionState,
    open,
    handleOpen,
    handleClose,
    tableHeading,
    data,
    loading,
    createRows,
    headings,
    TableRow,
    ModalBody,
    Refetch,
    deleteRow,
    updateUserStatus,
    updateKycStatus,
    modalHeading,
    isModal,
    setSelected,
    selected,
    searchPlaceHolder,
    openProfile,
    setOpenProfile,
    role,
    kycRights,
    roles,
    airdropModalShowHandle,
    setTostify,
    tostify,
    transactionSubmitModalShowHandle,
  } = props;
  const [rowData, setRowData] = useState([]);
  //pagination of table

  // bug-filter solving in process (only works search on first paginate page)
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const pagination = paginate(page, rowsPerPage);
  const [searchBar, setSearchBar] = useState({
    placeholder: "",
    onSearch: () => {},
  });
  const [dialog, setDialog] = useState({ visible: false });
  const [searchText, setSearchText] = useState("");
  // sorting states
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("userId");
  //search binding
  //Use Effect To set RowData
  useEffect(() => {
    setRowData(data);
  }, [data]);
  //Use Effect To Seacrh
  useEffect(() => {
    searchBinding(setSearchBar, setRowData, data, searchPlaceHolder);
    // eslint-disable-next-line
  }, [setSearchBar, data]);

  //set rows
  const [rows, setRows] = useState([]);
  const rowsAll = createRows(rowData);
  //Change Page pagination Handler
  useEffect(() => {
    // resolve filter options here
    if (searchText !== "") {
      setRows(rowsAll?.slice(0, rowsPerPage));
    } else {
      setRows(rowsAll?.slice(pagination.start, pagination.end));
    }
    //eslint-disable-next-line
  }, [rowData, page]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectFinal = (name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handelRefWarinig = (name) => {
    DialogMessage(
      setDialog,
      "",
      <>
        <p className="text-primary">Waring</p>
      </>,
      <>
        <p>
          Current Users Referral does'nt have wallet address do you want to
          continue?
        </p>
      </>,
      () => {
        handleSelectFinal(name);
      },
      () => {}
    );
  };
  //Sorting Handler
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked && selected.length == 0) {
      const newSelecteds = rowsAll.map((n) => {
        return n.from !== "Not Given" ? n.id : null;
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleSelectClick = async (event, name, from, userId) => {
    if (from == "Not Given") {
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: "error",
        message: "User's Wallet address dose not exist!",
      });
    } else {
      let refRes = await GetReferralTransactionInfo(userId);
      if (
        refRes.data === "0x0000000000000000000000000000000000000000" &&
        refRes.count === 1
      ) {
        handelRefWarinig(name);
      } else {
        handleSelectFinal(name);
      }
    }
  };
  const isSelected = (id) => (selected ? selected.indexOf(id) !== -1 : null);
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   // setPage(0);
  // };
  // const ActionComponentDisabled = () => <span />;
  const matches = useMediaQuery("(max-width:576px)");

  const [responsiveSearch, setResponsiveSearch] = useState(true);

  return (
    <>
      <DialogBox {...dialog} />
      <div className="block block-rounded block-bordered ggg">
        <div
          className={
            matches && responsiveSearch
              ? "block-header block-header-default bg-zinnia-grad-alt header-pb"
              : "block-header block-header-default bg-zinnia-grad-alt "
          }
        >
          <h5 className="block-title">
            <span className="">{tableHeading}</span>
          </h5>
          <div className="d-flex align-items-center search-w">
            <div className="d-flex align-items-center search-mx">
              <Search
                className="w-100"
                searchBar={searchBar}
                setSearchBar={setSearchBar}
                setResponsiveSearch={setResponsiveSearch}
                searchText={searchText}
                setSearchText={setSearchText}
              />
            </div>
            <div>
              {handleOpen ? (
                <>
                  <IconButton
                    disabled={
                      props &&
                      props.kycRights &&
                      props.kycRights.addKyc === false
                    }
                    onClick={handleOpen}
                    color="primary"
                    aria-label="add to shopping cart"
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-plus"
                      className={
                        props &&
                        props.kycRights &&
                        props.kycRights.addKyc === false
                          ? "font-size-h3 text-xplay-lighter"
                          : "font-size-h3 text-white"
                      }
                    />
                  </IconButton>
                </>
              ) : null}
            </div>
          </div>
          {tableHeading === "AirDrops" ? (
            <Button className="btn-black" onClick={airdropModalShowHandle}>
              <FontAwesomeIcon icon={faPlus} className="plus-icon me-2" />{" "}
              Manual Airdrop
            </Button>
          ) : (
            ""
          )}
          <Modal
            open={open ? open : false}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div>
              {tableHeading === "User Kyc" ? (
                <ModalBody
                  Refetch={Refetch}
                  setUpdatedRow={setUpdatedRow}
                  updatedRow={updatedRow}
                  actionState={actionState}
                  setActionState={setActionState}
                  handleClose={handleClose}
                />
              ) : (
                <GenericModalBody
                  roles={roles}
                  isModal={isModal}
                  heading={modalHeading}
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                  schema={schema}
                  formFields={formFields}
                  add={add}
                  update={update}
                  setAlert={setAlert}
                  setGeneral={setGeneral}
                  setLoading={setLoading}
                  setUpdatedRow={setUpdatedRow}
                  updatedRow={updatedRow}
                  actionState={actionState}
                  handleClose={handleClose}
                />
              )}
            </div>
          </Modal>
        </div>
        <TableContainer>
          <Table className="table m-0">
            <TableHeader
              tableHeading={tableHeading}
              Columns={headings}
              setRowsPerPage={setRowsPerPage}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              rowCountAll={rowsAll.length}
              handleSelectAllClick={handleSelectAllClick}
              numSelected={selected ? selected.length : 0}
              deleteRow={deleteRow}
            />
            <tbody>
              {rowData.length === 0 && loading === false ? (
                <DataNotFound />
              ) : (
                stableSort(
                  tableHeading === "Manage Permissions" ? rowsAll : rows,
                  getComparator(order, orderBy)
                ).map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <>
                      {/* {tableHeading === "Permissions" &&
                        row.type === "profile" ? (
                          <TableRowCollapse
                            key={Math.random().toString(36).substring(7)}
                            row={row}
                            index={index}
                            open={openProfile}
                            setOpen={setOpenProfile}
                          />
                        ) : null} */}
                      <TableRow
                        className="permission-table"
                        key={
                          Math.random().toString(36).substring(7) +
                          "tablerow" +
                          index +
                          Math.random().toString(36).substring(7)
                        }
                        kycRights={kycRights}
                        handleSelectClick={handleSelectClick}
                        updateKycStatus={updateKycStatus}
                        updateUserStatus={updateUserStatus}
                        deleteRow={deleteRow}
                        role={role}
                        row={row}
                        index={index}
                        actionState={actionState}
                        setUpdatedRow={setUpdatedRow}
                        setActionState={setActionState}
                        handleOpen={handleOpen}
                        selected={isItemSelected}
                        open={openProfile}
                        setOpen={setOpenProfile}
                        headings={headings}
                        transactionSubmitModalShowHandle={
                          transactionSubmitModalShowHandle
                        }
                      />
                    </>
                  );
                })
              )}
            </tbody>
          </Table>
        </TableContainer>
        <div className="divider" />
        {tableHeading === "Manage Permissions" ? null : (
          <>
            <div className="pagination-css px-md-4 py-md-3 px-2 py-1 d-flex justify-content-center">
              <Pagination
                count={getCount(rowData.length || 0, rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                showFirstButton
                showLastButton
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default GenericTable;
