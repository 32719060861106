import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  FetchUserKycStatus,
  FetchUserProfileImg,
} from "../../Services/User/User/user.js";
import { NavLink } from "react-router-dom";
import { ImageContext } from "../../App";
import { KycContext } from "../../userRoutes";
import Tooltip from "@mui/material/Tooltip";
import { WalletContext } from "../../App";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import InfoIcon from "@mui/icons-material/Info";
import { GetVestingCount } from "../../Services/User/web3Calls/contractCall.js";
import CancelIcon from "@mui/icons-material/Cancel";
// import DonutSmallIcon from "@mui/icons-material/DonutSmall";
// import PendingActionsIcon from "@mui/icons-material/PendingActions";
function SideNav() {
  const [profileImage, setProfileImage] = useContext(ImageContext);
  const [kycStatus, setKycStatus] = useContext(KycContext);
  const [status, setStatus] = useState("-");
  // notify open close
  // const [isActive, setIsActive] = useState(true);
  // const [isAccount, setIsAccount] = useState(true);

  // const handleClick = () => {
  // 👇️ toggle isActive state on click
  //   setIsActive(false);
  // };
  // const handleClickAccount = () => {
  // 👇️ toggle isActive state on click
  // setIsAccount(false);
  // };

  //  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line
  const [img, setImg] = useState("");
  const [blockedKyc, setBlockedKyc] = useState(false);
  const [walletAddress] = useContext(WalletContext);
  const user =
    localStorage.getItem("user") === "undefined" ||
    !localStorage.getItem("user")
      ? { email: "", username: "", _id: "" }
      : JSON.parse(localStorage.getItem("user"));
  const [disableTabs, setDisableTabs] = useState({
    wallet: true,
    application: true,
    buyTokens: true,
    claim: true,
    referral: true,
  });
  useEffect(() => {
    fetchStatus();
    fetchProfileImage();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    fetchTabsInfo();
    // eslint-disable-next-line
  }, [window.ethereum, walletAddress, kycStatus]);
  const fetchTabsInfo = async () => {
    var wallet = true,
      application = true,
      buyTokens = true,
      claim = true,
      referral = true;
    if (kycStatus === "active") {
      application = false;
      setBlockedKyc(false);
    } else if (
      kycStatus === "blocked" ||
      kycStatus === "in progress" ||
      kycStatus === "pending"
    ) {
      setBlockedKyc(true);
    }
    if (user.walletAddress !== "" || walletAddress !== "Connect Wallet") {
      wallet = false;
    }
    if (window.ethereum !== undefined) {
      if (walletAddress !== "Connect Wallet") {
        try {
          var vestingCount = await GetVestingCount(walletAddress);

          if (vestingCount > 0) {
            buyTokens = false;
            claim = false;
          }
        } catch (e) {
          console.error(e);
        }
      }
    }

    setDisableTabs({
      ...disableTabs,
      wallet: wallet,
      application: application,
      claim: claim,
      buyTokens: buyTokens,
      referral: referral,
    });
  };
  // eslint-disable-next-line
  const [tootOpen, setToolOpen] = useState(false);
  // const handleTooltipClose = () => {
  //   setToolOpen(false);
  // };
  const handleTooltipOpen = () => {
    setToolOpen(true);
  };

  useEffect(() => {});
  const fetchStatus = async () => {
    const status = await FetchUserKycStatus();

    const { message } = status;
    const kycStatus = message.toLowerCase();
    setKycStatus(kycStatus);
    setStatus(status.message);
  };
  const fetchProfileImage = async () => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const profile = await FetchUserProfileImg(userInfo.profileImgUrl);
    if (profile.data !== "") {
      // setProfileImage(process.env.REACT_APP_BASE_URL + profile.data);
      // setImg(process.env.REACT_APP_BASE_URL + profile.data);
      setProfileImage(profile.data);
      setImg(profile.data);
    } else {
      setProfileImage("images/user-thumb-lg.png");
    }
  };
  const addDefaultSrc = (ev) => {
    ev.target.src = "images/user-thumb-lg.png";
  };

  return (
    <>
      <div className="user-sidebar">
        <div className="user-sidebar-overlay"></div>
        <div className="user-box d-none d-lg-block">
          <div className="user-image ">
            <img
              className="rounded-circle"
              src={profileImage ? profileImage : ""}
              alt="thumb"
              onError={addDefaultSrc}
            />
          </div>
          <h6 className="user-name">{user.username}</h6>
          <Tooltip
            interactive={true}
            disableFocusListener
            placement="top"
            title={
              <div className="p-2">
                <span className="font-w700">{user._id}</span>
                <span
                  className=" font-bold text-black ml-3 cursor-puri"
                  onClick={() => navigator.clipboard.writeText(user._id)}
                >
                  Copy
                </span>
              </div>
            }
          >
            <div onClick={handleTooltipOpen} className="user-uid">
              Unique ID:{" "}
              <span>{JSON.stringify(user._id)?.substring(1, 7) + "..."}</span>
            </div>
          </Tooltip>
          <ul className="btn-grp guttar-10px">
            {/* <li>
              <span className='btn btn-xs btn-warning'>
                Confirm Email
              </span>
            </li> */}
            <li>
              {status === "active" ? (
                <span className="btn btn-xs btn-success">KYC {kycStatus}</span>
              ) : status === "in progress" ? (
                <span className="btn btn-xs btn-warning">KYC {kycStatus}</span>
              ) : (
                <span className="btn btn-xs btn-warning">KYC {kycStatus}</span>
              )}
            </li>
          </ul>
        </div>
        <ul className="user-icon-nav">
          <nav>
            <li>
              <NavLink to="/dashboard">
                <em className="ti ti-dashboard"></em>Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink to="/setupwallet">
                <div className="d-flex justify-content-between w-100">
                  <div>
                    <em className="ti ti-wallet me-3"></em>
                    <span className="pr-2">1. Set Up Your Wallet</span>
                  </div>
                  <span>
                    {disableTabs.wallet ? (
                      <>
                        <CancelIcon />
                      </>
                    ) : (
                      <span className="check-icon">
                        <CheckCircleIcon sx={{ color: "green" }} />
                      </span>
                    )}
                  </span>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink to="/kyc-verification">
                <div className="d-flex justify-content-between w-100">
                  <div>
                    <em className="ti ti-files me-3"></em>
                    <span className="mr-2">2. Identity Verification</span>
                  </div>
                  <span>
                    {disableTabs.application ? (
                      <>
                        <CancelIcon />
                      </>
                    ) : (
                      <span className="check-icon">
                        <CheckCircleIcon sx={{ color: "green" }} />
                      </span>
                    )}
                  </span>
                </div>
              </NavLink>
            </li>
            <li>
              {blockedKyc ? (
                <div className="d-flex justify-content-between w-100">
                  <div>
                    <em className="ti ti-pie-chart me-3"></em>
                    <span className="mr-2">3. Buy Tokens</span>

                    {/* <InfoIcon /> */}
                  </div>
                  <span>
                    {disableTabs.buyTokens ? (
                      <>
                        <CancelIcon />
                      </>
                    ) : (
                      <span className="check-icon">
                        <CheckCircleIcon sx={{ color: "green" }} />
                      </span>
                    )}
                  </span>
                </div>
              ) : (
                <NavLink to="/buytokens">
                  <div className="d-flex justify-content-between w-100">
                    <div>
                      <em className="ti ti-pie-chart me-3"></em>
                      <span className="mr-2" disable={disableTabs.buyTokens}>
                        3. Buy Tokens
                      </span>
                    </div>
                    <span>
                      {disableTabs.buyTokens ? (
                        <>
                          <CancelIcon />
                        </>
                      ) : (
                        <span className="check-icon">
                          <CheckCircleIcon sx={{ color: "green" }} />
                        </span>
                      )}
                    </span>
                  </div>
                </NavLink>
              )}
            </li>

            <li>
              <NavLink to="/claim">
                <div className="d-flex justify-content-between w-100">
                  <div>
                    <em className="ti ti-wand me-3"></em>
                    <span className="mr-2">4. Claim Tokens</span>
                  </div>
                  <span>
                    {disableTabs.claim ? (
                      <>
                        <CancelIcon />
                      </>
                    ) : (
                      <span className="check-icon">
                        <CheckCircleIcon sx={{ color: "green" }} />
                      </span>
                    )}
                  </span>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink to="/referral">
                <div>
                  <em className="ti ti-star me-3"></em>
                  <span className="mr-2">Referral</span>

                  {/* <span>
                    {disableTabs.claim ? (
                      <>
                        <CancelIcon />
                      </>
                    ) : (
                      <span className="check-icon">
                        <CheckCircleIcon sx={{ color: "green" }} />
                      </span>
                    )}
                  </span> */}
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink to="/transactions">
                <em className="ti ti-control-shuffle"></em>Transactions
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/account"
                // onClick={handleClickAccount}
              >
                <div className="d-flex justify-content-between w-100">
                  {/* <div class="msgBox">
                  <div class="arrow">
                    <div class="outer"></div>
                    <div class="inner"></div>
                  </div>
                  <div class="message-body">
                    <p>Hello world! This is a test message to show how to make an arrow on the side of the box.</p>
                  </div>
                </div> */}

                  <div className="d-flex position-relative">
                    <div>
                      <em className="ti ti-user me-3"></em>
                      <span className="mr-2 pr-2">Account</span>
                      {/* {!user.walletAddress && isAccount ? (
                        <>
                          <div className="badge-without-number with-wave"></div>
                        </>
                      ) : (
                        ""
                      )} */}
                    </div>

                    {/* {!user.walletAddress && isAccount ? (
                      <>
                        <div
                          className={isActive ? "icnBox" : "icnBoxhide"}
                          onClick={handleClick}
                        >
                          <span
                            className={isActive ? "icnCircle" : "icnBoxhide"}
                          >
                            <i className="fa fa-fw fa-close"></i>
                          </span>

                          {isActive == true && (
                            <span className="msg-icn">
                              Please provide your wallet address */}
                    {/* Make money from answering simple questions. We pay you in cash. Simple and fun. */}
                    {/* </span>
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink to="/security">
                <em className="ti ti-lock"></em>Security
              </NavLink>
            </li>
          </nav>
        </ul>
        <div className="user-sidebar-sap"></div>
        <ul className="user-nav">
          <li>
            {/* <Link to="/how-to-buy">How to buy?</Link> */}
            <a
              href="https://bettsrecruiting.com/recruitcoin/"
              target="_blank"
              rel="noreferrer"
            >
              About RecruitCoin
            </a>
          </li>
          {/* <li>
            <Link to="/faqs">Faqs</Link>
          </li> */}
          <li>
            Contact Support<span>support@bettsrecruiting.com</span>
          </li>
        </ul>
        <div className="d-lg-none">
          <div className="user-sidebar-sap"></div>
          <div className="gaps-1x"></div>
          <ul className="topbar-action-list">
            <li className="topbar-action-item topbar-action-link">
              <Link to="/">
                <em className="ti ti-home"></em> Go to main site
              </Link>
            </li>
            <li className="dropup topbar-action-item topbar-action-language">
              <span data-bs-toggle="dropdown" aria-haspopup="true">
                {" "}
                EN <em className="ti ti-angle-up"></em>{" "}
              </span>
              <ul className="dropdown-menu">
                <li>
                  <span>FR</span>
                </li>
                <li>
                  <span>JY</span>
                </li>
                <li>
                  <span>CH</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
export default SideNav;
