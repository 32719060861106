import { useState } from "react";
import ActionGroup from "../Comps-Utils/ActionsButtonGroup";
// import moment from 'moment'
import ModalAirdrop from "./modalairdrop";
import moment from "moment";
import { MMMM_Do_YYYY, h_mm_ss_a } from "../../helpers/constants";
import { TEXT_LEFT } from "../../helpers/CLASSES";

function numberWithCommas(x) {
  return parseFloat(x).toLocaleString(undefined, { maximumFractionDigits: 20 });
}

const TableRow = (props) => {
  const { row, index, selected, handleSelectClick, deleteRow } = props;

  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  // const handleAirdrops = () => {
  //   setOpenModal(true);
  // };
  return (
    <>
      {openModal ? (
        <ModalAirdrop handleCloseModal={handleCloseModal} data={row} />
      ) : (
        <></>
      )}
      <tr key={row.id + index} id={"transaction-tr-" + index}>
        <td className="px-4 text-left text-capitalize">
          <label style={{ margin: "0" }}>
            <input
              type="checkbox"
              name="checkbox"
              id={`custom-checkbox-${index}`}
              onChange={(e) =>
                handleSelectClick(e, row.id, row.from, row.userid)
              }
              checked={selected}
              style={{ height: "20px", width: "20px", marginTop: "8px" }}
            />
          </label>
        </td>
        <td className="text-left ">
          <span>👤 {row.name}</span>
          <span style={{ fontSize: "10px", textTransform: "initial" }}>
            📧 {row.email}
          </span>
        </td>
        <td className="text-left">
          <span className="received_amount">
            💰{" "}
            <b>
              {numberWithCommas(row.invested)} {row.paymentMethod}
            </b>
          </span>
          <span>
            ⭕{" "}
            <b>
              {numberWithCommas(row.payableToken)} {row.tokens}
            </b>
          </span>
        </td>
        <td>
          <span>👛 {row.from}</span>
          <span>
            {" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>Wallet Address</small>
          </span>
        </td>
        <td className={TEXT_LEFT}>
          <span>🕛 {moment(row.updatedAt).format(MMMM_Do_YYYY)}</span>
          <span>
            {" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            {moment(row.updatedAt).format(h_mm_ss_a)}
          </span>
        </td>
        <td className="text-left table-btn-group">
          <>
            <ActionGroup
              disKycDelete={row.manual && row.manual == true ? true : false}
              row={row}
              deleteRow={deleteRow}
            />
          </>
        </td>
      </tr>
    </>
  );
};
export default TableRow;
