import { Link } from "react-router-dom";
import Logo from "./assets/images/footer-logo.svg";
const Footer = () => {
  return (
    <footer className="nk-footer bg-theme-grad-alt">
      <section className="section-l section-footer py-0 tc-light tc-light-alt bg-transparent">
        <div className="ui-shape ui-shape-footer ui-shape-light"></div>
        <div className="container">
          <div className="nk-block block-footer">
            <div className="row my-md-0 mb-n4 justify-content-between align-items-start">
              <div className="col-lg-6 col-sm-4">
                <Link to={""} className="wgs-logo-s2 d-block mb-lg-4 mb-3">
                  <img src={Logo} alt="logo" />
                </Link>
                <div className="desktop-copyrights">
                  <div className="copyright-text mb-3">
                    <p>&copy; ICO Crypto, 2022</p>
                  </div>
                  <p className="mb-lg-3 mb-2">
                    Recruit Coin is a utility coin and does not give the owner
                    of the coin any equity or ownership in Betts Recruiting. The
                    utility of the coin is to exchange for services listed above
                    or exchange for other crypto currencies through standard
                    crypto currency exchanges.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="wgs wgs-subscribe-form">
                  <h6
                    className="wgs-title wgs-title-s2 text-white text-right "
                    data-delay=".7"
                  >
                    Get Updates
                  </h6>
                  <div className="wgs-body mb-3">
                    <div className="wgs-subscribe">
                      <form action="" className="nk-form-submit" method="post">
                        <div
                          className="field-inline field-inline-s3 field-split "
                          data-delay=".8"
                        >
                          <div className="field-wrap">
                            <input
                              className="input-bordered required"
                              type="text"
                              value=""
                              placeholder="Enter your email"
                            />
                            <input
                              type="text"
                              className="d-none"
                              name="form-anti-honeypot"
                              value=""
                            />
                          </div>
                          <div className="submit-wrap">
                            <button className="btn btn-icon btn-light btn-auto">
                              <i className="fas fa-paper-plane"></i>
                            </button>
                          </div>
                        </div>
                        <div className="form-results"></div>
                      </form>
                    </div>
                  </div>
                  <div className="copyright-text text-end mobile-copyrights">
                    <p>&copy; ICO Crypto, 2022</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="footer-bottom section-sm">
              <div className="row justify-content-between align-items-center">
                <div className="col-6"></div>
                <div className="col-6">
                  
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </footer>
  );
};
export default Footer;
