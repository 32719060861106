import { useState, useEffect, useContext } from "react";
import Card from "@mui/material/Card";
import TableRows from "./Tablerow";
import TemplatesTable from "../Comps-Utils/GenericTable";
import SkeltonTable from "../Comps-Utils/Skeltons/TableSkelton";
import {
  GetEmailTemplates,
  AddEmailTemplate,
  UpdateEmailTemplate,
  DeleteEmailTemplate,
} from "../../Services/Admin/EmailTemplates";
import { ToasterContext } from "../../App";
import {
  emailInitialValues,
  EmailSchema,
  setInitialValues,
  EmailFormFields,
  createRows,
  EmailListingTableHeadings,
} from "./helpers";
import { DialogMessage } from "../../helpers/dialouge";
import DialogBox from "../Comps-Utils/DialougeBox";
const EmailTemplates = () => {
  const [general, setGeneral] = useState([]);
  const [tostify, setTostify] = useContext(ToasterContext);
  const [loading, setLoading] = useState(true);
  const [updatedRow, setUpdatedRow] = useState({});
  const [actionState, setActionState] = useState("new");
  const [dialog, setDialog] = useState({ visible: false });
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setActionState("new");
    setUpdatedRow({});
  };
  const GetEmailsService = () => {
    GetEmailTemplates().then((res) => {
      if (res.status === 200) {
        setGeneral(res.data);
        setLoading(false);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const DeleteEmailTemplateDialog = (data) => {
    // setLoading(true);
    DialogMessage(
      setDialog,
      "",
      <>
        <p className="text-primary">Delete Email Template</p>
      </>,
      "Are you sure you want to delete email Template?",
      () => DeleteEmailService(data)
    );
  };
  const Refetch = () => {
    GetEmailTemplates().then((res) => {
      if (res.status === 200) {
        setGeneral(res.data);
      }
    });
  };
  const DeleteEmailService = (id) => {
    DeleteEmailTemplate(id).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
        Refetch();
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? "success" : "error",
        message: res.message,
      });
    });
    // tostify.dismissAll();
  };
  const AddEmailService = async (data, actions) => {
    tostify.dismissAll();
    AddEmailTemplate(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
        // GetEmailsService();
        handleClose();
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const updateEmailService = async (data, actions) => {
    tostify.dismissAll();
    UpdateEmailTemplate(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
        // GetEmailsService();
        handleClose();
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  useEffect(() => {
    GetEmailsService();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <DialogBox {...dialog} />
      <Card className="mx-auto w95 mt-3">
        {loading ? (
          <SkeltonTable header={[0, 1, 2]} rows={[0, 1, 2]} />
        ) : (
          <>
            <div className="email-formating">
              <h4>
                Please use mentioned string for respective email templates.{" "}
              </h4>
              <div className="email-templates-variables row mt-3">
                <div className="col-md-3">
                  <h3>Templates types</h3>
                  <div className="vitem">
                    <span className="me-1">
                      <small>Payment transaction:</small>
                    </span>
                    <code>transaction</code>
                  </div>
                  <div className="vitem">
                    <span className="me-1">
                      <small>Password reset:</small>
                    </span>
                    <code>reset</code>
                  </div>
                  <div className="vitem">
                    <span className="me-1">
                      <small>On KYC approved</small>
                    </span>
                    <code>kyc-approved</code>
                  </div>
                  <div className="vitem">
                    <span className="me-1">
                      <small>Email verification:</small>
                    </span>
                    <code>verification</code>
                  </div>
                  <div className="vitem">
                    <span className="me-1">
                      <small>Manual Airdrop:</small>
                    </span>
                    <code>investor-content</code>
                  </div>
                  <div className="vitem">
                    <span className="me-1">
                      <small>On referred</small>
                    </span>
                    <code>referred-content</code>
                  </div>
                </div>
                <div className="col-md-6">
                  <h3>Variables</h3>
                  <div className="vitem">
                    <span className="me-1">
                      <small>Reset password:</small>
                    </span>
                    <code>http://RESET-PASSWORD</code>
                  </div>
                  <div className="vitem">
                    <span className="me-1">
                      <small>Email verification:</small>
                    </span>
                    <code>http://NEW-USER-EMAIL</code>
                  </div>
                  <div className="vitem">
                    <span className="me-1">
                      <small>Payment transaction:</small>
                    </span>
                    <code>http://TRANSACTION-DETAIL-LINK</code>
                  </div>

                  <div className="vitem">
                    <span className="me-1">
                      <small>Investor first name:</small>
                    </span>
                    <code>{"{{NAME}}"}</code>
                  </div>
                  <div className="vitem">
                    <span className="me-1">
                      <small>Tokens:</small>
                    </span>
                    <code>{"{{TOKENS}}"}</code>
                  </div>
                  <div className="vitem">
                    <span className="me-1">
                      <small>Transaction link (blockchain):</small>
                    </span>
                    <code>{"{{TRANSACTION-LINK}}"}</code>
                  </div>
                  <div className="vitem">
                    <span className="me-1">
                      <small>Vesting schedule:</small>
                    </span>
                    <code>{"{{VESTING}}"}</code>
                  </div>
                  <div className="vitem">
                    <span className="me-1">
                      <small>Manual airdrop link:</small>
                    </span>
                    <code>{"{{DISPLAY-TRANSACTION-LINK}}"}</code>
                  </div>
                  <div className="vitem">
                    <span className="me-1">
                      <small>Referrer name:</small>
                    </span>
                    <code>{"{{REFERREDNAME}}"}</code>
                  </div>
                  <div className="vitem">
                    <span className="me-1">
                      <small>Payment method:</small>
                    </span>
                    <code>{"{{PAYMENTMETHOD}}"}</code>
                  </div>
                </div>
              </div>
              {/* <ul className="d-flex align-items-center justify-content-between">
                <li>
                  <span className="me-1">
                    <small>Reset password:</small>
                  </span>
                  <code>http://RESET-PASSWORD</code>
                </li>
                <li>
                  <span className="me-1">
                    <strong>Email Verification:</strong>
                  </span>{" "}
                  <code>http://NEW-USER-EMAIL</code>
                </li>
                <li>
                  <span className="me-1">
                    <strong>Payment Transaction:</strong>{" "}
                  </span>
                  <code>http://TRANSACTION-DETAIL-LINK</code>
                </li>
              </ul> */}
            </div>
            <TemplatesTable
              //states
              isModal={true}
              modalHeading={"Email Template"}
              tableHeading={"Manage Emails"}
              loading={loading}
              data={general}
              actionState={actionState}
              open={open}
              updatedRow={updatedRow}
              //setters
              setUpdatedRow={setUpdatedRow}
              setActionState={setActionState}
              setLoading={setLoading}
              setGeneral={setGeneral}
              //Handlers
              handleOpen={handleOpen}
              handleClose={handleClose}
              //Table Row Components
              TableRow={TableRows}
              //Helpers States &  Function
              createRows={createRows}
              headings={EmailListingTableHeadings}
              initialValues={emailInitialValues}
              schema={EmailSchema}
              setInitialValues={setInitialValues}
              formFields={EmailFormFields}
              //Services
              deleteRow={DeleteEmailTemplateDialog}
              add={AddEmailService}
              update={updateEmailService}
              tostify={tostify}
            />
          </>
        )}
      </Card>
    </>
  );
};
export default EmailTemplates;
