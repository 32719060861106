import { useState, useEffect, useContext } from "react";
import Alert from "../../../Alert";
import TableRows from "./Tablerow";
import Loader from "../../../Comps-Utils/ClimbingLoader";
import TimeLineTable from "../../../Comps-Utils/GenericTable";
import {
  GetCMSTimelineListing,
  DeleteCMSTimeline,
  UpdateStatusCMSTimeline,
  AddCMSTimelineListing,
  UpdateCMSTimelineListing,
} from "../../../../Services/Admin/CMS/Timeline";
import { ToasterContext } from "../../../../App";
import {
  timelineInitialValues,
  TimelineSchema,
  setInitialValues,
  TimelineFormFields,
  createRows,
  timelineListingTableHeadings,
} from "./helpers";

const TimelineListing = () => {
  const [general, setGeneral] = useState([]);
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [id, setId] = useState();
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ visible: false });
  const [updatedRow, setUpdatedRow] = useState({});
  const [actionState, setActionState] = useState("new");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setActionState("new");
    setUpdatedRow({});
  };
  const GetCMSTimelineLisitngService = () => {
    GetCMSTimelineListing().then((res) => {
      if (res.status === 200) {
        setGeneral(res.data);
        setId(res.data[0]._id);
        setLoading(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const DeleteCMSTimelineService = (id) => {
    DeleteCMSTimeline(id).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const UpdateStatusCMSTimelineService = (id, status) => {
    UpdateStatusCMSTimeline(id, status).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };

  const AddCMSTimelineLisitngService = (data, actions) => {
    AddCMSTimelineListing(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
        handleClose();
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const UpdateCMSTimelineLisitngService = (data) => {
    UpdateCMSTimelineListing(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
        handleClose();
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  useEffect(() => {
    GetCMSTimelineLisitngService();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Alert setAlert={setAlert} {...alert} />;
      <div className="ml-3 mt-3 w97-4">
        {loading ? (
          <Loader />
        ) : (
          <TimeLineTable
            //states
            isModal={true}
            modalHeading={"Timeline"}
            tableHeading={"Manage Timeline"}
            loading={loading}
            data={general}
            id={id}
            actionState={actionState}
            open={open}
            updatedRow={updatedRow}
            //setters
            setUpdatedRow={setUpdatedRow}
            setActionState={setActionState}
            setAlert={setAlert}
            setLoading={setLoading}
            setGeneral={setGeneral}
            //Handlers
            handleOpen={handleOpen}
            handleClose={handleClose}
            //Table Row Components
            TableRow={TableRows}
            //Helpers States &  Function
            createRows={createRows}
            headings={timelineListingTableHeadings}
            initialValues={timelineInitialValues}
            schema={TimelineSchema}
            setInitialValues={setInitialValues}
            formFields={TimelineFormFields}
            //Services
            updateKycStatus={UpdateStatusCMSTimelineService}
            deleteRow={DeleteCMSTimelineService}
            add={AddCMSTimelineLisitngService}
            update={UpdateCMSTimelineLisitngService}
            tostify={tostify}
          />
        )}
      </div>
    </>
  );
};
export default TimelineListing;
