import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ToasterContext } from "../../App";
import ImageList from "../../components/Kyc/ImageViewer/ImageList";
import { getKycDocs } from "../../Services/Admin/Kyc";

function KycDocuments(props) {
  const { userId } = props;
  const [kycDocs, setkycDocs] = useState("");
  const [tostify, setTostify] = useContext(ToasterContext);

  useEffect(() => {
    getKycDocs(userId).then((res) => {
      if (res.status === 200) {
        setkycDocs(res.data);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <h2 className="user-panel-title mt-4">Submitted Documents</h2>
      <div className="d-flex justify-content-between">
        <div className="mx-auto w100">
          <div>
            <div>
              <ImageList kycDocs={kycDocs} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default KycDocuments;
