import React from "react";
import Ethereum from "../../../components/Ethereum";
const EthereumPage = () => {
  return (
    <>
      <Ethereum />
    </>
  );
};

export default EthereumPage;
