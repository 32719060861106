import { getAuthHeader, ParseError } from "../../../Config/utils";
import { API } from "../apiConfig";

export const GetReferralData = async () => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  let response = API.get("referrals/getreferal/", headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
          code: response.code,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};
export const GetBonusData = async () => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();

  let response = API.get("referrals/getBonusRecord/", headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
          code: response.code,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};

export const PostVisitCount = async () => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  let response = API.get("referrals/increaseCount/", headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
          code: response.code,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};

// export const claimBonusUser = async (id) => {
//   const responseData = {
//     status: 210,
//     message: "Something went wrong, Please try again.",
//   };
//   let response = API.get("referrals/claimBonus/", id)
//     .then((response) => {
//       if (response.data && response.status === 200) {
//         response = response.data;
//         return {
//           ...responseData,
//           status: 200,
//           data: response.data,
//           message: response.msg,
//           code: response.code,
//         };
//       } else {
//         return {
//           ...responseData,
//           message: ParseError(response.data),
//         };
//       }
//     })
//     .catch((err) => {
//       return {
//         ...responseData,
//         message: ParseError(
//           err.response && err.response.data ? err.response.data : err.message
//         ),
//       };
//     });
//   return response;
// };
