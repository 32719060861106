import React from "react";
import ReactPlayer from "react-player";

const how_to_buy = () => {
  return (
    <>
      <div className="user-content">
        <div className="user-panel">
          <h2 className="user-panel-title">How to Buy token</h2>
          <div className="user-panel-content">
            <p>
              <strong>
                Investors really are spoiled for choice. There are a lot of
                serious ICOs raising funds for important and useful projects, At
                the same time.
              </strong>
            </p>
            {/* <div className="embed-responsive embed-responsive-16by9"> */}
            <div className="tc-light bg-zinnia  theme-shadow">
              <ReactPlayer
                className="user-panel-video"
                style={{ width: "100%", height: "385px" }}
                url={"https://bettsrecruiting-2.wistia.com/medias/jaoztgwjrh"}
                loop={true}
                playing={false}
                controls={true}
                // light={
                //   "https://fast.wistia.com/embed/medias/jaoztgwjrh/swatch"
                // }
                // thumbnail={
                //   "https://fast.wistia.com/embed/medias/jaoztgwjrh/swatch"
                // }
                src={"https://bettsrecruiting-2.wistia.com/medias/jaoztgwjrh"}
                playIcon={
                  <>
                    <div className="video-popup btn-play btn-play-s3">
                      <svg className="btn-play-icon-s2" viewBox="0 0 24 34">
                        <path
                          fillRule="evenodd"
                          d="M0.212,32.552 C0.427,32.851 0.769,33.010 1.117,33.010 C1.337,33.010 1.559,32.947 1.752,32.814 L23.521,17.879 C23.816,17.678 23.991,17.350 23.991,16.998 C23.991,16.646 23.816,16.319 23.521,16.115 L1.752,1.181 C1.415,0.950 0.972,0.922 0.606,1.107 C0.240,1.292 0.010,1.661 0.010,2.064 L0.010,22.480 C0.010,23.076 0.506,23.558 1.116,23.558 C1.727,23.558 2.222,23.076 2.222,22.480 L2.222,4.142 L20.963,16.998 L0.479,31.049 C-0.020,31.393 -0.140,32.066 0.212,32.552 Z"
                        />
                      </svg>
                    </div>
                  </>
                }
                config={{
                  youtube: {
                    playerVars: { showinfo: 0 },
                  },
                }}
              />
            </div>
            {/* </div> */}
            <h6>Step 1 : Register for an ICO through the project’s website </h6>
            <p>
              Every legitimate project that sources funds through an ICO has a
              website, where they specify what the project is all about, its
              goals, the amount of money needed, how long the funding campaign
              will go on for and so forth.
            </p>
            <img src="images/human-a.jpg" alt="human" />
            <h6>
              Step 2 : Get Bitcoin, Ethereum or any kind of Cryptocurrency{" "}
            </h6>
            <p>
              Bitcoin, still being the single most dominant cryptocurrency, is
              accepted pretty much anywhere in the crypto world. However, as
              Ethereum offers a stable and convenient Blockchain platform for
              developers to set up their projects.
            </p>
            <h6>Step 3 : Buy tokens from active ICO</h6>
            <p>
              Bitcoin, still being the single most dominant cryptocurrency, is
              accepted pretty much anywhere in the crypto world. However, as
              Ethereum offers a stable and convenient Blockchain platform for
              developers to set up their projects.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default how_to_buy;
