import React from "react";
import { useColorlibStepIconStyles } from "./style";
import { MdAccountBox } from "react-icons/md";
import { HiIdentification } from "react-icons/hi";
import { FaIdBadge } from "react-icons/fa";
import PropTypes from "prop-types";
import clsx from "clsx";

export const StepperIcons = (props) => {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  const icons = {
    1: <MdAccountBox className="d-30" />,
    2: <HiIdentification className="d-30" />,
    3: <FaIdBadge className="d-30" />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
};

StepperIcons.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};
