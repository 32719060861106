/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import moment from "moment";
import StatusDropdown from "../Comps-Utils/StatusDropDown/index";
import ButtonGroup from "@mui/material/ButtonGroup";
import Modal from "@mui/material/Modal";
import ImageViewer from "./ImageViewer/ImageViewerModalBody";
import ActionGroup from "../Comps-Utils/ActionsButtonGroup";
import Switch from "../Comps-Utils/Switch";
const TableRow = (props) => {
  const {
    handleOpen,
    row,
    setUpdatedRow,
    index,
    updateKycStatus,
    setActionState,
    updateUserStatus,
    deleteRow,
    kycRights,
  } = props;
  const [imageOpen, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);
  const handleImageClose = () => setImageOpen(false);
  return (
    <>
      <Modal
        open={imageOpen}
        onClose={handleImageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <ImageViewer
            // passport={row.passport.imageurl}
            // license={row.license.imageurl}
            // cnicFront={row.nationalId.frontimage}
            // cnicBack={row.nationalId.backimage}
            userId={row.id}
            handleImageClose={handleImageClose}
          />
        </div>
      </Modal>
      <tr key={row.id} id={"user-profile-tr-" + index}>
        <td className="text-left text-capitalize ">
          <span>{row.userId}</span>
        </td>
        <td className="text-left">
          <span>{row.name}</span>
          <span style={{ fontSize: "10px", textTransform: "initial" }}>
            {row.email}
          </span>
          <span style={{ fontSize: "10px", textTransform: "initial" }}>
            {row.walletAddress}
          </span>
        </td>
        <td className="px-4 text-left ">
          <span>{moment(row.lastLogin).format("MMM DD, YYYY")}</span>
          <span> {moment(row.lastLogin).format("h:mm:ss a")}</span>
        </td>
        <td className="px-4 text-left ">
          <span>{moment(row.date).format("MMM DD, YYYY")}</span>
          <span>{moment(row.date).format("h:mm:ss a")}</span>
        </td>
        <td className="text-left">
          <Switch
            dis={
              kycRights && kycRights.userStatus ? kycRights.userStatus : false
            }
            status={row.userstatus}
            id={row.id}
            service={updateUserStatus}
          />
        </td>
        {/* <td className="px-4 text-left ">
          <Tooltip title="View Kyc Files" arrow>
            <ButtonGroup
              className="p2"
              variant="contained"
              aria-label="primary button group"
            >
            </ButtonGroup>
          </Tooltip>
        </td> */}
        <td className="px-4 kyc-action multicolor-btn btn-shadow-none">
          <ButtonGroup
            className="bg-grey"
            variant="contained"
            aria-label="primary button group"
          >
            <StatusDropdown
              dis={
                kycRights && kycRights.kycStatus ? kycRights.kycStatus : false
              }
              UpdateStatusService={updateKycStatus}
              id={row.id}
              firstname={row.firstname}
              lastname={row.lastname}
              status={row.kycstatus.toLowerCase()}
            />
          </ButtonGroup>
          {/* <Switch UpdateStatusService={UpdateStatusService} id={row.id} checked={(row.status.toLowerCase()) === "active" ? true : false} /> */}
        </td>
        <td className="text-left table-btn-group">
          <>
            <ActionGroup
              disKyc={
                kycRights && kycRights.viewKyc ? kycRights.viewKyc : false
              }
              disKycEdit={
                kycRights && kycRights.editKyc ? kycRights.editKyc : false
              }
              disKycDelete={
                kycRights && kycRights.deleteKyc ? kycRights.deleteKyc : false
              }
              handleImageOpen={handleImageOpen}
              handleOpen={handleOpen}
              setUpdatedRow={setUpdatedRow}
              setActionState={setActionState}
              row={row}
              deleteRow={deleteRow}
            />
          </>
        </td>
      </tr>
    </>
  );
};
export default TableRow;
