import instance from "../../../../Config/axios";
import { ParseError, getAuthHeader } from "../../../../Config/utils";
import { TokenErr } from "../../../../Config/tokenErr";
const UpdateCMSTeam = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .put("/cms/team", { data: formData }, headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.msg,
            code: response.code,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const GetCMSTeamAdvisorListing = async (type) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .get("/team/" + type, headers)
    .then((response) => {
      // if(TokenErr(response.data)){
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const AddCMSTeamAdvisorLisitng = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  const data = new FormData();
  if (formData.profileImgUrl) {
    data.append("profileimg", formData.profileImgUrl);
  }
  data.append("description", formData.description);
  data.append("designation", formData.designation);
  data.append("linkedin", formData.linkedin);
  data.append("name", formData.name);
  data.append("status", formData.status);
  data.append("telegram", formData.telegram);
  data.append("twitter", formData.twitter);
  data.append("type", formData.type);
  return instance
    .post("/team/add", data, headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.msg,
            code: response.code,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const UpdateCMSTeamAdvisorListing = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const data = new FormData();
  if (formData.profileImgUrl) {
    data.append("profileimg", formData.profileImgUrl);
  }
  data.append("description", formData.description);
  data.append("designation", formData.designation);
  data.append("id", formData.id);
  data.append("linkedin", formData.linkedin);
  data.append("name", formData.name);
  data.append("status", formData.status);
  data.append("telegram", formData.telegram);
  data.append("twitter", formData.twitter);
  data.append("type", formData.type);
  data.append("sortValue", formData.sortValue);
  const headers = await getAuthHeader();
  return instance
    .put("/team/edit", data, headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.msg,
            code: response.code,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const DeleteCMSTeamLisiting = async (id) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .delete("/team/delete/" + id, headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.msg,
            code: response.code,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const UpdateCMSTeamStatus = async (id, status, type) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .put("/team/status", { id: id, status: status, type: type }, headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.msg,
            code: response.code,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
export {
  GetCMSTeamAdvisorListing,
  AddCMSTeamAdvisorLisitng,
  UpdateCMSTeamAdvisorListing,
  DeleteCMSTeamLisiting,
  UpdateCMSTeamStatus,
  UpdateCMSTeam,
};
