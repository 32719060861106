import React, { useEffect, useState } from "react";
import { FetchUserActivity } from "../../Services/User/User/user.js";
import moment from "moment";
const Activity = () => {
  const [activity, setActivity] = useState([
    {
      date: "",
      device: "",
      browser: "",
      ip: "",
    },
  ]);
  useEffect(() => {
    FetchActivity();
  }, []);
  const FetchActivity = async () => {
    const activity = await FetchUserActivity();
    setActivity(activity.message);
  };

  return (
    <>
      <div className="user-content">
        <div className="user-panel">
          <h2 className="user-panel-title">Activity</h2>
          <p>
            Here is your recent activities. you can also clear this log as well
            as disable this feature from security settings.{" "}
          </p>
          <table
            className="data-table activity-table dataTable no-footer"
            id="DataTables_Table_0"
          >
            <thead>
              <tr>
                <th className="activity-time">
                  <span>Date</span>
                </th>
                <th className="activity-device">
                  <span>Device</span>
                </th>
                <th className="activity-browser">
                  <span>Browser</span>
                </th>
                <th className="activity-ip">
                  <span>IP</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {activity.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="activity-time">
                      {moment(item.date).format("MMMM Do YYYY, h:mm:ss a")}
                    </td>
                    <td className="activity-device">{item.device}</td>
                    <td className="activity-browser">{item.browser}</td>
                    <td className="activity-ip">{item.ip}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Activity;
