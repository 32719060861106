import { useState, useEffect, useContext } from "react";
import Card from "@mui/material/Card";
import TableRows from "./Tablerow";
import Loader from "../../Comps-Utils/ClimbingLoader";
import LogosTable from "../../Comps-Utils/GenericTable";
import {
  GetCMSLogos,
  DeleteCMSLogo,
  StatusCMSLogo,
  UpdateCMSLogo,
  AddCMSLogo,
} from "../../../Services/Admin/CMS/Logo";
import { ToasterContext } from "../../../App";
import {
  logoInitialValues,
  LogoSchema,
  setInitialValues,
  LogoFormFields,
  createRows,
  LogosListingTableHeadings,
} from "./helpers";
const SliderListing = () => {
  const [general, setGeneral] = useState([]);
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [id, setId] = useState();
  const [loading, setLoading] = useState(true);
  const [updatedRow, setUpdatedRow] = useState({});
  const [actionState, setActionState] = useState("new");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setActionState("new");
    setUpdatedRow({});
  };
  const GetCMSLogoService = () => {
    GetCMSLogos().then((res) => {
      if (res.status === 200) {
        setGeneral(res.data);
        setId(res.data[0]._id);
        setLoading(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const DeleteCMSLogoService = (id) => {
    tostify.dismissAll();
    DeleteCMSLogo(id).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const UpdateStatusCMSLogoService = (id, status) => {
    tostify.dismissAll();
    StatusCMSLogo(id, status).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const AddLogoService = async (data, actions) => {
    tostify.dismissAll();
    AddCMSLogo(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
        handleClose();
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const updateLogoService = async (data, actions) => {
    tostify.dismissAll();
    UpdateCMSLogo(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
        handleClose();
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  useEffect(() => {
    GetCMSLogoService();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Card className="mx-auto w95 mt-3">
        {loading ? (
          <Loader />
        ) : (
          <LogosTable
            //states
            isModal={true}
            modalHeading={"Logo"}
            tableHeading={"Manage Logos"}
            loading={loading}
            data={general}
            id={id}
            actionState={actionState}
            open={open}
            updatedRow={updatedRow}
            //setters
            setUpdatedRow={setUpdatedRow}
            setActionState={setActionState}
            setLoading={setLoading}
            setGeneral={setGeneral}
            //Handlers
            handleOpen={handleOpen}
            handleClose={handleClose}
            //Table Row Components
            TableRow={TableRows}
            //Helpers States &  Function
            createRows={createRows}
            headings={LogosListingTableHeadings}
            initialValues={logoInitialValues}
            schema={LogoSchema}
            setInitialValues={setInitialValues}
            formFields={LogoFormFields}
            //Services
            updateKycStatus={UpdateStatusCMSLogoService}
            deleteRow={DeleteCMSLogoService}
            add={AddLogoService}
            update={updateLogoService}
            tostify={tostify}
          />
        )}
      </Card>
    </>
  );
};
export default SliderListing;
