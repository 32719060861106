import Switch from "../../Comps-Utils/Switch";
// import { serverUrl } from "../../../Config/config";
import ActionGroup from "../../Comps-Utils/ActionsButtonGroup";
const TableRow = (props) => {
  const {
    row,
    index,
    setActionState,
    handleOpen,
    setUpdatedRow,
    deleteRow,
    updateKycStatus,
  } = props;
  return (
    <>
      <tr key={row.id + index} id={"user-profile-tr-" + index}>
        <td className="px-4 text-left">
          <span>{row.title}</span>
        </td>
        <td className="px-4 text-left">
          <div className="logo-list-img">
            <img
              id="bannImg"
              alt="logo"
              // src={serverUrl + "/" + row.profileImgUrl}
              src={row.profileImgUrl}
            />
          </div>
        </td>
        <td className="text-left">
          <span>
            {" "}
            <Switch status={row.status} service={updateKycStatus} id={row.id} />
          </span>
        </td>
        <td className="text-center table-btn-group">
          <ActionGroup
            handleOpen={handleOpen}
            setUpdatedRow={setUpdatedRow}
            setActionState={setActionState}
            row={row}
            deleteRow={deleteRow}
          />
        </td>
      </tr>
    </>
  );
};
export default TableRow;
