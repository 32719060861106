import { useState, useEffect } from "react";
import moment from "moment";
const DateTime = (props) => {
  //
  const { date } = props;
  const [endDate, setEndDate] = useState({});
  const HandleCountDown = () => {
    const futureDate = moment(date);
    const today = moment();
    const timeDuration = moment.duration(futureDate.diff(today));
    setEndDate({
      years: timeDuration.years() >= 0 ? timeDuration.years() : 0,
      months: timeDuration.months() >= 0 ? timeDuration.months() : 0,
      days: timeDuration.days() >= 0 ? timeDuration.days() : 0,
      hours: timeDuration.hours() >= 0 ? timeDuration.hours() : 0,
      minuts: timeDuration.minutes() >= 0 ? timeDuration.minutes() : 0,
      seconds: timeDuration.seconds() >= 0 ? timeDuration.seconds() : 0,
    });
  };
  useEffect(() => {
    const timeInterval = setInterval(() => {
      HandleCountDown();
    }, 1000);
    return () => clearInterval(timeInterval);
  });
  return (
    <>
      {endDate.years === 0 &&
      endDate.months === 0 &&
      endDate.days === 0 &&
      endDate.hours === 0 &&
      endDate.minuts === 0 &&
      endDate.seconds === 0 ? (
        <>
          <p className="font-weight-normal remaingin-balance m-0 font-size-sm text-danger text-center mt-1">
            Deadline Passed
          </p>
        </>
      ) : (
        (endDate.years ||
          endDate.months ||
          endDate.days ||
          endDate.hours ||
          endDate.minuts ||
          endDate.seconds) && (
          <div className="d-flex justify-content-center">
            <p className="d-flex time-count-cont font-weight-bold m-0">
              {/* {endDate.years === 0 ? null : (
                <div className="d-flex flex-column me-3">
                  <span>{endDate.years}</span>
                  <span>Years</span>
                </div>
              )} */}
              <div className="d-flex flex-column me-3">
                <span>{endDate.years}</span>
                <span>Years</span>
              </div>
              {/* {endDate.months === 0 ? null : (
                <div className="d-flex flex-column me-3">
                  <span>{endDate.months}</span>
                  <span>Months</span>
                </div>
              )} */}
              <div className="d-flex flex-column me-3">
                <span>{endDate.months}</span>
                <span>Months</span>
              </div>
              <div className="d-flex flex-column me-3">
                <span>{endDate.days}</span>
                <span>Days</span>
              </div>
              <div className="d-flex flex-column me-3">
                <span>{endDate.hours}</span>
                <span>Hours</span>
              </div>
              <div className="d-flex flex-column me-3">
                <span>{endDate.minuts}</span>
                <span>Minutes</span>
              </div>
              <div className="d-flex flex-column me-3">
                <span>{endDate.seconds}</span>
                <span>Seconds</span>
              </div>
            </p>
          </div>
        )
      )}
    </>
  );
};
export default DateTime;
