import { useState, useContext, useEffect } from "react";
// import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import {
  FetchSpecificTransaction,
  // fetchWhiteListAddress,
  SubmitETHTransactionsDetails,
  SubmitBtcTransactionsDetails,
} from "../../Services/User/Tokens/token";
import { SaleContext, ToasterContext } from "../../App";
import { ClipLoader } from "react-spinners";
import { Link, useHistory } from "react-router-dom";
// import { VerifyTransaction } from "../../Services/User/BlockCypher/blockcypher";
import axios from "axios";
import { VerifyETHTransaction } from "../../Services/User/EthAPIFetch/ethapi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Snackbar } from "@mui/material";
import Web3 from "web3";
import { VerifyPolygonTransaction } from "../../Services/User/PolygonApiFtech/ethapi";
// import ErrorIcon from "@mui/icons-material/Error";
// import axios from "axios";
const Payment = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [copy, setCopy] = useState(false);
  const transid = searchParams.get("transid");
  // const network = searchParams.get("network");
  // const tokenPrice = searchParams.get("tokenPrice");
  const [saleObj] = useContext(SaleContext);
  const history = useHistory();

  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    txnno: "",
  });
  const [paymentObj, setPaymentObj] = useState({
    from: "",
    email: "",
    id: transid,
    txnno: "",
    amount: 0,
    receiveaddress: "",
    paymentoptions: "",
    network: "",
    tokenPrice: 0,
    status: "",
  });
  useEffect(() => {
    fetchTransactionWithId();
    // eslint-disable-next-line
  }, []);
  const fetchTransactionWithId = async () => {
    const data = await FetchSpecificTransaction(transid);

    const {
      paymentMethod,
      useremail,
      expectedamount,
      from,
      network,
      tokenPrice,
      status,
    } = data.data;
    setPaymentObj({
      ...paymentObj,
      paymentoptions: paymentMethod,
      from: from,
      email: useremail,
      id: transid,
      amount: expectedamount,
      receiveaddress:
        paymentMethod === "ETH"
          ? process.env.REACT_APP_ETH_PUBLIC_ADDRESS
          : process.env.REACT_APP_BTC_PUBLIC_ADDRESS,
      network: network,
      tokenPrice: tokenPrice,
      status: status,
    });
  };

  // const handleValidation = () => {
  //   let newErrors = {};
  //   const txRegex = new RegExp(/^[a-fA-F0-9]{64}$/g);
  //   if (paymentObj.txnno === "" || !txRegex.test(paymentObj.txnno)) {
  //     newErrors.txnno = "txn hash is empty/invalid ";
  //   }
  //   //
  //   if (Object.keys(newErrors).length > 0) {
  //     setErrors(newErrors);
  //     return true;
  //   } else {
  //     setErrors({});
  //     return false;
  //   }
  // };
  const checkValidation = (e) => {
    let errors = {};
    if (paymentObj.txnno === "") {
      errors.txnno = "Transaction hash is required";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return false;
    } else {
      setErrors({});
      return true;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentObj({ ...paymentObj, [name]: value.replaceAll(" ", "") });
  };
  const SubmitBTCDetails = async (e) => {
    const validate = checkValidation();
    if (validate) {
      e.preventDefault();
      setLoading(true);
      const btcTxRegex = new RegExp(/^[a-fA-F0-9]{64}$/g);

      const regextest = btcTxRegex.test(paymentObj.txnno);
      if (regextest) {
        axios
          .get(`${process.env.REACT_APP_BITCOIN_API_URL}${paymentObj.txnno}`)
          .then(async (fetchTransactionsfromCypher) => {
            if (fetchTransactionsfromCypher.status === 200) {
              const { vin, vout } = fetchTransactionsfromCypher.data;

              //Check 1. checking if address input is whitelisted and transaction from same address
              const inputAddress = await vin.filter(
                (i) =>
                  i.prevout.scriptpubkey_address.toLowerCase() ===
                  paymentObj.from.toLowerCase()
              );
              if (inputAddress.length > 0) {
                const outputAddress = vout.filter(
                  (i) =>
                    i.scriptpubkey_address.toLowerCase() ===
                    process.env.REACT_APP_BTC_PUBLIC_ADDRESS.toLowerCase()
                );
                // check2:
                if (outputAddress.length > 0) {
                  // const actualAmount = outputAddress[0].value;
                  const dummyObj = {
                    from: paymentObj.from,
                    id: paymentObj.id,
                    txnno: paymentObj.txnno,
                    // actualamount: actualAmount,
                  };
                  setLoading(true);
                  const data = await SubmitBtcTransactionsDetails(dummyObj);
                  setLoading(false);
                  setTostify({
                    ...tostify,
                    visible: true,
                    key: Math.random().toString(36).substring(7),
                    type: data.code === 200 ? "success" : "error",
                    message: data.data,
                  });
                } else {
                  setTostify({
                    ...tostify,
                    visible: true,
                    key: Math.random().toString(36).substring(7),
                    type: "error",
                    message: "Receving wallet address does not exist in tx",
                  });
                  setLoading(false);
                }
              } else {
                setTostify({
                  ...tostify,
                  visible: true,
                  key: Math.random().toString(36).substring(7),
                  type: "error",
                  message: "Sending wallet address does not exist in tx",
                });
                setLoading(false);
              }
              //   // Check 3. Check if tx is after we created transaction in our db
            }
            // Check 2. Check if output same as out btc address
            else {
              setTostify({
                ...tostify,
                visible: true,
                key: Math.random().toString(36).substring(7),
                type: "error",
                message: fetchTransactionsfromCypher.message,
              });
            }
          })
          .catch((err) => {
            setTostify({
              ...tostify,
              visible: true,
              key: Math.random().toString(36).substring(7),
              type: "error",
              message: "Something went wrong",
            });
          }); //from blockcypher
        setLoading(false);
      } else {
        setErrors({ ...errors, txnno: "txn hash is empty/invalid" });
        setLoading(false);
      }
    }
  };
  const SubmitETHDetails = async (e) => {
    const validate = checkValidation();
    if (validate) {
      e.preventDefault();
      setLoading(true);
      const ethTxRegex = new RegExp(/^0x([A-Fa-f0-9]{64})$/g);
      const { bonus, price } = saleObj;

      const regextest = ethTxRegex.test(paymentObj.txnno);

      if (regextest) {
        VerifyETHTransaction(paymentObj.txnno).then(async (res) => {
          let {
            // from,
            to,
            value,
          } = res.data.result ? res.data.result : { to: "", value: "" };
          if (!res.data.result) {
            let polygon_res = await VerifyPolygonTransaction(paymentObj.txnno);
            to = polygon_res.data.result ? polygon_res.data.result.to : "";
            value = polygon_res.data.result
              ? polygon_res.data.result.value
              : "";
          }
          // let amount = parseFloat(parseInt(value) / Math.pow(10, 18));
          //check 1
          // eslint-disable-next-line
          const fetchtxno = await FetchSpecificTransaction(transid); //from backend
          //   //Check 1. checking if address input is whitelisted and transaction from same address
          // const fetchwhitelist = await fetchWhiteListAddress("ethereum");
          // const checkWhitelist = await fetchwhitelist.data.find(
          //   (o) => o.address.toLowerCase() === from.toLowerCase()
          // );
          // const resolved = await Promise.all(checkWhitelist);
          //
          // if (checkWhitelist !== undefined) {
          if (
            to.toLowerCase() ===
            process.env.REACT_APP_ETH_PUBLIC_ADDRESS.toLowerCase()
          ) {
            const web3 = new Web3(process.env.REACT_APP_ETH_HTTP_PROVIDER); //ETH_HTTP_PROVIDER
            let coinPrice = await fetchCoinData(paymentObj.network);
            value = web3.utils.fromWei(value);
            let paymentValue = (value * coinPrice) / price;
            const dummyObj = {
              from: paymentObj.from,
              id: paymentObj.id,
              txnno: paymentObj.txnno,
              // actualamount: amount,
              network: paymentObj.network,
              // amount: paymentValue,
              bonus:
                bonus === 0 ||
                bonus === null ||
                bonus === undefined ||
                bonus === Infinity ||
                bonus === "NaN"
                  ? 0
                  : (bonus / 100) * paymentValue,
              icoamount:
                parseFloat(paymentValue) + +(paymentValue * bonus) / 100,
            };
            setLoading(true);
            const data = await SubmitETHTransactionsDetails(dummyObj);
            setLoading(false);
            setTostify({
              ...tostify,
              visible: true,
              key: Math.random().toString(36).substring(7),
              type: data.code === 200 ? "success" : "error",
              message: data.data,
            });
            if (data.code === 200) {
              history.push({
                pathname: "/transactions",
                state: { transid: transid },
              });
            }
          } else {
            setTostify({
              ...tostify,
              visible: true,
              key: Math.random().toString(36).substring(7),
              type: "error",
              // message: "Incorrect ETH receving wallet address ",
              message: "Wrong hash: Transaction does not match",
            });
            setLoading(false);
          }
          // check 3 here we check transaction dates
          // } else {
          //   setTostify({
          //     ...tostify,
          //     visible: true,
          //     key: Math.random().toString(36).substring(7),
          //     type: "error",
          //     message: "sending address in submitted tx is not whitelisted",
          //   });
          //   setLoading(false);
          // }
          // check 2
        });
      } else {
        setErrors({ ...errors, txnno: "txn hash is empty/invalid" });
        setLoading(false);
      }
    }
  };

  const SubmitUSDTDetails = async (e) => {
    const validate = checkValidation();
    if (validate) {
      e.preventDefault();
      setLoading(true);
      const ethTxRegex = new RegExp(/^0x([A-Fa-f0-9]{64})$/g);
      const { bonus, price } = saleObj;

      const regextest = ethTxRegex.test(paymentObj.txnno);

      if (regextest) {
        VerifyETHTransaction(paymentObj.txnno).then(async (res) => {
          let {
            //  from,
            to,
            input,
          } = res.data.result ? res.data.result : { to: "", input: "" };
          let networkChain = "ethereum";
          if (!res.data.result) {
            let polygon_res = await VerifyPolygonTransaction(paymentObj.txnno);
            to = polygon_res.data.result ? polygon_res.data.result.to : "";
            input = polygon_res.data.result
              ? polygon_res.data.result.input
              : "";
            networkChain = "polygon";
          }
          // let amount = parseFloat(parseInt(value) / Math.pow(10, 18));

          //check 1
          // eslint-disable-next-line
          const fetchtxno = await FetchSpecificTransaction(transid); //from backend
          //   //Check 1. checking if address input is whitelisted and transaction from same address
          // const fetchwhitelist = await fetchWhiteListAddress("ethereum");
          // const checkWhitelist = await fetchwhitelist.data.find(
          //   (o) => o.address.toLowerCase() === from.toLowerCase()
          // );
          // const resolved = await Promise.all(checkWhitelist);
          //
          // if (checkWhitelist !== undefined) {
          if (
            to.toLowerCase() ===
              process.env.REACT_APP_USDT_PUBLIC_ADDRESS.toLowerCase() ||
            to.toLowerCase() ===
              process.env.REACT_APP_USDT_POLYGON_PUBLIC_ADDRESS.toLowerCase()
          ) {
            const web3 = new Web3(process.env.REACT_APP_ETH_HTTP_PROVIDER); //ETH_HTTP_PROVIDER

            let value = input.substr(74);
            value = web3.eth.abi.decodeParameter("uint256", "0x" + value);
            value =
              networkChain == "ethereum"
                ? web3.utils.fromWei(value, "mwei")
                : web3.utils.fromWei(value);
            let paymentValue = value / paymentObj.tokenPrice / price;
            const dummyObj = {
              from: paymentObj.from,
              id: paymentObj.id,
              txnno: paymentObj.txnno,
              // actualamount: amount,
              network: paymentObj.network,
              // amount: paymentValue,
              bonus: (paymentValue * bonus) / 100,
              icoamount:
                parseFloat(paymentValue) + +(paymentValue * bonus) / 100,
            };
            setLoading(true);
            const data = await SubmitETHTransactionsDetails(dummyObj);
            setLoading(false);
            setTostify({
              ...tostify,
              visible: true,
              key: Math.random().toString(36).substring(7),
              type: data.code === 200 ? "success" : "error",
              message: data.data,
            });
            if (data.code === 200) {
              history.push({
                pathname: "/transactions",
                state: { transid: transid },
              });
            }
          } else {
            setTostify({
              ...tostify,
              visible: true,
              key: Math.random().toString(36).substring(7),
              type: "error",
              // message: "Incorrect ETH receving wallet address ",
              message: "Wrong hash: Transaction does not match",
            });
            setLoading(false);
          }
          // check 3 here we check transaction dates
          // } else {
          //   setTostify({
          //     ...tostify,
          //     visible: true,
          //     key: Math.random().toString(36).substring(7),
          //     type: "error",
          //     message: "sending address in submitted tx is not whitelisted",
          //   });
          //   setLoading(false);
          // }
          // check 2
        });
      } else {
        setErrors({ ...errors, txnno: "txn hash is empty/invalid" });
        setLoading(false);
      }
    }
  };

  const fetchCoinData = async (coinname) => {
    // react query data removed
    if (typeof coinname == "string") {
      const coinname1 = coinname ? coinname : paymentObj.network;
      let coinname1Api =
        coinname1 !== "ethereum" && coinname1 !== "bitcoin"
          ? coinname1
          : coinname1 === "ethereum"
          ? "ETH"
          : "BTC";
      let coinPrice = 0;
      await axios
        .get(
          `https://min-api.cryptocompare.com/data/price?fsym=${coinname1Api}&tsyms=USD`
        )
        .then((res) => {
          // setCoinPrice({
          //   ...coinPrice,
          //   btc: res.data.USD ? res.data.USD : 0,
          // });
          coinPrice = res.data.USD ? res.data.USD : 0;
          // return coinPrice;
        })
        .catch((err) => {
          console.log(err);
        });
      return coinPrice;
    }
  };
  //
  return (
    <>
      <div className="user-ath user-ath-page transaction-btc">
        <div className="container-fluid" id="bg-div">
          <div className="card-payment">
            <div className="card-payment-bg">
              <div className="img-logo-round">
                <Link to={"/"}>
                  <img
                    src="images/512-betts.png"
                    style={{ width: "32px" }}
                    srcSet="images/512-betts.png"
                    alt="icon"
                  />
                </Link>
              </div>
            </div>

            <div className="card-payment-body">
              <h2 className="text-center mb-2" style={{ color: "#000000" }}>
                Transaction details
              </h2>
              {paymentObj.status == 2 && (
                <p className="text-center" style={{ color: "#8B8B8B" }}>
                  Submit all the details in given form
                </p>
              )}
              <div className="card-payment-body-details mt-4">
                <div className="detail">
                  <p>
                    <img
                      className="img-fluid"
                      src="images/payment-amt.svg"
                      alt=""
                    />
                    {paymentObj.network == "USDT"
                      ? paymentObj.network
                      : paymentObj.paymentoptions}{" "}
                    Purchased Amount
                  </p>
                  <p>{paymentObj.amount}</p>
                </div>
                <div className="detail">
                  <p>
                    <img
                      className="img-fluid"
                      src="images/payment-opt.svg"
                      alt=""
                    />
                    Payment Option
                  </p>
                  <p>
                    {paymentObj.network == "USDT"
                      ? paymentObj.network
                      : paymentObj.paymentoptions}
                  </p>
                </div>
                <div className="detail">
                  <p>
                    <img
                      className="img-fluid"
                      src="images/payment-email.svg"
                      alt=""
                    />
                    Email
                  </p>
                  <p>{paymentObj.email}</p>
                </div>
                {/* <div className="detail">
                  <p>
                    <img
                      className="img-fluid"
                      src="images/payment-wallet-address.svg"
                      alt=""
                    />
                    {[paymentObj.paymentoptions]} Wallet Address
                  </p>
                  <p title={paymentObj.from}>
                    {paymentObj.from.length > 15 ? (
                      <>
                        {paymentObj.from.substring(0, 7) +
                          " .... " +
                          paymentObj.from.substring(
                            paymentObj.from.length - 7,
                            paymentObj.from.length
                          )}
                      </>
                    ) : (
                      <>{paymentObj.from}</>
                    )}
                    <Snackbar
                      message="Copied to clibboard"
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      autoHideDuration={2000}
                      onClose={() => setCopy(false)}
                      open={copy}
                    />
                    <CopyToClipboard text={paymentObj?.from}>
                      {/* <button
                        className="refferal-copy copy-clipboard"
                        style={{ width: "10%", textAlign: "center" }}
                      >
                        copy
                      </button> */}

                {/* <em
                        onClick={() => setCopy(!copy)}
                        style={{ cursor: "pointer" }}
                        className="ti ti-files ms-2"
                      ></em>
                    </CopyToClipboard>
                  </p>
                </div> */}
                <div className="detail">
                  <p>
                    <img
                      className="img-fluid"
                      src="images/payment-receive.svg"
                      alt=""
                    />
                    Receiving Wallet Address
                  </p>
                  <p title={paymentObj.receiveaddress}>
                    {/* <>{paymentObj.receiveaddress}</> */}
                    {paymentObj.receiveaddress.length > 15 ? (
                      <>
                        {paymentObj.receiveaddress.substring(0, 7) +
                          " .... " +
                          paymentObj.receiveaddress.substring(
                            paymentObj.receiveaddress.length - 7,
                            paymentObj.receiveaddress.length
                          )}
                      </>
                    ) : (
                      <>{paymentObj.receiveaddress}</>
                    )}

                    <Snackbar
                      message="Copied to clibboard"
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      autoHideDuration={2000}
                      onClose={() => setCopy(false)}
                      open={copy}
                    />
                    <CopyToClipboard text={paymentObj.receiveaddress}>
                      {/* <button
                        className="refferal-copy copy-clipboard"
                        style={{ width: "10%", textAlign: "center" }}
                      >
                        copy
                      </button> */}

                      <em
                        onClick={() => setCopy(!copy)}
                        style={{ cursor: "pointer" }}
                        className="ti ti-files ms-2"
                      ></em>
                    </CopyToClipboard>
                  </p>
                </div>
                {paymentObj.status == 2 ? (
                  <div
                    className="detail"
                    style={{
                      flexDirection: "column",
                      padding: "20px 0px 20px 0px",
                    }}
                  >
                    <p>
                      <img
                        className="img-fluid"
                        src="images/payment-transcation.svg"
                        alt=""
                      />
                      Submit Transaction Hash Here
                    </p>
                    <div className="input-group">
                      <input
                        type="text"
                        className="w-100"
                        name="txnno"
                        id="exp"
                        placeholder="Transaction Hash"
                        value={paymentObj.txnno}
                        onChange={handleChange}
                        required
                      />
                      <span className="error1">{errors.txnno}</span>{" "}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {paymentObj.status == 2 ? (
                <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center flex-column-reverse">
                  <button
                    className="btn btn-primary rounded-pill"
                    onClick={() => history.goBack()}
                  >
                    Back
                  </button>

                  <button
                    className="transction-btc-btn btn placeicon mb-sm-0 mb-3"
                    onClick={
                      paymentObj.network === "ethereum"
                        ? SubmitETHDetails
                        : paymentObj.network === "USDT"
                        ? SubmitUSDTDetails
                        : SubmitBTCDetails
                    }
                  >
                    {loading ? (
                      <ClipLoader color={"white"} />
                    ) : (
                      <>
                        <img
                          src="images/btn-tick.svg"
                          style={{ marginRight: "10px" }}
                          alt=""
                          className="img-fluid"
                        />{" "}
                        Submit Details
                      </>
                    )}
                  </button>
                </div>
              ) : (
                <>
                  <h4 className="text-center" style={{ color: "#8B8B8B" }}>
                    Transaction already submitted
                  </h4>
                  <p className="text-center">
                    <Link
                      to={{
                        pathname: "/transactions",
                        state: { transid: transid },
                      }}
                    >
                      <button className="transction-btc-btn btn placeicon mb-sm-2 mb-0 ">
                        View Transaction
                      </button>
                    </Link>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Payment;
