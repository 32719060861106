import Skeleton from "@material-ui/lab/Skeleton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PlusMinusSkeltonField from "./PlusMinusFieldSkelton";
const IcoSkelton = (props) => {
  return (
    <>
      {" "}
      <div
        id="add-user-header"
        className="d-flex align-items-center justify-content-between bg-zinnia-grad-alt"
      >
        <div>
          <h5 className="font-weight-bold font-size-lg  mx-3 my-1 text-white">
            <Skeleton height={60} width={250} />
          </h5>
        </div>
      </div>
      <div>
        <Box
          sx={{ minWidth: "30%", display: "flex", justifyContent: "center" }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              className="mr-5"
              icon={
                <Skeleton
                  className="circle mt-1"
                  variant="circle"
                  width={30}
                  height={30}
                />
              }
              label={<Skeleton height={40} width={110} />}
            />
            <Tab
              className="mr-5"
              icon={
                <Skeleton
                  className="circle mt-1"
                  variant="circle"
                  width={30}
                  height={30}
                />
              }
              label={<Skeleton height={40} width={110} />}
            />
            <Tab
              className="mr-5"
              icon={
                <Skeleton
                  className="circle mt-1"
                  variant="circle"
                  width={30}
                  height={30}
                />
              }
              label={<Skeleton height={40} width={110} />}
            />
            <Tab
              className="mr-5"
              icon={
                <Skeleton
                  className="circle mt-1"
                  variant="circle"
                  width={30}
                  height={30}
                />
              }
              label={<Skeleton height={40} width={110} />}
            />
            <Tab
              className="mr-5"
              icon={
                <Skeleton
                  className="circle mt-1"
                  variant="circle"
                  width={30}
                  height={30}
                />
              }
              label={<Skeleton height={40} width={110} />}
            />
            <Tab
              className="mr-5"
              icon={
                <Skeleton
                  className="circle mt-1"
                  variant="circle"
                  width={30}
                  height={30}
                />
              }
              label={<Skeleton height={40} width={110} />}
            />
            <Tab
              className="mr-5"
              icon={
                <Skeleton
                  className="circle mt-1"
                  variant="circle"
                  width={30}
                  height={30}
                />
              }
              label={<Skeleton height={40} width={110} />}
            />
          </Tabs>
        </Box>
        <form autoComplete="off">
          <div className="common-cms-pg block-content block-content-full mt-5">
            <div className="">
              <div className="row items-push ">
                <PlusMinusSkeltonField mintFields={[0]} />
                <div className="d-flex justify-content-end col-md-11">
                  <button
                    type="submit"
                    className="btn btn-md btn-grad btn-grad-theme btn-round"
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <Skeleton height={30} width={60} />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default IcoSkelton;
