import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
// import SignupSuccess from "./SignupSuccess";
import Clip from "../../components/Comps-Utils/ClipLoader";
import { ToasterContext } from "../../App";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import queryString from "query-string";

const SignUp = () => {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [registrationInfo, setRegistrationInfo] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    rpassword: "",
    aggrement: false,
    referralLink: "",
  });

  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    rpassword: "",
    aggrement: "",
  });
  const [showPass, setShowPass] = useState({
    password: true,
    confirmpass: true,
  });
  useEffect(() => {
    const myArray = window.location.pathname.split("/");
    if (myArray.length > 2) {
      setRegistrationInfo({ ...registrationInfo, referralLink: myArray[2] });
    }
    // eslint-disable-next-line
  }, []);
  const changeHandle = (e) => {
    const { value, name } = e.target;
    if (name === "aggrement") {
      const { checked, name } = e.target;
      setRegistrationInfo({ ...registrationInfo, [name]: checked });
    } else {
      setRegistrationInfo({ ...registrationInfo, [name]: value });
    }
  };

  const checkValidation = (e) => {
    let newErrors = {};
    const regExp = new RegExp(
      // eslint-disable-next-line
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    if (registrationInfo.firstname === "") {
      newErrors.firstname = "first name is required";
    } else if (registrationInfo.firstname.length < 3) {
      newErrors.firstname = "must be 3 character";
    }
    if (registrationInfo.email === "") {
      newErrors.email = "email is required";
    } else if (!regExp.test(registrationInfo.email)) {
      newErrors.email = "email is not valid";
    }
    let passExp = new RegExp(
      "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
    );

    if (!passExp.test(registrationInfo.password)) {
      newErrors.password =
        "Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase";
    } else if (registrationInfo.password.length < 8) {
      newErrors.password = "password is less than 8 characters";
    }
    if (registrationInfo.rpassword === "") {
      newErrors.rpassword = "repeat password is required";
    } else if (registrationInfo.rpassword !== registrationInfo.password) {
      newErrors.rpassword = "repeat password doesn't match";
    }
    if (registrationInfo.aggrement === false) {
      newErrors.aggrement = "terms and policy is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return true;
    } else {
      setErrors({});
      return false;
    }
  };

  const { search } = useLocation();
  const queryParams = () => {
    const values = queryString.parse(search);
    return values;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validate = checkValidation();

    if (!validate) {
      setLoading(true);
      const queryVal = queryParams();

      const obj = {
        firstname: registrationInfo.firstname,
        lastname: registrationInfo.lastname,
        email: registrationInfo.email,
        password: registrationInfo.password,
        referralLink: registrationInfo.referralLink,
        utmSourceOriginal: queryVal.utm_source,
        utmSourceMostRecent: queryVal.utm_source,
        utmMediumOriginal: queryVal.utm_medium,
        utmMediumMostRecent: queryVal.utm_medium,
        utmCampaignOriginal: queryVal.utm_campaign,
        utmCampaignMostRecent: queryVal.utm_campaign,
      };

      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/users/register`,
        obj
      );
      if (res.data.code === 200) {
        setLoading(false);
        window.location.href = "/signup-success";
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "error",
          message: res.data.msg,
        });
      }
    }
    e.preventDefault();
  };
  const handleVisiblityPassword = (field) => {
    if (field === "pass") {
      let pass = !showPass.password;
      setShowPass({ ...showPass, password: pass });
    } else {
      let pass = !showPass.confirmpass;
      setShowPass({ ...showPass, confirmpass: pass });
    }
  };
  return (
    <>
      <div className="user-ath">
        <div className="user-ath-page">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8 text-center">
                <div className="user-ath-logo logo-width">
                  <Link to={"/"}>
                    <img
                      src="images/logo.png"
                      srcSet="images/logo.png"
                      alt="icon"
                    />
                  </Link>
                </div>
                <div className="user-ath-box">
                  <h4>Create New Account</h4>
                  <form className="user-ath-form">
                    <div className="input-item">
                      <input
                        onChange={changeHandle}
                        name="firstname"
                        type="text"
                        placeholder="First Name"
                        className="input-bordered"
                      />
                      <span className="error1">{errors.firstname}</span>
                    </div>
                    <div className="input-item">
                      <input
                        onChange={changeHandle}
                        name="lastname"
                        type="text"
                        placeholder="Last Name"
                        className="input-bordered"
                      />
                      <span className="error1">{errors.lastname}</span>
                    </div>
                    <div className="input-item">
                      <input
                        onChange={changeHandle}
                        name="email"
                        type="text"
                        placeholder="Your Email"
                        className="input-bordered"
                      />
                      <span className="error1">{errors.email}</span>
                    </div>
                    <div className="input-item">
                      <input
                        onChange={changeHandle}
                        name="password"
                        type={showPass.password ? "password" : "text"}
                        placeholder="Password"
                        className="input-bordered"
                      />
                      <i
                        style={{
                          position: "absolute",
                          right: "50px",
                          marginTop: "10px",
                        }}
                        onClick={() => {
                          handleVisiblityPassword("pass");
                        }}
                      >
                        {showPass.password ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </i>
                      <span className="error1">{errors.password}</span>
                    </div>
                    <div className="input-item">
                      <input
                        onChange={changeHandle}
                        name="rpassword"
                        type={showPass.confirmpass ? "password" : "text"}
                        placeholder="Repeat Password"
                        className="input-bordered"
                      />
                      <i
                        style={{
                          position: "absolute",
                          right: "50px",
                          marginTop: "10px",
                        }}
                        onClick={() => {
                          handleVisiblityPassword("rpass");
                        }}
                      >
                        {showPass.confirmpass ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </i>
                      <span className="error1">{errors.rpassword}</span>
                    </div>
                    <div className="input-item">
                      <input
                        onChange={changeHandle}
                        name="referralLink"
                        type="text"
                        value={
                          registrationInfo?.referralLink !== ""
                            ? registrationInfo?.referralLink
                            : ""
                        }
                        // disabled={
                        //   registrationInfo.referralLink !== "" ? true : false
                        // }
                        placeholder="Enter Referral Code (Optional)"
                        className="input-bordered"
                      />
                      {/* <span className="error1">{errors.email}</span> */}
                    </div>
                    <div className="input-item text-start">
                      <input
                        className="input-checkbox"
                        id="term-condition"
                        type="checkbox"
                        name="aggrement"
                        onChange={changeHandle}
                      />
                      <label for="term-condition">
                        I agree to the Betts{" "}
                        <a
                          href="https://bettsrecruiting.com/terms-of-use/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Terms and Conditions
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://bettsrecruiting.com/privacy-policy/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>
                        .
                      </label>
                      <div className="error1">{errors.aggrement}</div>
                    </div>

                    <div className="gaps"></div>
                    <div className="">
                      <button
                        onClick={handleSubmit}
                        className="btn btn-primary"
                      >
                        {loading ? <Clip /> : <>Sign Up</>}
                      </button>
                    </div>
                  </form>
                </div>
                <div className="gaps-2x"></div>
                <div className="form-note">
                  {" "}
                  Already a member? <Link to={"/login"}>Login</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
