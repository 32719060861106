import { useContext, useEffect, useState } from "react";
import { SaleContext } from "../../../App";
import useSaleSetter from "../../../CustomHooks/Sale";
import { TotalVestingAmount } from "../../../Services/User/web3Calls/contractCall";
import message from "../assets/images/message-square.svg";
import "font-awesome/css/font-awesome.min.css";
// import Clock from "../../claimTokens/clock";
import FlipClock from "x-react-flipclock";
import moment from "moment";
const Banner = (props) => {
  // eslint-disable-next-line
  const sale = useSaleSetter();
  const [presaleAmou, setPresaleAmou] = useState({
    totalinvested: 0,
    presale: 0,
  });
  const saleObj = useContext(SaleContext);
  useEffect(() => {
    fetchPresale();
  }, []);
  const fetchPresale = async () => {
    const data = await TotalVestingAmount();
    setPresaleAmou(data);
  };
  return (
    <>
      <div className="token-box token-box-s1 " data-delay="1.65">
        <div className="container token-container">
          {/* <div className="token-box-girth mb-md-0 mb-5"> */}
          <div className="mb-md-0 mb-5">
            <div className="row g-0 justify-content-center">
              <div className="col-xl-4 col-md-5">
                <div className="token-countdown-wrapper split split-left split-md-left">
                  <>
                    {saleObj[0].loading ? (
                      <span>Fetching Dates ..... </span>
                    ) : saleObj[0].type === "Upcoming-Public" ? (
                      <>
                        <span className="token-countdown-title mb-lg-4 mb-2">
                          <>
                            Public Sale Starts In{" "}
                            <FlipClock
                              type="countdown"
                              count_to={moment(saleObj[0].start).format()}
                            />
                          </>
                        </span>
                      </>
                    ) : (
                      <>
                        {saleObj[0].type === "Pre Sale" ? (
                          <>
                            <span className="token-countdown-title mb-lg-4 mb-2">
                              <>
                                <h3 className="token-countdown-title">
                                  <h4 className="text-center text-primary">
                                    PreSale End In{" "}
                                  </h4>
                                  <div>
                                    {/* <Clock
                                    date={saleObj[0].end}
                                    styleprop={"dashboard-clock"}
                                    clockprop={"ticker"}
                                  /> */}
                                    <FlipClock
                                      type="countdown"
                                      count_to={moment(saleObj[0].end).format()}
                                    />
                                  </div>
                                </h3>
                              </>
                            </span>
                          </>
                        ) : saleObj[0].type === "Upcoming-Presale" ? (
                          <>
                            <h3 className="text-dark">PreSale Starts In</h3>{" "}
                            <FlipClock
                              type="countdown"
                              count_to={moment(saleObj[0].start).format()}
                            />
                          </>
                        ) : saleObj[0].type === "Ended" ? (
                          <>
                            <h6 className="text-dark"> All Sales Ended</h6>
                          </>
                        ) : saleObj[0].type === "Public Sale" ? (
                          <>
                            {" "}
                            <span className="token-countdown-title mb-lg-4 mb-2">
                              <div>Public Sale Ends in </div>
                              <FlipClock
                                type="countdown"
                                count_to={moment(saleObj[0].end).format()}
                              />
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                    {/* <div
                    className="token-countdown-clock"
                    data-date="2022/09/05"
                  ></div> */}
                  </>
                </div>
              </div>
              <div className="col-xl-6 col-md-7">
                <div className="token-status token-status-s1 bg-theme tc-light text-start split split-right split-md-right">
                  <h5 className="title-sm">Pre-Sale</h5>
                  <div className="progress-bar progress-bar-s1">
                    <div
                      className="progress-percent progress-percent-s1 bg-theme-grad-alt"
                      style={{ width: `${presaleAmou?.totalinvestedPercent}%` }}
                      // data-percent=""
                    ></div>
                  </div>
                  <div className="progress-points">
                    <span>{presaleAmou?.totalinvested.toFixed(2)}</span>{" "}
                    <span>{presaleAmou?.presale.toFixed(2)}</span>
                  </div>
                  <div className="progress-points">
                    <span> Sold</span> <span> Presale</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="chat-box">
            <div className="chat-box-style">
              <img src={message} alt="Chat Box"></img>
            </div>
          </div>
        </div>
        {/* CHAT */}
      </div>
    </>
  );
};
export default Banner;
