// import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
// import Select from "react-select";
import {
  Button,
  Col,
  // Form,
  Modal,
  Row,
} from "react-bootstrap";
// import { GetKycUsers } from "../../Services/Admin/Kyc";
// import { GetPriceData } from "../../Services/Admin/Web3call/contractCall";
// import { AddAdminTokenTransaction } from "../../Services/User/Tokens/token";
import { SaleContext, ToasterContext } from "../../App";
import Clip from "../Comps-Utils/ClipLoader";
import axios from "axios";
import { VerifyETHTransaction } from "../../Services/User/EthAPIFetch/ethapi";
import Web3 from "web3";
import {
  FetchSpecificTransaction,
  SubmitETHTransactionsDetails,
  // fetchWhiteListAddress,
  // fetchWhiteListAddressAdmin,
} from "../../Services/User/Tokens/token";
import { Snackbar } from "@material-ui/core";
import CopyToClipboard from "react-copy-to-clipboard";
import { VerifyPolygonTransaction } from "../../Services/User/PolygonApiFtech/ethapi";

const EthTransactionSubmitModal = ({
  transactionSubmitModalCloseHandle,
  transactionModalShow,
  transactionData,
}) => {
  // const [showCustomEmail, setShowCustomEmail] = useState(false);
  // const [users, setUsers] = useState([]);
  // const [prices, setPrices] = useState([]);
  // const [vesting, setVesting] = useState(false);
  // const vestingDate = [
  //   { label: "3 months", value: "3 months" },
  //   { label: "6 months", value: "6 months" },
  // ];
  const [saleObj] = useContext(SaleContext);
  const [loading, setLoading] = useState(false);
  const [copy, setCopy] = useState(false);

  const [tostify, setTostify] = useContext(ToasterContext);
  const [errors, setErrors] = useState({
    txnno: "",
  });
  const [paymentObj, setPaymentObj] = useState({
    paymentoptions: "",
    from: "",
    email: "",
    id: "",
    amount: 0,
    receiveaddress: "",
    txnno: "",
    network: "",
    tokenPrice: 0,
    userid: "",
  });

  useEffect(() => {
    fetchTransactionWithId();
  }, []);

  const fetchTransactionWithId = async () => {
    const data = await FetchSpecificTransaction(transactionData.id);

    const {
      paymentMethod,
      useremail,
      expectedamount,
      from,
      network,
      tokenPrice,
      userid,
    } = data.data;
    setPaymentObj({
      ...paymentObj,
      paymentoptions: paymentMethod,
      from: from,
      email: useremail,
      id: transactionData.id,
      amount: expectedamount,
      receiveaddress:
        paymentMethod === "ETH"
          ? process.env.REACT_APP_ETH_PUBLIC_ADDRESS
          : process.env.REACT_APP_BTC_PUBLIC_ADDRESS,
      network: network,
      tokenPrice: tokenPrice,
      userid: userid,
    });
  };

  const handleChange = (e) => {
    setPaymentObj({ ...paymentObj, [e.target.name]: e.target.value });
  };

  const checkValidation = (e) => {
    let errors = {};
    if (paymentObj.txnno === "") {
      errors.txnno = "Transaction hash is required";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return false;
    } else {
      setErrors({});
      return true;
    }
  };

  const fetchCoinData = async (coinname) => {
    // react query data removed
    if (typeof coinname == "string") {
      const coinname1 = coinname ? coinname : paymentObj.network;
      let coinname1Api =
        coinname1 !== "ethereum" && coinname1 !== "bitcoin"
          ? coinname1
          : coinname1 === "ethereum"
          ? "ETH"
          : "BTC";
      let coinPrice = 0;
      await axios
        .get(
          `https://min-api.cryptocompare.com/data/price?fsym=${coinname1Api}&tsyms=USD`
        )
        .then((res) => {
          coinPrice = res.data.USD ? res.data.USD : 0;
          // return coinPrice;
        })
        .catch((err) => {
          console.log(err);
        });
      return coinPrice;
    }
  };

  const submitTransactionsDetails = async () => {
    const validate = checkValidation();
    if (validate) {
      setLoading(true);
      const ethTxRegex = new RegExp(/^0x([A-Fa-f0-9]{64})$/g);
      const { bonus, price } = saleObj;

      const regextest = ethTxRegex.test(paymentObj.txnno);

      if (regextest) {
        VerifyETHTransaction(paymentObj.txnno).then(async (res) => {
          let {
            // from,
            to,
            value,
            input,
          } = res.data.result
            ? res.data.result
            : { to: "", value: "", input: "" };
          let networkChain = "ethereum";
          if (!res.data.result) {
            let polygon_res = await VerifyPolygonTransaction(paymentObj.txnno);
            to = polygon_res.data.result ? polygon_res.data.result.to : "";
            value = polygon_res.data.result
              ? polygon_res.data.result.value
              : "";
            input = polygon_res.data.result
              ? polygon_res.data.result.input
              : "";
            networkChain = "polygon";
          }
          // let amount = parseFloat(parseInt(value) / Math.pow(10, 18));
          const web3 = new Web3(process.env.REACT_APP_ETH_HTTP_PROVIDER); //ETH_HTTP_PROVIDER
          let paymentValue, bonusAmount, icoAmount;
          let verified = false;
          if (paymentObj.network == "ethereum") {
            if (
              to.toLowerCase() ===
              process.env.REACT_APP_ETH_PUBLIC_ADDRESS.toLowerCase()
            ) {
              let coinPrice = await fetchCoinData(paymentObj.network);
              value = web3.utils.fromWei(value);
              paymentValue = (value * coinPrice) / price;
              // matchAddress =
              //   process.env.REACT_APP_ETH_PUBLIC_ADDRESS.toLowerCase();
              bonusAmount =
                bonus === 0 ||
                bonus === null ||
                bonus === undefined ||
                bonus === Infinity ||
                bonus === "NaN"
                  ? 0
                  : (bonus / 100) * paymentValue;
              icoAmount =
                parseFloat(paymentValue) + +(paymentValue * bonus) / 100;
              verified = true;
            }
          } else {
            if (
              to.toLowerCase() ===
                process.env.REACT_APP_USDT_PUBLIC_ADDRESS.toLowerCase() ||
              to.toLowerCase() ===
                process.env.REACT_APP_USDT_POLYGON_PUBLIC_ADDRESS.toLowerCase()
            ) {
              let value = input.substr(74);
              value = web3.eth.abi.decodeParameter("uint256", "0x" + value);
              value =
                networkChain == "ethereum"
                  ? web3.utils.fromWei(value, "mwei")
                  : web3.utils.fromWei(value);
              paymentValue = value / paymentObj.tokenPrice / price;
              // matchAddress =
              //   process.env.REACT_APP_USDT_PUBLIC_ADDRESS.toLowerCase();
              bonusAmount = (paymentValue * bonus) / 100;
              icoAmount =
                parseFloat(paymentValue) + +(paymentValue * bonus) / 100;
              verified = true;
            }
          }
          //check 1
          // eslint-disable-next-line
          const fetchtxno = await FetchSpecificTransaction(paymentObj.id); //from backend
          //   //Check 1. checking if address input is whitelisted and transaction from same address
          // const fetchwhitelist = await fetchWhiteListAddressAdmin({
          //   network: "ethereum",
          //   userid: paymentObj.userid,
          // });
          // const checkWhitelist = await fetchwhitelist.data.find(
          //   (o) => o.address.toLowerCase() === from.toLowerCase()
          // );
          // const resolved = await Promise.all(checkWhitelist);
          //
          // if (checkWhitelist !== undefined) {
          if (verified) {
            const dummyObj = {
              from: paymentObj.from,
              id: paymentObj.id,
              txnno: paymentObj.txnno,
              // actualamount: amount,
              network: paymentObj.network,
              // amount: paymentValue,
              bonus: bonusAmount,
              icoamount: icoAmount,
            };
            const data = await SubmitETHTransactionsDetails(dummyObj);
            setLoading(false);
            setTostify({
              ...tostify,
              visible: true,
              key: Math.random().toString(36).substring(7),
              type: data.code === 200 ? "success" : "error",
              message: data.data,
            });
            transactionSubmitModalCloseHandle();
          } else {
            setTostify({
              ...tostify,
              visible: true,
              key: Math.random().toString(36).substring(7),
              type: "error",
              // message: "Incorrect ETH receving wallet address ",
              message: "Wrong hash: Transaction does not match",
            });
            setLoading(false);
          }
          // check 3 here we check transaction dates
          // } else {
          //   setTostify({
          //     ...tostify,
          //     visible: true,
          //     key: Math.random().toString(36).substring(7),
          //     type: "error",
          //     message: "sending address in submitted tx is not whitelisted",
          //   });
          //   setLoading(false);
          // }
          // check 2
        });
      } else {
        setErrors({ ...errors, txnno: "txn hash is empty/invalid" });
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        className="airdropmodal-wrapper"
        show={transactionModalShow}
        onHide={transactionSubmitModalCloseHandle}
      >
        <Modal.Header closeButton>
          <div className="modal-header-heading text-center">
            <h4>Submit Transaction</h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="airmodal-content">
            <Row>
              <Col lg="12">
                <div className="card-payment-body-details mt-4">
                  <div className="detail">
                    <p>
                      <img
                        className="img-fluid"
                        src="images/payment-amt.svg"
                        alt=""
                      />
                      {paymentObj.network == "USDT"
                        ? paymentObj.network
                        : paymentObj.paymentoptions}{" "}
                      Purchased Amount
                    </p>
                    <p>{paymentObj.amount}</p>
                  </div>
                  <div className="detail">
                    <p>
                      <img
                        className="img-fluid"
                        src="images/payment-opt.svg"
                        alt=""
                      />
                      Payment Option
                    </p>
                    <p>{paymentObj.paymentoptions}</p>
                  </div>
                  <div className="detail">
                    <p>
                      <img
                        className="img-fluid"
                        src="images/payment-email.svg"
                        alt=""
                      />
                      Email
                    </p>
                    <p>{paymentObj.email}</p>
                  </div>
                  {/* <div className="detail">
                    <p>
                      <img
                        className="img-fluid"
                        src="images/payment-wallet-address.svg"
                        alt=""
                      />
                      {[paymentObj.paymentoptions]} Wallet Address
                    </p>
                    <p title={paymentObj.from}>
                      {paymentObj.from.length > 15 ? (
                        <>
                          {paymentObj.from.substring(0, 7) +
                            " .... " +
                            paymentObj.from.substring(
                              paymentObj.from.length - 7,
                              paymentObj.from.length
                            )}
                        </>
                      ) : (
                        <>{paymentObj.from}</>
                      )}
                      <Snackbar
                        message="Copied to clibboard"
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        autoHideDuration={2000}
                        onClose={() => setCopy(false)}
                        open={copy}
                      />
                      <CopyToClipboard text={paymentObj?.from}>
                        <em
                          onClick={() => setCopy(!copy)}
                          style={{ cursor: "pointer" }}
                          className="ti ti-files ms-2"
                        ></em>
                      </CopyToClipboard>
                    </p>
                  </div> */}
                  <div className="detail">
                    <p>
                      <img
                        className="img-fluid"
                        src="images/payment-receive.svg"
                        alt=""
                      />
                      Receiving Wallet Address
                    </p>
                    <p title={paymentObj.receiveaddress}>
                      {/* <>{paymentObj.receiveaddress}</> */}
                      {paymentObj.receiveaddress.length > 15 ? (
                        <>
                          {paymentObj.receiveaddress.substring(0, 7) +
                            " .... " +
                            paymentObj.receiveaddress.substring(
                              paymentObj.receiveaddress.length - 7,
                              paymentObj.receiveaddress.length
                            )}
                        </>
                      ) : (
                        <>{paymentObj.receiveaddress}</>
                      )}

                      <Snackbar
                        message="Copied to clibboard"
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        autoHideDuration={2000}
                        onClose={() => setCopy(false)}
                        open={copy}
                      />
                      <CopyToClipboard text={paymentObj.receiveaddress}>
                        {/* <button
                        className="refferal-copy copy-clipboard"
                        style={{ width: "10%", textAlign: "center" }}
                      >
                        copy
                      </button> */}

                        <em
                          onClick={() => setCopy(!copy)}
                          style={{ cursor: "pointer" }}
                          className="ti ti-files ms-2"
                        ></em>
                      </CopyToClipboard>
                    </p>
                  </div>
                  <div
                    className="detail"
                    style={{
                      flexDirection: "column",
                      padding: "20px 0px 20px 0px",
                    }}
                  >
                    <p>
                      <img
                        className="img-fluid"
                        src="images/payment-transcation.svg"
                        alt=""
                      />
                      Submit Transaction Hash Here
                    </p>
                    <div className="input-group">
                      <input
                        type="text"
                        className="w-100"
                        name="txnno"
                        id="exp"
                        placeholder="Transaction Hash"
                        value={paymentObj.txnno}
                        onChange={handleChange}
                        required
                      />
                      <span className="error1">{errors.txnno}</span>{" "}
                    </div>
                  </div>
                </div>
                {/* <input 
                type="text"
                placeholder="Enter the transaction hash"
                name="transactionHash"
                onChange={handleChange}
                value={paymentObj.txnno}
               />
                <span className="error1">{errors.txnno}</span> */}
                {/* <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Desposit Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter the address"
                      name="from"
                      onChange={handleChange}
                      value={airdropData.from}
                    />
                  </Form.Group>
                  <span className="error1">{errors.address}</span>
                </Form> */}
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="airdropmodal-boxe-buttons d-flex ">
            <Button
              className="btn-gray me-3"
              onClick={transactionSubmitModalCloseHandle}
            >
              Cancel
            </Button>
            <Button className=" btn-gray" onClick={submitTransactionsDetails}>
              {loading ? <Clip /> : <>Create</>}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default EthTransactionSubmitModal;
