const SideBarToggle = (props) => {
  const { open, setOpen } = props;
  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className="btn btn-dual mr-1"
        data-toggle="layout"
        data-action="sidebar_toggle"
      >
        <i className="fa fa-fw fa-bars side-bars"></i>
      </button>
    </>
  );
};
export default SideBarToggle;
