import { sanitize } from "dompurify";
const GridRow = ({ row }) => {
  return (
    <div className="feature">
      <div className="row g-0">
        <div className="col-md-6 col-sm-12">
          <div className="feature-s10 feature-s10-left round bg-white">
            <h2 className="title title-bold title-s4">{row.head1}</h2>
            <h5 className="title-sm">{row.subhead1}</h5>
            <div className="" data-delay=".2">
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(row.description1),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="feature-s10 feature-s10-right solution-px round bg-theme tc-light">
            <h2 className="title title-bold title-s4">{row.head2}</h2>
            <h5 className="title-sm">{row.subhead2}</h5>
            <div className="" data-delay=".2">
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(row.description2),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GridRow;
