/* eslint-disable */
import React from "react";
import { useContext, useEffect, useState, useCallback } from "react";
import { WalletContext, SaleContext } from "../../App";
import { verifyMerkle } from "../../helpers/verifyMerkle";
import { GetTransactions } from "../../Services/Admin/Dashboard";
import { FetchTransaction } from "../../Services/User/Tokens/token";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import moment from "moment";
import {
  ICOTokenBalance,
  ICOTokenName,
  TotalVestingAmount,
} from "../../Services/User/web3Calls/contractCall";
import Clock from "../claimTokens/clock";
import { ClipLoader } from "react-spinners";
import DatesSkeleton from "../skeletons/dates_skeleton";
import Guideline from "../GuideLines/guideline";
import useSaleSetter from "../../CustomHooks/Sale";
function Dashboard() {
  const [walletAddress] = useContext(WalletContext);
  const [raisedAmou, setRaisedAmou] = useState({
    softcap: 0,
    hardcap: "",
    totalinvested: 0,
    totalinvestedPercent: 0,
    presale: 0,
  });
  const saleObj = useContext(SaleContext);
  const [icoPhase, setIcoPhase] = useState({
    preSale: { start: "", end: "", bonus: 0 },
    publicSale: { start: "", end: "", bonus: 0 },
    p1: { start: "", end: "", bonus: "" },
    p2: {
      start: "",
      end: "",
      bonus: 0,
    },
    p3: {
      start: "",
      end: "",
      bonus: 0,
    },
    p4: {
      start: "",
      end: "",
      bonus: 0,
    },
  });
  const [bal, setBal] = useState(0);

  const [icoName] = useState("");
  const [rewardToken, setRewardToken] = useState("-");
  useEffect(() => {
    fetchTokenName();
    fetchTotalInvestment();
    CountTransactions();
  }, []);
  const [info, setInfo] = useState([
    {
      title: "Setup Wallet",
      description:
        "<h6>First of all,A crypto wallet keeps track of your crypto accounts and allows you to approve transfers to and from other accounts. You need a wallet to participate in the Recruit Coin ICO.Recruit Coins are Ethereum ERC-20 compatible tokens. Your crypto wallet must be capable of holding ERC-20 tokens.</h6>",
    },
    {
      title: "Buy Tokens",
      description:
        "In Buy Token Section user will be able to invest in Recruit token .There are multiple options availble to invest e.g BTC,ETH,DAI.UST,BUSD.In order to invest in stable coins, you must have a metamask wallet and tokens/coins which you want to invest.If you want to invest in btc.you must have a btc wallet and btc balance in your account",
    },

    {
      title: "Application",
      description:
        "This is a KYC(know your customer) section.Here you have to submit details about your current country, phone number ,address etc. These details will be cross checked based on your submitted documents",
    },

    {
      title: "Claim Tokens",
      description:
        "After investment in Buy tokens section .User will be able to claim tokens after specific locked time .Claims amount will be based upon time of investment and bonus.",
    },
  ]);

  useEffect(() => {
    let pre_sale = {
      start:
        saleObj[0].sDates != undefined
          ? moment.unix(saleObj[0].sDates.presalestart).format("MMMM Do YYYY")
          : "",
      end:
        saleObj[0].sDates != undefined
          ? moment.unix(saleObj[0].sDates.presaleend).format("MMMM Do YYYY")
          : "",
    };
    let public_sale = {
      start:
        saleObj[0].sDates != undefined
          ? moment
              .unix(saleObj[0].sDates.publicsalestart)
              .format("MMMM Do YYYY")
          : "",
      end:
        saleObj[0].sDates != undefined
          ? moment.unix(saleObj[0].sDates.publicsaleend).format("MMMM Do YYYY")
          : "",
    };
    let phase1 = {
      start: moment(saleObj[0].start).format("MMMM Do YYYY"),
      end: moment(saleObj[0].start).add(saleObj[0].remaintime, "seconds"),
      bonus: 15,
    };
    let phase2 = {
      start: moment(phase1.end).format("MMMM Do YYYY"),
      end: moment(phase1.end).add(saleObj[0].remaintime, "seconds"),
      bonus: 10,
    };
    let phase3 = {
      start: moment(phase2.end).format("MMMM Do YYYY"),
      end: moment(phase2.end).add(saleObj[0].remaintime, "seconds"),
      bonus: 7,
    };
    let phase4 = {
      start: moment(phase3.end).format("MMMM Do YYYY"),
      end: moment(saleObj[0].end),
      bonus: 5,
    };

    setIcoPhase({
      ...icoPhase,
      preSale: pre_sale,
      publicSale: public_sale,
      p1: phase1,
      p2: phase2,
      p3: phase3,
      p4: phase4,
    });
  }, [saleObj]);

  const fetchTotalInvestment = async () => {
    const total = await TotalVestingAmount();
    setRaisedAmou(total);
  };
  const [tokensInvested, setTokensInvested] = useState({
    dai: 0,
    eth: 0,
    busd: 0,
    btc: 0,
    ust: 0,
    tusd: 0,
    credit: 0,
  });
  const CountTransactions = async () => {
    const transaction = await FetchTransaction();

    let _transactions = [];
    let _dai = [];
    let _busd = [];
    let _ust = [];
    let _tusd = [];
    let _btc = [];
    let _eth = [];
    let _credit = [];

    // eslint-disable-next-line no-loop-func
    // eslint-disable-next-line no-loop-func
    let match = 0;
    let dai = 0;
    let busd = 0;
    let ust = 0;
    let tusd = 0;
    let btc = 0;
    let eth = 0;
    let credit = 0;
    transaction.data.map((i, index) => {
      if (i.paymentMethod === "DAI") {
        dai += parseFloat(i.expectedamount);
      }
      if (i.paymentMethod === "BUSD") {
        busd += parseFloat(i.expectedamount);
      }
      if (i.paymentMethod === "USDT") {
        ust += parseFloat(i.expectedamount);
      }
      if (i.paymentMethod === "TUSD") {
        tusd += parseFloat(i.expectedamount);
      }
      if (i.paymentMethod === "BTC") {
        btc += parseFloat(i.expectedamount);
      }
      if (i.paymentMethod === "ETH") {
        if (i.network == "USDT") {
          ust += i.actualamount ? parseFloat(i.actualamount) : 0;
        } else {
          eth += i.actualamount ? parseFloat(i.actualamount) : 0;
        }
      }
      if (i.paymentMethod === "credit") {
        credit += parseFloat(i.expectedamount);
      }
    });
    setTokensInvested({
      ...tokensInvested,
      dai: dai.toFixed(2),
      btc: btc.toFixed(4),
      eth: eth.toFixed(4),
      tusd: tusd.toFixed(2),
      ust: ust.toFixed(2),
      busd: busd.toFixed(2),
      credit: credit.toFixed(2),
    });
  };

  const fetchTokenName = async () => {
    const tokenSymbol = await ICOTokenName();
    setRewardToken(tokenSymbol);
  };
  useEffect(() => {
    fetchPreSale();
    // eslint-disable-next-line
  }, [walletAddress]);
  const fetchPreSale = useCallback(async () => {
    const res = await verifyMerkle(walletAddress);
    const bal = await ICOTokenBalance(walletAddress);
    setBal(bal);

    if (res) {
    }
  }, [walletAddress]);
  return (
    <>
      <div className="user-content">
        <div className="user-panel">
          <div className="row user-blnc">
            <div className="col-md-3 mb-md-0 mb-2">
              <div className="tile-item tile-primary">
                <div className="tile-bubbles"></div>
                <h6 className="tile-title">{rewardToken} BALANCE</h6>
                <h1 className="tile-info">{bal?.toLocaleString()}</h1>
                <ul className="tile-list-inline"></ul>
              </div>
            </div>
            <div className="col-md-9 mb-md-0 mb-2">
              <div className="tile-item tile-light">
                <div className="tile-bubbles"></div>
                <h6 className="tile-title">YOUR CONTRIBUTION</h6>

                <div className="row">
                  <div className="d-flex align-items-center justify-content-between flex-wrap your-contribution">
                    <div className="text-dark">
                      <div className="invest-amou">{tokensInvested.dai}</div>
                      <div className="invest-name">DAI</div>
                    </div>
                    <div className="text-dark">
                      <div className="invest-amou">{tokensInvested.busd}</div>
                      <div className="invest-name">BUSD</div>
                    </div>
                    <div className="text-dark">
                      <div className="invest-amou">{tokensInvested.btc}</div>
                      <div className="invest-name">BTC</div>
                    </div>
                    <div className="text-dark">
                      <div className="invest-amou">{tokensInvested.eth}</div>
                      <div className="invest-name">ETH</div>
                    </div>
                    <div className="text-dark">
                      <div className="invest-amou">{tokensInvested.tusd}</div>
                      <div className="invest-name">TUSD</div>
                    </div>
                    <div className="text-dark">
                      <div className="invest-amou">{tokensInvested.ust}</div>
                      <div className="invest-name">USDT</div>
                    </div>

                    <div className="text-dark">
                      <div className="invest-amou">{tokensInvested.credit}</div>
                      <div className="invest-name">Credit Card</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="info-section shadow-none border-0 mb-lg-4 mb-sm-3">
            <h2 className="welcome-txt">Welcome to RecruitCoin!</h2>
            <p>
              By leveraging the power of blockchain technology and
              cryptocurrency we are recrafting how recruitment works. Welcome to
              the recruit-tech revolution!
            </p>
            <p>
              Getting set up is simple—to get started just follow the simple
              step-by-step instructions on the left!
            </p>
          </div>
          {/* <Guideline /> */}
          {saleObj[0].start === "" ? (
            <DatesSkeleton />
          ) : (
            <div className="token-card mb-lg-4 mb-sm-3 mb-1">
              <div className="token-info">
                <span className="token-smartag">{saleObj[0].type}</span>
                <h2 className="token-bonus">
                  {saleObj[0].bonus}% <span>Current Bonus</span>
                </h2>
                <ul className="token-timeline">
                  <li>
                    <span>START DATE</span>
                    {saleObj[0].start}
                  </li>
                  <li>
                    <span>END DATE</span>
                    {saleObj[0].end}
                  </li>
                </ul>
              </div>
              <div className="token-countdown">
                {saleObj[0].loading ? (
                  <span>Fetching Dates ..... </span>
                ) : saleObj[0].type === "upcoming-public" ? (
                  <>
                    <span className="token-countdown-title mb-lg-4 mb-2">
                      <>
                        Public Sale Starts In <Clock date={saleObj[0].start} />
                      </>
                    </span>
                  </>
                ) : (
                  <>
                    {saleObj[0].type === "presale" ? (
                      <>
                        <span className="token-countdown-title mb-lg-4 mb-2">
                          <>
                            <span className="token-countdown-title">
                              PreSale End In <Clock date={saleObj[0].end} />
                            </span>
                          </>
                        </span>
                      </>
                    ) : saleObj[0].type === "upcoming-presale" ? (
                      <>
                        <span className="text-white">PreSale Starts In</span>{" "}
                        <Clock date={saleObj[0].start} />
                      </>
                    ) : saleObj[0].type === "ended" ? (
                      <>
                        <h6 className="text-white">Sale Ended</h6>
                      </>
                    ) : (
                      <>
                        {" "}
                        <span className="token-countdown-title">
                          THE BONUS ENDS IN
                        </span>
                        <>
                          {moment(icoPhase.p1.end).valueOf() > Date.now() ? (
                            <Clock date={icoPhase.p1.end} />
                          ) : moment(icoPhase.p2.end).valueOf() > Date.now() ? (
                            <Clock date={icoPhase.p2.end} />
                          ) : moment(icoPhase.p3.end).valueOf() > Date.now() ? (
                            <Clock date={icoPhase.p3.end} />
                          ) : moment(icoPhase.p4.end).valueOf() > Date.now() ? (
                            <Clock date={icoPhase.p4.end} />
                          ) : (
                            <span>Bonus Ended</span>
                          )}
                        </>
                      </>
                    )}
                  </>
                )}
                <div
                  className="token-countdown-clock"
                  data-date="2022/09/05"
                ></div>
              </div>
            </div>
          )}
          {/* <div className="progress-card mb-lg-4 mb-sm-3 mb-1">
            <h4>Token Sale Progress</h4>
            <ul className="progress-info">
              <li className="text-dark">
                <span className="text-dark">Raised -</span>{" "}
                {raisedAmou?.totalinvested.toLocaleString("en", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}{" "}
                {icoName}
              </li>
            </ul>
            <div className="progress-bar">
              <div className="progress-hcap" style={{ width: "90%" }}>
                <div>
                  Hard cap <span>{raisedAmou?.hardcap}</span>
                </div>
              </div>
              <div className="progress-scap" style={{ width: "34%" }}>
                <div>
                  Soft cap <span>{raisedAmou?.softcap}</span>
                </div>
              </div>
              <div className="progress-psale" style={{ width: "12%" }}>
                <div>
                  Pre-Sale <span>{raisedAmou?.presale}</span>
                </div>
              </div>
              <div
                className="progress-percent"
                style={{ width: `${raisedAmou?.totalinvestedPercent}%` }}
              ></div>
            </div>
          </div> */}
          <div className="table-responsive b-color-tuple tokenSale-Table">
            <table className="table">
              <thead>
                <h3>{saleObj[0].type}</h3>
                <tr>
                  <th>Pre-Sale</th>
                  <th>
                    <div className="d-flex d-flex pb-lg-3 pb-2 mb-2 borderBtm">
                      <div className="me-5">
                        <span className="text-inherit d-block">Start Date</span>
                        <span className="tiny-tuple-text">
                          {icoPhase.preSale.start}
                        </span>
                      </div>
                      <div>
                        <span className="text-inherit d-block">End Date</span>
                        <span className="tiny-tuple-text">
                          {icoPhase.preSale.end}
                        </span>
                      </div>
                    </div>
                    <div>
                      <ul className="bonusWeeks">
                        <li>
                          <span className="tiny-tuple-text theme-color">
                            1 year
                          </span>
                          <span className="lightText">Cliff</span>
                        </li>
                        <li>
                          <span className="tiny-tuple-text theme-color">
                            24 Months
                          </span>
                          <span className="lightText">Vesting</span>
                        </li>
                      </ul>
                    </div>
                  </th>
                </tr>
              </thead>
              {saleObj.loading ? (
                <ClipLoader />
              ) : (
                <tbody>
                  <tr>
                    <td>
                      <span className="public-sale-text">Public Sale</span>
                    </td>
                    <td>
                      <div>
                        <div className="d-flex d-flex pb-lg-3 pb-2 mb-2 borderBtm">
                          <div className="me-5">
                            <span className="text-inherit d-block">
                              Start Date
                            </span>
                            <span className="tiny-tuple-text">
                              {icoPhase.publicSale.start}
                            </span>
                          </div>
                          <div>
                            <span className="text-inherit d-block">
                              End Date
                            </span>
                            <span className="tiny-tuple-text">
                              {icoPhase.publicSale.end}
                            </span>
                          </div>
                        </div>
                        <div>
                          <ul className="bonusWeeks justify-content-between">
                            <li>
                              <span className="tiny-tuple-text theme-color">
                                Week1
                              </span>
                              <span className="lightText">Bonus</span>
                              <span className="tiny-tuple-text">
                                {icoPhase.p1.bonus}%
                              </span>
                            </li>
                            <li>
                              <span className="tiny-tuple-text theme-color">
                                Week2
                              </span>
                              <span className="lightText">Bonus</span>
                              <span className="tiny-tuple-text">
                                {icoPhase.p2.bonus}%
                              </span>
                            </li>
                            <li>
                              <span className="tiny-tuple-text theme-color">
                                Week3
                              </span>
                              <span className="lightText">Bonus</span>
                              <span className="tiny-tuple-text">
                                {icoPhase.p3.bonus}%
                              </span>
                            </li>
                            <li>
                              <span className="tiny-tuple-text theme-color">
                                Week4
                              </span>
                              <span className="lightText">Bonus</span>
                              <span className="tiny-tuple-text">
                                {icoPhase.p4.bonus}%
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
export default Dashboard;
