import { useState, useEffect, useContext } from "react";
import Card from "@mui/material/Card";
import TableRows from "./Tablerow";
import Loader from "../../../Comps-Utils/ClimbingLoader";
import IcoTable from "../../../Comps-Utils/GenericTable";
import {
  GetFAQ,
  AddCMSFaqListing,
  UpdateCMSFaqListing,
  DeleteCMSFaqListing,
  UpdateStatusCMSFaqListing,
} from "../../../../Services/Admin/CMS/Faq";
import { ToasterContext } from "../../../../App";
import {
  faqPreIcoInitialValues,
  FaqPreIcoSchema,
  setInitialValues,
  FaqPreIcoFormFields,
  createRows,
  FaqsPreIcoTableHeadings,
} from "./helpers";
const PreIco = () => {
  const [general, setGeneral] = useState([]);
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [id, setId] = useState();
  const [loading, setLoading] = useState(true);
  const [updatedRow, setUpdatedRow] = useState({});
  const [actionState, setActionState] = useState("new");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setActionState("new");
    setUpdatedRow({});
  };
  const GetCMSDataService = (type) => {
    GetFAQ(type).then((res) => {
      if (res.status === 200) {
        setGeneral(res.data);
        setId(res.data[0]._id);
        setLoading(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const DeleteCMSFaqGeneralService = (id) => {
    DeleteCMSFaqListing(id).then((res) => {
      if (res.status === 200 && res.code === 200) {
        GetCMSDataService("kyc");
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const UpdateStatusFaqGeneralService = (id, status, type) => {
    UpdateStatusCMSFaqListing(id, status, type).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const AddCMSFaqGeneralLisitngService = (data) => {
    AddCMSFaqListing(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
        handleClose();
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const UpdateCMSFaqGeneralLisitngService = (data) => {
    UpdateCMSFaqListing(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
        handleClose();
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  useEffect(() => {
    GetCMSDataService("kyc");
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Card className="mx-auto w95">
        {loading ? (
          <Loader />
        ) : (
          <IcoTable
            //states
            isModal={true}
            modalHeading={"PRE-ICO"}
            tableHeading={"PRE-ICO"}
            loading={loading}
            data={general}
            id={id}
            actionState={actionState}
            open={open}
            updatedRow={updatedRow}
            //setters
            setUpdatedRow={setUpdatedRow}
            setActionState={setActionState}
            setLoading={setLoading}
            setGeneral={setGeneral}
            //Handlers
            handleOpen={handleOpen}
            handleClose={handleClose}
            //Table Row Components
            TableRow={TableRows}
            //Helpers States &  Function
            createRows={createRows}
            headings={FaqsPreIcoTableHeadings}
            initialValues={faqPreIcoInitialValues}
            schema={FaqPreIcoSchema}
            setInitialValues={setInitialValues}
            formFields={FaqPreIcoFormFields}
            //Services
            updateKycStatus={UpdateStatusFaqGeneralService}
            deleteRow={DeleteCMSFaqGeneralService}
            add={AddCMSFaqGeneralLisitngService}
            update={UpdateCMSFaqGeneralLisitngService}
            tostify={tostify}
          />
        )}
      </Card>
    </>
  );
};
export default PreIco;
