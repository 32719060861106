import { useState, useContext } from "react";
import { UpdateUserPassword } from "../../Services/User/User/user";
import Clip from "../../components/Comps-Utils/ClipLoader";
import { ToasterContext } from "../../App";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
const Change_password = () => {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [passSecure, setPassSecure] = useState({
    password: "",
    newPassword: "",
    confirmpass: "",
  });
  const [errors, setErrors] = useState({
    password: "",
    newPassword: "",
    confirmpass: "",
  });
  const [showPass, setShowPass] = useState({
    oldpassword: true,
    password: true,
    confirmpass: true,
  });
  const handleVisiblityPassword = (field) => {
    if (field === "pass") {
      let pass = !showPass.password;
      setShowPass({ ...showPass, password: pass });
    } else if (field === "rpass") {
      let pass = !showPass.confirmpass;
      setShowPass({ ...showPass, confirmpass: pass });
    } else if (field === "old") {
      let pass = !showPass.oldpassword;
      setShowPass({ ...showPass, oldpassword: pass });
    }
  };
  const handleChange = (e) => {
    setPassSecure({ ...passSecure, [e.target.name]: e.target.value });
  };
  const checkValidation = () => {
    let errors = {};
    if (passSecure.password === "") {
      errors.password = "old password is required";
    } else if (passSecure.password.length < 7) {
      errors.password = "old password is less than 8 character";
    }
    if (passSecure.newPassword === "") {
      errors.newPassword = "new password is required";
    } else if (passSecure.newPassword.length < 7) {
      errors.newPassword = "new password is less than 8 character";
    }
    if (passSecure.confirmpass === "") {
      errors.confirmpass = "confirm password is required";
    } else if (passSecure.confirmpass.length < 7) {
      errors.confirmpass = "confirm password is less than 8 character";
    } else if (passSecure.newPassword !== passSecure.confirmpass) {
      errors.confirmpass = "password doesn't match";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return false;
    } else {
      setErrors({});
      return true;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = checkValidation();
    if (validate) {
      setLoading(true);
      const user = await UpdateUserPassword(passSecure);
      if (user.data === 200) {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "success",
          message: user.message,
        });
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "error",
          message: user.message,
        });
      }
    }
  };
  return (
    <>
      <form action="#">
        <div className="row">
          <div className="col-lg-6">
            <div className="input-item input-with-label">
              <label htmlFor="swalllet" className="input-item-label">
                Old Password
              </label>
              <input
                className="input-bordered"
                type={showPass.oldpassword ? "password" : "text"}
                name="password"
                value={passSecure.password}
                onChange={handleChange}
              />
              <i
                style={{
                  position: "absolute",
                  right: "25px",
                  marginTop: "10px",
                }}
                onClick={() => {
                  handleVisiblityPassword("old");
                }}
              >
                {showPass.oldpassword ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </i>
              <span className="error">{errors.password}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="input-item input-with-label">
              <label htmlFor="date-of-birth" className="input-item-label">
                New Password
              </label>
              <input
                className="input-bordered"
                type={showPass.password ? "password" : "text"}
                name="newPassword"
                value={passSecure.newPassword}
                onChange={handleChange}
              />
              <i
                style={{
                  position: "absolute",
                  right: "25px",
                  marginTop: "10px",
                }}
                onClick={() => {
                  handleVisiblityPassword("pass");
                }}
              >
                {showPass.password ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </i>
              <span className="error">{errors.newPassword}</span>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-item input-with-label">
              <label htmlFor="date-of-birth" className="input-item-label">
                Confirm New Password
              </label>
              <input
                className="input-bordered passwordInput"
                type={showPass.confirmpass ? "password" : "text"}
                name="confirmpass"
                value={passSecure.confirmpass}
                onChange={handleChange}
              />
              <i
                className="passwordIcon"
                onClick={() => {
                  handleVisiblityPassword("rpass");
                }}
              >
                {showPass.confirmpass ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </i>
              <span className="error">{errors.confirmpass}</span>
            </div>
          </div>
        </div>
        <div className="note note-plane note-info mb-lg-3 mb-2">
          <em className="fas fa-info-circle error-color"></em>
          <p className="error-color">
            Password should be minimum 8 letter and include lower and uppercase
            letter.
          </p>
        </div>
        {/* <div className="gaps-3x"></div>
        <div className="gaps-1x"></div> */}
        <div className="d-sm-flex justify-content-between align-items-center">
          <button className="btn btn-primary" onClick={handleSubmit}>
            {loading ? <Clip /> : <>Update</>}
          </button>
          <div className="gaps-2x d-sm-none"></div>
          {/* <span className="color-success">
            <em className="ti ti-check-box"></em> Changed Password
          </span> */}
        </div>
      </form>
    </>
  );
};
export default Change_password;
