import * as Yup from "yup";
export const createRows = (rowData) => {
  const rows = rowData.map((i, index) => {
    return {
      id: i._id,
      title: i.title,
      description: i.description,
      status: i.status,
      type: i.type,
    };
  });
  return rows;
};
export const rowsPerPage = 10;
export const FaqsGeneralTableHeadings = [
  {
    id: "question",
    label: "Question",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "status",
    label: "Status",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "actions",
    label: "Actions",
    sort: true,
    class: "bg-body-light",
  },
];
export const faqGeneralInitialValues = {
  title: "",
  description: "",
  status: false,
  type: "general",
};
export const FaqGeneralSchema = Yup.object().shape({
  title: Yup.string().required("Title is required!"),
  description: Yup.string().required("Description is required!"),
});
export const FaqGeneralFormFields = [
  {
    field: "Title",
    fieldName: "title",
    place: "Enter Title",
    type: "text",
  },
  {
    field: "Description",
    fieldName: "description",
    place: "Enter Description",
    type: "description",
  },
  {
    field: "Status",
    fieldName: "status",
    place: "",
    type: "radio",
  },
];
export const setInitialValues = (updatedRow) => {
  const Values = {
    id: updatedRow.id ? updatedRow.id : "",
    title: updatedRow.title ? updatedRow.title : "",
    description: updatedRow.description ? updatedRow.description : "",
    status: updatedRow.status ? updatedRow.status : false,
    type: updatedRow.type ? updatedRow.type : "",
  };
  return Values;
};
