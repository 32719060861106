import Card from "@mui/material/Card";
import { useState, useEffect, useContext } from "react";
import BannerForm from "../../Comps-Utils/GenericForm";
import Loader from "../../Comps-Utils/ClimbingLoader";
import { GetCMS, UpdateCMSBanner } from "../../../Services/Admin/CMS/Banner";
import { ToasterContext } from "../../../App";
import {
  BannerInitialValues,
  BannerSchema,
  CMSBannerFormFields,
  getInitialValues,
} from "./helpers";
const Banner = () => {
  const [general, setGeneral] = useState([]);
  const [id, setId] = useState();
  const [loading, setLoading] = useState(true);
  const [reset, setReset] = useState(false);
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);

  const GetCMSDataService = () => {
    setLoading(true);
    GetCMS().then((res) => {
      if (res.status === 200) {
        setGeneral(res.data[0].banner);
        setId(res.data[0]._id);
        setLoading(false);
        setReset(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const updateBannerService = async (data, actions) => {
    UpdateCMSBanner(data, id).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
        setReset(true);
      }
      actions.setSubmitting(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };

  useEffect(() => {
    GetCMSDataService();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Card className="mx-auto w95">
        {loading ? (
          <Loader />
        ) : (
          <BannerForm
            heading={"CMS Common - Banner"}
            general={general}
            id={id}
            reset={reset}
            refresh={GetCMSDataService}
            getValues={getInitialValues}
            initialValues={BannerInitialValues}
            schema={BannerSchema}
            formFields={CMSBannerFormFields}
            update={updateBannerService}
            tostify={tostify}
          />
        )}
      </Card>
    </>
  );
};
export default Banner;
