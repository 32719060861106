import { useState, useEffect, useMemo, useContext } from "react";
import moment from "moment";
import Clip from "../../components/Comps-Utils/ClipLoader";
import countryList from "react-select-country-list";

import KycImage from "./kyc_image.js";
import { AddUser } from "../../Services/User/KYC/index.js";
import { FetchUserInfo } from "../../Services/User/User/user";
import { WalletContext } from "../../App";
import { ToasterContext } from "../../App";
import Select from "react-select";
import { getKycDocs } from "../../Services/Admin/Kyc";

function KycApplication() {
  //eslint-disable-next-line
  const [walletAddress] = useContext(WalletContext);
  const [loading, setLoading] = useState(false);
  const [upload, setUpload] = useState("");
  const [uploadPassport, setUploadPassport] = useState("");
  const [uploadCnicFront, setUploadCnicFront] = useState("");
  const [uploadCnicBack, setUploadCnicBack] = useState("");
  const [uploadLicense, setUploadLicense] = useState("");

  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);

  const [imageType, setImageType] = useState("passport");

  const [upload1, setUpload1] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const user_id = JSON.parse(localStorage.getItem("user"))._id;
  const [kycDocs, setkycDocs] = useState("");
  const [KYC, setKYC] = useState({
    firstname: "",
    lastname: "",
    email: JSON.parse(localStorage.getItem("user")).email
      ? JSON.parse(localStorage.getItem("user")).email
      : "",
    phone: "",
    dobVal: moment().format("yyyy-MM-DD"),
    dob: "",
    nationality: "",
    address1: "",
    upload: "",
    upload1: "",
    address2: "",
    city: "",
    zip: "",
    telegram: "",
    type: "passport",
    walletNetwork: "BSC",
    walletAddress: "",
  });

  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    dobVal: "",
    dob: "",
    nationality: "",
    address1: "",
    address2: "",
    city: "",
    // zip: "",
    telegram: "",
    walletNetwork: "",
    walletAddress: "",
    type: "",
  });
  useEffect(() => {
    setKYC({ ...KYC, upload: upload1, upload1: upload1, type: imageType });
    fetchUser();
    if (user_id) {
      fetchUserKycDocs(user_id);
    }
    // eslint-disable-next-line
  }, []);
  const fetchUserKycDocs = async (id) => {
    getKycDocs(id).then((res) => {
      if (res.status === 200) {
        setkycDocs(res.data);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const fetchUser = async () => {
    // const user = JSON.parse(localStorage.getItem('user'))

    const user = await FetchUserInfo();

    if (user.code === 200) {
      const {
        username,
        firstname,
        lastname,
        phone,
        dob,
        address1,
        address2,
        city,
        nationality,
        zip,
        email,
        telegram,
      } = user.data;
      setKYC({
        ...KYC,
        username: username ? username : "",
        firstname: firstname ? firstname : "",
        lastname: lastname ? lastname : "",
        email: email ? email : "",
        phone: phone ? phone : "",
        dob: dob
          ? moment.parseZone(dob).utcOffset(0, true).format("yyyy-MM-DD")
          : "",
        address1: address1 ? address1 : "",
        address2: address2 ? address2 : "",
        city: city ? city : "",
        zip: zip ? zip : "",
        nationality:
          nationality !== undefined
            ? nationality
            : { label: options && options[236].label },
        telegram: telegram,
      });

      // setUpload(user.data.profileImgUrl)
    } else if (user.code === 400) {
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: "error",
        message: "Unauthenticated",
      });
    } else {
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: "error",
        message: user.message,
      });
    }
  };
  const handleChange = (e, type) => {
    if (type === "phone") {
      setKYC({ ...KYC, phone: e });
    } else if (type === "country") {
      const country = { label: e };
      setKYC({ ...KYC, nationality: country });
    } else {
      setKYC({ ...KYC, [e.target.name]: e.target.value });
    }
  };

  const checkValidation = (e) => {
    let errors = {};
    let phone_regex = new RegExp(
      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g //eslint-disable-line
    );

    if (KYC.firstname === "") {
      errors.firstname = "first name is required";
    }
    if (KYC.lastname === "") {
      errors.lastname = "last name is required";
    }

    if (KYC.phone === "") {
      errors.phone = "phone number is required";
    }
    if (KYC.phone.length < 9) {
      errors.phone = "phone number is less than 10 digits";
    }
    if (!phone_regex.test(KYC.phone)) {
      errors.phone =
        "phone number format is invalid example: +(123) - 456-78-90";
    }
    if (
      !kycDocs.passport.imageurl &&
      (uploadPassport === undefined || uploadPassport === "") &&
      !kycDocs.license.imageurl &&
      (uploadLicense === undefined || uploadLicense === "") &&
      !kycDocs.nationalId.frontimage &&
      (uploadCnicFront === undefined || uploadCnicFront === "") &&
      !kycDocs.nationalId.backimage &&
      (uploadCnicBack === undefined || uploadCnicBack === "")
    ) {
      errors.type = "Upload Some images";
    }
    if (KYC.dob === "") {
      errors.dob = "date of birth is required";
    } else if (KYC.dob.length > 0) {
      //
      var dobVal = KYC.dob;
      dobVal = dobVal.split("-");
      var bday_in_milliseconds = new Date(
        parseInt(dobVal[0], 10),
        parseInt(dobVal[1], 10) - 1,
        parseInt(dobVal[1]),
        10
      ).getTime();
      var now = new Date().getTime();

      if (now - bday_in_milliseconds < 568024668000) {
        errors.dob = "date of birth is less than 18";
      }
    }
    if (KYC.nationality === "") {
      errors.nationality = "nationality is required";
    }
    if (KYC.address1 === "") {
      errors.address1 = "address is required";
    }
    if (KYC.city === "") {
      errors.city = "city is required";
    }
    // if (KYC.zip === "") {
    //   errors.zip = "zip code is required";
    // }
    // if (KYC.walletNetwork === "") {
    //   errors.walletNetwork = "wallet source is empty";
    // }
    // if (KYC.walletAddress === "") {
    //   errors.walletAddress = "wallet address is empty";
    // }
    // const walletRegex = new RegExp(/^0x[a-fA-F0-9]{40}$/g);
    // if (!walletRegex.test(KYC.walletAddress)) {
    //   errors.walletAddress = "Invalid  wallet Address";
    // }

    if (
      (uploadCnicFront !== "" && uploadCnicBack === "") ||
      (uploadCnicFront === "" && uploadCnicBack !== "")
    ) {
      errors.type = "National ID Front and Back side are required.";
    }

    // const walletRegex = new RegExp(/^0x[a-fA-F0-9]{40}$/g)
    // if (!walletRegex.test(KYC.walletAddress)) {
    //   errors.walletAddress = 'Invalid  wallet Address'
    // }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return false;
    } else {
      setErrors({});
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validate = checkValidation();

    if (validate) {
      setLoading(true);
      KYC.type = imageType;
      const upload = {
        passport: uploadPassport,

        license: uploadLicense,

        cnicFront: uploadCnicFront,

        cnicBack: uploadCnicBack,
      };
      const user = await AddUser(KYC, upload);

      if (user?.code === 200) {
        setLoading(false);

        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "success",
          message: user.message,
        });
        window.setTimeout(function () {
          window.location.href = "/kyc-verification";
        }, 2000);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "error",
          message: user.message,
        });
      }
    } else {
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: "error",
        message: "Some required fields are missing or have an error",
      });
    }
  };
  return (
    <div className="user-content">
      <div className="user-kyc">
        <form action="#">
          <div className="from-step">
            <div className="from-step-item 222">
              <div className="from-step-heading">
                <div className="from-step-number">01</div>
                <div className="from-step-head">
                  <h4>Step 1 : Personal Details</h4>
                  <p>
                    Simple personal information, required for identification
                  </p>
                </div>
              </div>
              <div className="from-step-content">
                <div className="note note-md note-info note-plane">
                  <em className="fas fa-info-circle"></em>
                  <p>
                    Please carefully fill out the form with your personal
                    details. Your can’t edit these details once you submitted
                    the form.
                  </p>
                </div>
                <div className="gaps-2x"></div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-item input-with-label">
                      <label for="first-name" className="input-item-label">
                        First Name
                      </label>
                      <input
                        className="input-bordered"
                        type="text"
                        id="first-name"
                        name="firstname"
                        value={KYC.firstname}
                        onChange={handleChange}
                      />
                      <span className="error1">{errors.firstname}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-with-label">
                      <label for="last-name" className="input-item-label">
                        Last Name
                      </label>
                      <input
                        className="input-bordered"
                        type="text"
                        value={KYC.lastname}
                        id="last-name"
                        name="lastname"
                        onChange={handleChange}
                      />
                      <span className="error1">{errors.lastname}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-with-label">
                      <label for="email-address" className="input-item-label">
                        Email Address
                      </label>
                      <input
                        className="input-bordered "
                        type="email"
                        id="email-address"
                        name="email"
                        value={KYC.email}
                        onChange={handleChange}
                        disabled
                      />
                      <span className="error1">{errors.email}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-with-label">
                      <label for="phone-number" className="input-item-label">
                        Phone Number
                      </label>
                      <input
                        className="input-bordered"
                        type="text"
                        id="phone-number"
                        name="phone"
                        value={KYC.phone}
                        onChange={handleChange}
                      />
                      <span className="error1">{errors.phone}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-with-label">
                      <label for="date-of-birth" className="input-item-label">
                        Date of Birth
                      </label>
                      <input
                        className="input-bordered date-picker"
                        type="date"
                        id="date-of-birth"
                        name="dob"
                        value={KYC.dob}
                        onChange={handleChange}
                      />
                      <span className="error1">{errors.dob}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-with-label">
                      <label for="country" className="input-item-label">
                        Nationality
                      </label>

                      <div className="small-input">
                        <Select
                          className="country-slector"
                          options={options}
                          name="nationality"
                          value={options?.label}
                          onChange={(value) =>
                            handleChange(value?.label, "country")
                          }
                          isSearchable={true}
                          placeholder={
                            KYC.nationality?.label
                              ? KYC.nationality?.label
                              : options[236].label
                          }
                        />
                      </div>

                      <span className="error1">{errors.nationality}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-with-label">
                      <label for="address-line-1" className="input-item-label">
                        Address Line 1
                      </label>
                      <input
                        className="input-bordered"
                        type="text"
                        id="address-line-1"
                        name="address1"
                        value={KYC.address1}
                        onChange={handleChange}
                      />
                      <span className="error1">{errors.address1}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-with-label">
                      <label for="address-line-2" className="input-item-label">
                        Address Line 2 <span>(optional)</span>
                      </label>
                      <input
                        className="input-bordered"
                        type="text"
                        id="address-line-2"
                        name="address2"
                        value={KYC.address2}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-with-label">
                      <label for="email-address" className="input-item-label">
                        City, State of Residence
                      </label>
                      <input
                        className="input-bordered"
                        type="text"
                        id="email-address"
                        name="city"
                        value={KYC.city}
                        onChange={handleChange}
                      />
                      <span className="error1">{errors.city}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-with-label">
                      <label for="phone-number" className="input-item-label">
                        Zip Code
                      </label>
                      <input
                        className="input-bordered"
                        type="text"
                        id="phone-number"
                        name="zip"
                        value={KYC.zip}
                        onChange={handleChange}
                      />
                      {/* <span className="error1">{errors.zip}</span> */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-with-label">
                      <label for="phone-number" className="input-item-label">
                        Telegram Username <span>(optional)</span>
                      </label>
                      <input
                        className="input-bordered"
                        type="text"
                        id="phone-number"
                        name="telegram"
                        value={KYC.telegram}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="from-step-item">
              <KycImage
                upload={upload}
                setUpload={setUpload}
                upload1={upload1}
                setUpload1={setUpload1}
                setUploadPassport={setUploadPassport}
                uploadPassport={uploadPassport}
                setUploadCnicFront={setUploadCnicFront}
                uploadCnicFront={uploadCnicFront}
                setUploadCnicBack={setUploadCnicBack}
                uploadCnicBack={uploadCnicBack}
                setUploadLicense={setUploadLicense}
                uploadLicense={uploadLicense}
                imageType={imageType}
                setImageType={setImageType}
                errors={errors.type}
                kycDocs={kycDocs}
              />
            </div>

            <div className="from-step-item">
              {/* <div className="from-step-heading">
                <div className="from-step-number">03</div>
                <div className="from-step-head">
                  <h4>Step 3 : Your Paying Wallet</h4>
                  <p>
                    Submit your wallet address that you are going to send funds
                  </p>
                </div>
              </div> */}
              <div className="from-step-content">
                {/* <div className="note note-md note-info note-plane">
                  <em className="fas fa-info-circle"></em>
                  <p>
                    DO NOT USE your exchange wallet address such as Kraken,
                    Bitfinex, Bithumb, Binance etc.
                  </p>
                </div>
                <div className="gaps-2x"></div> */}
                {/* <div className="row">
                  <div className="col-md-6">
                    <div className="input-item input-with-label">
                      <label for="token-address" className="input-item-label">
                        Wallet Network
                      </label>
                      <input
                        className="input-bordered"
                        type="text"
                        disabled={true}
                        id="token-address"
                        name="walletAddress"
                        onChange={handleChange}
                        value={KYC.walletNetwork}
                      />

                      <span className="input-note">
                        Note: Address should be BEP20-compliant. Make sure that
                        you hold this wallet private key.
                      </span>
                    </div>
                  </div>
                </div> */}
                {/* <div className="input-item input-with-label">
                  <label for="token-address" className="input-item-label">
                    Enter your wallet address
                  </label>
                  <input
                    className="input-bordered"
                    type="text"
                    id="token-address"
                    name="walletAddress"
                    onChange={handleChange}
                    value={KYC.walletAddress}
                  />
                  <div className="error">{errors.walletAddress}</div>
                  <span className="input-note">
                    Note: Address should be BEP20-compliant. Make sure that you
                    hold this wallet private key.
                  </span>
                </div> */}
                <div className="gaps-2x"></div>
                <button
                  className="btn btn-primary d-flex"
                  data-bs-toggle="modal"
                  data-bs-target="#kycConfirm"
                  onClick={handleSubmit}
                >
                  {loading ? <Clip /> : <>Submit Details</>}
                </button>
                <div className="gaps-2x"></div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default KycApplication;
