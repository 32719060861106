import TableSortLabel from "@material-ui/core/TableSortLabel";
const TableHeader = (props) => {
  const { order, orderBy, onRequestSort, Columns } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <thead className="thead-light text-capitalize font-size-sm font-weight-bold border-top">
      <tr>
        {Columns.map((headCell, index) => (
          <th
            id={"table-header" + index}
            key={headCell.label}
            className={headCell.class}
            sortdirection={orderBy === headCell.id ? order : "false"}
          >
            {headCell.sort ? (
              <TableSortLabel
                id={"sort-table" + index}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};
export default TableHeader;
