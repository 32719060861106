import React from "react";
// import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import soldout from "../../../src/assets/media/photos/soldout.png";

function SaleEnded({ saleType }) {
  return (
    <>
      <div className="user-content">
        <div className="user-panel">
          <h2>Sales</h2>

          <div className="status status-empty">
            {/* <div className="status-icon p-4">
              <HourglassTopIcon />
            </div> */}
            <div className="investor-banner-img">
              <img
                src={soldout}
                // srcSet="../../../public/images/soldout.png"
                alt="icon"
                className=""
              />
            </div>
            <span className="status-text">
              {saleType == "Not Started"
                ? "Sale Not Started"
                : saleType == "Upcoming-Public"
                ? "PreSale Ended"
                : "Pre-Sale and Public-Sale Ended"}
            </span>
            <div className="investor-banner-text">
              {/* <h2>Pre-Sale Ended</h2> */}
              {saleType == "Not Started" ? (
                <p>
                  Thank you for your interest in RecruitCoin! The Pre-Sale and
                  Public-Sale stage is not started yet. Please visit the{" "}
                  <a
                    href="https://bettsrecruiting.com/recruitcoin/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Betts website
                  </a>{" "}
                  for the most up to date information about the Sale timeline.
                </p>
              ) : (
                <p>
                  Thank you for your interest in RecruitCoin! The{" "}
                  {saleType == "Upcoming-Public"
                    ? "Pre-Sale"
                    : "Pre-Sale and Public-Sale"}{" "}
                  stage was wildly successful and we reached our hard cap limit.
                  Please visit the{" "}
                  <a
                    href="https://bettsrecruiting.com/recruitcoin/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Betts website
                  </a>{" "}
                  for the most up to date information about the{" "}
                  {saleType == "Upcoming-Public"
                    ? "Public Sale"
                    : "Pre Sale and Public Sale"}{" "}
                  timeline.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SaleEnded;
