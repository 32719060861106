import React from "react";

const Notification = () => {
  return (
    <>
      <p>You can manage your all kind of notification from here.</p>
      <div className="gaps-1x"></div>
      <ul className="notification-list">
        <li>
          <span>Notify me by email about resumption of sales</span>
          <div className="padl-2x">
            <input className="input-switch" type="checkbox" id="rsmpts" />
            <label htmlFor="rsmpts"></label>
          </div>
        </li>
        <li>
          <span>Notify me by email htmlFor latest news</span>
          <div className="padl-2x">
            <input className="input-switch" type="checkbox" id="lnews" />
            <label htmlFor="lnews"></label>
          </div>
        </li>
        <li>
          <span>Alert me by email htmlFor unusual activity.</span>
          <div className="padl-2x">
            <input className="input-switch" type="checkbox" id="unuact" />
            <label htmlFor="unuact"></label>
          </div>
        </li>
      </ul>
      <div className="gaps-4x"></div>
      <div className="d-flex justify-content-between align-items-center">
        <span></span>
        {/* <span className="color-success">
          <em className="ti ti-check-box"></em> Update Notification Settings
        </span> */}
      </div>
    </>
  );
};

export default Notification;
