import { useContext, useEffect } from "react";
import { LayoutContext } from "../../../Layouts/PresentationLayout";
import SideBarToggle from "./SideBarToggleButton";
import useConnectWallet from "../../../Services/User/Wallet/wallet";
import UserDropDownHeader from "./UserDropDown";
// import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWallet";
import { WalletContext } from "../../../App";
import { ToasterContext } from "../../../App";
import { ConnectButton } from "@rainbow-me/rainbowkit";
const Header = () => {
  const wallet = useConnectWallet();
  const { open, setOpen } = useContext(LayoutContext);
  const [tostify, setTostify] = useContext(ToasterContext);
  const [walletAddress, setWalletAddress] = useContext(WalletContext);
  useEffect(() => {
    if (window.ethereum !== undefined) {
      handleWallet();
    }
    // eslint-disable-next-line
  }, [walletAddress]);
  const handleWallet = async () => {
    if (window.ethereum !== undefined) {
      wallet
        .then((res) => {
          if (res.code === -32002) {
            setTostify({
              ...tostify,
              visible: true,
              metamask: true,
              key: Math.random().toString(36).substring(7),
              type: "error",
              message: res.message,
            });
          } else if (res !== undefined) {
            setWalletAddress(res);
            window.ethereum
              .request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: process.env.REACT_APP_NETWORK_CHAIN_ID,
                    chainName: process.env.REACT_APP_NETWORK_NAME,
                    nativeCurrency: {
                      name: process.env.REACT_APP_NETWORK_CURRENCY,
                      symbol: process.env.REACT_APP_NETWORK_CURRENCY,
                      decimals: 18,
                    },
                    rpcUrls: [process.env.REACT_APP_RPC_URL],
                    blockExplorerUrls: [
                      process.env.REACT_APP_BLOCK_EXPLORER_URL,
                    ],
                  },
                ],
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((err) => {});
    } else {
      window.open(
        "https://metamask.io/",
        "_blank" // <- This is what makes it open in a new window.
      );
      tostify.dismissAll();
      // setTostify({
      //   ...tostify,
      //   visible: true,
      //   metamask: true,
      //   key: Math.random().toString(36).substring(7),
      //   type: "error",
      //   message: "Install Metamask ",
      // });
    }
  };
  return (
    <>
      <header id="page-header border">
        <div className="content-header ">
          <div className="ml-md-3 ml-auto mb-md-0 mb-2 toggleBtn">
            <SideBarToggle tostify={tostify} open={open} setOpen={setOpen} />
          </div>
          <div className="d-flex align-items-md-center align-items-baseline justify-content-sm-end justify-content-center w-100">
            <div className="connect-wallet pr-md-2 pr-1 py-0">
              {" "}
              {walletAddress !== "Connect Wallet" ? (
                // <button className="connect-wallet" onClick={handleWallet}>
                //   <span className="pr-sm-2">
                //     <AccountBalanceWalletOutlinedIcon />
                //   </span>
                //   <span className="mobile-none">{walletAddress}</span>
                // </button>

                <ConnectButton />
              ) : (
                <ConnectButton />
                // <button className="connect-wallet" onClick={handleWallet}>
                //   <span className="pr-sm-2">
                //     <AccountBalanceWalletOutlinedIcon />
                //   </span>
                //   <span className="mobile-none">{walletAddress}</span>
                // </button>
              )}
            </div>
            <div>
              <UserDropDownHeader />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
