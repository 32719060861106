import TextField50 from "./Textfield50";
import TextFiledLabelRequired from "./TextfieldLabelRequired";
import { ConnectedFocusError } from "focus-formik-error";
import AutocompleteCountry from "./AutoCompleteCountry";
import { TiTick } from "react-icons/ti";
import { BiReset } from "react-icons/bi";
import ClipLoader from "../Comps-Utils/ClipLoader";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import SelectRole from "./SelectRole";
import { useCallback } from "react";
const FormikFormDouble = (props) => {
  const {
    touched,
    errors,
    isSubmitting,
    values,
    handleChange,
    handleSubmit,
    formFields,
    formFields2,
    headingFormFields2,
    formFields3,
    headingFormFields3,
    heading,
    resetForm,
    reset,
    refresh,
    handleClose,
    setUpdatedRow,
    actionState,
    isModal,
    setFieldValue,
  } = props;
  return (
    <>
      <div className="form-bg">
        {heading === "Admin" ? null : (
          <div
            id="add-user-header"
            className="d-flex align-items-center justify-content-between bg-zinnia-grad-alt add-user-css"
          >
            <div>
              <h5 className="user-header-h5 mb-1">
                {actionState === "edit" && isModal
                  ? "Edit " + heading
                  : actionState === "edit"
                  ? heading
                  : null}
                {actionState !== "edit" && isModal
                  ? "Add " + heading
                  : actionState !== "edit"
                  ? heading
                  : null}
              </h5>
            </div>
            {isModal ? (
              <Button
                id="coin-modal-close"
                className="btn-neutral mx-2 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center text-white border-radius-15px"
                onClick={() => {
                  handleClose();
                  setUpdatedRow({});
                }}
              >
                <CloseIcon />
              </Button>
            ) : null}
          </div>
        )}
        <form autoComplete="off" className="modal-form pt-lg-4 pt-3 form-px">
          <ConnectedFocusError />
          <div className="card-common-style block-content block-content-full ggg">
            <div className="">
              <div className="row items-push">
                {formFields.map((item, index) => {
                  return (
                    <div
                      // key={
                      //   "double-form-field-" +
                      //   item.fieldName +
                      //   Math.random().toString(36).substring(7)
                      // }
                      className="d-flex flex-sm-row flex-column justify-content-between mb-2"
                    >
                      <div className="w45 me-2 mb-2">
                        <TextFiledLabelRequired
                          widthclass=""
                          field={item.field}
                        />
                        <TextField50
                          widthclass=""
                          fieldName={item.fieldName}
                          handleChange={handleChange}
                          values={values}
                          touched={touched}
                          errors={errors}
                          place={item.place}
                          type={item.type}
                        />
                      </div>
                      {item && item.field1 ? (
                        <div className="w45 mb-sm-0 mb-2">
                          <TextFiledLabelRequired
                            // key={
                            //   "double-text-field-50" +
                            //   Math.random().toString(36).substring(7)
                            // }
                            widthclass=""
                            field={item.field1}
                          />
                          {item && item.fieldName1 === "nationality" ? (
                            <AutocompleteCountry
                              fieldName={item.fieldName1}
                              handleChange={handleChange}
                              values={values}
                              touched={touched}
                              errors={errors}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldValue}
                              setFieldError={setFieldValue}
                            />
                          ) : item && item.fieldName1 === "permissions" ? (
                            <SelectRole
                              fieldName={item.fieldName1}
                              handleChange={handleChange}
                              values={values}
                              touched={touched}
                              errors={errors}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldValue}
                              setFieldError={setFieldValue}
                            />
                          ) : (
                            <TextField50
                              widthclass=""
                              fieldName={item.fieldName1}
                              handleChange={handleChange}
                              values={values}
                              touched={touched}
                              errors={errors}
                              place={item.place1}
                              type={item.type1}
                            />
                          )}

                          {/* {item && item.fieldName1 === "nationality" ? (
                          <AutocompleteCountry
                            fieldName={item.fieldName1}
                            handleChange={handleChange}
                            values={values}
                            touched={touched}
                            errors={errors}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldValue}
                            setFieldError={setFieldValue}
                          />
                        ) : item && item.fieldName1 === "permissions" ? (
                          <SelectRole
                            fieldName={item.fieldName1}
                            handleChange={handleChange}
                            values={values}
                            touched={touched}
                            errors={errors}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldValue}
                            setFieldError={setFieldValue}
                          />
                        ) : (
                          <TextField50
                            widthclass=""
                            fieldName={item.fieldName1}
                            handleChange={handleChange}
                            values={values}
                            touched={touched}
                            errors={errors}
                            place={item.place1}
                            type={item.type1}
                          />
                        )} */}
                        </div>
                      ) : null}
                      {/* {item.type === "image" ? (
                      <ProfileBox
                        setFieldValue={setFieldValue}
                        values={values}
                        touched={touched}
                        errors={errors}
                      />
                    ) : item.type === "radio" ? (
                      <RadioActiveInactive
                        setFieldValue={setFieldValue}
                        values={values}
                        touched={touched}
                        errors={errors}
                      />
                    ) : item.type === "description" ? (
                      <ReactQuill50
                        setFieldValue={setFieldValue}
                        fieldName={item.fieldName}
                        handleChange={handleChange}
                        values={values}
                        touched={touched}
                        errors={errors}
                        place={item.place}
                        type={item.type}
                      />
                    ) : item.type === "password" ? (
                      <PasswordField50
                        fieldName={item.fieldName}
                        handleChange={handleChange}
                        values={values}
                        touched={touched}
                        errors={errors}
                        place={item.place}
                        type={item.type}
                      />
                    ) : (
                      <TextField50
                        widthclass="w-50"
                        fieldName={item.fieldName}
                        handleChange={handleChange}
                        values={values}
                        touched={touched}
                        errors={errors}
                        place={item.place}
                        type={item.type}
                      />
                    )} */}
                    </div>
                  );
                })}
                {formFields2 ? (
                  <div
                    // key={
                    //   "double-form-field2-div" +
                    //   Math.random().toString(36).substring(7)
                    // }
                    className="block-content block-content-full md-setting"
                  >
                    <div className="items-push">
                      <h2 className="content-heading mb-sm-4">
                        {headingFormFields2}
                      </h2>
                      <div className="row items-push"></div>
                      {formFields2 &&
                        formFields2.map((item, index) => {
                          return (
                            <>
                              <div
                                // key={
                                //   "double-form-field-" +
                                //   item.fieldName2 +
                                //   Math.random().toString(36).substring(7)
                                // }
                                className="d-flex flex-sm-row flex-column justify-content-between mb-2"
                              >
                                <div className="w45 me-2 mb-2">
                                  <TextFiledLabelRequired
                                    widthclass=""
                                    field={item.field}
                                  />
                                  <TextField50
                                    widthclass=""
                                    fieldName={item.fieldName}
                                    handleChange={handleChange}
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    place={item.place}
                                    type={item.type}
                                  />
                                </div>
                                {item && item.field1 ? (
                                  <div className="w45 mb-sm-0 mb-2">
                                    <TextFiledLabelRequired
                                      widthclass=""
                                      field={item.field1}
                                    />
                                    <TextField50
                                      widthclass=""
                                      fieldName={item.fieldName1}
                                      handleChange={handleChange}
                                      values={values}
                                      touched={touched}
                                      errors={errors}
                                      place={item.place1}
                                      type={item.type1}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                ) : null}
                {formFields3 ? (
                  <div
                    // key={
                    //   "double-form-field3-div" +
                    //   Math.random().toString(36).substring(7)
                    // }
                    className="block-content block-content-full md-setting"
                  >
                    <div className="items-push">
                      <h2 className="content-heading mb-sm-4">
                        {headingFormFields3}
                      </h2>
                      <div className="row items-push"></div>
                      {formFields3 &&
                        formFields3.map((item, index) => {
                          return (
                            <>
                              <div
                                // key={
                                //   "double-form-field-" +
                                //   item.fieldName3 +
                                //   Math.random().toString(36).substring(7)
                                // }
                                className="d-flex flex-sm-row flex-column justify-content-between mb-2"
                              >
                                <div className="w45 me-2 mb-2">
                                  <TextFiledLabelRequired
                                    widthclass=""
                                    field={item.field}
                                  />
                                  <TextField50
                                    widthclass=""
                                    fieldName={item.fieldName}
                                    handleChange={handleChange}
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    place={item.place}
                                    type={item.type}
                                  />
                                </div>
                                {item && item.field1 ? (
                                  <div className="w45 mb-sm-0 mb-2">
                                    <TextFiledLabelRequired
                                      widthclass=""
                                      field={item.field1}
                                    />
                                    <TextField50
                                      widthclass=""
                                      fieldName={item.fieldName1}
                                      handleChange={handleChange}
                                      values={values}
                                      touched={touched}
                                      errors={errors}
                                      place={item.place1}
                                      type={item.type1}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                ) : null}
                <div className="d-flex justify-content-center mb-3">
                  <button
                    disabled={isSubmitting}
                    onClick={useCallback(
                      (e) => {
                        e.preventDefault();
                        if (reset === true) {
                          refresh();
                        } else {
                          resetForm();
                        }
                      },
                      // eslint-disable-next-line
                      [reset]
                    )}
                    className="btn btn-md btn-grad btn-grad-theme btn-round mr-2"
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <BiReset size={25} className="mr-1" /> <span>Reset</span>
                    </div>
                  </button>
                  <button
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    type="submit"
                    className="btn btn-md btn-grad btn-grad-theme btn-round"
                  >
                    {isSubmitting ? (
                      <div>
                        <ClipLoader color={"white"} />
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center align-items-center">
                        <TiTick size={25} /> <span>Save</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default FormikFormDouble;
