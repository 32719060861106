export const createRows = (rowData) => {
  //eslint-disable-next-line
  var invest = (Math.random() * (0.005 - 0.001) + 0.001).toFixed(5);
  const rows = rowData.map((i) => {
    let paymentMethod = i.paymentMethod;
    let payableToken =
      i.actualamount === ""
        ? 0
        : (
            (i.biddingtokenprice / i.claimtokenprice) * i.actualamount +
            parseFloat(i.bonus)
          ).toFixed(9);
    if (i.paymentMethod === "credit") {
      paymentMethod = "USD";
    } else if (i.paymentMethod === "ETH") {
      if (i.network === "USDT") {
        paymentMethod = i.network;
        payableToken = i.payabletoken?.toFixed(9);
      } else {
        paymentMethod = i.paymentMethod;
      }
    }
    return {
      id: i._id,
      userid: i.userid,
      email: i.useremail && i.useremail === "" ? "..." : i.useremail,
      name: i.username === "" ? "..." : i.username,
      invested: i.actualamount === "" ? 0 : i.actualamount,
      paymentMethod: paymentMethod,
      payableToken: payableToken,
      tokens: i.tokens,
      updatedAt: i.date,
      selected: false,
      from:
        i.recevingaddress === ""
          ? "Not Given"
          : i.recevingaddress.substring(0, 5) +
            "...." +
            i.recevingaddress.substring(38, 42),
      manual: i.manual,
    };
  });
  return rows;
};
export const rowsPerPage = 10;
export const AirDropTableHeadings = [
  {
    id: "name",
    label: "User",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "tokens_distribution",
    label: "Tokens distribution",
    sort: true,
    class: "bg-body-light",
  },
  // {
  //   id: "payableToken",
  //   label: "Payable Token",
  //   sort: true,
  //   class: "bg-body-light",
  // },
  // {
  //   id: "status",
  //   label: "Status",
  //   class: "bg-body-light",
  // },
  {
    id: "Receving Address",
    label: "Receving Address",
    class: "bg-body-light",
  },
  {
    id: "time",
    label: "Time",
    class: "bg-body-light",
  },
];
