import React, { useContext, useEffect } from "react";
import Home from "../../src/UserDashboard";
import SideNav from "../UserDashboard/navigation/sidenav";
import InvestorBanner from "../UserDashboard/InvestorBanner/InvestorBanner";
import { TotalVestingAmount } from "../Services/User/web3Calls/contractCall";
import { GetICOInfo } from "../Services/Admin/IcoSettings";
import { HardCapLimitContext } from "../App";

const UserLayout = (props) => {
  // const [hardCapLimit, setHardCapLimit] = useState(false);
  const [hardCapLimit, setHardCapLimit] = useContext(HardCapLimitContext);

  // const [publicSaleEnded, setPublicSaleEnded] = useState(false);
  const getHardCapLimit = async () => {
    const data = await TotalVestingAmount();
    const icoData = await GetICOInfo();
    let currenDate = new Date().getTime();
    let preSaleStart = new Date(icoData.data[0].presalestart).getTime();
    let preSaleEnd = new Date(icoData.data[0].presaleend).getTime();
    let publicSaleStart = new Date(icoData.data[0].publicsalestart).getTime();
    let publicSaleEnd = new Date(icoData.data[0].publicsaleend).getTime();

    if (preSaleStart <= currenDate && preSaleEnd >= currenDate) {
      if (data.harCapLimit < data.hardcap) {
        setHardCapLimit(false);
      } else if (data.harCapLimit > data.hardcap) {
        setHardCapLimit(true);
      }
    } else if (publicSaleStart <= currenDate && publicSaleEnd >= currenDate) {
      setHardCapLimit(false);
      if (data.publicSaleToken === 0) {
        // setPublicSaleEnded(true);
        setHardCapLimit(true);
      }
    }
  };
  useEffect(() => {
    getHardCapLimit();
  }, []);
  const { children } = props;
  return (
    <>
      <Home />
      <div className="user-wraper">
        {hardCapLimit ? (
          <div className="container">
            <InvestorBanner />
          </div>
        ) : (
          ""
        )}
        <div className="container">
          <div className="d-flex">
            <div className="sidenav-main">
              <SideNav />
            </div>
            <div className="user-content-main">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserLayout;
