import React from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { KycContext } from "../../userRoutes";

const KycVerification = () => {
  const kycStatus = useContext(KycContext);

  return (
    <>
      <div className="user-content">
        <div className="user-panel">
          <h2 className="user-panel-title">Identity Verification - KYC</h2>
          <p>
            Know Your Customer—or KYC—refers to the process that cryptocurrency
            exchanges must go through to confirm their end users’ and customers’
            personal information. To comply with regulation, every RecruitCoin
            ICO participant will have to go through identity verification or
            KYC. Please complete our fast and secure verification process to
            participate in our token sale. We respect your privacy. All
            documents and information uploaded are encrypted and only accessible
            to those involved in the verification process. You can proceed from
            here to verify your identity and check your application status if
            you have already submitted.{" "}
          </p>

          {/* <div className="gaps-2x"></div> */}
          <div className="status status-empty">
            <div className="status-icon">
              <em className="ti ti-files"></em>
              <div className="status-icon-sm">
                <em className="ti ti-close"></em>
              </div>
            </div>
            <span className="status-text">
              {kycStatus[0].toLowerCase() == "pending" &&
                "You have not submitted your KYC Application"}
              {kycStatus[0].toLowerCase() == "in progress" &&
                "You have submitted your KYC Application. Click to View or Update"}
            </span>
            <Link to={"/kyc"} className="btn btn-primary">
              CLick to proceed
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default KycVerification;
