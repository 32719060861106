import * as Yup from "yup";
export const BannerInitialValues = {
  mainhead: "",
  subhead: "",
  text: "",
};
export const CMSBannerFormFields = [
  {
    field: "Main Heading",
    fieldName: "mainhead",
    place: "Enter Main heading",
    type: "text",
  },
  {
    field: "Sub Heading",
    fieldName: "subhead",
    place: "Enter Sub Heading",
    type: "text",
  },
  {
    field: "Below Button Text",
    fieldName: "text",
    place: "Enter Below Button Text",
    type: "description",
  },
];
export const BannerSchema = Yup.object().shape({
  mainhead: Yup.string()
    .min(3, "Heading be at least 3 characters.")
    .required("Heading is required!"),
  subhead: Yup.string()
    .min(3, "Sub Heading be at least 3 characters.")
    .required("Sub Heading is required!"),
  text: Yup.string().required("Below Button Text is required!"),
});
export const getInitialValues = (CurrentUser, cmsId) => {
  return {
    id: cmsId ? cmsId : "",
    mainhead: CurrentUser.mainhead,
    subhead: CurrentUser.subhead,
    text: CurrentUser.text,
  };
};
