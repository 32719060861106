import React from "react";
import SiteSettings from "../../../components/SiteSettings";
const SiteSettingsPage = () => {
  return (
    <>
      <SiteSettings />
    </>
  );
};

export default SiteSettingsPage;
