import { lazy, Suspense, useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
// import { ClimbingBoxLoader } from "react-spinners";
import ResetPassword from "./UserDashboard/password/reset_password";
import ConfirmPassword from "./UserDashboard/password/confirm_password";
import SignUp from "./UserDashboard/SignUp/signUp";
import AlertToaster from "./components/Comps-Utils/Toast";
import "react-perfect-scrollbar/dist/css/styles.css";
import SignUpSuccess from "./UserDashboard/SignUp/SignupSuccess";
import successMessage from "./UserDashboard/successMessage/successMessage";
import EmailConfirmation from "./UserDashboard/EmailConfirmation/EmailConfirmation";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

const Login = lazy(() => import("./Pages/Login"));
// const Homepage = lazy(() => import("./UserDashboard/HomePage/HomePage"));

const AuthRoutes = (props) => {
  const { setTostify, tostify } = props;
  const location = useLocation();
  useEffect(() => {
    tostify.dismissAll();
    setTostify({ ...tostify, visible: false, key: "", type: "", message: "" });
    // eslint-disable-next-line
  }, [location.pathname]);
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };
  const pageTransition = {
    type: "tween",
    ease: "linear",
    duration: 0.3,
  };

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);
    return (
      <>
        <AnimatePresence>{show}</AnimatePresence>
      </>
    );
  };

  const TostifyWait = () => {
    const [showTostify, setShowTostify] = useState(false);
    useEffect(() => {
      let timeout = "";
      if (location.pathname === "/dashboard") {
        // eslint-disable-next-line no-use-before-define
        // eslint-disable-next-line
        let timeout = setTimeout(() => setShowTostify(true), 5000);
      } else {
        // eslint-disable-next-line no-use-before-define
        // eslint-disable-next-line
        let timeout = setTimeout(() => setShowTostify(true), 300);
      }
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        {showTostify && (
          <motion.div
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <AlertToaster setTostify={setTostify} {...tostify} />
          </motion.div>
        )}
      </>
    );
  };
  return (
    <>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <TostifyWait />
          <Switch>
            <Redirect exact from="/" to="/login" />
            <Route
              path={[
                // "/home",
                "/login",
                "/signup",
                "/reset-password",
                "/signup-success",
                "/email-confirmation",
                "/confirm-password/:id",
                "/confirm-email/:id",
              ]}
            >
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}
                  style={{ height: "100%" }}
                >
                  {/* <Route path="/home" component={Homepage} /> */}
                  <Route path="/login" component={Login} />
                  <Route path="/signup" component={SignUp} />
                  <Route path="/reset-password" component={ResetPassword} />
                  <Route path="/signup-success" component={SignUpSuccess} />
                  <Route path="/confirm-email/:id" component={successMessage} />
                  <Route
                    path="/email-confirmation"
                    component={EmailConfirmation}
                  />
                  <Route
                    path="/confirm-password/:id"
                    component={ConfirmPassword}
                  />
                </motion.div>
              </Switch>
            </Route>
            <Route render={() => <Redirect to="/login" />} />
          </Switch>
        </Suspense>
      </AnimatePresence>{" "}
    </>
  );
};
export default AuthRoutes;
