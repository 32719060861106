import { useState } from "react";
import Switch from "../Comps-Utils/Switch";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import TickCrossCheck from "../Comps-Utils/TickCrossCheckBox";
import { Permissions_S } from "../../helpers/constants";
import {
  TEXT_LEFT,
  ML_2,
  D_FLEX,
  TEXT_PRIMARY,
  TEXT_CAPITALIZE,
  THREAD_LIGHT__TEXT_CAPITALIZE__FONT_SIZE_SM__FONT_WEIGHT_BOLD__BORDER_TOP,
} from "../../helpers/CLASSES";
import {
  KYC_S,
  SMALL_S,
  EXPAND_ROW_S,
  TYPE,
  STATUS_S,
  PERMISSIONS,
  TYPE_S,
  TYPESTATUS_S,
  KYCSTATUS_S,
  VIEWKYC_S,
  EDITKYC_S,
  DELETEKYC_S,
  ADDKYC_S,
} from "../../helpers/constants";
import { KEYBOARD_ARROW_UP, KEYBOARD_ARROW_DOWN } from "../../helpers/ICONS";
const TableRows = (props) => {
  const { row, index, open, updateUserStatus, headings } = props;
  const [openRow, setOpenRow] = useState(false);
  return (
    <>
      <tr
        key={row.id + index + Math.random().toString(36).substring(7)}
        id={Permissions_S + "-tr-" + index}
      >
        <td className={TEXT_LEFT}>
          <div className={D_FLEX}>
            <span className={ML_2}>{row.type}</span>
            {row.type === KYC_S ? (
              <IconButton
                aria-label={EXPAND_ROW_S}
                size={SMALL_S}
                onClick={() => setOpenRow(!openRow)}
              >
                {open ? <KEYBOARD_ARROW_UP /> : <KEYBOARD_ARROW_DOWN />}
              </IconButton>
            ) : null}
          </div>
        </td>
        <>
          {headings.map((role) => {
            return (
              <>
                {role.label === TYPE ? null : (
                  <td className={TEXT_LEFT}>
                    <Switch
                      status={row.sideBar[role.label]}
                      id={row.type}
                      subtype={STATUS_S}
                      role={role.label}
                      service={updateUserStatus}
                    />
                  </td>
                )}
              </>
            );
          })}
        </>
      </tr>
      <TableRow key={row.id + index + Math.random().toString(36).substring(7)}>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: "#efebec",
          }}
          colSpan={headings.length}
        >
          <Collapse in={openRow} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                <span className={TEXT_CAPITALIZE + " " + TEXT_PRIMARY}>
                  {row.type}
                  {PERMISSIONS}
                </span>
              </Typography>
              <Table
                sx={{ margin: "20px" }}
                size="small"
                aria-label="purchases"
              >
                <thead
                  className={
                    THREAD_LIGHT__TEXT_CAPITALIZE__FONT_SIZE_SM__FONT_WEIGHT_BOLD__BORDER_TOP
                  }
                >
                  <tr
                    key={
                      row.id + index + Math.random().toString(36).substring(7)
                    }
                  >
                    <th key={TYPE_S + "-sub-table-th"}>{TYPE}</th>
                    {Object.keys(row.sideBar).map((keyName, i) => (
                      <th className={TEXT_LEFT} key={i}>
                        {keyName}
                      </th>
                    ))}
                  </tr>
                </thead>
                {row && row.userStatus && row.type === { KYC_S } ? (
                  <>
                    <tr
                      key={
                        row.id + index + Math.random().toString(36).substring(7)
                      }
                      id={TYPE_S + "-tr-" + index}
                    >
                      <td key={TYPE_S + "-Sub-table-td"}>User Status</td>
                      {Object.keys(row.userStatus).map((value, i) => {
                        return (
                          <>
                            <td className={TEXT_LEFT}>
                              <TickCrossCheck
                                check={row.userStatus[value]}
                                id={row.type}
                                subtype={TYPESTATUS_S}
                                role={value}
                                service={updateUserStatus}
                              />
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  </>
                ) : null}
                {row && row.kycStatus && row.type === { KYC_S } ? (
                  <>
                    <tr key={row.id + index} id={"transaction-tr-" + index}>
                      <td key={"Type-Sub-table"}>User Kyc</td>
                      {Object.keys(row.kycStatus).map((value, i) => {
                        return (
                          <>
                            <td className={TEXT_LEFT}>
                              <TickCrossCheck
                                check={row.kycStatus[value]}
                                id={row.type}
                                subtype={KYCSTATUS_S}
                                role={value}
                                service={updateUserStatus}
                              />
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  </>
                ) : null}
                {row && row.viewKyc && row.type === { KYC_S } ? (
                  <>
                    <tr key={row.id + index} id={"transaction-tr-" + index}>
                      <td key={"Type-Sub-table"}>View Kyc</td>
                      {Object.keys(row.viewKyc).map((value, i) => {
                        return (
                          <>
                            <td className={TEXT_LEFT}>
                              <TickCrossCheck
                                check={row.viewKyc[value]}
                                id={row.type}
                                subtype={VIEWKYC_S}
                                role={value}
                                service={updateUserStatus}
                              />
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  </>
                ) : null}
                {row && row.editKyc && row.type === { KYC_S } ? (
                  <>
                    <tr key={row.id + index} id={"transaction-tr-" + index}>
                      <td key={"Type-Sub-table"}>Edit Kyc</td>
                      {Object.keys(row.editKyc).map((value, i) => {
                        return (
                          <>
                            <td className={TEXT_LEFT}>
                              <TickCrossCheck
                                check={row.editKyc[value]}
                                id={row.type}
                                subtype={EDITKYC_S}
                                role={value}
                                service={updateUserStatus}
                              />
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  </>
                ) : null}
                {row && row.deleteKyc && row.type === { KYC_S } ? (
                  <>
                    <tr key={row.id + index} id={"transaction-tr-" + index}>
                      <td key={"Type-Sub-table"}>Delete Kyc</td>
                      {Object.keys(row.deleteKyc).map((value, i) => {
                        return (
                          <>
                            <td className={TEXT_LEFT}>
                              <TickCrossCheck
                                check={row.deleteKyc[value]}
                                id={row.type}
                                subtype={DELETEKYC_S}
                                role={value}
                                service={updateUserStatus}
                              />
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  </>
                ) : null}
                {row && row.addKyc && row.type === { KYC_S } ? (
                  <>
                    <tr key={row.id + index} id={"transaction-tr-" + index}>
                      <td key={"Type-Sub-table"}>Add Kyc</td>
                      {Object.keys(row.addKyc).map((value, i) => {
                        return (
                          <>
                            <td className={TEXT_LEFT}>
                              <TickCrossCheck
                                check={row.addKyc[value]}
                                id={row.type}
                                subtype={ADDKYC_S}
                                role={value}
                                service={updateUserStatus}
                              />
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  </>
                ) : null}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
export default TableRows;
