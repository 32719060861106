// import Tooltip from "@mui/material/Tooltip";
const TextFiledLabel = (props) => {
  const { field, widthclass } = props;
  return (
    <>
      <div className={widthclass}>
        <label>
          {field} <span className="text-danger">*</span>
        </label>
      </div>
    </>
  );
};
export default TextFiledLabel;
