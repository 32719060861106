import React from "react";
import soldout from "../../../src/assets/media/photos/soldout.png";

function InvestorBanner() {
  return (
    <div className="investor-banner row">
      <div className="col-md-7">
        <div className="investor-banner-text">
          <h2>Pre-Sale Ended</h2>
          <p>
            Thank you for your interest in RecruitCoin! The Pre-Sale stage was
            wildly successful and we reached our hard cap limit. Please visit
            the Betts website for the most up to date information about the
            Public Sale timeline.
          </p>
        </div>
      </div>
      <div className="col-md-5">
        <div className="investor-banner-img">
          <img
            src={soldout}
            // srcSet="../../../public/images/soldout.png"
            alt="icon"
            className=""
          />
        </div>
      </div>
    </div>
  );
}

export default InvestorBanner;
