import React from "react";
import CMS from "../../../components/CMS/ManageTeams/LeaderShipListing";
const index = () => {
  return (
    <>
      <CMS />
    </>
  );
};

export default index;
