import Card from "@mui/material/Card";
import { useState, useEffect, useContext } from "react";
import TokenForm from "../../Comps-Utils/GenericForm";
import Loader from "../../Comps-Utils/ClimbingLoader";
import { GetCMS } from "../../../Services/Admin/CMS/Banner";
import { UpdateCMSTokens } from "../../../Services/Admin/CMS/Tokens";
import { ToasterContext } from "../../../App";
import {
  TokensInitialValues,
  TokensSchema,
  CMSTokensFormFields,
  getInitialValues,
} from "./helpers";
const CMS = () => {
  const [general, setGeneral] = useState([]);
  const [id, setId] = useState();
  const [loading, setLoading] = useState(true);
  const [reset, setReset] = useState(false);
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const GetCMSDataService = () => {
    setLoading(true);
    GetCMS().then((res) => {
      if (res.status === 200) {
        setGeneral(res.data[0].tokens);
        setId(res.data[0]._id);
        setLoading(false);
        setReset(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const updateTokensService = async (data, actions) => {
    UpdateCMSTokens(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data.tokens);
        setReset(true);
      }
      actions.setSubmitting(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  useEffect(() => {
    GetCMSDataService();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Card className="mx-auto w95">
        {loading ? (
          <Loader />
        ) : (
          <TokenForm
            heading={"CMS COMMON - Tokens"}
            general={general}
            id={id}
            reset={reset}
            refresh={GetCMSDataService}
            getValues={getInitialValues}
            initialValues={TokensInitialValues}
            schema={TokensSchema}
            formFields={CMSTokensFormFields}
            update={updateTokensService}
            tostify={tostify}
          />
        )}
      </Card>
    </>
  );
};
export default CMS;
