import { useState } from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";

function ModalTransactions({ data, callbackFunc, transactionsObj }) {
  const [show] = useState(data);
  const totalTokens =
    parseFloat(transactionsObj.payabletoken) +
    parseFloat(transactionsObj.bonus);

  const handleClose = () => callbackFunc();

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="transaction-modal investor-modal"
      >
        <Modal.Header
          id="style-default"
          className="align-items-baseline modal-scroll-y modal-height ggg"
          closeButton
        >
          <div className="modal-scroll">
            <div className="tranx-popup">
              <h3>View Transaction Details</h3>
              {transactionsObj.txnno ? (
                <>
                  <p>
                    Transaction <strong>{transactionsObj.txnno}</strong> was
                    place on{" "}
                    <strong>
                      {moment(transactionsObj.date).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </strong>{" "}
                    and it’s <strong> Paid.</strong>
                  </p>
                  <p>
                    You can view your transaction details in your wallet{" "}
                    {/* <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://testnet.bscscan.com/tx/${transactionsObj.txnno}`}
                >
                  <strong className="text-primary">Here</strong>
                </a>{" "} */}
                  </p>
                </>
              ) : transactionsObj.paymentMethod === "credit" ? (
                ""
              ) : (
                <>
                  <p>
                    You have not submit payments for this transaction. Kindly
                    submit transaction details by clicking the below button.
                  </p>
                  <button
                    className="transction-btc-btn btn placeicon mb-sm-2 mb-0"
                    onClick={() =>
                      (window.location.href = `/paymentManual?&transid=${transactionsObj._id}`)
                    }
                  >
                    <>Enter Transaction Hash</>
                  </button>
                </>
              )}

              <div className="tranx-purchase-details">
                <h3 className="user-panel-title">Purchase Details</h3>
                <ul className="tranx-purchase-info">
                  <li>
                    <div className="tranx-purchase-head">Sale type</div>
                    <div className="tranx-purchase-des">
                      {transactionsObj.saletype}
                    </div>
                  </li>
                  <li>
                    <div className="tranx-purchase-head">Payment Method</div>
                    <div className="tranx-purchase-des">
                      {transactionsObj.network
                        ? transactionsObj.network
                        : transactionsObj.paymentMethod}
                    </div>
                  </li>
                  <li>
                    <div className="tranx-purchase-head">Your Contribution</div>
                    <div className="tranx-purchase-des">
                      {transactionsObj.actualamount
                        ? transactionsObj.actualamount
                        : transactionsObj.expectedamount}{" "}
                      {transactionsObj.network}
                    </div>
                  </li>
                  {/* <li>
                  <div className='tranx-purchase-head'>Token (T)</div>
                  <div className='tranx-purchase-des'>
                    <span>{transactionsObj.amount}</span>
                    <span>({transactionsObj.amount} * 1)</span>
                  </div>
                </li> */}
                  <li>
                    <div className="tranx-purchase-head">Bonus Tokens (B)</div>
                    <div className="tranx-purchase-des">
                      <span>{transactionsObj.bonus}</span>
                      <span>({transactionsObj.amount} * 1)</span>
                    </div>
                  </li>
                  <li>
                    <div className="tranx-purchase-head">Total Tokens</div>
                    <div className="tranx-purchase-des">
                      <span>{totalTokens}</span>
                      <span>(T+B)</span>
                    </div>
                  </li>
                </ul>
                <h4>
                  Payment Deposit From <em className="ti ti-arrow-right"></em>{" "}
                  <span>{transactionsObj.network} Address</span>
                </h4>
                <div className="tranx-payment-info">
                  <em className="fab "></em>
                  <input
                    type="text"
                    className="tranx-payment-address"
                    value={transactionsObj.from}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Header>
      </Modal>
    </>
  );
}

export default ModalTransactions;
