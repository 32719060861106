import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import AColor from "../assets/images/team/a-color.jpg";
import { Link } from "react-router-dom";
import useStyles from "../../../assets/CustomeMaterialStyleSheet";
import { serverUrl } from "../../../Config/config";
import { sanitize } from "dompurify";
const Body = (props) => {
  const { handleClose, selected } = props;
  const classes = useStyles();
  return (
    <>
      <div className="team-popup-main">
        <div
          key={selected.name + "team-modal"}
          className={`${classes.paperTeam} main-popup-card`}
        >
          <div
            key={selected.name + "team-modal-sub"}
            className="team-popup p-2 w-100"
          >
            <div className="d-flex justify-content-end">
              <Button
                id="modal-close"
                className="text-default"
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon />
              </Button>
            </div>
            <div className="row align-items-center text-center flex-column mb-lg-4 mb-3">
              <div className="col-md- mb-lg-3 mb-sm-2 mb-1">
                <div className="team-photo team-modal-img">
                  <img
                    src={
                      selected && selected.profileimg
                        ? serverUrl + "/" + selected.profileimg
                        : AColor
                    }
                    alt="team"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="team-popup-info ps-md-3 text-center">
                  <div className="mb-2">
                    <h3 className="team-name title title-lg mb-1 pt-0 theme-color">
                      {selected && selected.name ? selected.name : ""}
                    </h3>
                    <p className="team-position mb-1">
                      {" "}
                      {selected && selected.designation
                        ? selected.designation
                        : ""}
                    </p>
                  </div>
                  <div>
                    <ul className="team-social team-social-s2 mb-2 social-icon justify-content-center">
                      <li>
                        <Link to={""}>
                          <em className="fab fa-facebook-f"></em>
                        </Link>
                      </li>
                      <li>
                        <Link to={""}>
                          <em className="fab fa-linkedin-in"></em>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className="" data-delay=".2">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sanitize(
                            selected && selected.description
                              ? selected.description
                              : ""
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Body;
