import { useState } from "react";
import { ErrorMessage } from "formik";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { TextField } from "@material-ui/core";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
const PasswordField50 = (props) => {
  const { fieldName, handleChange, values, touched, place, errors } = props;
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };
  return (
    <>
      <div className="col-md-9">
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          id="first-name-input"
          name={fieldName}
          type={showPassword ? "text" : "password"}
          onChange={handleChange}
          placeholder={place}
          value={values[fieldName]}
          error={touched[fieldName] && errors[fieldName]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <ErrorMessage
          name={fieldName}
          component="div"
          className={"error-text"}
        />
      </div>
    </>
  );
};
export default PasswordField50;
