import { useState, useEffect, useContext } from "react";
import { Formik } from "formik";
import Form from "./form";
import { getInitialValues, ProfileSchema } from "./helper";
import {
  FetchAdminProfileImg,
  UpdatePersonalInfo,
} from "../../Services/Admin/Admin";
import { ImageContext } from "../../App";
// import { serverUrl } from "../../Config/config";
import { ToasterContext } from "../../App";
const FormikForm = (props) => {
  const { adminInfo } = props;
  const [profileImage, setProfileImage] = useContext(ImageContext);
  const [upload, setUpload] = useState(adminInfo.profileImgUrl);
  const [img, setImg] = useState("");
  const [tostify, setTostify] = useContext(ToasterContext);
  //
  useEffect(() => {
    if (upload && upload.preview) {
      setImg(upload.preview);
    } else {
      setImg(profileImage);
    }
    // eslint-disable-next-line
  }, [upload]);
  const SubmitForm = (data, actions) => {
    tostify.dismissAll();
    UpdatePersonalInfo(data).then((res) => {
      if (res.status === 200) {
        localStorage.setItem("user", JSON.stringify(res.data));
        localStorage.setItem(
          "role",
          res?.data?.admin
            ? "admin"
            : res?.data?.superAdmin
            ? "superAdmin"
            : "superAdmin"
        );
        // setProfileImage(serverUrl + res?.data.profileImgUrl);
        fetchProfileImage();
      }
      actions.setSubmitting(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };

  const fetchProfileImage = async () => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const profile = await FetchAdminProfileImg(userInfo.profileImgUrl);
    if (
      profile.data !== undefined ||
      profile.data !== "" ||
      profile.data.data !== 400
    ) {
      // setProfileImage(process.env.REACT_APP_BASE_URL + profile.data);
      // setImg(process.env.REACT_APP_BASE_URL + profile.data);
      setProfileImage(profile.data);
    } else {
      setProfileImage("images/user-thumb-lg.png");
    }
  };

  return (
    <>
      <Formik
        initialValues={getInitialValues(adminInfo)}
        onSubmit={(values, actions, upload, errors) => {
          SubmitForm(values, actions, upload);
        }}
        validationSchema={ProfileSchema}
      >
        {(props) => {
          return (
            <>
              <Form
                tostify={tostify}
                profileImage={profileImage}
                {...props}
                upload={upload}
                setUpload={setUpload}
                img={img}
                setImg={setImg}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
};
export default FormikForm;
