import React from "react";
import { sanitize } from "dompurify";
import iconDownload from "../assets/images/download-icon.svg";
import iconWhitepaper from "../assets/images/whitepaper-icon.svg";
import iconOneSheet from "../assets/images/onesheet-icon.svg";
const Resources = (props) => {
  const { documents } = props;
  return (
    <>
      <section className="bg-light-alt section ov-h" id="resources">
        <div className="container">
          <div className="section-head text-center wide-auto-sm">
            <h4 className="title title-xl-s2 title-semibold text-default">
              {documents?.mainhead}
            </h4>
            <div className="" data-delay=".2">
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(documents?.description),
                }}
              />
            </div>
          </div>
          <div className="crypto-whitepaper">
            <div className="crypto-center">
              <div className="d-flex align-items-center">
                <img src={iconWhitepaper} alt="White Paper"></img>
                <a
                  rel="noreferrer"
                  href={documents?.whitepaper}
                  target="_blank"
                >
                  <p className="mx-3 fw-bold mb-0">White Paper [PDF]</p>
                </a>
                <img src={iconDownload} alt="PDF"></img>
              </div>
              <div className="d-flex align-items-center">
                <img src={iconOneSheet} alt="White Paper"></img>
                <a rel="noreferrer" href={documents?.onepage} target="_blank">
                  <p className="mx-3 fw-bold mb-0">One Sheet [PDF]</p>
                </a>
                <img src={iconDownload} alt="PDF"></img>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Resources;
