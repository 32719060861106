import * as Yup from "yup";
export const createRows = (rowData) => {
  const rows = rowData.map((i, index) => {
    return {
      id: i._id,
      title: i.title,
      profileImgUrl: i.logo,
      status: i.status,
    };
  });
  return rows;
};
export const rowsPerPage = 2;
export const LogosListingTableHeadings = [
  {
    id: "title",
    label: "Title",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "profileImgUrl",
    label: "Logo",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "status",
    label: "Status",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "actions",
    label: "Actions",
    sort: true,
    class: "bg-body-light text-center",
  },
];
export const logoInitialValues = {
  title: "",
  profileImgUrl: "",
  status: false,
};
export const LogoSchema = Yup.object().shape({
  title: Yup.string().required("Title is required!"),
  profileImgUrl: Yup.string().required("Logo is required!"),
});
export const LogoFormFields = [
  {
    field: "Logo",
    fieldName: "profileImgUrl",
    place: "Choose an Image",
    type: "image",
  },
  {
    field: "Title",
    fieldName: "title",
    place: "Enter Title",
    type: "text",
  },
  {
    field: "Status",
    fieldName: "status",
    place: "",
    type: "radio",
  },
];
export const setInitialValues = (updatedRow) => {
  const Values = {
    id: updatedRow.id ? updatedRow.id : "",
    title: updatedRow.title ? updatedRow.title : "",
    status: updatedRow.status ? updatedRow.status : "",
    profileImgUrl: updatedRow.profileImgUrl ? updatedRow.profileImgUrl : "",
  };
  return Values;
};
