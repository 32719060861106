/* eslint-disable */
import { useState, useEffect } from "react";
import Logo from "../assets/images/logo.png";
import Logo2 from "../assets/images/BettsDark.svg";
import "../assets/css/style-zinnia.css";
import { Link } from "react-router-dom";
import Banner from "../Banner";
const HeaderAuth = (props) => {
  // SIDEVBAR OPEN CLOSE
  const [sidebar, setSidebar] = useState(false);
  const [crossbtn, setCrossBtn] = useState(false);
  const showSidebar = () => {
    setSidebar(!sidebar);
    setCrossBtn(!crossbtn);
  };

  const { banner, icoInfo, siteInfo } = props;
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return (
    <>
      <header
        className={
          offset > 0
            ? "nk-header page-header is-transparent is-sticky is-shrink has-fixed"
            : "nk-header page-header is-transparent is-sticky is-shrink"
        }
        id="header"
      >
        <div className="header-main">
          <div className="header-container header-container-s1">
            <div className="header-wrap">
              <div className="header-logo logo ">
                <Link to={""} className="logo-link">
                  <img className="logo-dark" src={Logo} alt="logo" />
                  <img className="logo-light" src={Logo2} alt="logo" />
                </Link>
              </div>
              <div className="header-nav-toggle" onClick={showSidebar}>
                <span
                  className={
                    crossbtn ? "navbar-toggle active" : "navbar-toggle"
                  }
                >
                  <a
                    href="#"
                    className="navbar-toggle"
                    data-menu-toggle="header-menu"
                  >
                    <div className="toggle-line active-line">
                      <span></span>
                    </div>
                  </a>
                </span>
              </div>
              <div className="header-navbar header-navbar-s1">
                <nav
                  className="header-menu justify-content-between"
                  id="header-menu"
                >
                  <ul className="menu  remove-animation">
                    <li className="menu-item">
                      <a className="menu-link nav-link" href="#about">
                        About Betts
                      </a>
                    </li>
                    <li className="menu-item">
                      <a className="menu-link nav-link" href="#benefits">
                        Problems & Solutions
                      </a>
                    </li>
                    <li className="menu-item">
                      <a className="menu-link nav-link" href="#why">
                        Referral System
                      </a>
                    </li>
                    <li className="menu-item">
                      <a className="menu-link nav-link" href="#services">
                        Timeline
                      </a>
                    </li>
                    <li className="menu-item">
                      <a className="menu-link nav-link" href="#tokensale">
                        Using Tokens
                      </a>
                    </li>

                    <li className="menu-item">
                      <a className="menu-link nav-link" href="#roadmap">
                        Resources
                      </a>
                    </li>
                    <li className="menu-item">
                      <a className="menu-link nav-link" href="#team">
                        Roadmap
                      </a>
                    </li>
                    <li className="menu-item">
                      <a className="menu-link nav-link" href="#faqs">
                        Team
                      </a>
                    </li>
                    {/* <li className="menu-item has-sub">
                      <div className="menu-link nav-link menu-toggle">More</div>
                      <ul className="menu-sub menu-drop">
                         <li className="menu-item">
                          <a className="menu-link nav-link" href="#documents">
                            Documents
                          </a>
                        </li> 
                         <li className="menu-item">
                          <a className="menu-link nav-link" href="#services">
                            Services
                          </a>
                        </li> 
                        <li className="menu-item">
                          <a className="menu-link nav-link" href="#partners">
                            Partners
                          </a>
                        </li>
                        <li className="menu-item">
                          <a className="menu-link nav-link" href="#contact">
                            Contact
                          </a>
                        </li>
                      </ul>
                    </li> */}
                  </ul>
                  <ul
                    className="menu-btns align-items-center  remove-animation"
                    data-delay=".85"
                  >
                    {sessionStorage.getItem("token") ? (
                      <li>
                        <Link
                          to={"/dashboard"}
                          className="btn btn-md btn-round btn-outline btn-auto scroll-orange"
                        >
                          <span>Dashboard</span>
                        </Link>
                      </li>
                    ) : (
                      <>
                        <li>
                          <Link
                            to={"/login"}
                            className="btn btn-md btn-round white-btn btn-auto"
                          >
                            <span>Login</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/signup"}
                            className="btn btn-md btn-round btn-outline btn-auto scroll-orange"
                          >
                            <span>Register</span>
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </nav>
              </div>
              <nav
                className={sidebar ? "zinnia-sidebar active" : "zinnia-sidebar"}
              >
                {/* <div className="header-nav-toggle" onClick={showSidebar}>
                <a
                  href="#"
                  className="navbar-toggle"
                  data-menu-toggle="header-menu"
                >
                  <div className="toggle-line">
                    <span></span>
                  </div>
                </a>
              </div> */}
                <ul className="menu">
                  <li className="menu-item">
                    <a className="menu-link nav-link" href="#about">
                      About Betts
                    </a>
                  </li>
                  <li className="menu-item">
                    <a className="menu-link nav-link" href="#why">
                      Problems & Solutions
                    </a>
                  </li>
                  <li className="menu-item">
                    <a className="menu-link nav-link" href="#benifits">
                      Referral System
                    </a>
                  </li>
                  <li className="menu-item">
                    <a className="menu-link nav-link" href="#services">
                      Timeline
                    </a>
                  </li>
                  <li className="menu-item">
                    <a className="menu-link nav-link" href="#tokensale">
                      Using Tokens
                    </a>
                  </li>
                  <li className="menu-item">
                    <a className="menu-link nav-link" href="#roadmap">
                      Resources
                    </a>
                  </li>
                  <li className="menu-item">
                    <a className="menu-link nav-link" href="#team">
                      Roadmap
                    </a>
                  </li>
                  <li className="menu-item">
                    <a className="menu-link nav-link" href="#faqs">
                      Team
                    </a>
                  </li>
                  {/* <li className="menu-item has-sub more-items">
                    <div className="menu-link nav-link menu-toggle">More</div>
                    <ul className="menu-sub menu-drop">
                      <li className="menu-item">
                        <a className="menu-link nav-link" href="#documents">
                          Documents
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="menu-link nav-link" href="#services">
                          Services
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="menu-link nav-link" href="#partners">
                          Partners
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="menu-link nav-link" href="#contact">
                          Contact
                        </a>
                      </li>
                    </ul>
                  </li> */}
                  {/* <li className="menu-item has-sub more-items">
                      <div className="menu-link nav-link menu-toggle text-lg-white">
                        More
                      </div>
                      <ul className="menu-sub menu-drop">
                        <li className="menu-item">
                          <a className="menu-link nav-link" href="#documents">
                            Documents
                          </a>
                        </li>
                        <li className="menu-item">
                          <a className="menu-link nav-link" href="#services">
                            Services
                          </a>
                        </li>
                        <li className="menu-item">
                          <a className="menu-link nav-link" href="#partners">
                            Partners
                          </a>
                        </li>
                        <li className="menu-item">
                          <a className="menu-link nav-link" href="#contact">
                            Contact
                          </a>
                        </li>
                      </ul>
                    </li> */}
                </ul>
                <ul
                  className="menu-btns align-items-center justify-content-center remove-animation"
                  data-delay=".85"
                >
                  {sessionStorage.getItem("token") ? (
                    <li>
                      <Link
                        to={"/dashboard"}
                        className="btn-md btn-round btn-auto sidebar-inner-btn"
                      >
                        <span>Dashboard</span>
                      </Link>
                    </li>
                  ) : (
                    <>
                      <li>
                        <Link
                          to={"/login"}
                          className="btn-md btn-round btn-auto sidebar-inner-btn"
                        >
                          <span>Login</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/signup"}
                          className="btn-md btn-round btn-auto sidebar-inner-btn"
                        >
                          <span>Register</span>
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Banner siteInfo={siteInfo} banner={banner} icoInfo={icoInfo} />
      </header>
    </>
  );
};
export default HeaderAuth;
