import Banner from "./Banner";
import PreSale from "./PreSale";
const About = (props) => {
  const { banner, icoInfo, siteInfo } = props;

  return (
    <div className="header-banner bg-theme-grad-alt" id="banner">
      <Banner siteInfo={siteInfo} banner={banner} />
      <PreSale icoInfo={icoInfo} />
      <div id="particles-bg" className="particles-container particles-bg"></div>
    </div>
  );
};
export default About;
