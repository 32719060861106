// import { toast } from "react-toastify";

export const TokenErr = (res) => {
  if (res.code === 403 || res.code === 401) {
    // toast.error(res.message);
    setTimeout(() => {
      window.location = "/login";
      sessionStorage.clear();
      localStorage.removeItem("token");
      localStorage.setItem("session_error", true);
    }, [1000]);

    return false;
  } else {
    return true;
  }
};
