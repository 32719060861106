import { useState, useContext, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FetchAdminProfileImg } from "../../../Services/Admin/Admin";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { NavLink } from "react-router-dom";
import { ImageContext } from "../../../App";
const UserDropDownHeader = (props) => {
  const [profileImage, setProfileImage] = useContext(ImageContext);
  // eslint-disable-next-line
  const [img, setImg] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const Logout = async () => {
    await localStorage.clear();
    await sessionStorage.clear();
    window.location = "/login";
  };
  const fetchProfileImage = async () => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const profile = await FetchAdminProfileImg(userInfo.profileImgUrl);
    if (
      profile.data !== undefined ||
      profile.data !== "" ||
      profile.data.data !== 400
    ) {
      // setProfileImage(process.env.REACT_APP_BASE_URL + profile.data);
      // setImg(process.env.REACT_APP_BASE_URL + profile.data);
      setProfileImage(profile.data);
      setImg(profile.data);
    } else {
      setProfileImage("images/user-thumb-lg.png");
    }
  };
  useEffect(() => {
    fetchProfileImage();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div>
        <div className="d-flex">
          <div className="dropdown d-inline-block mr-3">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Tooltip title="Account settings">
                <IconButton
                  className="avatar ml-0"
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={anchorOpen ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={anchorOpen ? "true" : undefined}
                >
                  <Avatar
                    src={
                      profileImage === ""
                        ? "images/user-thumb-lg.png"
                        : profileImage
                    }
                    sx={{ width: 32, height: 32 }}
                  ></Avatar>
                </IconButton>
              </Tooltip>
            </Box>
          </div>
        </div>
        <Menu
          className="dashboard-menu pb-0 menu-ul"
          id="basic-menu"
          anchorEl={anchorEl}
          open={anchorOpen}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <div className="dropdown-flex-col">
            <div className="mt-lg-n2 mb-4 w100 bg-dark rounded-top font-w600 text-white text-center p-3">
              User Options
            </div>
            <MenuItem
              className="mb-3"
              component={NavLink}
              onClick={handleClose}
              to="/profile"
              sx={{
                bgcolor: "background.paper",
                p: 2,
                minWidth: 200,
              }}
            >
              <i className="far fa-fw fa-user mr-1"></i>
              <span>Profile</span>
            </MenuItem>
            <MenuItem
              className="mb-3"
              component={NavLink}
              onClick={handleClose}
              to="/sitesettings"
              sx={{
                bgcolor: "background.paper",
                p: 2,
                minWidth: 200,
              }}
            >
              <i className="far fa-fw fa-building mr-1"></i>
              <span>Site Settings</span>
            </MenuItem>
            <MenuItem
              className="mb-3"
              onClick={Logout}
              sx={{
                bgcolor: "background.paper",
                p: 2,
                minWidth: 200,
              }}
            >
              <i className="far fa-fw fa-arrow-alt-circle-left mr-1"></i>
              <span>Sign Out</span>
            </MenuItem>
          </div>
        </Menu>
      </div>
    </>
  );
};
export default UserDropDownHeader;
