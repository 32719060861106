import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";
import { sanitize } from "dompurify";
import ReactPlayer from "react-player";
const Banner = (props) => {
  const { banner, siteInfo } = props;
  // const { banner } = props;
  const videoEl = useRef(null);
  const attemptPlay = () => {
    videoEl && videoEl.current && videoEl.current.play().catch((error) => {});
  };
  useEffect(() => {
    attemptPlay();
  }, []);
  return (
    <>
      <div className="header-banner bg-theme-grad-alt" id="banner">
        <div className="nk-banner has-ovm has-mask">
          <div className="banner banner-fs banner-single banner-with-token-box-s1">
            {/* <div cl/assName="ui-shape ui-shape-header ui-shape-light"></div> */}
            <div className="banner-wrap position-relative">
              <div className="ui-shape ui-shape-header ui-shape-light"></div>
              <div className="container">
                <div className="row align-items-center justify-content-center">
                  <div className="col-sm-12 col-lg-7  mb-lg-0 mb-4">
                    <div className="cpn-title cpn-mobile" data-delay="1.25">
                      <h1 className="title title-xl-s2 title-semibold text-white mb-xl-5 mb-4 text-center">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitize(banner.mainhead),
                          }}
                        />
                      </h1>
                    </div>
                    <div className="tc-light bg-zinnia  theme-shadow">
                      <ReactPlayer
                        // style={{ margin: "0 auto" }}
                        url={
                          "https://bettsrecruiting-2.wistia.com/medias/jaoztgwjrh"
                        }
                        loop={true}
                        playing={false}
                        controls={true}
                        // light={
                        //   "https://fast.wistia.com/embed/medias/jaoztgwjrh/swatch"
                        // }
                        // thumbnail={
                        //   "https://fast.wistia.com/embed/medias/jaoztgwjrh/swatch"
                        // }
                        src={
                          "https://bettsrecruiting-2.wistia.com/medias/jaoztgwjrh"
                        }
                        playIcon={
                          <>
                            <div className="video-popup btn-play btn-play-s3">
                              <svg
                                className="btn-play-icon-s2"
                                viewBox="0 0 24 34"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M0.212,32.552 C0.427,32.851 0.769,33.010 1.117,33.010 C1.337,33.010 1.559,32.947 1.752,32.814 L23.521,17.879 C23.816,17.678 23.991,17.350 23.991,16.998 C23.991,16.646 23.816,16.319 23.521,16.115 L1.752,1.181 C1.415,0.950 0.972,0.922 0.606,1.107 C0.240,1.292 0.010,1.661 0.010,2.064 L0.010,22.480 C0.010,23.076 0.506,23.558 1.116,23.558 C1.727,23.558 2.222,23.076 2.222,22.480 L2.222,4.142 L20.963,16.998 L0.479,31.049 C-0.020,31.393 -0.140,32.066 0.212,32.552 Z"
                                />
                              </svg>
                            </div>
                          </>
                        }
                        config={{
                          youtube: {
                            playerVars: { showinfo: 0 },
                          },
                        }}
                      />
                    </div>
                    <div
                      className="banner-social-wrap text-center banner-social-mobile mt-5"
                      data-animate="fadeIn"
                      data-delay="1.55"
                    >
                      <ul className="banner-social banner-social-vertical">
                        <li>
                          <a
                            rel="noreferrer"
                            href={siteInfo.facebook}
                            target="_blank"
                            id="twitter-link"
                            className="text-white"
                          >
                            <em className="fab fa-facebook-f"></em>
                          </a>
                        </li>
                        <li>
                          <a
                            rel="noreferrer"
                            href={siteInfo.twitter}
                            target="_blank"
                            id="twitter-link"
                            className="text-white"
                          >
                            <em className="fab fa-twitter"></em>
                          </a>
                        </li>
                        <li>
                          <a
                            rel="noreferrer"
                            href={siteInfo.youtube}
                            target="_blank"
                            id="twitter-link"
                            className="text-white"
                          >
                            <em className="fab fa-linkedin"></em>
                          </a>
                        </li>
                        <li>
                          <a
                            rel="noreferrer"
                            href={siteInfo.youtube}
                            target="_blank"
                            id="twitter-link"
                            className="text-white"
                          >
                            <em className="fab fa-instagram"></em>
                          </a>
                        </li>
                        {/* <li>
                    <a
                      rel="noreferrer"
                      href={siteInfo.youtube}
                      target="_blank"
                      id="twitter-link"
                      className="text-white"
                    >
                      <em className="fab fa-youtube"></em>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      href={siteInfo.github}
                      target="_blank"
                      id="twitter-link"
                      className="text-white"
                    >
                      <em className="fab fa-github"></em>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      href={siteInfo.bitcoin}
                      target="_blank"
                      id="twitter-link"
                      className="text-white"
                    >
                      <em className="fab fa-bitcoin"></em>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      href={siteInfo.medium}
                      target="_blank"
                      id="twitter-link"
                      className="text-white"
                    >
                      <em className="fab fa-medium-m"></em>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      href={siteInfo.discord}
                      target="_blank"
                      id="twitter-link"
                      className="text-white"
                    >
                      <em className="fab fa-discord"></em>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      href={siteInfo.telegram}
                      target="_blank"
                      id="twitter-link"
                      className="text-white"
                    >
                      <em className="fab fa-telegram"></em>
                    </a>
                  </li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-5">
                    <div className="banner-caption tc-light text-center">
                      <div
                        className="cpn-title mb-4 cpn-desktop"
                        data-delay="1.25"
                      >
                        <h1 className="title title-xl-s2 title-semibold">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: sanitize(banner.mainhead),
                            }}
                          />
                        </h1>
                      </div>
                      <div className="cpn-text cpn-text-center mb-3 mt-2">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitize(banner.subhead),
                          }}
                        />
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sanitize(banner.text),
                        }}
                      />
                    </div>
                    <div className="cpn-btns text-center mt-3">
                      <ul
                        className="btn-grp-zinnia justify-content-center "
                        data-delay="1.45"
                      >
                        <li>
                          <Link
                            to={"/signup"}
                            className="zinnia-btn btn-md btn-round btn-with-icon btn-light"
                          >
                            <span>Join Pre-Sale</span>
                            <em className="icon fas fa-angle-double-right"></em>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="gap-2x"></div>
                  </div>
                </div>
              </div>
              <div
                className="banner-social-wrap text-center banner-social-desktop"
                data-animate="fadeIn"
                data-delay="1.55"
              >
                <ul className="banner-social banner-social-vertical">
                  <li>
                    <a
                      rel="noreferrer"
                      href={siteInfo.facebook}
                      target="_blank"
                      id="twitter-link"
                      className="text-white"
                    >
                      <em className="fab fa-facebook-f"></em>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      href={siteInfo.twitter}
                      target="_blank"
                      id="twitter-link"
                      className="text-white"
                    >
                      <em className="fab fa-twitter"></em>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      href={siteInfo.youtube}
                      target="_blank"
                      id="twitter-link"
                      className="text-white"
                    >
                      <em className="fab fa-linkedin"></em>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      href={siteInfo.youtube}
                      target="_blank"
                      id="twitter-link"
                      className="text-white"
                    >
                      <em className="fab fa-instagram"></em>
                    </a>
                  </li>
                  {/* <li>
                    <a
                      rel="noreferrer"
                      href={siteInfo.youtube}
                      target="_blank"
                      id="twitter-link"
                      className="text-white"
                    >
                      <em className="fab fa-youtube"></em>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      href={siteInfo.github}
                      target="_blank"
                      id="twitter-link"
                      className="text-white"
                    >
                      <em className="fab fa-github"></em>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      href={siteInfo.bitcoin}
                      target="_blank"
                      id="twitter-link"
                      className="text-white"
                    >
                      <em className="fab fa-bitcoin"></em>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      href={siteInfo.medium}
                      target="_blank"
                      id="twitter-link"
                      className="text-white"
                    >
                      <em className="fab fa-medium-m"></em>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      href={siteInfo.discord}
                      target="_blank"
                      id="twitter-link"
                      className="text-white"
                    >
                      <em className="fab fa-discord"></em>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      href={siteInfo.telegram}
                      target="_blank"
                      id="twitter-link"
                      className="text-white"
                    >
                      <em className="fab fa-telegram"></em>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="nk-ovm mask-b mask-contain-bottom"></div>
        </div>
        <div
          id="particles-bg"
          className="particles-container particles-bg"
        ></div>
      </div>
    </>
  );
};
export default Banner;
