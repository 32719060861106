import * as Yup from "yup";
import moment from "moment";
export const setAdminInitialValues = (roles) => {
  const values = {
    id: "",
    address1: "",
    address2: "",
    city: "",
    dob: "",
    email: "",
    firstname: "",
    lastname: "",
    nationality: { code: "PK", label: "Pakistan", phone: "92" },
    phone: "",
    zip: "",
    telegram: "",
    roles: roles,
    permissions: "Manager",
    walletAddress: "12345",
    walletNetwork: "123521",
    upload: "",
    upload1: "",
    type: "",
  };
  return values;
};
export const setInitialValues = (updatedRow, roles) => {
  const Values = {
    id: updatedRow.id ? updatedRow.id : "",
    firstname: updatedRow.id ? updatedRow.firstname : "",
    lastname: updatedRow.id ? updatedRow.lastname : "",
    email: updatedRow.id ? updatedRow.email : "",
    phone: updatedRow.id ? updatedRow.phone : "",
    dob: updatedRow.id ? moment(updatedRow.dob).format("yyyy-MM-DD") : "",
    nationality: updatedRow.id
      ? updatedRow.nationality
      : { code: "PK", label: "Pakistan", phone: "92" },
    address1: updatedRow.id ? updatedRow.address1 : "",
    address2: updatedRow.id ? updatedRow.address2 : "",
    city: updatedRow.id ? updatedRow.city : "",
    zip: updatedRow.zip ? updatedRow.zip : "",
    telegram: updatedRow.telegram ? updatedRow.telegram : "",
    permissions: updatedRow.permissions ? updatedRow.permissions : "",
    roles: roles,
    // walletAddress: updatedRow.id ? updatedRow.walletAddress : '',
    // walletNetwork: updatedRow.id ? updatedRow.walletNetwork : '',
    walletAddress: "12345",
    walletNetwork: "BSC",
    upload: "",
    upload1: "",
    type: "",
  };
  return Values;
};
export const uploadInitialValue = {
  upload: "",
};
export const AdminsSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, "Name must be at least 3 characters.")
    .required("First Name is required!"),
  lastname: Yup.string()
    .min(3, "Last Name  be at least 3 characters.")
    .required("Last Name is required!"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  phone: Yup.string().required("Phone is required!"),
  dob: Yup.string()
    .required("Date of Birth is required!")
    .test(
      "dob",
      "You must be 18 years or older to sign up",
      (date) => moment().diff(moment(date), "years") >= 18
    ),
  nationality: Yup.object()
    .required("Please Select Your Nationality!")
    .nullable(),
  address1: Yup.string()
    .min(5, "Address must be at least 5 characters.")
    .required("Address is required!"),
  city: Yup.string()
    .min(3, "City must be at least 3 characters.")
    .required("City is required!"),
  zip: Yup.string().required("Zip Code is required!"),
});
export const AdminsFormFields = [
  {
    field: "First Name",
    fieldName: "firstname",
    place: "Enter First Name",
    type: "text",
    field1: "Last Name",
    fieldName1: "lastname",
    place1: "Enter Last Name",
    type1: "text",
  },
  {
    field: "Email Address",
    fieldName: "email",
    place: "Enter Email Address",
    type: "email",
    field1: "Nationality",
    fieldName1: "nationality",
    place1: "Select Nationality",
    type1: "text",
  },
  {
    field: "Date of Birth",
    fieldName: "dob",
    place: "Enter Date of Birth",
    type: "date",
    field1: "Phone",
    fieldName1: "phone",
    place1: "Enter Phone Number",
    type1: "text",
  },
  {
    field: "Telegram Username",
    fieldName: "telegram",
    place: "Enter Telegram User Name",
    type: "text",
    field1: "Role",
    fieldName1: "permissions",
    place1: "Select Permissions",
    type1: "text",
  },
  {
    field: "Address Line 1",
    fieldName: "address1",
    place: "Enter Address",
    type: "text",
    field1: "Address Line 2",
    fieldName1: "address2",
    place1: "Enter Address",
    type1: "text",
  },
  {
    field: "City of Residence",
    fieldName: "city",
    place: "Enter City",
    type: "text",
    field1: "Zip Code",
    fieldName1: "zip",
    place1: "Enter Zip Code",
    type1: "text",
  },
];
export const walletNetworkValue = ["Ethereum", "BSC", "Polygon"];
export const createRows = (rowData) => {
  const rows = rowData.map((i, index) => {
    //
    return {
      userId: index + 1,
      id: i._id,
      address1: i.address1,
      address2: i.address2,
      city: i.city,
      dob: moment(i.dob).format("MM DD YYYY"),
      date: i.date,
      email: i.email,
      emailStatus: i.emailStatus,
      name:
        (i.firstname + " " + i.lastname).trim() === ""
          ? "..."
          : i.firstname + " " + i.lastname,
      firstname: i.firstname,
      lastname: i.lastname,
      kycstatus:
        i.kycstatus === "" || i.kycstatus === null ? "pending" : i.kycstatus,
      license: i.license,
      nationalId: i.nationalId,
      nationality: i.nationality,
      permissions: i.permissions,
      passport: i.passport,
      phone: i.phone,
      lastLogin: i.createdAt,
      profileImgUrl: i.profileImgUrl,
      username: i.username,
      userstatus: i.userstatus,
      walletAddress: i.walletAddress,
      walletNetwork: i.walletNetwork,
      zip: i.zip,
      telegram: i.telegram,
      // dob: updatedRow.id ? updatedRow.dob : '',
      // nationality: updatedRow.id ? updatedRow.nationality : '',
      // address1: updatedRow.id ? updatedRow.address1 : '',
      // address2: updatedRow.id ? updatedRow.address2 : '',
      // city: updatedRow.id ? updatedRow.city : '',
      // zip: updatedRow.zip ? updatedRow.zip : '',
      // telegram: updatedRow.id ? updatedRow.telegram : '',
      // // walletAddress: updatedRow.id ? updatedRow.walletAddress : '',
      // // walletNetwork: updatedRow.id ? updatedRow.walletNetwork : '',
      // walletAddress:"12345",
      // walletNetwork:"123521"
    };
  });
  return rows;
};
export const rowsPerPage = 10;
export const AdminsTableHeadings = [
  {
    id: "id",
    label: "id",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "user",
    label: "User",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "permissions",
    label: "Role",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "lastLogin",
    label: "Last Login",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "User Status",
    label: "User Status",
    class: "bg-body-light",
  },
  {
    id: "actions",
    label: "Actions",
    class: "bg-body-light text-left",
  },
];
