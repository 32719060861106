const TableRow = (props) => {
  const { row, index } = props;
  return (
    <>
      <tr key={row.id + index} id={"user-profile-tr-" + index}>
        <td className="px-4 text-left text-capitalize">
          <span>{row.userId}</span>
        </td>
        <td className="px-4 text-left">
          <span>{row.name}</span>
        </td>
        <td className="text-left">
          <span>{row.email}</span>
        </td>
        <td className="text-left">
          <span>{row.address1}</span>
          <span>{row.address2}</span>
        </td>
        <td className="text-left">
          <span>{row.city}</span>
        </td>
        <td className="text-left">
          <span>{row.country}</span>
        </td>
      </tr>
    </>
  );
};
export default TableRow;
