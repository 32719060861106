import { useState, useEffect, useContext } from "react";
import DashboardCards from "./DashboardCards";
import DashboardTable from "../Comps-Utils/GenericTable";
import {
  createRows,
  dashboardTableHeadings,
  OnSearch,
  CardsHeadings,
  CountInvestors,
} from "./helpers";
import TransactionsGraph from "./DashboardGraph";
import { GetTransactions } from "../../Services/Admin/Dashboard";
import TableRows from "./tableRow";
import Alert from "../Alert";
import DashboardSkelton from "../Comps-Utils/Skeltons/AdminDashboardSkelton";
import { ToasterContext } from "../../App";
import { TRANSACTIONS, SUCCESS, ERROR } from "../../helpers/constants";
const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ visible: false });
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [transactions, setTransactions] = useState([]);
  const [investors, setInvestors] = useState();
  const GetTransactionsService = () => {
    setLoading(true);
    GetTransactions().then((res) => {
      if (res.status === 200) {
        setTransactions(res.data);
        setLoading(false);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? SUCCESS : ERROR,
          message: res.message,
        });
      }
    });
  };
  useEffect(() => {
    GetTransactionsService();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setInvestors(CountInvestors(transactions));
    // eslint-disable-next-line
  }, [transactions]);
  return (
    <>
      <Alert setAlert={setAlert} {...alert} />
      <div className="mx-auto w95">
        {loading ? (
          <DashboardSkelton
            key={"DashboardSkelton" + Math.random().toString(36).substring(7)}
            CardsHeadings={CardsHeadings}
          />
        ) : (
          <>
            <DashboardCards
              key={"dashboard-cards" + Math.random().toString(36).substring(7)}
              CardsHeadings={CardsHeadings}
              investors={investors}
            />
            <TransactionsGraph transactionData={transactions} />
            <div className="mt-3">
              <DashboardTable
                key={
                  Math.random().toString(36).substring(7) +
                  "dashboard-table" +
                  Math.random().toString(36).substring(7)
                }
                OnSearch={OnSearch}
                tableHeading={TRANSACTIONS}
                loading={loading}
                data={transactions}
                TableRow={TableRows}
                createRows={createRows}
                headings={dashboardTableHeadings}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Dashboard;
