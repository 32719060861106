// import { APIEthereumBaseURL } from "../apiConfig";
import { ParseError } from "../../../Config/utils";
import axios from "axios";

export const VerifyPolygonTransaction = async (txn) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  let response = await axios
    .get(
      `${process.env.REACT_APP_POLYGON_API_URL}&action=eth_getTransactionByHash&txhash=${txn}&apikey=${process.env.REACT_APP_POLYGON_API_KEY}`
    )

    .then((response) => {
      if (response) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          code: response,
          data: response,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(">>>>>>>>>>>>>", err);
      if (err.response.status === 429) {
        return {
          ...responseData,
          status: 400,
          code: response,
          data: response,
          message: "Our Services are currently busy try Again later",
        };
      } else if (err.response.status === 404) {
        return {
          ...responseData,
          status: 400,
          code: response,
          data: response,
          message: err.response.data,
        };
      } else {
        return {
          ...responseData,
          status: 400,
          code: response,
          data: response,
          message: err.response.data,
        };
      }
    });
  return response;
};
