import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
// import { ClipLoader } from "react-spinners";
import { SaleContext } from "../../App";
import { KycContext } from "../../userRoutes";
import KycDocuments from "./kyc-documents";
function KycSuccess() {
  const kycStatus = useContext(KycContext);
  const saleObj = useContext(SaleContext);
  const userInfo = JSON.parse(localStorage.getItem("user"));

  const [icoPhase, setIcoPhase] = useState({
    preSale: { start: "", end: "", bonus: 0 },
    publicSale: { start: "", end: "", bonus: 0 },
    p1: { start: "", end: "", bonus: "" },
    p2: {
      start: "",
      end: "",
      bonus: 0,
    },
    p3: {
      start: "",
      end: "",
      bonus: 0,
    },
    p4: {
      start: "",
      end: "",
      bonus: 0,
    },
  });

  useEffect(() => {
    let pre_sale = {
      start:
        saleObj[0].sDates != undefined
          ? moment.unix(saleObj[0].sDates.presalestart).format("MMMM Do YYYY")
          : "",
      end:
        saleObj[0].sDates != undefined
          ? moment.unix(saleObj[0].sDates.presaleend).format("MMMM Do YYYY")
          : "",
    };
    let public_sale = {
      start:
        saleObj[0].sDates != undefined
          ? moment
              .unix(saleObj[0].sDates.publicsalestart)
              .format("MMMM Do YYYY")
          : "",
      end:
        saleObj[0].sDates != undefined
          ? moment.unix(saleObj[0].sDates.publicsaleend).format("MMMM Do YYYY")
          : "",
    };
    let phase1 = {
      start: moment(saleObj[0].start).format("MMMM Do YYYY"),
      end: moment(saleObj[0].start).add(saleObj[0].remaintime, "seconds"),
      bonus: 15,
    };
    let phase2 = {
      start: moment(phase1.end).format("MMMM Do YYYY"),
      end: moment(phase1.end).add(saleObj[0].remaintime, "seconds"),
      bonus: 10,
    };
    let phase3 = {
      start: moment(phase2.end).format("MMMM Do YYYY"),
      end: moment(phase2.end).add(saleObj[0].remaintime, "seconds"),
      bonus: 7,
    };
    let phase4 = {
      start: moment(phase3.end).format("MMMM Do YYYY"),
      end: moment(saleObj[0].end),
      bonus: 5,
    };

    setIcoPhase({
      ...icoPhase,
      preSale: pre_sale,
      publicSale: public_sale,
      p1: phase1,
      p2: phase2,
      p3: phase3,
      p4: phase4,
    });
  }, [saleObj]);

  return (
    <>
      <div className="user-content">
        <div className="user-panel">
          <h2 className="user-panel-title">Identity Verification - KYC</h2>
          <p>
            Know Your Customer—or KYC—refers to the process that cryptocurrency
            exchanges must go through to confirm their end users’ and customers’
            personal information. To comply with regulation, every RecruitCoin
            ICO participant will have to go through identity verification or
            KYC. Please complete our fast and secure verification process to
            participate in our token sale. We respect your privacy. All
            documents and information uploaded are encrypted and only accessible
            to those involved in the verification process. You can proceed from
            here to verify your identity and check your application status if
            you have already submitted.{" "}
          </p>
          {/* <div className="gaps-2x"></div> */}
          <div className="status status-empty">
            <div className="status-icon">
              <em className="ti ti-files"></em>
              <div className="status-icon-sm">
                <em className="ti ti-check"></em>
              </div>
            </div>
            <span className="status-text">
              {kycStatus[0].toLowerCase() == "active" &&
                "Your Submitted KYC Application has been approved"}
              {kycStatus[0].toLowerCase() == "blocked" && (
                <span>
                  Your Submitted KYC Application has been blocked. A
                  representative from Betts will contact you directly to resolve
                  this issue. You can also use the chat feature on our{" "}
                  <a
                    href="https://bettsrecruiting.com/recruitcoin/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    public website
                  </a>{" "}
                  to get assistance during normal working hours.
                </span>
              )}{" "}
            </span>
          </div>
          {kycStatus[0].toLowerCase() == "active" && (
            <KycDocuments userId={userInfo._id} />
          )}
        </div>
      </div>
    </>
  );
}

export default KycSuccess;
