import Skeleton from "@material-ui/lab/Skeleton";
const PlusMinusSkeltonField = (props) => {
  const { mintFields } = props;
  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="w45">
          <span className="d-flex justify-content-center">
            {" "}
            <Skeleton height={66} width={200} />
          </span>
        </div>
        <div className="w45">
          <span className="d-flex justify-content-center">
            {" "}
            <Skeleton height={66} width={200} />
          </span>
        </div>
      </div>
      {mintFields.map((i) => {
        return (
          <div
            key={
              "plus-minus-skelton-form" +
              Math.random().toString(36).substring(7)
            }
            className="d-flex justify-content-evenly"
          >
            <Skeleton height={66} width={50} />
            <Skeleton height={66} width={400} />
            <Skeleton height={66} width={400} />
            <Skeleton height={66} width={50} />
          </div>
        );
      })}
    </>
  );
};
export default PlusMinusSkeltonField;
