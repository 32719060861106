import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
const TableRowCollapse = (props) => {
  const { row, index, open, setOpen } = props;
  return (
    <>
      <tr key={row.id + index} id={"transaction-tr-" + index}>
        <td className="text-left ">
          <div className="d-flex">
            <span className="mr-2">{row.type}</span>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </div>
        </td>
      </tr>
    </>
  );
};
export default TableRowCollapse;
