import {
  TX_AIRDROP,
  TX_VERIFIED,
  TX_NOT_SUBMIT,
  HASH_SUBMIT,
} from "../../helpers/constants";
import {
  TX_AIRDROP_BGCOLOR,
  TX_AIRDROP_COLOR,
  TX_NOT_SUBMIT_COLOR,
  TX_VERIFIED_BGCOLOR,
  TX_VERIFIED_COLOR,
  TX_NOT_SUBMIT_BGCOLOR,
  HASH_SUBMIT_BGCOLOR,
  HASH_SUBMIT_COLOR,
} from "../../helpers/CLASSES";
export const createRows = (rowData) => {
  const rows = rowData.map((i) => {
    return {
      userId: i.id,
      email: i.useremail && i.useremail === "" ? "..." : i.useremail,
      name: i.username === "" ? "..." : i.username,
      invested: i.expected,
      paymentMethod: i.paymentMethod,
      payableToken:
        i.actualamount === ""
          ? parseFloat(i.payabletoken).toFixed(9)
          : (
              (i.biddingtokenprice / i.claimtokenprice) * i.actualamount +
              parseFloat(i.bonus)
            ).toFixed(9),
      tokens: i.tokens,
      status: (i.status === "2"
        ? TX_NOT_SUBMIT
        : i.status === "3"
        ? HASH_SUBMIT
        : i.status === "4"
        ? TX_VERIFIED
        : i.status === "5"
        ? TX_AIRDROP
        : " "
      ).toLowerCase(),
      statusColor:
        i.status === "2"
          ? TX_NOT_SUBMIT_COLOR
          : i.status === "3"
          ? HASH_SUBMIT_COLOR
          : i.status === "4"
          ? TX_VERIFIED_COLOR
          : i.status === "5"
          ? TX_AIRDROP_COLOR
          : " ",
      statusBGColor:
        i.status === "2"
          ? TX_NOT_SUBMIT_BGCOLOR
          : i.status === "3"
          ? HASH_SUBMIT_BGCOLOR
          : i.status === "4"
          ? TX_VERIFIED_BGCOLOR
          : i.status === "5"
          ? TX_AIRDROP_BGCOLOR
          : " ",
      updatedAt: i.date,
      actualamount: i.actualamount === "" ? "0" : i.actualamount,
      expectedAmount: parseFloat(i.expectedamount).toFixed(9),
      expectedamount: i.expectedamount,
      saleType: i.saletype,
      recevingAddress:
        i.recevingaddress === ""
          ? "Not Given"
          : i.recevingaddress.substring(0, 5) +
            "...." +
            i.recevingaddress.substring(36, 40),
    };
  });
  return rows;
};
export const rowsPerPage = 10;
export const BitcoinTableHeadings = [
  // {
  //   id: "id",
  //   label: "#",
  //   sort: true,
  //   class: "bg-body-light",
  // },
  {
    id: "name",
    label: "User",
    sort: true,
    class: "bg-body-light",
  },

  {
    id: "received_amount",
    label: "Received amount",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "payableToken",
    label: "Payable Tokens",
    sort: true,
    class: "bg-body-light",
  },
  // {
  //   id: "Sale Type",
  //   label: "Sale Type",
  //   class: "bg-body-light",
  // },

  // {
  //   id: "Actual Amount",
  //   label: "Actual Amount",
  //   class: "bg-body-light",
  // },
  // {
  //   id: "Expected Amount",
  //   label: "Expected Amount",
  //   class: "bg-body-light",
  // },
  // {
  //   id: "Receving Address",
  //   label: "Receving Address",
  //   class: "bg-body-light",
  // },
  {
    id: "time",
    label: "Time",
    class: "bg-body-light",
  },
  {
    id: "status",
    label: "Status",
    class: "bg-body-light",
  },
];
