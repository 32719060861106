import React, { useEffect, useState, useContext } from "react";
import {
  FetchUserFaStatus,
  FetchUserQR,
  VerifyUserQR,
} from "../Services/User/User/user.js";
import Clip from "../components/Comps-Utils/ClipLoader.js";
import { ToasterContext } from "../App";
import Switch from "react-ios-switch";

function Enable2FA() {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [qrInfo, setQrInfo] = useState({
    image: "",
    secret: "",
    status: false,
  });
  const [code, setCode] = useState("");

  useEffect(() => {
    fetchQR();
  }, [checked]);

  const fetchQR = async () => {
    // const user = JSON.parse(localStorage.getItem('user'))

    const user = await FetchUserQR();
    setQrInfo(user.message);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = await VerifyUserQR({ code: code });
    if (data.data === 200) {
      setQrInfo({ ...qrInfo, status: true });
      setLoading(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: "success",
        message: "Successfully Verified",
      });
    } else {
      setLoading(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: "error",
        message: "Invalid Authentication code ",
      });
    }
  };

  const handleChangeStatus = async (e) => {
    // setChecked(e)
    setLoading(true);
    const res = await FetchUserFaStatus({ fa2status: e });
    if (res.code === 200) {
      setChecked(e);
      setLoading(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: "success",
        message: res.message,
      });
    } else {
      setLoading(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: "error",
        message: res.message,
      });
    }
  };

  return (
    <div>
      <h5 className="user-panel-subtitle">Two-Factor Security Option</h5>
      <p>
        Two-factor authentication is a method for protection your web account.
        When it is activated you need to enter not only your password, but also
        a special code. You can receive this code by in mobile app. Even if
        third person will find your password, then can not access with that
        code.
      </p>
      <div className="gaps-2x"></div>
      <div className="d-flex guttar-20px">
        <div>
          <span>Current Status:</span>
        </div>
        <div>
          {!qrInfo.status ? (
            <span className="bg-danger text-white p-2 bg-secondary">
              InActive
            </span>
          ) : (
            <span className="bg-warning text-white p-2">Active</span>
          )}
          {qrInfo.status ? (
            <Switch
              className="switch-btn fa2status-btn"
              // disabled={
              //   id === "dashboard" ||
              //   id === "profile" ||
              //   id === "password" ||
              //   (props && props.dis === false)
              // }
              checked={qrInfo.status}
              onChange={handleChangeStatus}
              inputProps={{ "aria-label": "controlled" }}
            />
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="gaps-2x"></div>
      <h5>
        1) Install an authentication app on your device. Any app that supports
        the Time-based One-Time Password (TOTP) protocol should work, including:
      </h5>
      <ul className="ath-content-sublist">
        <li>
          <span>Google Authenticator</span> (Android/iOS)
        </li>
      </ul>
      <h5>2) Use the authenticator app to scan the barcode below.</h5>
      <img className="ath-content-qrimg" src={qrInfo.image} alt="qr" />
      <div className="gaps-2x"></div>
      <h5>3) Enter the code generated by the authenticator app.</h5>
      <form>
        <div className="row">
          <div className="col-md-6">
            <div className="input-item input-with-label mb-lg-3 mb-2">
              <input
                className="input-bordered"
                type="text"
                id="security-code"
                name="code"
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <button className="btn btn-primary" onClick={handleSubmit}>
          {loading ? <Clip /> : <>Verify</>}
        </button>
      </form>
    </div>
  );
}

export default Enable2FA;
