import { useParams } from "react-router-dom";
import PasswordForm from "./password_form";

const Confirm_password = () => {
  const { id } = useParams();
  return (
    <>
      <div className="user-ath">
        <div className="user-ath-page">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8 text-center">
                <div className="user-ath-logo logo-width">
                  <span>
                    <img
                      src="../images/logo.png"
                      srcSet="images/logo-sm2x.png 2x"
                      alt="icon"
                    />
                  </span>
                </div>
                <div className="user-ath-box">
                  <h4>Confirm Your Password</h4>
                  <PasswordForm id={id} />
                </div>
                <div className="gaps-2x"></div>
                <div className="form-note">
                  {" "}
                  Not a member? <a href="signup.html">Sign up now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirm_password;
