import Skeleton from "@material-ui/lab/Skeleton";
import { Card } from "@material-ui/core";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  ETHEREUM_ICON_WHITE_35,
  BITCOIN_ICON_WHITE_35,
  PERSON_ICON_WHITE_35,
  DAI_ICON_WHITE_35,
  TUSD_ICON_WHITE_35,
  USDT_ICON_WHITE_35,
  BUSD_ICON_WHITE_35,
} from "../../../helpers/ICONS";
import {
  PERSON_S,
  BITCOIN_S,
  ETHEREUM_S,
  DAI_S,
  TUSD_S,
  USDT_S,
  BUSD_S,
} from "../../../helpers/constants";
import SkeltonTable from "./TableSkelton";
const DashboardSkelton = (props) => {
  const { CardsHeadings } = props;
  return (
    <>
      <div className="d-flex justify-content-start flex-wrap">
        {CardsHeadings.map((c, index) => {
          return (
            <Card
              key={
                "dashbaord-skelton-card" +
                index +
                Math.random().toString(36).substring(7)
              }
              className={"mb-3 mr-3 p-0 col-md-2 " + c.bg}
              sx={{ minWidth: "246px", width: "100%", maxHeight: 90 }}
            >
              <CardContent>
                <div className="d-flex justify-content-between">
                  <div>
                    <Typography color="#ffffff" gutterBottom>
                      <Skeleton height={20} width={60} />
                    </Typography>
                    <Typography color="text.secondary" gutterBottom>
                      <Skeleton height={20} width={120} />
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      {c.logo === PERSON_S ? (
                        <PERSON_ICON_WHITE_35 />
                      ) : c.logo === ETHEREUM_S ? (
                        <ETHEREUM_ICON_WHITE_35 />
                      ) : c.logo === BITCOIN_S ? (
                        <BITCOIN_ICON_WHITE_35 />
                      ) : c.logo === DAI_S ? (
                        <DAI_ICON_WHITE_35 />
                      ) : c.logo === TUSD_S ? (
                        <TUSD_ICON_WHITE_35 />
                      ) : c.logo === USDT_S ? (
                        <USDT_ICON_WHITE_35 />
                      ) : c.logo === BUSD_S ? (
                        <BUSD_ICON_WHITE_35 />
                      ) : null}
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </Card>
          );
        })}
      </div>
      <div className="p-0">
        <Card>
          <div className="d-flex m-3 justify-content-between">
            <div>
              <Typography color="text.secondary" gutterBottom>
                <Skeleton height={50} width={250} />
              </Typography>
            </div>
            <div className="d-flex justify-content-between">
              <div className="header-logo logo mr-1 circle ">
                <Skeleton
                  className="circle"
                  variant="circle"
                  width={25}
                  height={25}
                />
              </div>
              <div className="header-logo logo mr-1 circle">
                <Skeleton
                  className="circle"
                  variant="circle"
                  width={25}
                  height={25}
                />
              </div>
              <div className="header-logo logo circle">
                <Skeleton
                  className="circle"
                  variant="circle"
                  width={25}
                  height={25}
                />
              </div>
            </div>
          </div>
          <Skeleton className="mt-xl-n6 m-3" height={380} />
          <div className="d-flex justify-content-center mt-lg-n5 mb-2">
            <div className="mr-1 circle d-flex ">
              <Skeleton
                className="circle mr-1"
                variant="circle"
                width={25}
                height={25}
              />
              <Skeleton height={30} width={60} />
            </div>
            <div className=" mr-1 circle d-flex">
              <Skeleton
                className="circle mr-1"
                variant="circle"
                width={25}
                height={25}
              />
              <Skeleton height={30} width={60} />
            </div>
            <div className="mr-1 circle d-flex">
              <Skeleton
                className="circle mr-1"
                variant="circle"
                width={25}
                height={25}
              />
              <Skeleton height={30} width={60} />
            </div>
            <div className="mr-1 circle d-flex">
              <Skeleton
                className="circle mr-1"
                variant="circle"
                width={25}
                height={25}
              />
              <Skeleton height={30} width={60} />
            </div>
            <div className="mr-1 circle d-flex">
              <Skeleton
                className="circle mr-1"
                variant="circle"
                width={25}
                height={25}
              />
              <Skeleton height={30} width={60} />
            </div>
          </div>
        </Card>
      </div>
      <SkeltonTable header={[0, 1, 2, 3, 4]} rows={[0, 1, 2]} />
    </>
  );
};
export default DashboardSkelton;
