import { useState } from "react";
import { ConnectedFocusError } from "focus-formik-error";
import { TiTick } from "react-icons/ti";
import ClipLoader from "../ClipLoader";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// import FavoriteIcon from "@mui/icons-material/Favorite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCamera } from '@fortawesome/free-solid-svg-icons'
import {
  FaCalendarCheck,
  FaCheckCircle,
  FaMapMarker,
  FaMoneyCheck,
} from "react-icons/fa";
import FormFieldPlusMinus from "../PlusMinuInputForm";
import Form from "../FormikFormFieldsSingleLine";
import MintingSkeltonLoader from "../Skeltons/PlusMinusFieldSkelton";
import FieldInputSkelton from "../Skeltons/FieldInputSkelton";
import PaymentTokensSkelton from "../Skeltons/PLusMinusPaymentMethodsSkelton";
import WithdrawSkelton from "../Skeltons/withdrawIcoSkelton";
const PermissionsTabs = (props) => {
  const {
    isSubmitting,
    handleSubmit,
    formFields,
    resetForm,
    reset,
    refresh,
    isModal,
    setSelectedTab,
    mintFields,
    setMintFields,
    setValuesTemp,
    heading,
    tab,
    general,
    skeltonLoading,
  } = props;
  const [value, setValue] = useState(0);
  const handleChangeTab = (value, name) => {
    setValuesTemp({});
    resetForm();
    setValue(value);
    setSelectedTab(name);
    setMintFields([0]);
  };

  return (
    <>
      {isModal ? null : (
        <div
          id="add-user-header"
          className="d-flex align-items-center justify-content-between bg-zinnia-grad-alt"
        >
          <div>
            <h5 className="font-weight-bold font-size-lg mt-3 mx-3 my-2 text-white">
              ICO Settings
            </h5>
          </div>
        </div>
      )}
      <div>
        <div className="ico-tabs">
          <Box
            sx={{ minWidth: "30%", display: "flex", justifyContent: "center" }}
          >
            <Tabs
              className="scroll-btn"
              value={value}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <div className="col-lg-4 px-tab">
                <Tab
                  className={`tab-card ${
                    value == 0 ? "selected-icosetting" : ""
                  }`}
                  icon={
                    <span className="tab-icon">
                      <FontAwesomeIcon
                        icon={"fa-solid fa-table-columns"}
                        className="font-size-h3 text-white tab-svg text-white"
                      />
                    </span>
                  }
                  onClick={() => handleChangeTab(0, "Early Minting")}
                  label="Early Minting"
                />
              </div>
              <div className="col-lg-4 px-tab">
                <Tab
                  className={`tab-card ${
                    value == 2 ? "selected-icosetting" : ""
                  }`}
                  icon={
                    <span className="tab-icon">
                      <FontAwesomeIcon
                        icon={"fa-solid fa-table-columns"}
                        className="font-size-h3 text-white tab-svg text-white"
                      />
                    </span>
                  }
                  onClick={() => handleChangeTab(2, "Founder")}
                  label="Founder"
                />
              </div>
              <div className="col-lg-4 px-tab">
                <Tab
                  className={`tab-card ${
                    value == 1 ? "selected-icosetting" : ""
                  }`}
                  icon={
                    <span className="tab-icon">
                      <FontAwesomeIcon
                        icon={"fa-solid fa-address-card"}
                        className="font-size-h3 text-white tab-svg"
                      />
                    </span>
                  }
                  onClick={() => handleChangeTab(1, "Minting (Uncapped)")}
                  label="Minting (Uncapped)"
                />
              </div>
              <div className="col-lg-4">
                <Tab
                  className={`tab-card ${
                    value == 3 ? "selected-icosetting" : ""
                  }`}
                  icon={
                    <span className="tab-icon">
                      <FaCalendarCheck />
                    </span>
                  }
                  onClick={() => {
                    handleChangeTab(3, "Ico Dates");
                    handleChangeTab(3, "Ico Dates");
                  }}
                  label="Ico Dates"
                />
              </div>
              <div className="col-lg-4">
                <Tab
                  className={`tab-card ${
                    value == 4 ? "selected-icosetting" : ""
                  }`}
                  icon={
                    <span className="tab-icon">
                      <FaCheckCircle />
                    </span>
                  }
                  onClick={() => handleChangeTab(4, "Payment Tokens")}
                  label="Payment Tokens"
                />
              </div>
              <div className="col-lg-4">
                <Tab
                  className={`tab-card ${
                    value == 5 ? "selected-icosetting" : ""
                  }`}
                  icon={
                    <span className="tab-icon">
                      <FaMoneyCheck />
                    </span>
                  }
                  onClick={() => handleChangeTab(5, "Set Prices")}
                  label="Set Prices"
                />
              </div>
              <div className="col-lg-4 px-tab">
                <Tab
                  className={`tab-card ${
                    value == 6 ? "selected-icosetting" : ""
                  }`}
                  icon={
                    <span className="tab-icon">
                      {/* <FontAwesomeIcon
                    icon={"fa-close fa-table-columns"}
                    className="font-size-h3 text-white tab-svg text-white"
                  /> */}
                      <FaMapMarker />
                    </span>
                  }
                  onClick={() => handleChangeTab(6, "Withdraw Tokens")}
                  label="Withdraw Tokens"
                />
              </div>
              <div className="col-lg-4 px-tab">
                <Tab
                  className={`tab-card ${
                    value == 7 ? "selected-icosetting" : ""
                  }`}
                  icon={
                    <span className="tab-icon">
                      <FontAwesomeIcon
                        icon={"fa-brands fa-bitcoin"}
                        className="font-size-h3 text-white tab-svg"
                      />
                    </span>
                  }
                  onClick={() => handleChangeTab(7, "Bonus")}
                  label="Bonus"
                />
              </div>
            </Tabs>
          </Box>
        </div>
        <form autoComplete="off" className="ico-form">
          <ConnectedFocusError />
          <div className="common-cms-pg block-content block-content-full hhh mt-5">
            <div className="">
              <div className="row items-push">
                {value === 0 || value === 2 || value === 1 || value === 4 ? (
                  <>
                    {(skeltonLoading && tab === "Minting (Uncapped)") ||
                    (skeltonLoading && tab === "Early Minting") ||
                    (skeltonLoading && tab === "Founder") ? (
                      <MintingSkeltonLoader mintFields={mintFields} />
                    ) : skeltonLoading && tab === "Payment Tokens" ? (
                      <PaymentTokensSkelton mintFields={mintFields} />
                    ) : (
                      <FormFieldPlusMinus
                        {...props}
                        tab={tab}
                        isModal={isModal}
                        reset={reset}
                        refresh={refresh}
                        formFields={formFields}
                        mintFields={mintFields}
                        setMintFields={setMintFields}
                        setValuesTemp={setValuesTemp}
                      />
                    )}
                  </>
                ) : value === 3 || value === 5 || value === 6 || value === 7 ? (
                  <>
                    {(skeltonLoading && tab === "Bonus") ||
                    (skeltonLoading && tab === "Ico Dates") ? (
                      <FieldInputSkelton length={[0, 1, 2, 3]} />
                    ) : (skeltonLoading && tab === "Set Prices") ||
                      (skeltonLoading && tab === "Withdraw Tokens") ? (
                      <WithdrawSkelton length={[0, 1]} />
                    ) : (
                      <Form
                        general={general}
                        tab={tab}
                        formFields={formFields}
                        {...props}
                        heading={heading}
                        isModal={isModal}
                        reset={reset}
                        refresh={refresh}
                      />
                    )}
                  </>
                ) : null}
                <div className="d-flex justify-content-center col-md-12 mb-3">
                  {/* <button
                    onClick={useCallback(
                      (e) => {
                        e.preventDefault();
                        if (reset === true) {
                          refresh();
                        } else {
                          resetForm();
                        }
                      },
                      // eslint-disable-next-line
                      [reset]
                    )}
                    disabled={isSubmitting}
                    className="btn btn-md btn-grad btn-grad-theme btn-round mr-2"
                  >
                    <div className="d-flex justify-content-center">
                      <BiReset size={25} className="mr-1" /> <span>Reset</span>
                    </div>
                  </button> */}
                  <button
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    type="submit"
                    className="btn btn-md btn-grad btn-grad-theme btn-round"
                  >
                    {isSubmitting ? (
                      <div>
                        <ClipLoader color={"white"} />
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center align-items-center">
                        {value === 0 || value === 1 ? (
                          <>
                            <TiTick size={25} /> <span>Mint</span>
                          </>
                        ) : (
                          <>
                            {" "}
                            <TiTick size={25} /> <span>Save</span>{" "}
                          </>
                        )}
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default PermissionsTabs;
