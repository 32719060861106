import React, { useContext } from "react";
import { KycContext } from "../../userRoutes";

const KycFailPage = () => {
  const [kycStatus] = useContext(KycContext);
  return (
    <>
      <div className="user-content">
        <div className="user-panel">
          <h2 className="user-panel-title">Identity Verification - KYC</h2>
          <p>
            To comply with regulation each participant will have to go through
            identity verification (KYC). So please complete our fast and secure
            verification process to participate in our token sale. You can
            proceed from here to verify your identity and also you can check
            your application status if you submit already.{" "}
          </p>
          {/* <div className="gaps-2x"></div> */}
          <div className="status status-empty">
            <div className="status-icon">
              <em className="ti ti-files"></em>
              <div className="status-icon-sm">
                <em className="ti ti-close"></em>
              </div>
            </div>
            <span className="status-text">
              Your Kyc is {kycStatus} .Check your kyc status
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default KycFailPage;
