import * as Yup from "yup";
export const ServicesInitialValues = {
  mainHeading: "",
  description: "",
  heading1: "",
  description1: "",
  heading2: "",
  description2: "",
  heading3: "",
  description3: "",
  heading4: "",
  description4: "",
};
export const CMSServicesFormFields = [
  {
    field: "Main Heading",
    fieldName: "mainhead",
    place: "Enter Main heading",
    type: "text",
  },
  {
    field: "Description",
    fieldName: "description",
    place: "Enter Description",
    type: "description",
  },
];
export const CMSServicesFormHeadingFields = [
  {
    field: "Heading 1",
    fieldName: "heading1",
    place: "Enter Heading 1",
    type: "text",
  },
  {
    field: "Description",
    fieldName: "description1",
    place: "Enter Description",
    type: "description",
  },
  {
    field: "Heading 2",
    fieldName: "heading2",
    place: "Enter Heading 2",
    type: "text",
  },
  {
    field: "Description",
    fieldName: "description2",
    place: "Enter Description",
    type: "description",
  },
  {
    field: "Heading 3",
    fieldName: "heading3",
    place: "Enter Heading 3",
    type: "text",
  },
  {
    field: "Description",
    fieldName: "description3",
    place: "Enter Description",
    type: "description",
  },
  {
    field: "Heading 4",
    fieldName: "heading4",
    place: "Enter Heading 4",
    type: "text",
  },
  {
    field: "Description",
    fieldName: "description4",
    place: "Enter Description",
    type: "description",
  },
];
export const ServicesSchema = Yup.object().shape({
  mainhead: Yup.string().required("Heading is required!"),
  description: Yup.string()
    .min(3, "Sub Heading be at least 3 characters.")
    .required("Description is required!"),
  heading1: Yup.string().required("Heading 1 is required!"),
  description1: Yup.string().required("Description is required!"),
  heading2: Yup.string().required("Heading 2 is required!"),
  description2: Yup.string().required("Description is required!"),
  heading3: Yup.string().required("Heading 3 is required!"),
  description3: Yup.string().required("Description is required!"),
  heading4: Yup.string().required("Heading 4 is required!"),
  description4: Yup.string().required("Description is required!"),
});
export const getInitialValues = (CurrentUser, cmsId) => {
  return {
    id: cmsId ? cmsId : "",
    mainhead: CurrentUser.mainhead,
    description: CurrentUser.description,
    heading1: CurrentUser?.card1?.heading,
    description1: CurrentUser?.card1?.description,
    heading2: CurrentUser?.card2?.heading,
    description2: CurrentUser?.card2?.description,
    heading3: CurrentUser?.card3?.heading,
    description3: CurrentUser?.card3?.description,
    heading4: CurrentUser?.card4?.heading,
    description4: CurrentUser?.card3?.description,
  };
};
