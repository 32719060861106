import instance from "../../../Config/axios";
import { ParseError, getAuthHeader } from "../../../Config/utils";
const GetKycUsers = async () => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .get("/users/", headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const updateKycStatus = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .put("/users/updateKYCStatus", formData, headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
          code: response.code,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const updateUserStatus = async (id, status) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .put("/users/updateUserStatus", { id: id, status: status }, headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
          code: response.code,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const AddUser = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  const data = new FormData();
  const filesIndex = {
    passportIndex: -1,
    licenseIndex: -1,
    cnicFrontIndex: -1,
    cnicBackIndex: -1,
  };
  var uploadIndex = 0;

  if (formData.license) {
    data.append("files", formData.license);
    filesIndex.licenseIndex = uploadIndex;
    uploadIndex = uploadIndex + 1;
  }
  if (formData.passport) {
    data.append("files", formData.passport);
    filesIndex.passportIndex = uploadIndex;
    uploadIndex = uploadIndex + 1;
  }
  if (formData.cnicFront) {
    data.append("files", formData.cnicFront);
    filesIndex.cnicFrontIndex = uploadIndex;
    uploadIndex = uploadIndex + 1;
  }
  if (formData.cnicBack) {
    data.append("files", formData.cnicBack);
    filesIndex.cnicBackIndex = uploadIndex;
  }
  data.append("firstname", formData.firstname);
  data.append("lastname", formData.lastname);
  data.append("email", formData.email);
  data.append("phone", formData.phone);
  data.append("dob", formData.dob);
  data.append("nationality", JSON.stringify(formData.nationality));
  data.append("city", formData.city);
  data.append("zip", formData.zip);
  data.append("address1", formData.address1);
  data.append("address2", formData.address2);
  data.append("telegram", formData.telegram);
  data.append("walletAddress", formData.walletAddress);
  data.append("walletNetwork", formData.walletNetwork);
  data.append("type", formData.type);
  data.append("username", formData.firstname + formData.lastname);
  data.append("password", 12345678);
  data.append("filesIndex", JSON.stringify(filesIndex));

  return instance
    .post("/users/addKYC", data, headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const UpdateKycUser = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .put("/users/updateKYC", formData, headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const uploadKyc = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  const data = new FormData();
  const filesIndex = {
    passportIndex: -1,
    licenseIndex: -1,
    cnicFrontIndex: -1,
    cnicBackIndex: -1,
  };
  var uploadIndex = 0;

  if (formData.license) {
    data.append("files", formData.license);
    filesIndex.licenseIndex = uploadIndex;
    uploadIndex = uploadIndex + 1;
  }
  if (formData.passport) {
    data.append("files", formData.passport);
    filesIndex.passportIndex = uploadIndex;
    uploadIndex = uploadIndex + 1;
  }
  if (formData.cnicFront) {
    data.append("files", formData.cnicFront);
    filesIndex.cnicFrontIndex = uploadIndex;
    uploadIndex = uploadIndex + 1;
  }
  if (formData.cnicBack) {
    data.append("files", formData.cnicBack);
    filesIndex.cnicBackIndex = uploadIndex;
  }
  // if (formData.upload) {
  //   data.append("files", formData.upload);
  // }
  // if (formData.upload1) {
  //   data.append("files", formData.upload1);
  // }
  data.append("id", formData.id);
  data.append("type", formData.type);
  data.append("filesIndex", JSON.stringify(filesIndex));
  return instance
    .post("/users/updateKYCDocs", data, headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          code: response.code,
          data: response.data,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const deleteUser = async (id) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getAuthHeader();
  return instance
    .delete(`/users/delete/${id}`, headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const getKycDocs = async (id) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getAuthHeader();
  return instance
    .get(`/users/getKYCDocs/${id}`, headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const sendUserKycApprovedNotification = async (id) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getAuthHeader();
  return instance
    .post(`/users/sendkyc/approved/${id}`, headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export {
  GetKycUsers,
  updateKycStatus,
  deleteUser,
  AddUser,
  uploadKyc,
  UpdateKycUser,
  updateUserStatus,
  getKycDocs,
  sendUserKycApprovedNotification,
};
