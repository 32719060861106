import React, { useState } from "react";
import { Collapse } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import {
  AdminSideBarSubItems,
  AdminSideBarProfileSubMenu,
  AdminSideBarCMSSubMenu,
  AdminSideBarCMSSubMenuFaqs,
  AdminSideBarCMSSubMenuSlider,
  AdminSideBarCMSSubMenuManageTeam,
  AdminSideBarCMSSubMenuTimeline,
} from "../../../helpers/adminSideMenuOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SubMenuItems = ({ open, options }) => {
  return (
    <>
      {options.map((item, index) => {
        return (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Tooltip title={item.title} arrow>
              <li className="nav-main-item submenu-child">
                <NavLink
                  className="nav-main-link"
                  data-toggle="submenu"
                  aria-haspopup="true"
                  aria-expanded="false"
                  activeClassName="active"
                  to={item.url}
                >
                  <FontAwesomeIcon
                    icon={item.icon}
                    className="nav-main-link-icon si ml-3"
                  />
                </NavLink>
              </li>
            </Tooltip>
          </Collapse>
        );
      })}
    </>
  );
};

const CMSSubMenuItems = ({ openCMS }) => {
  const [openFaqs, setOpenFaqs] = React.useState(false);
  const [openSlider, setOpenSlider] = React.useState(false);
  const [openTeam, setOpenTeam] = React.useState(false);
  const [openTimeLine, setOpenTimeLine] = React.useState(false);
  return (
    <>
      {AdminSideBarCMSSubMenu.map((item, index) => {
        return (
          <Collapse in={openCMS} timeout="auto" unmountOnExit>
            <Tooltip title={item.title} arrow disableInteractive>
              <li
                className={
                  (item.title === "FAQS" && openFaqs) ||
                  (item.title === "Slider" && openSlider) ||
                  (item.title === "Manage Team" && openTeam) ||
                  (item.title === "Timeline" && openTimeLine)
                    ? "nav-main-item open"
                    : "nav-main-item"
                }
              >
                {item.title === "FAQS" ||
                item.title === "Slider" ||
                item.title === "Timeline" ||
                item.title === "Manage Team" ? (
                  <span
                    className={
                      item.title === "FAQS" ||
                      item.title === "Slider" ||
                      item.title === "Timeline" ||
                      item.title === "Manage Team"
                        ? "nav-main-link nav-main-link-submenu"
                        : "nav-main-link "
                    }
                    onClick={
                      item.title === "FAQS"
                        ? () => setOpenFaqs(!openFaqs)
                        : item.title === "Slider"
                        ? () => setOpenSlider(!openSlider)
                        : item.title === "Manage Team"
                        ? () => setOpenTeam(!openTeam)
                        : item.title === "Timeline"
                        ? () => setOpenTimeLine(!openTimeLine)
                        : null
                    }
                    key={index}
                    data-toggle="submenu"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {" "}
                    <FontAwesomeIcon
                      icon={item.icon}
                      className="nav-main-link-icon si"
                    />{" "}
                  </span>
                ) : (
                  <NavLink
                    onClick={
                      item.title === "FAQS"
                        ? () => setOpenFaqs(!openFaqs)
                        : item.title === "Slider"
                        ? () => setOpenSlider(!openSlider)
                        : item.title === "Manage Team"
                        ? () => setOpenTeam(!openTeam)
                        : item.title === "Timeline"
                        ? () => setOpenTimeLine(!openTimeLine)
                        : null
                    }
                    activeClassName="active"
                    className={
                      item.title === "FAQS" ||
                      item.title === "Slider" ||
                      item.title === "Timeline" ||
                      item.title === "Manage Team"
                        ? "nav-main-link nav-main-link-submenu"
                        : "nav-main-link "
                    }
                    key={index}
                    data-toggle="submenu"
                    aria-haspopup="true"
                    aria-expanded="false"
                    to={item.url}
                  >
                    <FontAwesomeIcon
                      icon={item.icon}
                      className="nav-main-link-icon si"
                    />
                  </NavLink>
                )}
              </li>
            </Tooltip>
            {item.title === "FAQS" && (
              <SubMenuItems
                key={Math.random().toString(36).substring(7)}
                open={openFaqs}
                options={AdminSideBarCMSSubMenuFaqs}
              />
            )}
            {item.title === "Slider" && (
              <SubMenuItems
                key={Math.random().toString(36).substring(7)}
                open={openSlider}
                options={AdminSideBarCMSSubMenuSlider}
              />
            )}
            {item.title === "Manage Team" && (
              <SubMenuItems
                key={Math.random().toString(36).substring(7)}
                open={openTeam}
                options={AdminSideBarCMSSubMenuManageTeam}
              />
            )}
            {item.title === "Timeline" && (
              <SubMenuItems
                key={Math.random().toString(36).substring(7)}
                open={openTimeLine}
                options={AdminSideBarCMSSubMenuTimeline}
              />
            )}
          </Collapse>
        );
      })}
    </>
  );
};

const SidePanel = () => {
  const [open, setOpen] = useState(false);
  const [openCMS, setOpenCMS] = React.useState(false);

  return (
    <>
      <div
        className="bg-header-dark"
        style={{
          width: "100%",
          borderBottom: "1px solid rgb(98 70 57)",
          borderRight: "1px solid rgb(98 70 57)",
        }}
      >
        <div className="content-header bg-dark site-logo-in-mobile">
          <NavLink to={"/"} style={{ paddingLeft: "12px" }}>
            <img
              src="images/512-betts.png"
              className="img-fluid"
              srcSet="images/512-betts.png"
              alt="icon"
              width="30px"
            />
          </NavLink>
        </div>
      </div>
      <div>
        {/* <div className="content-side-mini content-side-full"> */}
        <div className="content-side content-side-full">
          <ul className="nav-main">
            {AdminSideBarSubItems.map((item) => {
              return (
                <>
                  <Tooltip title={item.title} arrow disableInteractive>
                    <li
                      className={
                        (item.title === "Profile" && open) ||
                        (item.title === "CMS" && openCMS)
                          ? "nav-main-item open"
                          : "nav-main-item"
                      }
                    >
                      {item.title === "Profile" || item.title === "CMS" ? (
                        <span
                          onClick={
                            item.title === "Profile"
                              ? () => {
                                  setOpen(!open);
                                }
                              : item.title === "CMS"
                              ? () => setOpenCMS(!openCMS)
                              : null
                          }
                          className={"nav-main-link nav-main-link-submenu"}
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <FontAwesomeIcon
                            icon={item.icon}
                            className="nav-main-link-icon si"
                          />
                        </span>
                      ) : (
                        <NavLink
                          activeClassName="active"
                          className={"nav-main-link"}
                          aria-haspopup="true"
                          aria-expanded="false"
                          to={item.url}
                        >
                          <FontAwesomeIcon
                            icon={item.icon}
                            className="nav-main-link-icon si"
                          />{" "}
                        </NavLink>
                      )}
                    </li>
                  </Tooltip>
                  {item.title === "CMS" && (
                    <CMSSubMenuItems openCMS={openCMS} />
                  )}
                  {item.title === "Profile" && (
                    <SubMenuItems
                      // key={Math.random().toString(36).substring(7)}
                      open={open}
                      options={AdminSideBarProfileSubMenu}
                    />
                  )}
                </>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
export default SidePanel;
