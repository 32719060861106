import moment from "moment";
export const TokenomicsData = {
  tokensOffered: 25,
  softCap: 12.5,
  hardCap: 1.25,
  tokenDistributed: "3 months after token sale ends",
  Sales: [
    {
      name: "Token PreSale",
      startDate: moment("09-01-2022").format("MMM Do"),
      endDate: moment("09-23-2022").add(7, "days").format("MMM Do"),
      bonus: 15,
      lockPeriod: 3,
    },
    {
      name: "Public Sale 1",
      startDate: moment("10-01-2022").format("MMM Do"),

      endDate: moment("10-08-2022").format("MMM Do"),
      // endDate: moment("07-08-2022").add(7, "days").format("MMM Do"),
      bonus: 15,
      lockPeriod: 3,
    },
    {
      name: "Public Sale 2",
      startDate: moment("10-09-2022").format("MMM Do"),
      endDate: moment("10-15-2022").format("MMM Do"),
      bonus: 10,
      lockPeriod: 3,
    },
    {
      name: "Public Sale 3",
      startDate: moment("10-16-2022").format("MMM Do"),
      endDate: moment("10-22-2022").format("MMM Do"),
      bonus: 7,
      lockPeriod: 3,
    },
    {
      name: "Public Sale 4",
      startDate: moment("10-23-2022").format("MMM Do"),
      endDate: moment("10-31-2022").format("MMM Do"),
      bonus: 5,
      lockPeriod: 3,
    },
    {
      name: "ICO",
      startDate: moment("11-01-2022").format("MMM Do"),
      endDate: "Present",
      bonus: 0,
      lockPeriod: 6,
    },
  ],
};
