import React from "react";
import Skeleton from "@mui/material/Skeleton";
function DatesSkeleton() {
  return (
    <div className="token-card">
      <div className="token-info">
        <span className="token-smartag">-</span>
        <h2 className="token-bonus">
          <Skeleton />% <span>Current Bonus</span>
        </h2>
        <ul className="token-timeline">
          <li>
            <span>START DATE</span>
            <Skeleton />
          </li>
          <li>
            <span>END DATE</span>
            <Skeleton />
          </li>
        </ul>
      </div>
      <div className="token-countdown">
        <>
          <span className="token-countdown-title mb-lg-4 mb-2">
            <>
              Public Sale Starts In <Skeleton />
            </>
          </span>
        </>

        <div className="token-countdown-clock" data-date="2022/09/05"></div>
      </div>
    </div>
  );
}

export default DatesSkeleton;
