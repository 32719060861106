import Web3 from "web3";
import IcoAbi from "./icoTokenMeta";
import Multicall from "@dopex-io/web3-multicall";
import { toast } from "react-toastify";
import Bep20Abi from "./BEP20TokenAbi";
import "react-toastify/dist/ReactToastify.css";
import { ethers } from "ethers";
import { chains } from "../../../Config/networkConfig";
// import { getSpecificNetworks } from "../../NetworkConfig/networks";

// initiate Providers And default Set if no provider is given
var multicall;
var web3;
web3 = new Web3(process.env.REACT_APP_RPC_URL, {
  headers: [
    {
      name: "Access-Control-Allow-Origin",
      value: process.env.REACT_APP_RPC_URL,
    },
  ],
});
if (window.ethereum !== undefined) {
  try {
    // const connectedWallet = "";
    web3.eth.getAccounts().then(async (address) => {
      // let connectedWallet = address[0];
    });
  } catch (error) {
    console.log("error", error);
  }
}
var ICOAddress = process.env.REACT_APP_ICO_ADDRESS
  ? process.env.REACT_APP_ICO_ADDRESS
  : "0x000";
var multiCallAddress;
var chainIdWeb3;

// case 1 : window.ethereum undefined
// case 2 : wrong network configuration
// case 3 : tokenAddress and contract address different
// case 4 :window.ethereum defined and right
export const FetchProvider = async (contractAddress, contractAbi) => {
  if (window.ethereum === undefined) {
    multiCallAddress = process.env.REACT_APP_MULTI_CALL;
    contractAddress = process.env.REACT_APP_ICO_ADDRESS;
    chainIdWeb3 = process.env.REACT_APP_CHAIN_ID;
    ICOAddress = process.env.REACT_APP_ICO_ADDRESS;
    web3 = new Web3(process.env.REACT_APP_RPC_URL);
    multicall = new Multicall({
      multicallAddress: multiCallAddress,
      provider: web3,
    });
    if (Web3.utils.isAddress(contractAddress)) {
      return new web3.eth.Contract(contractAbi, contractAddress);
    }
  } else {
    chainIdWeb3 = await web3.eth.getChainId();
    if (chainIdWeb3 !== undefined) {
      // const keys = await getSpecificNetworks(chainIdWeb3);
      var keys = Object.values(chains).filter(
        (item) => item.chainId == chainIdWeb3
      );
      if (keys.length === 0) {
        multiCallAddress = process.env.REACT_APP_MULTI_CALL;
        contractAddress = process.env.REACT_APP_ICO_ADDRESS;
        chainIdWeb3 = process.env.REACT_APP_CHAIN_ID;
        ICOAddress = process.env.REACT_APP_ICO_ADDRESS;
        web3 = new Web3(process.env.REACT_APP_RPC_URL);
        multicall = new Multicall({
          // process.env.REACT_APP_MULTI_CALL,
          multicallAddress: multiCallAddress,
          provider: web3,
        });
        if (Web3.utils.isAddress(contractAddress)) {
          return new web3.eth.Contract(contractAbi, contractAddress);
        }
      } else {
        multiCallAddress = keys[0].multiCall;
        ICOAddress = keys[0].icoContractAddress;
        if (contractAddress !== "0X000") {
          // contractAddress = contractAddress;
        } else {
          contractAddress = keys[0].icoContractAddress;
        }
        web3 = new Web3(keys[0].defaultRpc);
        multicall = new Multicall({
          multicallAddress: multiCallAddress,
          provider: web3,
        });
        if (Web3.utils.isAddress(contractAddress)) {
          return new web3.eth.Contract(contractAbi, contractAddress);
        }
      }
    }
  }
};
const FetchEtherProvider = async (contractAddress, contractAbi, provider) => {
  if (contractAddress !== "0X000") {
    // contractAddress = contractAddress;
  }

  chainIdWeb3 = await web3.eth.getChainId();
  if (chainIdWeb3) {
    // const keys = await getSpecificNetworks(chainIdWeb3);
    var keys = Object.values(chains).filter(
      (item) => item.chainId == chainIdWeb3
    );
    if (keys.length > 0) {
      multiCallAddress = keys[0].multiCall;
      contractAddress = keys[0].icoContractAddress;
      if (Web3.utils.isAddress(contractAddress)) {
        return new ethers.Contract(contractAddress, contractAbi, provider);
      }
    }
  } else {
    toast.error("No wallet Connected/Chain is connected");
  }
};
export const ICOTokenName = async (tokenAddress, walletAddress) => {
  const contract = await FetchProvider(tokenAddress, IcoAbi);
  const symbol = await contract.methods.symbol().call();
  return symbol;
};

export const GetBonusData = async () => {
  try {
    var multicallDates = [];
    const contract = await FetchProvider(ICOAddress, IcoAbi);
    for (let i = 0; i < 4; i++) {
      multicallDates[i] = contract.methods.bonus(i);
    }
    const contractData = await multicall.aggregate(multicallDates);
    const Info = {
      bonusWeek1: contractData[0],
      bonusWeek2: contractData[1],
      bonusWeek3: contractData[2],
      bonusWeek4: contractData[3],
    };
    return Info;
  } catch (e) {
    // var temp = e.message;
    // temp = temp.split(".")[1];
    // temp = JSON.parse(temp);
    //
    // toast.error(temp.message);
  }
};

export const GetPriceData = async () => {
  try {
    var multicallDates = [];
    const contract = await FetchProvider(ICOAddress, IcoAbi);
    for (let i = 0; i < 2; i++) {
      multicallDates[i] = contract.methods.prices(i);
    }
    const contractData = await multicall.aggregate(multicallDates);
    const Info = {
      preSalePrice: contractData[0],
      publicSalePrice: contractData[1],
    };
    return Info;
  } catch (e) {
    // var temp = e.message;
    // temp = temp.split(".")[1];
    // temp = JSON.parse(temp);
    //
    // toast.error(temp.message);
  }
};
export const GetPreAndPublicDates = async () => {
  var multicallDates = [];

  const contract = await FetchProvider(ICOAddress, IcoAbi);
  if (contract !== undefined) {
    for (let i = 0; i < 4; i++) {
      multicallDates[i] = contract.methods.dates(i);
    }

    const contractData = await multicall.aggregate(multicallDates);
    const Info = {
      presalestart: contractData[0],
      presaleend: contractData[1],
      publicsalestart: contractData[2],
      publicsaleend: contractData[3],
    };

    return Info;
  }
};
export const GetTokenBalance = async (tokenAddress, ICOAddress) => {
  const contract = await FetchProvider(tokenAddress, Bep20Abi);
  const decimal = await contract.methods.decimals().call();
  const bal = await contract.methods.balanceOf(ICOAddress).call();
  const value = parseFloat(bal / Math.pow(10, decimal)).toFixed(6);
  return value;
};

export const getPaymentTokens = async (data) => {
  try {
    var multicallDates = [];
    var balance = [];
    var names = [];
    const contract = await FetchProvider(ICOAddress, IcoAbi);
    let length = await contract.methods.getPaymentTokensCount().call();
    for (let i = 0; i < length; i++) {
      multicallDates[i] = contract.methods.paymentTokens(i);
    }
    const contractData = await multicall.aggregate(multicallDates);
    for (let i = 0; i < length; i++) {
      let result = await GetTokenBalance(contractData[i], ICOAddress);
      let name = await ICOTokenName(contractData[i]);
      balance.push(result);
      names.push(name);
    }
    let obj = [];
    for (let i = 0; i < length; i++) {
      obj.push({
        index: i,
        name: names[i],
        address: contractData[i],
        balance: balance[i],
      });
    }
    return obj;
  } catch (e) {
    console.log(e);
  }
};
// -----------------
// -----------------
// --write method --
// -----------------
// -----------------
export const updateBonusData = async (data, signer, provider) => {
  try {
    // const contract = await FetchProvider(ICOAddress, IcoAbi);
    const contract = await FetchEtherProvider(ICOAddress, IcoAbi, provider);
    const signWithEther = contract.connect(signer);
    let result = await signWithEther.setBonus(data);
    let refresh = await GetBonusData();
    let temp = {
      resp: result,
      newData: refresh,
    };
    return temp;
  } catch (e) {
    console.log(e);
  }
};
export const updatePriceData = async (data, signer, provider) => {
  try {
    // const contract = await FetchProvider(ICOAddress, IcoAbi);

    const contract = await FetchEtherProvider(ICOAddress, IcoAbi, provider);
    const signWithEther = await contract.connect(signer);
    let result = await signWithEther.setPrices(data);
    let refresh = await GetPriceData();
    let temp = {
      resp: result,
      newData: refresh,
    };
    return temp;
  } catch (e) {
    console.log(e);
  }
};

export const SetPreAndPublicDates = async (
  presalestart,
  presaleend,
  publicsalestart,
  publicsaleend,
  signer,
  provider
) => {
  // const contract = await FetchProvider(ICOAddress, IcoAbi);

  const contract = await FetchEtherProvider(ICOAddress, IcoAbi, provider);

  const signWithContract = contract.connect(signer);
  let result = await signWithContract.setDates([
    presalestart,
    presaleend,
    publicsalestart,
    publicsaleend,
  ]);
  // let result = await contract.methods
  //   .setDates([presalestart, presaleend, publicsalestart, publicsaleend])
  //   .send();

  await result.wait();
  let refresh = await GetPreAndPublicDates();

  let temp = {
    resp: result,
    newData: refresh,
  };
  return temp;
};

export const Founder = async (address, amount, signer, provider) => {
  const contract = await FetchEtherProvider(ICOAddress, IcoAbi, provider);
  const signWithContract = contract.connect(signer);
  try {
    let result = await signWithContract.founderAllocationMint(address, amount);
    await result.wait();
  } catch (e) {
    console.log(e);
  }
};

export const updatePaymentTokens = async (data, signer, provider) => {
  try {
    const contract = await FetchProvider(ICOAddress, IcoAbi, provider);
    const signWithEther = contract.connect(signer);
    let result = await signWithEther.setPaymentTokens(data);
    let temp = {
      resp: result,
    };
    return temp;
  } catch (e) {
    console.log(e);
  }
};
export const EarlyMintingUpdate = async (address, amount, signer, provider) => {
  // const contract = await FetchProvider(ICOAddress, IcoAbi);
  const contractEther = await FetchEtherProvider(ICOAddress, IcoAbi, provider);
  // try {
  // let result = await contract.methods
  //   .earlyMint(address, amount)
  //   .estimateGas();
  const signWithEther = await contractEther.connect(signer);
  let tx = await signWithEther.earlyMint(address, amount);
  return await tx.wait();
  // } catch (e) {
  //   var temp = e.message;
  //   temp = temp.split(".")[1];
  //   temp = JSON.parse(temp);
  //   toast.error(temp.message);
  // }
};
export const MintingUncappedUpdate = async (
  address,
  amount,
  signer,
  provider
) => {
  const contractEther = await FetchEtherProvider(ICOAddress, IcoAbi, provider);
  try {
    const signWithEther = await contractEther.connect(signer);
    let tx = await signWithEther.mint(address, amount);
    return await tx.wait();
  } catch (e) {
    var temp = e.message;
    temp = temp.split(".")[1];
    temp = JSON.parse(temp);
    toast.error(temp.message);
  }
};
export const withdrawPaymentTokens = async (
  index,
  amount,
  signer,
  provider
) => {
  try {
    // const contract = await FetchProvider(ICOAddress, IcoAbi);
    const contract = await FetchEtherProvider(ICOAddress, IcoAbi, provider);
    const signWithContract = contract.connect(signer);
    let result = await signWithContract.withdrawTokens(index, amount);
    let refresh = await getPaymentTokens();
    let temp = {
      resp: result,
      newData: refresh,
    };
    return temp;
  } catch (e) {
    console.log(e);
  }
};
