import { QRCodeSVG } from "qrcode.react";
import { Modal } from "react-bootstrap";
function ModalAirdrop({ handleCloseModal, data }) {
  const { invested, paymentMethod, payableToken, tokens, from, updatedAt } =
    data;
  const handleClaimTokens = () => {
    //we will call claim function here
  };
  return (
    <div>
      <Modal show={true} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <h4 className="mt-1">AirDrops</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-8">
              <div className="mb-4">
                <h5>
                  Purchased Tokens:
                  <span className="text-primary">
                    {" "}
                    {invested} {paymentMethod}
                  </span>
                </h5>
              </div>
              <div className="mb-4">
                <h5>
                  Transaction Details:{" "}
                  <span className="text-primary">
                    {" "}
                    tx12ncjakad132asdas123asdad12321sdadassdacz{" "}
                  </span>
                </h5>
              </div>
              <div className="mb-4">
                <h5>
                  Wallet Address :{" "}
                  <span className="text-primary"> {from} </span>
                </h5>
              </div>
            </div>
            <div className="col-6">
              <div className="mb-4">
                <h5>
                  Purchase Date:{" "}
                  <span className="text-primary"> {updatedAt} </span>
                </h5>
              </div>
              <div className="mb-4">
                <h5>
                  Paid Amount:{" "}
                  <span className="text-primary">
                    {" "}
                    {payableToken} {tokens}{" "}
                  </span>
                </h5>
              </div>
            </div>
          </div>
          <div>
            <p>Pay through Wallet App</p>
            <QRCodeSVG value={process.env.REACT_APP_BTC_PUBLIC_ADDRESS} />
          </div>
          <div> - Or - </div>
          <div className="text-center">
            {" "}
            <button
              onClick={handleClaimTokens}
              className="btn btn-primary"
              style={{
                background: "#FF5F45",
                color: "white",
                border: "1px solid white",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                borderRadius: "5px",
              }}
            >
              Drop Tokens
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default ModalAirdrop;
