import instance from "../../../Config/axios";
import { TokenErr } from "../../../Config/tokenErr";
import { ParseError, getAuthHeader } from "../../../Config/utils";

const GetAdminsProfiles = async () => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getAuthHeader();
  return instance
    .get("/users/admins/", headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.msg,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const AddAdmin = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  const data = new FormData();
  if (formData.upload) {
    data.append("files", formData.upload);
  }
  if (formData.upload1) {
    data.append("files", formData.upload1);
  }
  data.append("firstname", formData.firstname);
  data.append("lastname", formData.lastname);
  data.append("email", formData.email);
  data.append("phone", formData.phone);
  data.append("dob", formData.dob);
  data.append("nationality", JSON.stringify(formData.nationality));
  data.append("city", formData.city);
  data.append("zip", formData.city);
  data.append("address1", formData.address1);
  data.append("address2", formData.address2);
  data.append("telegram", formData.telegram);
  data.append("walletAddress", formData.walletAddress);
  data.append("walletNetwork", formData.walletNetwork);
  data.append("type", formData.type);
  data.append("admin", true);
  data.append("permissions", formData.permissions);
  data.append("password", null);
  data.append("username", formData.firstname + " " + formData.lastname);

  return instance
    .post("/users/admin", data, headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
          code: response.code,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const updateAdmin = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  const data = new FormData();
  if (formData.upload) {
    data.append("files", formData.upload);
  }
  if (formData.upload1) {
    data.append("files", formData.upload1);
  }
  data.append("firstname", formData.firstname);
  data.append("lastname", formData.lastname);
  data.append("email", formData.email);
  data.append("phone", formData.phone);
  data.append("dob", formData.dob);
  data.append("nationality", JSON.stringify(formData.nationality));
  data.append("city", formData.city);
  data.append("zip", formData.city);
  data.append("address1", formData.address1);
  data.append("address2", formData.address2);
  data.append("telegram", formData.telegram);
  data.append("walletAddress", formData.walletAddress);
  data.append("walletNetwork", formData.walletNetwork);
  data.append("permissions", formData.permissions);
  data.append("id", formData.id);

  return instance
    .put("/users/admin", data, headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
          code: response.code,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const updateAdminStatus = async (id, status) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .put("/users/admin/updateStatus", { id: id, status: status }, headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
          code: response.code,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const deleteAdmin = async (id) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .delete("/users/admin/delete/" + id, headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
          code: response.code,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetRoleList = async () => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .get("permissions/rolelist/", headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.msg,
            code: response.code,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export {
  GetRoleList,
  GetAdminsProfiles,
  AddAdmin,
  updateAdminStatus,
  updateAdmin,
  deleteAdmin,
};
