import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DialogBox = (props) => {
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    setOpen(props.visible);
  }, [props.visible, props.message, props.header]);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        id="dialog-component"
        open={open}
        onClose={handleClose}
        classes={{ paper: "shadow-sm-dark rounded-sm" }}
      >
        <div className="text-center p-5" id="dbox">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="exlamation-img d-inline-flex justify-content-center align-items-center rounded-circle btn-icon avatar-icon-wrapper bg-neutral-first text-first m-0 d-130">
              {/* <FontAwesomeIcon
                icon={["far", "question-circle"]}
                className="d-flex align-self-center display-3"
              /> */}
              <svg
                data-name="Слой 1"
                id="Слой_1"
                viewBox="0 0 128 128"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title />
                <path d="M126,120H2a2,2,0,0,1-1.73-3l62-108a2,2,0,0,1,3.46,0l62,108a2,2,0,0,1-1.73,3ZM5.45,116h117.1L64,14Z" />
                <path d="M64,108a8.81,8.81,0,1,1,8.81-8.81A8.82,8.82,0,0,1,64,108Zm0-13.62a4.81,4.81,0,1,0,4.81,4.81A4.82,4.82,0,0,0,64,94.38Z" />
                <path d="M64,86.33a8.82,8.82,0,0,1-8.81-8.81V52.81a8.81,8.81,0,0,1,17.62,0V77.52A8.82,8.82,0,0,1,64,86.33ZM64,48a4.82,4.82,0,0,0-4.81,4.81V77.52a4.81,4.81,0,0,0,9.62,0V52.81A4.82,4.82,0,0,0,64,48Z" />
              </svg>
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">{props.header}</h4>
          <p className="mb-0 text-black-50">{props.message}</p>
          <div className="pt-4">
            <Button
              onClick={() => {
                props.ok();
                handleClose();
              }}
              className="btn-first btn-pill mx-1 btn-message"
              id="dbox-ok"
            >
              <span className="btn-wrapper--label">Yes</span>
            </Button>
            <Button
              onClick={() => {
                props.cancel();
                handleClose();
              }}
              className="btn-neutral-secondary btn-pill mx-1 btn-message"
              id="dbox-cancel"
            >
              <span className="btn-wrapper--label">No</span>
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default DialogBox;
