import { useState } from "react";
import SQA from "../assets/images/team/sq-a.jpg";
import { Link } from "react-router-dom";
import { sanitize } from "dompurify";
import Modal from "@mui/material/Modal";
import ModalBody from "./TeamMemberModal";
import { serverUrl } from "../../../Config/config";
const TeamListing = (props) => {
  const { head, description, team } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const addDefaultSource = (ev) => {
    ev.target.src = SQA;
  };
  return (
    <>
      <div className="container">
        <div className="section-head text-center wide-auto-sm team-head">
          <h4 className="title title-xl-s2 title-semibold text-default">
            {head}
          </h4>
          <div className="" data-delay=".2">
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(description),
              }}
            />
          </div>
        </div>
        <div className="nk-block nk-block-team-list team-list">
          <div className="row justify-content-center">
            {team.map((item, index) => {
              return (
                <div
                  key={
                    item.name +
                    "team" +
                    index +
                    Math.random().toString(36).substring(7)
                  }
                  className="col-sm-6 col-lg-3 col-width"
                >
                  <div className="team team-s3">
                    <div className="team-photo round-full team-photo-bg">
                      <Link
                        onClick={() => {
                          setSelected(item);
                          handleOpen();
                        }}
                        to={"#team-popup-1"}
                        className="team-show content-popup"
                        data-overlay="bg-theme-grad-alt"
                      ></Link>
                      <img
                        src={serverUrl + "/" + item.profileimg}
                        alt="team"
                        className="round-full lazyload"
                        onError={addDefaultSource}
                      />
                    </div>
                    <div className="team-card-header">
                      <h5 className="team-name title title-sm">{item.name}</h5>
                      <span className="team-position">{item.designation}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="d-flex justify-content-center">
            <Link
              to={"/signup"}
              className="zinnia-btn btn-md btn-round btn-with-icon btn-light btn-shade mb-5"
            >
              <span>Join Pre-Sale</span>
              <em className="icon fas fa-angle-double-right"></em>
            </Link>
          </div>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <ModalBody handleClose={handleClose} selected={selected} />
          </div>
        </Modal>
      </div>
    </>
  );
};
export default TeamListing;
