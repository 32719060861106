import { useState } from "react";
import TextField50 from "./Textfield50";
import TextFiledLabelRequired from "./TextfieldLabelRequired";
import RadioActiveInactive from "./RadioControlActiveInactive";
import ProfileBox from "./profileUploadBox";
import ReactQuill50 from "./ReactQuill50";
import PasswordField50 from "./PasswordField50";
import { ConnectedFocusError } from "focus-formik-error";
import { TiTick } from "react-icons/ti";
import { BiReset } from "react-icons/bi";
import ClipLoader from "../Comps-Utils/ClipLoader";
import DateFlat from "../Comps-Utils/FlatPicker";
import AssetSelect from "../Comps-Utils/SelectAsset";
import SunEditorReact from "../Comps-Utils/SunEditor";
const FormikFieldSingle = (props) => {
  const {
    touched,
    errors,
    isSubmitting,
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    formFields,
    formFields2,
    headingFormFields2,
    resetForm,
    reset,
    refresh,
    isModal,
    heading,
    tab,
    general,
    actionState,
  } = props;
  const [amountExceed, setAmountExceed] = useState(false);
  const handleChangeWithdraw = (e) => {
    let value = parseFloat(e.target.value);
    if (value < 0 || value > parseFloat(values["balance"])) {
      setAmountExceed(true);
    } else {
      setAmountExceed(false);
      setFieldValue("amount", e.target.value);
    }
  };
  const handleMaxAmount = (values, setAmountExceed, setFieldValue) => {
    setAmountExceed(false);
    setFieldValue("amount", parseFloat(values["balance"]).toFixed(6));
  };
  return (
    <>
      <div className="password-form radio-form">
        {isModal || heading === "Ico Settings" ? null : (
          <div
            id="add-user-header"
            className="d-flex align-items-center justify-content-between bg-zinnia-grad-alt add-user-css"
          >
            <div>
              <h5 className="user-header-h5 mb-1">{heading}</h5>
            </div>
          </div>
        )}
        <form autoComplete="off" className="ico-dates-input">
          <ConnectedFocusError />
          <div
            className={
              heading === "Ico Settings"
                ? "common-cms-pg block-content block-content-full  mt-2"
                : "common-cms-pg block-content block-content-full"
            }
          >
            <div className="cms-col-mb">
              <div className="row items-push">
                {formFields.map((item, index) => {
                  return (
                    <div
                      key={index + "singleForm"}
                      className="cms_cards flex-md-row flex-column align-items-center justify-content-start"
                    >
                      {item.type === "image" ||
                      (tab && tab === "Withdraw Tokens") ? null : (
                        <TextFiledLabelRequired
                          widthclass={
                            item.type === "radio"
                              ? "col-lg-2 col-md-4 "
                              : "col-lg-2 col-md-4 "
                          }
                          field={item.field}
                        />
                      )}
                      {item.type === "image" ? (
                        <ProfileBox
                          setFieldValue={setFieldValue}
                          values={values}
                          touched={touched}
                          errors={errors}
                        />
                      ) : item.type === "radio" ? (
                        <RadioActiveInactive
                          className="mr-2"
                          setFieldValue={setFieldValue}
                          values={values}
                          touched={touched}
                          errors={errors}
                        />
                      ) : item.type === "description" ? (
                        <ReactQuill50
                          setFieldValue={setFieldValue}
                          fieldName={item.fieldName}
                          handleChange={handleChange}
                          values={values}
                          touched={touched}
                          errors={errors}
                          place={item.place}
                          type={item.type}
                        />
                      ) : item.type === "password" ? (
                        <PasswordField50
                          fieldName={item.fieldName}
                          handleChange={handleChange}
                          values={values}
                          touched={touched}
                          errors={errors}
                          place={item.place}
                          type={item.type}
                        />
                      ) : item.type === "date" &&
                        heading === "Ico Settings" &&
                        tab === "Ico Dates" ? (
                        values !== undefined && values !== "Invalid date" ? (
                          <DateFlat
                            values={values}
                            setFieldValue={setFieldValue}
                            value={values[item.fieldName]}
                            widthclass="col-md-9"
                            fieldName={item.fieldName}
                            touched={touched}
                            errors={errors}
                          />
                        ) : (
                          <></>
                        )
                      ) : item.type === "auto" && tab === "Withdraw Tokens" ? (
                        <div className="w-45 small-input token-label input-20">
                          <AssetSelect
                            fieldName={item.fieldName}
                            handleChange={handleChange}
                            values={values}
                            touched={touched}
                            errors={errors}
                            place={item.place}
                            type={item.type}
                            setFieldValue={setFieldValue}
                            opts={general}
                            amountExceed={amountExceed}
                          />
                        </div>
                      ) : item.type === "editor" ? (
                        // <EditorConvertToHTML values={values} />
                        <SunEditorReact
                          values={values}
                          place={item.place}
                          type={item.type}
                          setFieldValue={setFieldValue}
                          fieldName={item.fieldName}
                          touched={touched}
                          errors={errors}
                        />
                      ) : (
                        <>
                          <TextField50
                            heading={heading}
                            widthclass={
                              tab && tab === "Withdraw Tokens"
                                ? "w-md-45 input-20"
                                : "col-lg-9 col-md-8 field-ico"
                            }
                            fieldName={item.fieldName}
                            handleChange={
                              tab === "Withdraw Tokens"
                                ? handleChangeWithdraw
                                : handleChange
                            }
                            actionState={actionState}
                            values={values}
                            touched={touched}
                            errors={errors}
                            place={item.place}
                            type={item.type}
                            setAmountExceed={setAmountExceed}
                            setFieldError={setFieldError}
                            setFieldValue={setFieldValue}
                            handleMaxAmount={handleMaxAmount}
                            index={index}
                            tab={tab}
                          />
                        </>
                      )}
                    </div>
                  );
                })}
                {formFields2 ? (
                  <div className="block-content block-content-full">
                    <div className="">
                      <h2 className="content-heading mb-sm-4">
                        {headingFormFields2}
                      </h2>
                      <div className="row items-push">
                        {formFields2 &&
                          formFields2.map((item, index) => {
                            return (
                              <div
                                key={
                                  index +
                                  item.type +
                                  Math.random().toString(36).substring(7)
                                }
                                className="d-flex flex-md-row flex-column align-items-center mb-3 justify-content-start"
                              >
                                {item.type === "image" ? null : (
                                  <TextFiledLabelRequired
                                    widthclass="col-lg-2 col-md-4 col-12"
                                    field={item.field}
                                  />
                                )}
                                {item.type === "image" ? (
                                  <ProfileBox
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                  />
                                ) : item.type === "radio" ? (
                                  <RadioActiveInactive
                                    className="me-2 radio-btn"
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                  />
                                ) : item.type === "description" ? (
                                  <ReactQuill50
                                    setFieldValue={setFieldValue}
                                    fieldName={item.fieldName}
                                    handleChange={handleChange}
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    place={item.place}
                                    type={item.type}
                                  />
                                ) : item.type === "password" ? (
                                  <PasswordField50
                                    fieldName={item.fieldName}
                                    handleChange={handleChange}
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    place={item.place}
                                    type={item.type}
                                  />
                                ) : (
                                  <TextField50
                                    widthclass="col-lg-9 col-md-8 col-12"
                                    fieldName={item.fieldName}
                                    handleChange={handleChange}
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    place={item.place}
                                    type={item.type}
                                  />
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                ) : null}
                {heading !== "Ico Settings" ? (
                  <>
                    <div className="d-flex justify-content-center col-md-12 mb-3">
                      <button
                        onClick={
                          (e) => {
                            e.preventDefault();
                            if (reset === true) {
                              refresh();
                            } else {
                              resetForm();
                            }
                          }
                          // eslint-disable-next-line
                        }
                        disabled={isSubmitting}
                        className="btn btn-md btn-grad btn-grad-theme btn-round mr-2"
                      >
                        <div className="d-flex justify-content-center align-items-center align-items-center">
                          <BiReset size={25} className="mr-1" />{" "}
                          <span>Reset</span>
                        </div>
                      </button>
                      <button
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                        type="submit"
                        className="btn btn-md btn-grad btn-grad-theme btn-round"
                      >
                        {isSubmitting ? (
                          <div>
                            <ClipLoader color={"white"} />
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center align-items-center">
                            <TiTick size={25} /> <span>Save</span>
                          </div>
                        )}
                      </button>
                    </div>{" "}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default FormikFieldSingle;
