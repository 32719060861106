import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ErrorMessage } from "formik";
const CountrySelect = (props) => {
  const {
    fieldName,
    values,
    touched,
    errors,
    setFieldValue,
    opts,
    amountExceed,
  } = props;
  return (
    <Autocomplete
      // sx={{border:"1px solid #c4c4c4"}}
      id="country-select-demo"
      value={values}
      options={opts}
      size="small"
      autoHighlight
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {/* <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          /> */}
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <>
          <TextField
            className="tab-ico-input"
            name={fieldName}
            variant="outlined"
            error={touched.fieldName && errors.fieldName}
            {...params}
            label="Choose an Asset"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
          <ErrorMessage
            name={fieldName}
            component="div"
            className={"error-text"}
          />
          <div className="d-flex justify-content-start">
            {amountExceed ? (
              <span
                className="d-flex"
                style={{
                  marginRight: "14px",
                  color: "#FF4D4D",
                  fontSize: "0.75rem",
                  marginBottom: "5px",
                  textAlign: "left",
                  fontFamily: "Heebo,sans-serif",
                  fontWeight: "400",
                  lineHeight: "1.66",
                  marginTop: "-25px !important",
                }}
              >
                Maximum amount can be less than or equal to current balance.{" "}
              </span>
            ) : (
              <>
                {" "}
                <span>Balance:</span>
                <span className="ml-2">
                  {values["amount"] === ""
                    ? parseFloat(values["balance"]).toFixed(6)
                    : parseFloat(values["balance"]).toFixed(6) -
                      parseFloat(values["amount"]).toFixed(6)}
                </span>{" "}
              </>
            )}
          </div>
        </>
      )}
      onInputChange={(event, newInputValue) => {}}
      onChange={(event, value) => {
        setFieldValue("name", value.name);
        setFieldValue("index", value.index);
        setFieldValue("address", value.address);
        setFieldValue("balance", value.balance);
        setFieldValue("amount", 0);
      }}
    />
  );
};
export default CountrySelect;
// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
