import { Formik } from "formik";
import AppBar from "@material-ui/core/AppBar";
import useStyles from "../../../assets/CustomeMaterialStyleSheet";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import ModalFormSingleLine from "../FormikFormFieldsSingleLine";
import ModalFormDoubleLine from "../FormikFormFieldsDoubleLine";
import PerfectScrollbar from "react-perfect-scrollbar";
const Body = (props) => {
  const {
    actionState,
    handleClose,
    updatedRow,
    setUpdatedRow,
    add,
    update,
    initialValues,
    setInitialValues,
    schema,
    formFields,
    heading,
    isModal,
    roles,
  } = props;
  const classes = useStyles();
  const SubmitForm = (data, actions) => {
    if (actionState === "edit") {
      update(data, actions);
    } else {
      add(data, actions);
    }
    actions.setSubmitting(false);
    handleClose();
  };
  return (
    <>
      <div className="email-form">
        <AppBar
          className={
            heading === "Email Template" ? classes.header80 : classes.header60
          }
        >
          <div
            id="add-user-header"
            className="d-flex align-items-center justify-content-between add-user-css"
          >
            <div>
              <h5 className="user-header-h5 mb-2">
                {actionState === "edit" && isModal
                  ? "Edit " + heading
                  : actionState === "edit"
                  ? heading
                  : null}
                {actionState !== "edit" && isModal
                  ? "Add " + heading
                  : actionState !== "edit"
                  ? heading
                  : null}
              </h5>
            </div>
            <Button
              id="coin-modal-close"
              className="btn-neutral mx-2 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center text-white border-radius-15px"
              onClick={() => {
                handleClose();
                setUpdatedRow({});
              }}
            >
              <CloseIcon />
            </Button>
          </div>
        </AppBar>
        <div className="email-inner-form">
          <Formik
            initialValues={
              actionState === "edit" && heading === "Admin"
                ? setInitialValues(updatedRow, roles)
                : actionState === "edit" && heading !== "Admin"
                ? setInitialValues(updatedRow)
                : initialValues
            }
            onSubmit={(values, actions) => {
              SubmitForm(values, actions);
            }}
            validationSchema={schema}
          >
            {(props) => {
              return (
                <>
                  <div
                    className={
                      heading === "Email Template"
                        ? classes.paper80
                        : classes.paper60
                    }
                  >
                    <PerfectScrollbar
                      options={{
                        wheelPropagation: true,
                      }}
                    >
                      {heading === "Admin" ? (
                        <ModalFormDoubleLine
                          formFields={formFields}
                          actionState={actionState}
                          handleClose={handleClose}
                          setUpdatedRow={setUpdatedRow}
                          isModal={isModal}
                          heading={heading}
                          {...props}
                        />
                      ) : (
                        <ModalFormSingleLine
                          isModal={isModal}
                          actionState={actionState}
                          handleClose={handleClose}
                          setUpdatedRow={setUpdatedRow}
                          heading={heading}
                          formFields={formFields}
                          {...props}
                        />
                      )}
                    </PerfectScrollbar>
                  </div>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default Body;
