import { useEffect } from "react";
import { TiTick } from "react-icons/ti";
import TextField50 from "../Comps-Utils/Textfield50";
import TextFiledLabel from "../Comps-Utils/TextfieldLabelRequired";
import { ConnectedFocusError } from "focus-formik-error";
import Profileuploader from "../Comps-Utils/ProfileUploaderField";
import ClipLoader from "../Comps-Utils/ClipLoader";
import Box from "@mui/material/Box";
import { BiReset } from "react-icons/bi";
import Avatar from "@mui/material/Avatar";
import { Row, Col } from "react-bootstrap";
import { AdminProfileFormFields } from "./helper";
import AutocompleteCountry from "../Comps-Utils/AutoCompleteCountry";
// import {serverUrl} from '../../Config/config';
const InfoForm = (props) => {
  const {
    touched,
    errors,
    isSubmitting,
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    setUpload,
    resetForm,
    upload,
    img,
    setFieldTouched,
    setFieldError,
  } = props;
  useEffect(() => {
    setFieldValue("profileImgUrl", upload);
  }, [upload, setFieldValue]);
  return (
    <>
      <div
        id="add-user-header"
        className="d-flex align-items-center justify-content-between bg-zinnia-grad-alt"
      >
        <div>
          <h5 className="user-header-h5 mb-1 add-user-css">Profile</h5>
        </div>
      </div>
      <div className="profile-handler">
        <form autoComplete="off" className="profile-form">
          <ConnectedFocusError />
          <div className="block-content block-content-full">
            <div className="">
              <div className="row items-push d-flex align-items-center justify-content-center">
                <div className="profile-field-section">
                  <Row>
                    {AdminProfileFormFields.map((item, index) => {
                      return (
                        <Col
                          // key={
                          //   index +
                          //   item.fieldName +
                          //   Math.random().toString(36).substring(7)
                          // }
                          md={6}
                        >
                          <div className="profile-label">
                            <TextFiledLabel field={item.field} />
                            {item.type === "selectCountry" ? (
                              <AutocompleteCountry
                                fieldName={item.fieldName}
                                handleChange={handleChange}
                                values={values}
                                touched={touched}
                                errors={errors}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                setFieldError={setFieldError}
                              />
                            ) : (
                              <TextField50
                                fieldName={item.fieldName}
                                handleChange={handleChange}
                                values={values}
                                touched={touched}
                                errors={errors}
                                place={item.place}
                                type={item.type}
                              />
                            )}
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
                <div className="d-flex justify-content-center mb-3">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      resetForm();
                    }}
                    disabled={isSubmitting}
                    className="btn btn-md btn-grad btn-grad-theme btn-round mr-2"
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <BiReset size={25} className="mr-1" /> <span>Reset</span>
                    </div>
                  </button>
                  <button
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    type="submit"
                    className="btn btn-md btn-grad btn-grad-theme btn-round"
                  >
                    {isSubmitting ? (
                      <div>
                        <ClipLoader color={"white"} />
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center align-items-center">
                        <TiTick size={25} /> <span>Save</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="avater-container input-item input-with-label">
          <div className="avatar-design"></div>
          <div className="avatar-img-contain input-item input-with-label">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Avatar sx={{ width: 75, height: 75 }} className="profile-img">
                <img
                  src={
                    img === "" || img === undefined
                      ? "images/user-thumb-lg.png"
                      : img
                  }
                  alt="profile-img"
                ></img>
              </Avatar>
            </Box>
            <div className="text-center">
              <h4 className="text-primary mx-1 mb-1">{values.username}</h4>
              <h6 className="text-secondary mx-1 mb-1">{values.email}</h6>
            </div>
          </div>
          <Profileuploader upload={upload} setUpload={setUpload} />
        </div>
      </div>
    </>
  );
};
export default InfoForm;
