import Skeleton from "@material-ui/lab/Skeleton";
const WithdrawSkelton = (props) => {
  const { length } = props;

  return (
    <>
      {length.map((i) => {
        return (
          <>
            <div className="d-flex justify-content-center">
              <Skeleton height={66} width={600} />
            </div>
          </>
        );
      })}
    </>
  );
};
export default WithdrawSkelton;
