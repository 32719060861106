import React from "react";
import { Modal } from "react-bootstrap";
import StripePayment from "../stripePayment";
function ModalCredit({ purchase, show, closeCreditModal }) {
  return (
    <div>
      <Modal
        show={show}
        backdrop="static"
        onHide={closeCreditModal}
        className="bitcoin-modal d-flex align-items-center auto-height-modal"
      >
        <Modal.Header className="align-items-baseline" closeButton>
          <h3 className=" text-center">Pay Through Credit Card</h3>
        </Modal.Header>
        <Modal.Body>
          <StripePayment purchase={purchase} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalCredit;
