import * as Yup from "yup";
export const createRows = (rowData) => {
  const rows = rowData.map((i, index) => {
    return {
      id: i._id,
      name: i.name,
      designation: i.designation,
      description: i.description ? i.description : "",
      telegram: i.telegram,
      linkedin: i.linkedin,
      twitter: i.twitter,
      status: i.status,
      type: i.type,
      profileImgUrl: i.profileimg,
      sortValue: i.sortValue,
    };
  });
  return rows;
};
export const rowsPerPage = 10;
export const AdvisorListingTableHeadings = [
  {
    id: "name",
    label: "Name",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "designation",
    label: "Designation",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "status",
    label: "Status",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "actions",
    label: "Actions",
    sort: true,
    class: "bg-body-light",
  },
];
export const teamAdvisorsInitialValues = {
  name: "",
  designation: "",
  description: "",
  telegram: "",
  linkedin: "",
  twitter: "",
  status: false,
  type: "advisor",
  profileImgUrl: "",
  sortValue: 0,
};
export const TeamAdvisorSchema = Yup.object().shape({
  name: Yup.string().required("Name is required!"),
  designation: Yup.string().required("Designation is required!"),
  description: Yup.string().required("Description is required!"),
  telegram: Yup.string().required("Telegram Url is required!"),
  linkedin: Yup.string().required("Linkedin Url is required!"),
  twitter: Yup.string().required("Twitter Url is required!"),
});
export const TeamAdvisorFormFields = [
  {
    field: "image",
    fieldName: "profileImgUrl",
    place: "Enter Image",
    type: "image",
  },
  {
    field: "Name",
    fieldName: "name",
    place: "Enter Name",
    type: "text",
  },
  {
    field: "Designation",
    fieldName: "designation",
    place: "Enter Designation",
    type: "text",
  },
  {
    field: "Description",
    fieldName: "description",
    place: "Enter Description",
    type: "description",
  },
  {
    field: "Telegram Url",
    fieldName: "telegram",
    place: "Enter Telegram Url",
    type: "text",
  },
  {
    field: "Linkedin Url",
    fieldName: "linkedin",
    place: "Enter Linkedin Url",
    type: "text",
  },
  {
    field: "Twitter Url",
    fieldName: "twitter",
    place: "Enter Twitter Url",
    type: "text",
  },
  {
    field: "Status",
    fieldName: "status",
    place: "",
    type: "radio",
  },
  {
    field: "sortValue",
    fieldName: "sortValue",
    place: "Enter sort Value",
    type: "number",
  },
];
export const setInitialValues = (updatedRow) => {
  const Values = {
    id: updatedRow.id ? updatedRow.id : "",
    name: updatedRow.name ? updatedRow.name : "",
    type: updatedRow.type ? updatedRow.type : "",
    designation: updatedRow.designation ? updatedRow.designation : "",
    description: updatedRow.description ? updatedRow.description : "",
    telegram: updatedRow.telegram ? updatedRow.telegram : "",
    linkedin: updatedRow.linkedin ? updatedRow.linkedin : "",
    twitter: updatedRow.twitter ? updatedRow.twitter : "",
    status: updatedRow.status ? updatedRow.status : false,
    profileImgUrl: updatedRow ? updatedRow.profileImgUrl : "",
    sortValue: updatedRow ? updatedRow.sortValue : 0,
  };
  return Values;
};
