import * as Yup from "yup";
export const SliderInitialValues = {
  mainHeading: "",
  description: "",
  belowText: "",
};
export const CMSSliderFormFields = [
  {
    field: "Main Heading",
    fieldName: "mainhead",
    place: "Enter Main heading",
    type: "text",
  },
  {
    field: "Description",
    fieldName: "description",
    place: "Enter Description",
    type: "text",
  },
  {
    field: "Below Text",
    fieldName: "text",
    place: "Enter Below Text",
    type: "text",
  },
];
export const SliderSchema = Yup.object().shape({
  mainhead: Yup.string()
    .min(3, "Heading be at least 3 characters.")
    .required("Heading is required!"),
  description: Yup.string().required("Description is required!"),
  text: Yup.string().required("Below Text is required!"),
});
export const getInitialValues = (CurrentUser, id) => {
  return {
    id: id ? id : "",
    mainhead: CurrentUser.mainhead,
    description: CurrentUser.description,
    text: CurrentUser.text,
  };
};
