import { useEffect, useState } from "react";
import Profileuploader from "./ProfileUploaderField";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
// import { serverUrl } from "../../Config/config";
const InfoForm = (props) => {
  const { values, setFieldValue } = props;
  const [upload, setUpload] = useState(values.profileImgUrl);
  const [img, setImg] = useState("");
  useEffect(() => {
    if (upload && upload.preview) {
      setImg(upload.preview);
    } else {
      setImg(upload);
      // setProfileImage(upload)
    }
    // eslint-disable-next-line
  }, [upload]);
  useEffect(() => {
    setFieldValue("profileImgUrl", upload);
  }, [upload, setFieldValue]);
  return (
    <>
      <div className="col-md-12 avater-container input-item input-with-label d-flex justify-content-between align-items-center ">
        <div className="avatar-img-contain input-item input-with-label d-flex flex-col">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {/* <Avatar
                    src={img === "" || img === undefined ? "images/user-thumb-lg.png" : img}
                    sx={{ width: 80, height: 80 }}
                  ></Avatar> */}
            <Avatar sx={{ width: 75, height: 75 }}>
              <img
                src={
                  img === "" || img === undefined
                    ? "images/user-thumb-lg.png"
                    : img
                }
                alt="profile-img"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = "favicon-16x16.png";
                }}
              ></img>
            </Avatar>
          </Box>
          <div className="ml-3 mt-2">
            {/* <h4 className="text-primary m-0">{values.username}</h4> */}
            {/* <h6 className="text-secondary">{values.email}</h6> */}
          </div>
        </div>
        {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Avatar
                    src={img === "" ? "images/user-thumb-lg.png" : img}
                    sx={{ width: 80, height: 80 }}
                  ></Avatar>
                </Box> */}
        <Profileuploader upload={upload} setUpload={setUpload} />
        {/* <div>
                  <span className='error1'>{errors.firstname}</span>
                </div> */}
      </div>
    </>
  );
};
export default InfoForm;
