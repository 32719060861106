import "./App.css";
import React, { useState, useEffect } from "react";
import AdminRoutes from "./adminRoutes";
import AuthRoutes from "./authRoutes";
import UserRoutes from "./userRoutes";
import { ToastContainer, toast } from "react-toastify";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  fab,
  faFacebook,
  faTwitter,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import {
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar,
} from "@fortawesome/free-regular-svg-icons";
import {
  fas,
  faExclamation,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faCheck,
  faSmile,
  faHeart,
  faBatteryEmpty,
  faBatteryFull,
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkedAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar
);
library.add(
  fab,
  faFacebook,
  faTwitter,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub
);
library.add(
  fas,
  faExclamation,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faCheck,
  faSmile,
  faHeart,
  faBatteryEmpty,
  faBatteryFull,
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkedAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink
);

export const ImageContext = React.createContext("");
export const WalletContext = React.createContext("");
export const ToasterContext = React.createContext("");
export const RightsContext = React.createContext("");
export const SaleContext = React.createContext("");
export const HardCapLimitContext = React.createContext("");

function App() {
  // const token = sessionStorage.getItem("token");
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const tempRights = localStorage.getItem("rights");
  const [profileImg, setProfileImg] = useState("");
  const [rights, setRights] = useState("");
  const [hardCapLimit, setHardCapLimit] = useState(false);
  const [walletAddress, setWalletAddress] = useState("Connect Wallet");
  const [tostify, setTostify] = useState({
    visible: false,
    key: "",
    type: "",
    message: "",
    dismissAll: () => toast.dismiss(),
  });
  const [saleObj, setSaleObj] = useState({
    type: "",
    price: 0,
    start: "",
    end: "",
    addresses: "",
    bonus: 0,
    phase: "",
    remaintime: 0,
    loading: true,
  });
  useEffect(() => {}, [tostify]);
  useEffect(() => {
    setRights(role === "user" ? "" : JSON.parse(tempRights));
    if (token) {
      sessionStorage.setItem("token", token);
    } else {
      sessionStorage.clear();
    }
    //eslint-disable-next-line
  }, [tempRights]);

  return (
    <>
      <WalletContext.Provider value={[walletAddress, setWalletAddress]}>
        <SaleContext.Provider value={[saleObj, setSaleObj]}>
          <ImageContext.Provider value={[profileImg, setProfileImg]}>
            <ToasterContext.Provider value={[tostify, setTostify]}>
              <RightsContext.Provider value={[rights, setRights]}>
                <HardCapLimitContext.Provider
                  value={[hardCapLimit, setHardCapLimit]}
                >
                  <ToastContainer
                    closeButton={false}
                    position="top-right"
                    autoClose={8000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    draggable={false}
                    // pauseOnVisibilityChange
                    closeOnClick
                    // pauseOnHover
                  />

                  {token ? (
                    role === "admin" || role === "superAdmin" ? (
                      <AdminRoutes
                        rights={rights}
                        tostify={tostify}
                        setTostify={setTostify}
                      />
                    ) : (
                      <>
                        <UserRoutes tostify={tostify} setTostify={setTostify} />
                      </>
                    )
                  ) : (
                    <AuthRoutes tostify={tostify} setTostify={setTostify} />
                  )}
                </HardCapLimitContext.Provider>
              </RightsContext.Provider>
            </ToasterContext.Provider>
          </ImageContext.Provider>
        </SaleContext.Provider>
      </WalletContext.Provider>
    </>
  );
}
export default App;
