import { API } from "../apiConfig";
import { getAuthHeader, ParseError } from "../../../Config/utils";
import { TokenErr } from "../../../Config/tokenErr";
export const RegisterUserApi = (data) => {
  let response = API.post(`/users/register`, data);
  return response;
};
export const LoginUserApi = async (data) => {
  let response = await API.post(`/users/login`, data);
  return response;
};
export const ResetPasswordApi = async (data) => {
  let response = await API.post(`/users/forget`, data);
  return response;
};
export const ForgetPasswordApi = async (data) => {
  let response = await API.put(`/users/reset-password`, data);
  return response;
};
export const UpdateUserInfo = async (formData, upload) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const data = new FormData();
  if (upload) {
    data.append("profileimg", upload);
  }
  data.append("firstname", formData.firstname);
  data.append("lastname", formData.lastname);
  data.append("phone", formData.phone);
  data.append("dob", formData.dob);
  data.append("address1", formData.address1);
  data.append("address2", formData.address2);
  data.append("city", formData.city);
  data.append("nationality", JSON.stringify(formData.nationality));
  const headers = await getAuthHeader();
  let response = API.put(`/users/update`, data, headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            code: response.code,
            data: response.data,
            message: response.msg,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};
export const FetchUserInfo = async () => {
  const headers = await getAuthHeader();

  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  let response = API.get(`/users/profile`, headers)
    .then(async (response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.msg,
            code: response.code,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};
export const FetchUserQR = async (e) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  let response = API.get(`/users/get-qr`, headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.msg,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};
export const VerifyUserQR = async (data) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  let response = API.post(`/users/verify-qr`, { data: data }, headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.code,
            message: response.msg,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};
export const verifyLogin = async (data) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  let response = API.post(`/users/verify-qr`, { data: data }, headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.code,
            message: response.msg,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};
export const UpdateUserPassword = async (data) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  let response = API.put("/users/password", { data: data }, headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.msg,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};
export const AddUserKYC = async (data) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  let response = API.post("/kyc/add", { data: data }, headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.code,
            message: response.msg,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};
export const FetchUserFaqs = async (data) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  let response = API.get("/faq/", headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            code: response.code,
            data: response.code,
            message: response.msg,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};
export const FetchUserKycStatus = async () => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  let response = API.get("/users/kycStatus", headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.data,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};
export const FetchUserActivity = async (data) => {
  const headers = await getAuthHeader();
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  let response = API.get("/users/activity", headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.code,
            message: response.msg,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};
export const UpdateUserWallet = async (data) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  let response = API.put("/users/update-wallet", { data: data }, headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.msg,
            code: response.code,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};
export const GetUserWallet = async (data) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  let response = API.get("/users/getwallet", headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.msg,
          code: response.code,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};
export const FetchUserFaStatus = async (data) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  let response = API.post(`/users/faStatus`, { data: data }, headers)
    .then((response) => {
      //
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          code: response.code,
          data: response.fa2Status,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.msg),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};
export const FetchUserProfileImg = async (key) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  let response = API.get(`/users/profileImage/${key}`, headers)
    .then((response) => {
      if (TokenErr(response)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.msg,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};
