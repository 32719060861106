import { useState, useEffect, useContext, useCallback } from "react";
import {
  ApplyFilterTransaction,
  GetTransactionsByType,
} from "../../Services/Admin/Dashboard";
import { createRows, EthereumTableHeadings } from "./helpers";
import TableRows from "./tablerow";
import Loader from "../Comps-Utils/ClimbingLoader";
import EthTable from "../Comps-Utils/GenericTable";
import moment from "moment";
import { ToasterContext } from "../../App";
import {
  SUCCESS_S,
  ERROR_S,
  ETH_C,
  ETHEREUM_TABLE_HEADING,
  ETHEREUM_SEARCH_PLACEHOLDER,
} from "../../helpers/constants";
import { MXAUTO_W95, MT_3 } from "../../helpers/CLASSES";
import StaticDateRangePickerDemo from "../Filters/date";
import RangeSlider from "../Filters/range";

import Tags from "../Filters/filters";
import { ClipLoader } from "react-spinners";
import EthTransactionSubmitModal from "./EthTransactionSubmitModal";
const Erthereum = () => {
  const [filterValues, setFilterValues] = useState({
    type: "ETH",
    datefrom: "2022-01-01",
    dateto: moment(new Date()).format("YYYY-MM-DD"),
    lowprice: 0,
    highprice: 1000000,
    status: ["2", "3", "4", "5"],
  });
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [tostify, setTostify] = useContext(ToasterContext);
  const [transactions, setTransactions] = useState([]);
  const [transactionModalShow, setTransactionModalShow] = useState(false);
  const [transactionData, setTransactionData] = useState();
  const GetTransactionsService = useCallback(
    (type) => {
      setLoading(true);
      GetTransactionsByType(type).then((res) => {
        if (res.status === 200) {
          setTransactions(res.data);
          setLoading(false);
        } else {
          setLoading(false);
          setTostify({
            ...tostify,
            visible: true,
            key: Math.random().toString(36).substring(7),
            type: res && res.status === 200 ? { SUCCESS_S } : { ERROR_S },
            message: res.message,
          });
        }
      });
    },
    [tostify, setTostify]
  );
  useEffect(() => {
    GetTransactionsService(ETH_C);
    // eslint-disable-next-line
  }, []);
  const SetFilterData = (data, type) => {
    if (type === "date") {
      setFilterValues({
        ...filterValues,
        datefrom: moment(data[0]).format("YYYY-MM-DD"),
        dateto: moment(data[1]).format("YYYY-MM-DD"),
      });
    }
    if (type === "range") {
      setFilterValues({
        ...filterValues,
        lowprice: data[0],
        highprice: data[1],
      });
    }

    if (type === "status") {
      if (data.includes("6") || data.length === 0) {
        data = ["2", "3", "4", "5"];
      }
      setFilterValues({ ...filterValues, status: data });
    }
  };
  const GetFilteredData = () => {
    setLoadingFilter(true);
    ApplyFilterTransaction(filterValues).then((res) => {
      setLoadingFilter(false);
      setTransactions(res.data);
    });
  };

  const transactionSubmitModalShowHandle = (row) => {
    setTransactionData(row);
    setTransactionModalShow(true);
  };
  const transactionSubmitModalCloseHandle = () => {
    setTransactionModalShow(false);
    Refetch();
  };

  const Refetch = () => {
    // setLoading(true);
    GetTransactionsByType(ETH_C).then((res) => {
      if (res.status === 200) {
        setTransactions(res.data);
        // setLoading(false);
      } else {
        // setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? { SUCCESS_S } : { ERROR_S },
          message: res.message,
        });
      }
    });
  };

  return (
    <>
      <div className={MXAUTO_W95}>
        {loading ? (
          <Loader />
        ) : (
          <>
            {transactionModalShow && (
              <EthTransactionSubmitModal
                transactionSubmitModalCloseHandle={
                  transactionSubmitModalCloseHandle
                }
                transactionModalShow={transactionModalShow}
                transactionData={transactionData}
              />
            )}
            <div className="filter-div row">
              <div className="col-lg-3 col-sm-6 col-12 mb-3">
                <h5>Filter Tx Status</h5>
                <div className="tag-filter">
                  <Tags SetFilterData={SetFilterData} />
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 mb-3">
                <h5>Tx Date Range</h5>
                <div className="filter-input">
                  <StaticDateRangePickerDemo SetFilterData={SetFilterData} />
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 mb-3">
                <h5 className="mb-0">Tokens Quantity</h5>
                <div className="p-2 range-bar">
                  <RangeSlider SetFilterData={SetFilterData} />
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 mb-3">
                <button
                  className="btn btn-primary mt-btn filter-loader"
                  onClick={GetFilteredData}
                >
                  {loadingFilter ? (
                    <ClipLoader color="white" className="loader" />
                  ) : (
                    <span>Apply Filters</span>
                  )}
                </button>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 mb-3">
                {/* <button
                  className="btn btn-primary mt-btn filter-loader"
                  onClick={ResetFilters}
                >
                  <span>Reset Filters</span>
                </button> */}
              </div>
            </div>
            <div className={MT_3}>
              <EthTable
                tableHeading={ETHEREUM_TABLE_HEADING}
                searchPlaceHolder={ETHEREUM_SEARCH_PLACEHOLDER}
                loading={loading}
                data={transactions}
                transactionSubmitModalShowHandle={
                  transactionSubmitModalShowHandle
                }
                TableRow={TableRows}
                createRows={createRows}
                headings={EthereumTableHeadings}
                tostify={tostify}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Erthereum;
