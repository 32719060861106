import Card from "@mui/material/Card";
import { useState, useContext } from "react";
import GenericForm from "../Comps-Utils/GenericForm";
import { ToasterContext } from "../../App";
import { UpdatePassword } from "../../Services/Admin/Admin";
import {
  ChangePasswordInitialValues,
  AdminChangePasswordFormFields,
  PasswordSchema,
} from "./helpers";
const PasswordChange = () => {
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [id, setId] = useState();
  const UpdatePasswordService = (data, actions) => {
    tostify.dismissAll();
    UpdatePassword(data).then((res) => {
      if (res.status === 200 && res.data === 200) {
        actions.resetForm();
      }
      actions.setSubmitting(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.data === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  return (
    <>
      <Card className="mx-auto w95">
        <>
          <GenericForm
            key={"password-form" + Math.random().toString(36).substring(7)}
            id={id}
            setId={setId}
            heading={"Password"}
            general={[]}
            initialValues={ChangePasswordInitialValues}
            schema={PasswordSchema}
            formFields={AdminChangePasswordFormFields}
            update={UpdatePasswordService}
            tostify={tostify}
          />
        </>
      </Card>
    </>
  );
};
export default PasswordChange;
