import React from "react";
import PreSale from "../../../components/PreSale/presale";
const PreSalePage = () => {
  return (
    <>
      <PreSale />
    </>
  );
};

export default PreSalePage;
