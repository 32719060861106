import React from "react";
import Dashboard from "../../../components/Dashboard";
const DashboardPage = () => {
  return (
    <>
      <Dashboard />
    </>
  );
};

export default DashboardPage;
