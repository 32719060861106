import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ErrorMessage } from "formik";
const CountrySelect = (props) => {
  const { fieldName, values, touched, errors, setFieldValue } = props;
  return (
    <Autocomplete
      // sx={{border:"1px solid #c4c4c4"}}
      id="country-select-demo"
      value={values[fieldName]}
      options={values.roles}
      size="small"
      autoHighlight
      getOptionLabel={(option) => option}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {option}{" "}
        </Box>
      )}
      renderInput={(params) => (
        <>
          {/* <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="first-name-input"
            name={fieldName}
            defaultValue=""
            onChange={handleChange}
            placeholder={place}
            value={values[fieldName]}
            error={touched[fieldName] && errors[fieldName]}
          /> */}
          <TextField
            className="small-input"
            name={fieldName}
            variant="outlined"
            error={touched.fieldName && errors.fieldName}
            {...params}
            label="Choose a Role"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
          <ErrorMessage
            name={fieldName}
            component="div"
            className={"error-text"}
          />
        </>
      )}
      onInputChange={(event, newInputValue) => {}}
      onChange={(event, value) => {
        setFieldValue(fieldName, value);
      }}
    />
  );
};
export default CountrySelect;
