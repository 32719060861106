import React from "react";
import Skeleton from "@mui/material/Skeleton";
function SkeletonClaim() {
  const claimArr = [1, 2, 3, 4, 5];
  return (
    <div>
      {claimArr.map((item, index) => {
        return (
          <div className="single-timeline-area bg-white" key={index}>
            <div
              className="timeline-date wow fadeInLeft"
              data-wow-delay="0.1s"
              style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInLeft",
              }}
            >
              <Skeleton
                variant="text"
                width={60}
                sx={{ bgcolor: "#f64b1ca6" }}
              />
            </div>
            <div className="row m-0">
              <div className="col-12 col-md-12 col-lg-12 pl-2  claim-box">
                <div
                  className="single-timeline-content d-flex wow fadeInLeft"
                  data-wow-delay="0.7s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.7s",
                    animationName: "fadeInLeft",
                    border: "1px solid #ff000038",
                  }}
                >
                  <Skeleton
                    variant="circular"
                    width={30}
                    height={30}
                    sx={{ bgcolor: "#f64b1ca6", marginRight: "30px" }}
                  />

                  <div className="timeline-text w-75">
                    <Skeleton variant="text" sx={{ bgcolor: "#f64b1ca6" }} />
                    {/* <p className='mt-2  mr-2'>
                                <Clock date={item.time1} />
                              </p> */}
                    <p className="">
                      <Skeleton variant="text" sx={{ bgcolor: "#f64b1ca6" }} />
                    </p>
                  </div>

                  {/* <Skeleton variant='text' height={100} width={100} sx={{ bgcolor: '#f64b1ca6' }} /> */}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default SkeletonClaim;
