import Switch from "../../../Comps-Utils/Switch";
// import { serverUrl } from "../../../../Config/config";
import Avatar from "@mui/material/Avatar";
import ActionGroup from "../../../Comps-Utils/ActionsButtonGroup";
const TableRow = (props) => {
  const {
    updateKycStatus,
    deleteRow,
    row,
    index,
    setActionState,
    handleOpen,
    setUpdatedRow,
  } = props;
  return (
    <>
      <tr key={row.id + index} id={"user-profile-tr-" + index}>
        <td className="px-4 text-left d-flex">
          <Avatar
            src={
              row.profileImgUrl === ""
                ? "images/user-thumb-lg.png"
                : row.profileImgUrl
            }
            sx={{ width: 32, height: 32 }}
          ></Avatar>
          <span className="ml-2">{row.name}</span>
        </td>
        <td className="px-4 text-left">
          <span>{row.designation}</span>
        </td>
        <td className="text-left">
          <span className="mt-2">
            {" "}
            <Switch
              status={row.status}
              id={row.id}
              type={row.type}
              service={updateKycStatus}
            />
          </span>
        </td>
        <td className="text-left table-btn-group">
          <ActionGroup
            handleOpen={handleOpen}
            setUpdatedRow={setUpdatedRow}
            setActionState={setActionState}
            row={row}
            deleteRow={deleteRow}
          />
        </td>
      </tr>
    </>
  );
};
export default TableRow;
