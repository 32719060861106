import React, { useState, useEffect } from "react";
import { Collapse } from "@material-ui/core";
import { NavLink, Link } from "react-router-dom";
import {
  AdminSideBarProfileSubMenu,
  AdminSideBarCMSSubMenu,
  AdminSideBarCMSSubMenuFaqs,
  AdminSideBarCMSSubMenuSlider,
  AdminSideBarCMSSubMenuManageTeam,
  AdminSideBarCMSSubMenuTimeline,
} from "../../../helpers/adminSideMenuOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const MainMenuItems = ({ item, open, openCMS, setOpen, setOpenCMS, index }) => {
  return (
    <>
      <li
        key={index + Math.random().toString(36).substring(7)}
        className={
          (item.title === "Profile" && open) ||
          (item.title === "CMS" && openCMS)
            ? "nav-main-item open"
            : "nav-main-item"
        }
      >
        {item.title === "Profile" || item.title === "CMS" ? (
          <span
            onClick={
              item.title === "Profile"
                ? () => {
                    setOpen(!open);
                  }
                : item.title === "CMS"
                ? () => setOpenCMS(!openCMS)
                : null
            }
            className={"nav-main-link nav-main-link-submenu"}
            aria-haspopup="true"
            aria-expanded="false"
          >
            <FontAwesomeIcon icon={item.icon} className="mr-3 font-size-h3" />
            <span className="nav-main-link-name text-white">{item.title}</span>
          </span>
        ) : (
          <NavLink
            activeClassName="active"
            className={"nav-main-link "}
            aria-haspopup="true"
            aria-expanded="false"
            to={item.url}
          >
            <FontAwesomeIcon icon={item.icon} className="mr-3 font-size-h3" />
            {/* <i className={iconClass + " " + item.icon}></i> */}
            <span className="nav-main-link-name text-white">{item.title}</span>
          </NavLink>
        )}
      </li>
    </>
  );
};
const SubMenuItems = ({ open, options }) => {
  return (
    <>
      {options.map((item, index) => {
        return (
          <Collapse
            key={
              "item.title" +
              Math.random().toString(36).substring(7) +
              "collpase"
            }
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <li className="nav-main-item submenu-child fffdd">
              <NavLink
                className="nav-main-link"
                activeClassName="active"
                data-toggle="submenu"
                aria-haspopup="true"
                aria-expanded="false"
                to={item.url}
              >
                {/* <Link to={item.url}> */}
                {/* <i className={iconClass + " " + item.icon}></i> */}
                <FontAwesomeIcon
                  icon={item.icon}
                  className="ml-4 font-size-h3"
                />
                <span className="nav-main-link-name ml-2 text-white">
                  {item.title}
                </span>
                {/* </Link> */}
              </NavLink>
            </li>
          </Collapse>
        );
      })}
    </>
  );
};
const CMSSubMenuItems = ({ openCMS }) => {
  const [openFaqs, setOpenFaqs] = React.useState(false);
  const [openSlider, setOpenSlider] = React.useState(false);
  const [openTeam, setOpenTeam] = React.useState(false);
  const [openTimeLine, setOpenTimeLine] = React.useState(false);
  let subCmsTeamArray = ["/team", "/leadership", "/advisor"];
  let subCmsTimelineArray = ["/timeline", "/timelinelisting"];
  let subSliderArray = ["/slider", "/listing"];
  let subCmsFaqsArray = ["/faqs", "/general", "/preico", "/tokenlisting"];

  useEffect(() => {
    if (subCmsFaqsArray.includes(window.location.pathname)) {
      setOpenFaqs(true);
      setOpenTeam(false);
      setOpenSlider(false);
      setOpenTimeLine(false);
    } else if (subCmsTeamArray.includes(window.location.pathname)) {
      setOpenFaqs(false);
      setOpenTeam(true);
      setOpenSlider(false);
      setOpenTimeLine(false);
    } else if (subSliderArray.includes(window.location.pathname)) {
      setOpenFaqs(false);
      setOpenTeam(false);
      setOpenSlider(true);
      setOpenTimeLine(false);
    } else if (subCmsTimelineArray.includes(window.location.pathname)) {
      setOpenFaqs(false);
      setOpenTeam(false);
      setOpenSlider(false);
      setOpenTimeLine(true);
    }
    // eslint-disable-next-line
  }, [window.location]);

  return (
    <>
      {AdminSideBarCMSSubMenu.map((item, index) => {
        return (
          <Collapse
            key={
              "collapse" + item.title + Math.random().toString(36).substring(7)
            }
            in={openCMS}
            timeout="auto"
            unmountOnExit
          >
            <li
              key={item.title + Math.random().toString(36).substring(7)}
              className={
                (item.title === "FAQS" && openFaqs) ||
                (item.title === "Slider" && openSlider) ||
                (item.title === "Manage Team" && openTeam) ||
                (item.title === "Timeline" && openTimeLine)
                  ? "nav-main-item open"
                  : "nav-main-item"
              }
            >
              {item.title === "FAQS" ||
              item.title === "Slider" ||
              item.title === "Timeline" ||
              item.title === "Manage Team" ? (
                <span
                  className={
                    item.title === "FAQS" ||
                    item.title === "Slider" ||
                    item.title === "Timeline" ||
                    item.title === "Manage Team"
                      ? "nav-main-link nav-main-link-submenu"
                      : "nav-main-link "
                  }
                  onClick={
                    item.title === "FAQS"
                      ? () => setOpenFaqs(!openFaqs)
                      : item.title === "Slider"
                      ? () => setOpenSlider(!openSlider)
                      : item.title === "Manage Team"
                      ? () => setOpenTeam(!openTeam)
                      : item.title === "Timeline"
                      ? () => setOpenTimeLine(!openTimeLine)
                      : null
                  }
                  key={index}
                  data-toggle="submenu"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon
                    icon={item.icon}
                    className=" ml-3 font-size-h3"
                  />
                  <span className="nav-main-link-name ml-3 text-white">
                    {item.title}
                  </span>
                </span>
              ) : (
                <NavLink
                  as={Link}
                  activeClassName="active"
                  className={
                    item.title === "FAQS" ||
                    item.title === "Slider" ||
                    item.title === "Timeline" ||
                    item.title === "Manage Team"
                      ? "nav-main-link nav-main-link-submenu"
                      : "nav-main-link "
                  }
                  key={index}
                  data-toggle="submenu"
                  aria-haspopup="true"
                  aria-expanded="false"
                  to={item.url}
                >
                  {/* <i className={iconClass + " " + item.icon}></i> */}
                  <FontAwesomeIcon
                    icon={item.icon}
                    className=" ml-3 font-size-h3"
                  />
                  <span className="nav-main-link-name ml-3 text-white">
                    {item.title}
                  </span>
                </NavLink>
              )}
            </li>
            {item.title === "FAQS" && (
              <SubMenuItems
                key={Math.random().toString(36).substring(7)}
                open={openFaqs}
                options={AdminSideBarCMSSubMenuFaqs}
              />
            )}
            {item.title === "Slider" && (
              <SubMenuItems
                key={Math.random().toString(36).substring(7)}
                open={openSlider}
                options={AdminSideBarCMSSubMenuSlider}
              />
            )}
            {item.title === "Manage Team" && (
              <SubMenuItems
                key={Math.random().toString(36).substring(7)}
                open={openTeam}
                options={AdminSideBarCMSSubMenuManageTeam}
              />
            )}
            {item.title === "Timeline" && (
              <SubMenuItems
                key={Math.random().toString(36).substring(7)}
                open={openTimeLine}
                options={AdminSideBarCMSSubMenuTimeline}
              />
            )}
          </Collapse>
        );
      })}
    </>
  );
};
const SidePanel = (props) => {
  const { sidebarMain } = props;
  const [open, setOpen] = useState(false);
  const [openCMS, setOpenCMS] = React.useState(false);
  useEffect(() => {
    if (
      window.location.pathname === "/password" ||
      window.location.pathname === "/profile"
    ) {
      setOpen(true);
    }
    let cmsArray = [
      "/banner",
      "/logos",
      "/aboutico",
      "/tokens",
      "/documents",
      "/services",
      "/timeline",
      "/timelinelisting",
      "/contactus",
      "/why",
      "/slider",
      "/listing",
      "/team",
      "/leadership",
      "/advisor",
      "/faqs",
      "/general",
      "/preico",
      "/tokenlisting",
      "/team",
      "/leadership",
      "/advisor",
    ];
    if (cmsArray.includes(window.location.pathname)) {
      setOpenCMS(true);
    }
    //eslint-disable-next-line
  }, [window.location]);

  return (
    <>
      <div
        className="bg-header-dark"
        style={{ width: "100%", borderBottom: "1px solid rgb(98 70 57)" }}
      >
        <div className="content-header bg-dark site-logo">
          <NavLink to={"/"} style={{ paddingLeft: "12px" }}>
            <img
              src="images/logo.png"
              className="img-fluid"
              srcSet="images/logo-white.svg"
              alt="icon"
            />
          </NavLink>
        </div>
      </div>
      <div>
        <div className="content-side content-side-full">
          <ul className="nav-main">
            {sidebarMain.map((item, index) => {
              return (
                <>
                  {item ? (
                    <MainMenuItems
                      key={
                        item.title +
                        index +
                        Math.random().toString(36).substring(7)
                      }
                      item={item}
                      open={open}
                      setOpen={setOpen}
                      openCMS={openCMS}
                      setOpenCMS={setOpenCMS}
                      index={index}
                    />
                  ) : null}
                  {item.title === "CMS" && (
                    <CMSSubMenuItems
                      key={item.title + Math.random().toString(36).substring(7)}
                      openCMS={openCMS}
                    />
                  )}
                  {item.title === "Profile" && (
                    <SubMenuItems
                      key={item.title + Math.random().toString(36).substring(7)}
                      // key={Math.random().toString(36).substring(7)}
                      open={open}
                      options={AdminSideBarProfileSubMenu}
                    />
                  )}
                </>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
export default SidePanel;
