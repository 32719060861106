import { useState } from "react";

// import TextField50 from "../Comps-Utils/Textfield50";
// import TextFiledLabel from "../Comps-Utils/TextfieldLabelRequired";
// import { ConnectedFocusError } from "focus-formik-error";
// import { GeneralFormFields } from "./helpers";
// import Uploader from "../Comps-Utils/ProfileUploaderField";
import Uploader from "../../components/Kyc/upload";

// import { Card, Button } from "@material-ui/core";
import { Collapse } from "@material-ui/core";
const KycImage = ({
  upload,
  upload1,
  setUpload,
  setUpload1,
  setUploadPassport,
  uploadPassport,
  setUploadCnicFront,
  uploadCnicFront,
  setUploadCnicBack,
  uploadCnicBack,
  setUploadLicense,
  uploadLicense,
  imageType,
  setImageType,
  errors,
  kycDocs,
}) => {
  const [openPassport, setOpenPassport] = useState(true);
  const [openCnic, setOpenCnic] = useState(false);
  const [openDriver, setOpenDriver] = useState(false);

  return (
    <>
      <div className="from-step-item">
        <div className="from-step-heading">
          <div className="from-step-number">02</div>
          <div className="from-step-head">
            <h4>Step 2 : Verify identity</h4>
            <p>Upload documents to verify your indentity.</p>
          </div>
        </div>
        <div className="from-step-content">
          <div className="note note-md note-info note-plane">
            <em className="fas fa-info-circle"></em>
            <p>Please upload any of the following personal documents.</p>
          </div>
          <span className="error1">{errors}</span>
          <div className="gaps-2x"></div>
          <ul className="nav nav-tabs nav-tabs-bordered" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                onClick={() => {
                  setOpenPassport(true);
                  setOpenCnic(false);
                  setOpenDriver(false);
                  // setImageType("passport");
                }}
                href="#passport"
              >
                <div className="nav-tabs-icon">
                  <img
                    src="images/icon-passport.png"
                    alt="icon"
                    onError={(e) =>
                      (e.target.onerror = null)(
                        (e.target.src = "images/vector-id-front.png")
                      )
                    }
                  />
                  <img
                    src="images/icon-passport-color.png"
                    alt="icon"
                    onError={(e) =>
                      (e.target.onerror = null)(
                        (e.target.src = "images/vector-id-front.png")
                      )
                    }
                  />
                </div>
                <span>Passport</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                onClick={() => {
                  setOpenPassport(false);
                  setOpenCnic(true);
                  setOpenDriver(false);
                  // setImageType("cnic");
                }}
                href="#national-card"
              >
                <div className="nav-tabs-icon">
                  <img
                    src="images/icon-national-id.png"
                    alt="icon"
                    onError={(e) =>
                      (e.target.onerror = null)(
                        (e.target.src = "images/vector-id-front.png")
                      )
                    }
                  />
                  <img
                    src="images/vector-id-front.png"
                    alt="icon"
                    onError={(e) =>
                      (e.target.onerror = null)(
                        (e.target.src = "images/vector-id-front.png")
                      )
                    }
                  />
                </div>
                <span>National Card</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#driver-licence"
                onClick={() => {
                  setOpenPassport(false);
                  setOpenCnic(false);
                  setOpenDriver(true);
                  // setImageType("licence");
                  // setUpload([]);
                  // setUpload1([]);
                }}
              >
                <div className="nav-tabs-icon">
                  <img
                    src="images/icon-licence.png"
                    alt="icon"
                    onError={(e) =>
                      (e.target.onerror = null)(
                        (e.target.src = "images/vector-id-front.png")
                      )
                    }
                  />
                  <img
                    src="images/vector-id-front.png"
                    alt="icon"
                    onError={(e) =>
                      (e.target.onerror = null)(
                        (e.target.src = "images/vector-id-front.png")
                      )
                    }
                  />
                </div>
                <span>Driver’s License</span>
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <Collapse in={openPassport} timeout="auto" unmountOnExit>
              <div className="tab-pane fade show active" id="passport">
                <h5 className="kyc-upload-title">
                  To avoid delays when verifying account, Please make sure
                  bellow:
                </h5>
                <ul className="kyc-upload-list">
                  <li>Chosen credential must not be expired.</li>
                  <li>
                    Document should be in good condition and clearly visible.
                  </li>
                  <li>Make sure that there is no light glare on the card.</li>
                </ul>
                <div className="gaps-4x"></div>
                <span className="upload-title">
                  Upload Here Your Passport Copy
                </span>
                <div className="row align-items-center">
                  <div className="col-8">
                    <div className="upload-box">
                      <div className="upload-zone">
                        <div className="dz-message" data-dz-message>
                          {/* <span className="dz-message-text">
                            Drag and drop file
                          </span>
                          <span className="dz-message-or">or</span> */}
                          <label className="custom-file-upload">
                            <Uploader
                              upload={uploadPassport}
                              setUpload={setUploadPassport}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="kyc-upload-img">
                      <img
                        src={
                          uploadPassport && uploadPassport.preview
                            ? URL.createObjectURL(uploadPassport)
                            : kycDocs && kycDocs.passport.imageurl
                            ? kycDocs.passport.imageurl
                            : "images/vector-id-front.png"
                        }
                        alt="vector"
                        onError={(e) =>
                          (e.target.onerror = null)(
                            (e.target.src = "images/vector-id-front.png")
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="gaps-1x"></div>
              </div>
            </Collapse>
            <Collapse in={openCnic} timeout="auto" unmountOnExit>
              <>
                <h5 className="kyc-upload-title">
                  To avoid delays when verifying account, Please make sure
                  bellow:
                </h5>
                <ul className="kyc-upload-list">
                  <li>Chosen credential must not be expired.</li>
                  <li>
                    Document should be in good condition and clearly visible.
                  </li>
                  <li>Make sure that there is no light glare on the card.</li>
                </ul>
                <div className="gaps-4x"></div>
                <span className="upload-title">
                  Upload Here Your National id Front Side
                </span>
                <div className="row align-items-center">
                  <div className="col-8">
                    <div className="upload-box">
                      <div className="upload-zone">
                        <div className="dz-message" data-dz-message>
                          {/* <span className="dz-message-text">
                            Drag and drop file
                          </span>
                          <span className="dz-message-or">or</span>
                          <button className="btn btn-primary">SELECT</button> */}
                          <label className="custom-file-upload">
                            <Uploader
                              upload={uploadCnicFront}
                              setUpload={setUploadCnicFront}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="kyc-upload-img">
                      <img
                        src={
                          uploadCnicFront && uploadCnicFront.preview
                            ? URL.createObjectURL(uploadCnicFront)
                            : kycDocs && kycDocs.nationalId.frontimage
                            ? kycDocs.nationalId.frontimage
                            : "images/vector-id-front.png"
                        }
                        alt="vector"
                        onError={(e) =>
                          (e.target.onerror = null)(
                            (e.target.src = "images/vector-id-front.png")
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="gaps-3x"></div>
                <span className="upload-title">
                  Upload Here Your National id Back Side
                </span>
                <div className="row align-items-center">
                  <div className="col-8">
                    <div className="upload-box">
                      <div className="upload-zone">
                        <div className="dz-message" data-dz-message>
                          {/* <span className="dz-message-text">
                            Drag and drop file
                          </span>
                          <span className="dz-message-or">or</span>
                          <button className="btn btn-primary">SELECT</button> */}
                          <label className="custom-file-upload">
                            <Uploader
                              upload={uploadCnicBack}
                              setUpload={setUploadCnicBack}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="kyc-upload-img">
                      <img
                        src={
                          uploadCnicBack && uploadCnicBack.preview
                            ? URL.createObjectURL(uploadCnicBack)
                            : kycDocs && kycDocs.nationalId.backimage
                            ? kycDocs.nationalId.backimage
                            : "images/vector-id-front.png"
                        }
                        alt="vector"
                        onError={(e) =>
                          (e.target.onerror = null)(
                            (e.target.src = "images/vector-id-front.png")
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="gaps-1x"></div>
              </>
            </Collapse>
            <Collapse in={openDriver} timeout="auto" unmountOnExit>
              <>
                <h5 className="kyc-upload-title">
                  To avoid delays when verifying account, Please make sure
                  bellow:
                </h5>
                <ul className="kyc-upload-list">
                  <li>Chosen credential must not be expired.</li>
                  <li>
                    Document should be in good condition and clearly visible.
                  </li>
                  <li>Make sure that there is no light glare on the card.</li>
                </ul>
                <div className="gaps-4x"></div>
                <span className="upload-title">
                  Upload Here Your Driving Licence Copy
                </span>
                <div className="row align-items-center">
                  <div className="col-8">
                    <div className="upload-box">
                      <div className="upload-zone">
                        <div className="dz-message" data-dz-message>
                          {/* <span className="dz-message-text">
                            Drag and drop file
                          </span> */}
                          {/* <span className="dz-message-or">or</span> */}
                          <label className="custom-file-upload">
                            <Uploader
                              upload={uploadLicense}
                              setUpload={setUploadLicense}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="kyc-upload-img">
                      <img
                        src={
                          uploadLicense && uploadLicense.preview
                            ? URL.createObjectURL(uploadLicense)
                            : kycDocs && kycDocs.license.imageurl
                            ? kycDocs.license.imageurl
                            : "images/vector-id-front.png"
                        }
                        alt="vector"
                        onError={(e) =>
                          (e.target.onerror = null)(
                            (e.target.src = "images/vector-id-front.png")
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="gaps-1x"></div>
              </>
            </Collapse>
          </div>
          <div className="gaps-2x"></div>
        </div>
      </div>
    </>
  );
};
export default KycImage;
