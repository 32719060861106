import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./Checkout";
const StripePayment = ({ purchase }) => {
  const stripe = loadStripe(
    // "pk_test_51LaOFhIeiadi6nHxvgB83edACCvArDOi2jWvFu82vBQulcAB1jMdF7ViiNlZhZO7iiJpZ3BMNcA9iE9ghqsteMeg00s1z2rgnf"
    process.env.REACT_APP_STRIPE_CLIENT
  );
  return (
    <Elements stripe={stripe}>
      <CheckoutForm purchase={purchase} />
    </Elements>
  );
};
export default StripePayment;
