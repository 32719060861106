import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const TableRow = (props) => {
  const {
    handleImageOpen,
    handleOpen,
    setUpdatedRow,
    setActionState,
    row,
    deleteRow,
    handleAirdrops,
    handleTemplateOpen,
    transactionSubmitModalShowHandle,
  } = props;
  return (
    <>
      <ButtonGroup
        className="p2"
        variant="contained"
        aria-label="primary button group"
      >
        {handleAirdrops ? (
          <Button
            onClick={() => {
              handleAirdrops();
            }}
            className="p-0-4 bg-view"
            variant="contained"
            size="small"
          >
            {/* <FontAwesomeIcon icon={['fab', 'apple']} /> */}
            <FontAwesomeIcon icon="fa-solid fa-paper-plane" />
          </Button>
        ) : null}
        {handleImageOpen ? (
          <Button
            disabled={props && props.disKyc === false}
            onClick={() => {
              handleImageOpen();
            }}
            className={
              props && props.disKyc === false
                ? "p-0-4"
                : "p-0-4 bg-xinspire-light"
            }
            variant="contained"
            size="small"
          >
            <PreviewIcon />
          </Button>
        ) : null}
        {handleTemplateOpen ? (
          <Button
            disabled={props && props.disKyc === false}
            onClick={() => {
              handleTemplateOpen();
            }}
            className={
              props && props.disKyc === false
                ? "p-0-4"
                : "p-0-4 bg-xinspire-light"
            }
            variant="contained"
            size="small"
          >
            <PreviewIcon />
          </Button>
        ) : null}
        {handleOpen ? (
          <Button
            disabled={props && props.disKycEdit === false}
            onClick={() => {
              handleOpen();
              setActionState("edit");
              setUpdatedRow(row);
            }}
            className="p-0-4 bg-xinspire-light"
            variant="contained"
            size="small"
          >
            <EditIcon />
          </Button>
        ) : null}
        {deleteRow ? (
          <Button
            disabled={props && props.disKycDelete === false}
            onClick={() => {
              deleteRow(row.id, row.email);
            }}
            className={
              props && props.disKycDelete === false
                ? "p-0-4"
                : "p-0-4 bg-alternet"
            }
            variant="contained"
            size="small"
          >
            <DeleteIcon />
          </Button>
        ) : null}
        {transactionSubmitModalShowHandle ? (
          <Button
            disabled={props && props.disTransactionsubmit === false}
            onClick={() => {
              transactionSubmitModalShowHandle(row);
            }}
            className={
              props && props.disTransactionsubmit === false
                ? "p-0-4"
                : "p-0-4 bg-alternet"
            }
            variant="contained"
            size="small"
          >
            <AccountBalanceWalletIcon />
          </Button>
        ) : null}
      </ButtonGroup>
    </>
  );
};
export default TableRow;
