const TextFiledLabel = (props) => {
  const { field, widthclass } = props;
  return (
    <>
      <div className={widthclass}>
        <label>{field}</label>
      </div>
    </>
  );
};
export default TextFiledLabel;
