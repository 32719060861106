import { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
const RadioActiveInactive = (props) => {
  const { setFieldValue, values } = props;
  const [value, setValue] = useState(values.status ? "active" : "in progress");
  useEffect(() => {
    setValue(values.status ? "active" : "in progress");
  }, [values]);
  const handleChange = (event) => {
    setFieldValue("status", event.target.value === "active" ? true : false);
    setValue(event.target.value);
  };
  return (
    <>
      <FormControl className="col-md-8 pl-3">
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            className="me-4 radio-btn"
            value="active"
            control={<Radio />}
            label="Active"
          />
          <FormControlLabel
            value="in progress"
            control={<Radio />}
            label="In Progress"
          />
        </RadioGroup>
      </FormControl>
    </>
  );
};
export default RadioActiveInactive;
