import Listing from "./teamListingRow";
const Team = (props) => {
  const { team, leadershipListing } = props;
  // eslint-disable-next-line
  return (
    <>
      <section className="section bg-white overspill-inherit" id="team">
        <Listing
          head={team.mainhead}
          description={team.description}
          team={leadershipListing}
        />
        {/* <Listing head={team.mainhead2} description={team.description2} team={advisorListing} /> */}
      </section>
    </>
  );
};
export default Team;
