import { ClimbingBoxLoader } from "react-spinners";
const Loader = (props) => {
  return (
    <>
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <ClimbingBoxLoader color={"#2f4858"} loading={true} />
        </div>
        <div className="text-muted font-size-xl text-center pt-3">Loading</div>
      </div>
    </>
  );
};
export default Loader;
