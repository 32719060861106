import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { WalletContext } from "../../App";
// import { Link } from "react-router-dom";

function WalletPromptModal({
  showWalletModal,
  notUpdateWallet,
  yesUpdateWallet,
  showWalletInfo,
}) {
  const [walletAddress] = useContext(WalletContext);
  return (
    <div>
      <Modal
        show={showWalletModal}
        backdrop="static"
        onHide={notUpdateWallet}
        className="bitcoin-modal d-flex align-items-center auto-height-modal"
      >
        <Modal.Header className="align-items-baseline" closeButton>
          <h3 className=" text-center">{showWalletInfo.heading}</h3>
        </Modal.Header>
        <Modal.Body>
          <p className="text-primary">
            {/* The connected account (wallet address) does not match the
            information we have on file. If you would like to switch to this
            account for future Recruit Coin transactions, please update your
            Wallet Address. Please note that previous transactions are permanent
            and cannot be modified. */}
            {showWalletInfo.message}
          </p>
          <p className="text-primary">{walletAddress}</p>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={closeWalletModal}>
            Close
          </Button> */}
          {/* <Link
            to={"/account?tab=wallet-address"}
            className="btn btn-secondary"
            onClick={closeWalletModal}
          >
            Wallet Settings
          </Link> */}
          <Button variant="primary" onClick={notUpdateWallet}>
            NO
          </Button>
          <Button variant="primary" onClick={yesUpdateWallet}>
            YES
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default WalletPromptModal;
