import React from "react";
import Profile from "../../../components/Profile";
const ProfilePage = () => {
  return (
    <>
      <Profile />
    </>
  );
};

export default ProfilePage;
