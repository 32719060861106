import moment from "moment";
import ActionGroup from "../Comps-Utils/ActionsButtonGroup";
import Switch from "../Comps-Utils/Switch";
import { ADMINS, MMMM_Do_YYYY, h_mm_ss_a } from "../../helpers/constants";
import {
  TEXT_LEFT__TEXT_CAPITALIZE,
  TEXT_LEFT,
  PX_4,
} from "../../helpers/CLASSES";
const TableRow = (props) => {
  const {
    handleOpen,
    row,
    setUpdatedRow,
    index,
    setActionState,
    updateUserStatus,
    deleteRow,
  } = props;
  return (
    <>
      <tr key={row.id + index} id={ADMINS + "-tr-" + index}>
        <td className={TEXT_LEFT__TEXT_CAPITALIZE}>
          <span>{row.userId}</span>
        </td>
        <td className={TEXT_LEFT}>
          <span>{row.name}</span>
          <span style={{ fontSize: "10px", textTransform: "initial" }}>
            {row.email}
          </span>
        </td>
        <td className={TEXT_LEFT}>
          <span>{row.permissions}</span>
        </td>
        <td className={PX_4 + " " + TEXT_LEFT}>
          <span>{moment(row.lastLogin).format(MMMM_Do_YYYY)}</span>
          <span> {moment(row.lastLogin).format(h_mm_ss_a)}</span>
        </td>
        <td className={TEXT_LEFT}>
          <Switch
            status={row.userstatus}
            id={row.id}
            service={updateUserStatus}
          />
        </td>
        <td className={`${TEXT_LEFT} table-btn-group `}>
          <>
            <ActionGroup
              className="table-btn-group"
              handleOpen={handleOpen}
              setUpdatedRow={setUpdatedRow}
              setActionState={setActionState}
              row={row}
              deleteRow={deleteRow}
            />
          </>
        </td>
      </tr>
    </>
  );
};
export default TableRow;
