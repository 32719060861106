import Card from "@mui/material/Card";
import { useState, useContext, useEffect } from "react";
import { ToasterContext } from "../../App";
import { useProvider, useSigner } from "wagmi";
import { SetMerkleRootforPresale } from "../../Services/User/web3Calls/contractCall";
import Web3 from "web3";
import {
  AddPreSaleUsers,
  DeletePreSaleUsers,
  GetPreSaleUsers,
} from "../../Services/User/Presale/presale";
import { ClipLoader } from "react-spinners";
const { MerkleTree } = require("merkletreejs");
window.Buffer = window.Buffer || require("buffer").Buffer;

const SHA256 = require("keccak256");

function PreSaleUser() {
  // wagmi multi wallet
  const { data: signerData } = useSigner();
  const provider = useProvider();

  //
  const [tostify, setTostify] = useContext(ToasterContext);
  const [loading, setLoading] = useState(false);
  // const [id, setId] = useState()
  const [address, setAddress] = useState("");
  const [list, setList] = useState([]);

  useEffect(() => {
    fetchPresaleUsers();
  }, []);
  const fetchPresaleUsers = async () => {
    const data = await GetPreSaleUsers();
    setList(data.data[0].address);
  };
  const handleChange = (e) => {
    setAddress(e.target.value);
  };
  const GenerateMerkle = async () => {
    const whitelisters = list;

    const leaves = whitelisters.map((x) => SHA256(x));
    const tree = new MerkleTree(leaves, SHA256, { sortPairs: true });
    const root = tree.getRoot().toString("hex");
    const byte32Str = "0x" + root;

    await SetMerkleRootforPresale(byte32Str, signerData, provider)
      .then((res) => {
        tostify.dismissAll();
      })
      .catch((err) => {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "error",
          message: "Error : " + err?.reason,
        });
      });
    //here we call merkle tree generate function
    //here we call contract function
  };

  const AddAddress = async () => {
    if (Web3.utils.isAddress(address)) {
      setLoading(true);
      // let id=
      const list1 = [...list];

      list1.push(address);
      const data = await AddPreSaleUsers({ address: address });
      setLoading(false);
      if (data.code === 200) {
        setList(data.data[0].address);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "error",
          message: data.message,
        });
      }
    } else {
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: "error",
        message: "Invalid Wallet Address",
      });
    }
  };
  const RemoveAddress = async (address) => {
    const data = await DeletePreSaleUsers({ address: address });
    if (data.code === 200) {
      setList(data.data[0].address);
    } else {
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: "error",
        message: data.message,
      });
    }
  };
  return (
    <Card className="mx-auto w95">
      <div className="pre-whitelist">
        <h2 className="text-white">Presale Whitelisting</h2>
      </div>
      <div className="whitelist-input">
        <input
          type="text"
          onChange={handleChange}
          placeholder="Enter address to Whitelist"
        />
        <button onClick={AddAddress} className="btn-primary address-btn">
          {loading ? (
            <ClipLoader className="loader" color={"white"} height={"10px"} />
          ) : (
            <>Add Address</>
          )}
        </button>
      </div>
      <div className="white-container">
        <div className="whitelist-header">
          {" "}
          <h4>Whitelisted</h4>
        </div>
        <ul>
          {list.length > 0 ? (
            <>
              {list.map((item, index) => {
                return (
                  <li
                    key={
                      "presale-lsit-li" +
                      index +
                      Math.random().toString(36).substring(7)
                    }
                    style={{ listStyleType: "decimal" }}
                  >
                    <div className="d-flex justify-content-between">
                      <span className="tag-elipse">{item} </span>
                      <button
                        className="btn btn-primary shadow-none"
                        onClick={() => {
                          RemoveAddress(item);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </li>
                );
              })}
            </>
          ) : (
            <>No Whitelisted Address</>
          )}
        </ul>
      </div>
      <div className="text-start m-4">
        <button onClick={GenerateMerkle} className="btn btn-primary">
          Save Root
        </button>
      </div>
    </Card>
  );
}
export default PreSaleUser;
