import React from "react";
import { Link } from "react-router-dom";

const SignupSuccess = () => {
  return (
    <>
      <div className="user-ath">
        <div className="user-ath-page">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8 text-center">
                <div className="user-ath-logo logo-width">
                  <Link to={""}>
                    <img
                      src="images/logo.png"
                      srcSet="images/logo.png"
                      alt="icon"
                    />
                  </Link>
                </div>
                <div className="user-ath-box">
                  <div className="status status-thank">
                    <div className="status-icon">
                      <em className="ti ti-check"></em>
                    </div>
                    {/* <h2 className="status-text">Thank you!</h2> */}
                    <span className="status-text">
                      Thank you for registering. Please verify your email
                      address to continue.
                    </span>
                  </div>
                </div>
                <div className="gaps-2x"></div>
                <div className="form-note">
                  {" "}
                  Already a member? <Link to={"/login"}>Login</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupSuccess;
