import { useContext, useEffect, useState } from "react";
import ModalPurchase from "./modalpurchase";
import { WalletContext, SaleContext, HardCapLimitContext } from "../../App";
import { useNetwork } from "wagmi";
import Clip from "../../components/Comps-Utils/ClipLoader";
import {
  ApproveToken,
  CheckApproveAmount,
  DepositTokens,
  getBonusWeek,
  getReferralCount,
  GetTokenBalance,
} from "../../Services/User/web3Calls/contractCall";
// import Skeleton from '@mui/material/Skeleton'
import { useQuery } from "react-query";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ModalLoading from "./modalLoading";
import BTCInstruction from "./btcEthInstructionModal";
import axios from "axios";
import { ToasterContext } from "../../App";
import {
  // AddWhiteListAddress,
  // fetchWhiteListAddress,
  GetReferralTransactionInfo,
} from "../../Services/User/Tokens/token";
import { ClipLoader } from "react-spinners";
import SaleEnded from "./saleEnded";
import { ICOTokenName } from "../../Services/User/web3Calls/contractCall";
import ErrorIcon from "@mui/icons-material/Error";
import { GetUserWallet } from "../../Services/User/User/user";
import ModalCredit from "./modalCredit";
import { useProvider } from "wagmi";

import { useSigner } from "wagmi";
import { chains } from "../../Config/networkConfig";
import { Link } from "react-router-dom";
// import { ConnectButton } from "@rainbow-me/rainbowkit";
// import { getSpecificNetworks } from "../../Services/NetworkConfig/networks";
const BuyTokens = () => {
  // eslint-disable-next-line
  const { data: signerData } = useSigner();
  const { chain } = useNetwork();
  const provider = useProvider();
  const [tostify, setTostify] = useContext(ToasterContext);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");

  const [tokenBalance, settokenBalance] = useState("");
  const [saleObj] = useContext(SaleContext);

  // console.log("sale objet in context", saleObj);

  const [walletAddress] = useContext(WalletContext);
  const [hardCapLimit] = useContext(HardCapLimitContext);
  const [btcModal, setBtcModal] = useState(false);
  const [creditCardModal, setCreditCardModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const [referralAddress, setReferralAddress] = useState("0x0000000000000000000000000000000000000000");
  // const [referralCount, setReferralCount] = useState(0);

  const [coinPrice, setCoinPrice] = useState({
    btc: 0,
    ethereum: 0,
  });
  //eslint-disable-next-line
  const [walletNotify, setWalletNotify] = useState(false);
  const local = JSON.parse(localStorage.getItem("user"));
  const [approveToken, setApproveToken] = useState("");

  const [select, setSelected] = useState({
    index: 2,
    name: "USDT",
    price: 1,
    address: "0xE097d6B3100777DC31B34dC2c58fB524C2e76921",
  });
  const [dollar, setDollar] = useState();
  // const [whitelistLoad, setWhitelistLoad] = useState(false);
  const [purchase, setPurchase] = useState({
    userid: local._id,
    username: local.username,
    useremail: local.email,
    firstname: local.firstname,
    lastname: local.lastname,
    tokens: "Recruit",
    amount: dollar,
    from: walletAddress,
    date: Date.now(),
    status: "",
    saletype: "",
    transactionId: "",
    paymentMethod: select.name,
    payabletoken: "",
    bonus: 0.0,
    icoamount: 0.0,
    biddingtokenprice: 0,
    claimtokenprice: 0,
    referralAddress: "0x0000000000000000000000000000000000000000",
    referralCount: 0,
    referralEmail: false,
    networkName: "",
    tokenPrice: 0,
    vesting: "",
  });
  const [EthbtcFlow, setEthBtcFlow] = useState({
    whitelist: false,
    walletAddress: "",
    whitelisterror: "",
    network: "USDT",
  });
  const [rewardToken, setRewardToken] = useState("-");
  useEffect(() => {
    fetchTokenName();
    fetchCoinData(token[Val].name);
    getReferralData();
  }, []);
  const fetchTokenName = async () => {
    const tokenSymbol = await ICOTokenName();
    setRewardToken(tokenSymbol);
  };
  const CheckApprovedAmount = async () => {
    // not btc
    if (
      Val !== 4 &&
      Val !== 5 &&
      Val !== 7 &&
      walletAddress !== "Connect Wallet"
    ) {
      const bal = await GetTokenBalance(select.address, walletAddress);
      settokenBalance(bal);

      const approveBalance = await CheckApproveAmount(select, walletAddress);

      if (parseFloat(approveBalance) > 0) {
        setApproveToken("approved");
      } else {
        setApproveToken("");
      }
    } else {
      setApproveToken("purchased");
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const fetchCoinData = (coinname) => {
    // react query data removed
    if (typeof coinname == "string") {
      const coinname1 = coinname ? coinname : EthbtcFlow.network;
      let coinname1Api =
        coinname1 !== "ethereum" && coinname1 !== "bitcoin"
          ? coinname1
          : coinname1 === "ethereum"
          ? "ETH"
          : "BTC";
      axios
        .get(
          `https://min-api.cryptocompare.com/data/price?fsym=${coinname1Api}&tsyms=USD`
        )
        .then((res) => {
          setCoinPrice({
            ...coinPrice,
            btc: res.data.USD ? res.data.USD : 0,
          });
          return res.data.USD;
        })
        .catch((err) => {});
    }
  };
  const closeCreditModal = () => {
    setCreditCardModal(false);
  };
  // eslint-disable-next-line
  const { data } = useQuery("prices", fetchCoinData);
  const [buyToken, setBuyToken] = useState();

  const [Val, setval] = useState(1);
  const [token, setToken] = useState([
    {
      index: 1,
      name: "DAI",
      price: 1,
      address: "0x534399090DA190a2e1Cf868299A448907f1b2a27",
    },
    {
      index: 2,
      name: "USDT",
      price: 1,
      address: "0xE097d6B3100777DC31B34dC2c58fB524C2e76921",
    },
    {
      index: 3,
      name: "BUSD",
      price: 1,
      address: "0x8301F2213c0eeD49a7E28Ae4c3e91722919B8B47",
    },
    //not working for now
    {
      index: 4,
      name: "TUSD",
      price: 1,
      address: "0xF976a4dc07201F4e0F9FC8f85766344D7a93c456",
    },
    {
      index: 5,
      name: "Bitcoin",
      price: 32200,
      address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
    },
    {
      index: 6,
      name: "Ethereum",
      price: 2500,
      address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
    },
    {
      index: 7,
      name: "Credit Card",
      price: 1,
      address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
    },
  ]);
  useEffect(() => {
    fetchWalletData();
    // eslint-disable-next-line
  }, [walletAddress]);

  const getReferralData = () => {
    GetReferralTransactionInfo(local._id).then(async (res) => {
      // setPurchase({...purchase, referralAddress: res.data})
      const referralCount = await getReferralCount(res.data);
      // setReferralCount(referralCount);
      const bonusWeek = await getBonusWeek();
      const vesting = bonusWeek > 2 ? "6 months" : "3 months";

      setPurchase({
        ...purchase,
        referralCount: referralCount,
        referralAddress: res.data,
        vesting: vesting,
      });
    });
  };

  const handleStableCoinsPurchase = async (e) => {
    e.preventDefault();
    if (walletAddress !== "Connect Wallet") {
      const approveBalance = await CheckApproveAmount(select, walletAddress);
      if (parseFloat(approveBalance) > 0) {
        if (parseFloat(tokenBalance) > dollar) {
          if (buyToken > 0) {
            setErrors("");
            setLoading(true);
            setApproveToken("Approved");
            // check if user has already some trnasaction
            // const address = await GetReferralTransactionInfo(local._id);
            DepositTokens(
              dollar,
              select.index,
              purchase.referralAddress,
              signerData,
              provider
            )
              .then(async (res) => {
                setPurchase({
                  ...purchase,
                  transactionId: res.transactionHash,
                  amount: dollar,
                  from: walletAddress,
                  data: Date.now(),
                  saletype: saleObj.type,
                  biddingtokenprice: coinPrice.btc,
                  claimtokenprice: saleObj.price,
                });
                setLoading(false);
                setShowModal(true);

                const bal = await GetTokenBalance(
                  select.address,
                  walletAddress
                );
                settokenBalance(bal);
              })
              .catch((err) => {
                setLoading(false);
                setTostify({
                  ...tostify,
                  visible: true,
                  key: Math.random().toString(36).substring(7),
                  type: "error",
                  message: "Error " + err?.reason,
                });
              });
          } else {
            setErrors("Value should be equal/greater than 1 ");
          }
        } else {
          setErrors("Not Enough Balance");
        }
      } else {
        setLoading(true);
        ApproveToken(select, signerData, provider)
          .then((res) => {
            setApproveToken("Approved");
            setLoading(false);
          })
          .catch((err) => {
            setTostify({
              ...tostify,
              visible: true,
              key: Math.random().toString(36).substring(7),
              type: "error",
              message: err?.reason,
            });
            setLoading(false);
          });
      }
    } else {
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: "error",
        message: "Connect your Wallet",
      });
    }
  };

  const fetchWalletData = async () => {
    const data = await GetUserWallet();

    if (data.data.walletAddress === "") {
      setWalletNotify(true);
    }
  };
  useEffect(() => {
    CheckApprovedAmount();
    // eslint-disable-next-line
  }, [walletAddress, select]);
  const handleInput = (e) => {
    var { value } = e.target;
    const { bonus, price } = saleObj;
    setBuyToken(value);

    if (value === "" || isNaN(value)) {
      setErrors("enter valid amount");
      setDollar("");
      setPurchase({
        ...purchase,
        amount: 0,
        bonus: 0,
        icoamount: 0,
      });
    } else {
      setErrors("");
      // if not btc
      if (Val === 7) {
        setDollar((value * price).toFixed(5));

        setPurchase({
          ...purchase,
          amount: value,
          bonus: (value * bonus) / 100,
          icoamount: parseFloat(value) + (value * bonus) / 100,
        });
      } else if (Val !== 4 && Val !== 5) {
        setDollar((value * select.price * price).toFixed(5));

        setPurchase({
          ...purchase,
          amount: value,
          tokenPrice: select.price,
          bonus: (value * bonus) / 100,
          icoamount: parseFloat(value) + +(value * bonus) / 100,
        });
      } else {
        setDollar(((value * price) / coinPrice.btc).toFixed(5));
        setPurchase({
          ...purchase,
          amount: value,
          bonus:
            bonus === 0 ||
            bonus === null ||
            bonus === undefined ||
            bonus === Infinity ||
            bonus === "NaN"
              ? 0
              : (bonus / 100) * value,
          icoamount: parseFloat(value) + +(value * bonus) / 100,
        });
      }
    }
  };

  const handlePaymentAmount = (e) => {
    var { value } = e.target;
    const { bonus, price } = saleObj;
    console.log("saleObj", saleObj);
    let paymentValue = 0;
    setDollar(value);

    if (value === "" || isNaN(value)) {
      setErrors("enter valid amount");
      setBuyToken("");
      setPurchase({
        ...purchase,
        amount: 0,
        bonus: 0,
        icoamount: 0,
      });
    } else {
      setErrors("");

      // if not btc
      if (Val === 7) {
        paymentValue = value / price;
        setBuyToken(paymentValue.toFixed(5));

        setPurchase({
          ...purchase,
          amount: paymentValue,
          bonus: (paymentValue * bonus) / 100,
          icoamount: parseFloat(paymentValue) + (paymentValue * bonus) / 100,
        });
      } else if (Val !== 4 && Val !== 5) {
        paymentValue = value / select.price / price;
        setBuyToken(paymentValue.toFixed(5));
        console.log(">>>>>>>>>>>>select price", select.price);
        setPurchase({
          ...purchase,
          tokenPrice: select.price,
          amount: paymentValue,
          bonus: (paymentValue * bonus) / 100,
          icoamount: parseFloat(paymentValue) + +(paymentValue * bonus) / 100,
        });
      } else {
        paymentValue = (value * coinPrice.btc) / price;
        setBuyToken(paymentValue.toFixed(5));

        setPurchase({
          ...purchase,
          amount: paymentValue,
          bonus:
            bonus === 0 ||
            bonus === null ||
            bonus === undefined ||
            bonus === Infinity ||
            bonus === "NaN"
              ? 0
              : (bonus / 100) * paymentValue,
          icoamount: parseFloat(paymentValue) + +(paymentValue * bonus) / 100,
        });
      }
    }
  };
  // ---------------------------
  // handle multiple wallets here
  // ---------------------------

  useEffect(() => {
    fetchChainTokens();
    //eslint-disable-next-line
  }, [chain]);
  const fetchChainTokens = async () => {
    if (chain !== undefined) {
      var keys = Object.values(chains).filter(
        (item) => item.chainId == chain?.id
      );
      if (keys.length > 0) {
        setToken(keys[0].tokens);
        setSelected({
          ...select,
          address: keys[0]?.tokens[0]?.address,
        });
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "error",
          message: (
            <>
              <p>
                Before connecting your wallet, you must change your wallet
                network to the <b>{process.env.REACT_APP_NETWORK_NAME}</b>.
                RecruitCoin is part of the polygon blockchain
              </p>
            </>
          ),
        });
      }
    }
  };

  //

  const handleNetworkChange = async (e, networkVal) => {
    setBuyToken("");
    setDollar("");

    if (networkVal === 7) {
      setApproveToken("purchased");
      setPurchase({
        ...purchase,
        bonus: 0,
        icoamount: 0,
        paymentMethod: "credit",
        amount: 0,
      });
      setval(networkVal);
      setSelected(token[networkVal - 1]);
    } else if (networkVal === 4 || networkVal === 5 || networkVal === 1) {
      setApproveToken("purchased");
      fetchCoinData(networkVal === 4 ? "bitcoin" : "ethereum");
      setval(networkVal);
      setEthBtcFlow({
        EthbtcFlow,
        network:
          networkVal === 4 ? "bitcoin" : networkVal === 1 ? "USDT" : "ethereum",
      });
      setPurchase({
        ...purchase,
        paymentMethod: token[networkVal].name,
        bonus: 0,
        icoamount: 0,
        amount: 0,
      });
      setSelected(token[networkVal]);
    } else {
      console.log("<<<<<<>>>>>>>>>", token[networkVal].name);
      console.log("<<<<<<>>>>>>>>>", token[networkVal].networkVal);
      console.log(
        "<<<<<<>>>>>>>>> address <<>>>>0x367fE170f1D0c00e9C23cA641137490465648f99",
        token[networkVal].address
      );
      fetchCoinData(token[networkVal].name);
      setval(networkVal);
      setSelected(token[networkVal]);
      settokenBalance("");
      const bal = await GetTokenBalance(
        token[networkVal].address,
        walletAddress
      );
      console.log("<<>>>", bal);
      settokenBalance(bal);
      const approveBalance = await CheckApproveAmount(
        token[networkVal],
        walletAddress
      );

      if (parseFloat(approveBalance) > 0) {
        setApproveToken("approved");
      } else {
        setApproveToken("");
      }

      setPurchase({
        ...purchase,
        paymentMethod: token[networkVal].name,
        bonus: 0,
        icoamount: 0,
        amount: 0,
      });
    }
  };

  const handleBtcPurchase = async (e) => {
    e.preventDefault();

    if (dollar <= 0) {
      setErrors("enter valid amount");
    } else if (coinPrice.btc <= 0) {
      setErrors("unable to fetch price");
    } else if (dollar > 0) {
      setErrors("");
      setBtcModal(true);
      setPurchase({
        ...purchase,
        amount: dollar,
        biddingtokenprice:
          EthbtcFlow.network == "USDT" ? token[Val].price : coinPrice.btc,
        claimtokenprice: saleObj.price,
        saletype: saleObj.type,
      });
    } else {
      setErrors("enter valid amount");
    }
    // const transaction = await PostBtcEthTransactions()
  };
  const closeBtcModal = () => {
    setBtcModal(false);
  };

  // const handleWhiteListUser = async (e) => {
  //   e.preventDefault();
  //   setWhitelistLoad(true);

  //   const txRegex = new RegExp(
  //     /\b((bc|tb)(0([ac-hj-np-z02-9]{39}|[ac-hj-np-z02-9]{59})|1[ac-hj-np-z02-9]{8,87})|([13]|[mn2])[a-km-zA-HJ-NP-Z1-9]{25,39})\b/
  //   );
  //   const txEthRegex = new RegExp(/^0x[a-fA-F0-9]{40}$/g);
  //   if (
  //     EthbtcFlow.walletAddress !== "" &&
  //     EthbtcFlow.walletAddress !== undefined &&
  //     txRegex.test(EthbtcFlow.walletAddress) &&
  //     EthbtcFlow.network === "bitcoin"
  //   ) {
  //     setEthBtcFlow({ ...EthbtcFlow, whitelisterror: "" });
  //     const fetch = await AddWhiteListAddress({
  //       userid: local._id,
  //       useremail: local.email,
  //       walletAddress: EthbtcFlow.walletAddress.replace(/\s/g, ""),
  //       network: EthbtcFlow.network,
  //     });

  //     setWhitelistLoad(false);
  //     setTostify({
  //       ...tostify,
  //       visible: true,
  //       key: Math.random().toString(36).substring(7),
  //       type: fetch.code === 200 ? "success" : "error",
  //       message: fetch.message,
  //     });
  //   } else if (
  //     EthbtcFlow.walletAddress !== "" &&
  //     EthbtcFlow.walletAddress !== undefined &&
  //     txEthRegex.test(EthbtcFlow.walletAddress) &&
  //     (EthbtcFlow.network === "ethereum" || EthbtcFlow.network === "USDT")
  //   ) {
  //     setEthBtcFlow({ ...EthbtcFlow, whitelisterror: "" });
  //     let networkName =
  //       EthbtcFlow.network == "bitcoin" ? "bitcoin" : "ethereum";
  //     const fetch = await AddWhiteListAddress({
  //       userid: local._id,
  //       useremail: local.email,
  //       walletAddress: EthbtcFlow.walletAddress.replace(/\s/g, ""),
  //       network: networkName,
  //     });
  //     setWhitelistLoad(false);
  //     setTostify({
  //       ...tostify,
  //       visible: true,
  //       key: Math.random().toString(36).substring(7),
  //       type: fetch.code === 200 ? "success" : "error",
  //       message: fetch.message,
  //     });
  //   } else {
  //     setEthBtcFlow({
  //       ...EthbtcFlow,
  //       whitelisterror: "must be valid whitelist address",
  //     });

  //     setWhitelistLoad(false);
  //   }
  // };
  const handleCreditCard = (e) => {
    e.preventDefault();
    if (dollar <= 0) {
      setErrors("enter valid amount");
    } else if (dollar >= 0.4) {
      setErrors("");

      setPurchase({
        ...purchase,
        amount: dollar,
        biddingtokenprice: 1,
        claimtokenprice: saleObj.price,
        saletype: saleObj.type,
      });
      setCreditCardModal(true);
    } else {
      setErrors("value must be more than 0.5 usd");
    }
  };
  return (
    <>
      {saleObj.type !== "Token Sale Dates" &&
      saleObj.type !== "Not Started" &&
      saleObj.type !== "Upcoming-Public" &&
      !hardCapLimit ? (
        <>
          <div className="user-content">
            <div className="user-panel buy-token-icon">
              <div className="d-flex">
                <h2 className="user-panel-title">Buy Tokens</h2>
                <span className="badge-presale">{saleObj.type}</span>
              </div>

              <form>
                <h5 className="user-panel-subtitle">
                  1. Select the payment method
                </h5>
                <div className="gaps-1x"></div>

                <div className="payment-list">
                  <div className="row">
                    {/* <div className="col-md-6 col-sm-12">
                      <div className="payment-item">
                        <input
                          className="payment-check"
                          type="radio"
                          id="paybtc"
                          name="payOption"
                          value="tranxbtc"
                          onChange={(e) => handleNetworkChange(e, 4)}
                          checked={Val === 4 ? true : false}
                        />
                        <label for="paybtc" className="d-flex  flex-column">
                          <div className="payment-icon">
                            <img
                              src="images/payment-icons/btc.svg"
                              alt="icon"
                            />
                          </div>
                          <span className="payment-cur px-1">
                            {token[4]?.name}
                          </span>
                        </label>
                      </div>
                    </div> */}
                    <div className="col-md-12 col-sm-12">
                      <div className="payment-item">
                        <input
                          className="payment-check"
                          type="radio"
                          id="payethh"
                          name="payOption"
                          value="tranxethh"
                          onClick={(e) => handleNetworkChange(e, 5)}
                          checked={Val === 5 ? true : false}
                        />
                        <label for="payethh" className="d-flex  flex-column">
                          <div className="payment-icon ">
                            <img
                              src="images/payment-icons/eth.svg"
                              alt="icon"
                            />
                          </div>
                          <span className="payment-cur px-1">
                            {token[5]?.name}
                          </span>
                        </label>
                        {/* <span>@ {token[5].price}</span> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="payment-list">
                  <div className="row">
                    {/* <div className="col-md-4 col-sm-6">
                      <div className="payment-item">
                        <input
                          className="payment-check"
                          type="radio"
                          id="payeth"
                          name="payOption"
                          value="tranxETH"
                          onChange={(e) => handleNetworkChange(e, 0)}
                          checked={Val === 0 ? true : false}
                        />
                        <label for="payeth" className="d-flex  flex-column">
                          <div className="payment-icon ">
                            <img
                              src="images/payment-icons/dai.svg"
                              alt="icon"
                            />
                          </div>
                          <span className="payment-cur px-1">
                            {token[0].name}
                          </span>
                        </label>
                      </div>
                    </div> */}

                    <div className="col-md-12 col-sm-12">
                      <div className="payment-item">
                        <input
                          className="payment-check"
                          type="radio"
                          id="paylightcoin"
                          name="payOption"
                          value="tranxLTC"
                          onClick={(e) => handleNetworkChange(e, 1)}
                          checked={Val === 1 ? true : false}
                        />
                        <label
                          for="paylightcoin"
                          className="d-flex  flex-column"
                        >
                          <div className="payment-icon ">
                            <img
                              src="images/payment-icons/usdt.svg"
                              alt="icon"
                            />
                          </div>
                          <span className="payment-cur px-1">
                            {token[1]?.name}
                            <small
                              className="d-block"
                              style={{ marginTop: "-5px" }}
                            >
                              Ethereum Mainnet
                            </small>
                          </span>
                        </label>
                      </div>
                    </div>
                    {/* <div className="col-md-4 col-sm-6">
                      <div className="payment-item">
                        <input
                          className="payment-check"
                          type="radio"
                          id="paybtcc"
                          name="payOption"
                          value="tranxBTCC"
                          onClick={(e) => handleNetworkChange(e, 2)}
                          checked={Val === 2 ? true : false}
                        />
                        <label for="paybtcc" className="d-flex  flex-column">
                          <div className="payment-icon ">
                            <img
                              src="images/payment-icons/busd.svg"
                              alt="icon"
                            />
                          </div>
                          <span className="payment-cur px-1">
                            {token[2]?.name}
                          </span>
                        </label>
                      </div>
                    </div> */}
                    {/* <div className="col-md-4 col-sm-6">
                      <div className="payment-item">
                        <input
                          className="payment-check"
                          type="radio"
                          id="payusd"
                          name="payOption"
                          value="tranxUSD"
                          onChange={(e) => handleNetworkChange(e, 3)}
                          checked={Val === 3 ? true : false}
                        />
                        <label for="payusd" className="d-flex  flex-column">
                          <div className="payment-icon payment-icon-usd">
                            <img
                              src="images/payment-icons/tusd.svg"
                              alt="icon"
                            />
                          </div>
                          <span className="payment-cur px-1">
                            {token[3]?.name}
                          </span>
                        </label>
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* credit card */}
                <div className="payment-list">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="payment-item">
                        <input
                          className="payment-check"
                          type="radio"
                          id="paycredit"
                          name="payOption"
                          value="credit"
                          onClick={(e) => handleNetworkChange(e, 7)}
                          checked={Val === 7 ? true : false}
                        />
                        <label for="paycredit" className="d-flex  flex-column">
                          <CreditCardIcon />{" "}
                          <span className="payment-cur px-1">
                            {token[6]?.name}
                          </span>
                        </label>
                        {/* <span>@ {token[5].price}</span> */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* credit card end */}
                {/* <div className="d-flex">
                  <h5 className="d-flex mb-3">
                    <div className="payment-icon payment-icon-usd mx-0">
                      <span className="pr-2 ">
                        <CreditCardIcon />{" "}
                      </span>
                    </div>
                    <Link to="/paymentCredit" targe0t="_blank">
                      Pay through your credit card
                    </Link>
                  </h5>
                </div> */}

                {/* {Val === 4 || Val === 5 || Val === 1 ? (
                  <>
                    Enter your source wallet address
                    <div className="tranx-payment-info">
                      <div className="d-flex align-items-center">
                        <div className="icon-inside-input2">
                          <em
                            className={
                              Val === 4
                                ? "fab fa-bitcoin text-warning"
                                : "fab fa-ethereum text-dark"
                            }
                          ></em>
                        </div>
                        <input
                          style={{ width: "80%" }}
                          type="text"
                          className="tranx-payment-address error"
                          placeholder={
                            Val === 4
                              ? "Enter btc address you want to whitelist"
                              : "Enter Ethereum address you want to whitelist"
                          }
                          value={EthbtcFlow.walletAddress}
                          onChange={(e) => {
                            setEthBtcFlow({
                              ...EthbtcFlow,
                              walletAddress: e.target.value,
                            });
                          }}
                        />
                        <button
                          onClick={handleWhiteListUser}
                          className="text-white btn-primary address-btn"
                          type="button"
                        >
                          {whitelistLoad ? (
                            <ClipLoader color="white" />
                          ) : (
                            <span className="text-white ">WhiteList</span>
                          )}
                        </button>
                      </div>
                      <div className="mt-2">
                        {EthbtcFlow.whitelisterror !== "" &&
                        EthbtcFlow.whitelisterror !== undefined ? (
                          <span className="text-primary">
                            <span className="mr-1 error size-error">
                              <ErrorIcon />
                            </span>
                            <span className="error">
                              {EthbtcFlow.whitelisterror}
                            </span>
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )} */}

                <div className="gaps-1x"></div>
                <h5 className="user-panel-subtitle">
                  2. Enter the number of {rewardToken} tokens to purchase or the
                  amount you would like to spend
                  {/* 02. Set amount of {rewardToken} tokens you would like to
                  purchase */}
                </h5>
                <p class="buy-token-content">
                  {/* To become a part of the {rewardToken} investor and purchasing{" "}
                  {rewardToken} tokens will only be possible after payment made
                  and receving an approval.
                  <br />
                  Please input below the amount of tokens you want to buy of{" "}
                  {rewardToken} tokens: */}
                  Your payment will be recorded in the{" "}
                  <Link to="/transactions">Transactions</Link> section. Once
                  confirmed, the transaction will be recorded on the block chain
                  and your vesting period will commence. Use the{" "}
                  <Link to="/claim">Claim Tokens</Link> section to view your
                  vesting schedule and claim vested tokens.
                </p>
                <div className="gaps-1x"></div>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="payment-calculator">
                      <div className="payment-get">
                        <label for="paymentGet">Tokens to Purchase</label>
                        <div className="payment-input input-bordered amount-style">
                          <input
                            className="input-bordered p-0 border-0 remove-counter"
                            type="number"
                            id="paymentGet"
                            value={buyToken}
                            // defaultValue={0}
                            placeholder="0"
                            onChange={handleInput}
                          />
                          <span className="payment-get-cur payment-cal-cur p-0 ms-2 line-none">
                            {rewardToken}
                          </span>
                        </div>
                        <h6 className="text-primary">
                          price :{saleObj.price.toFixed(5)}
                        </h6>
                      </div>

                      <em
                        className="ti ti-exchange-vertical"
                        style={{ marginBottom: "22px" }}
                      ></em>

                      <div className="payment-from">
                        <label for="paymentFrom">Payment Amount</label>
                        <div className="payment-input input-bordered amount-style">
                          <input
                            className="input-bordered p-0 border-0 remove-counter"
                            type="number"
                            id="paymentFrom"
                            placeholder="0"
                            value={dollar}
                            onChange={handlePaymentAmount}
                          />
                          <span className="payment-from-cur payment-cal-cur p-0 ms-2 line-none">
                            {select.name === "Credit Card"
                              ? "USD"
                              : select.name}
                          </span>
                        </div>
                        {Val === 4 ? (
                          <h6 className="text-primary">
                            BTC Price :
                            {coinPrice.btc === 0 ? (
                              <>
                                {" "}
                                <ClipLoader size={12} color={"#F64B1C"} />
                                {"  "}
                                {"fetching "}
                              </>
                            ) : (
                              coinPrice.btc
                            )}
                          </h6>
                        ) : Val === 5 ? (
                          <h6 className="text-primary">
                            Ethereum Price :
                            {coinPrice.btc === 0 ? (
                              <>
                                {" "}
                                <ClipLoader size={12} color={"#F64B1C"} />
                                {"  "}
                                {"fetching "}
                              </>
                            ) : (
                              coinPrice.btc
                            )}
                          </h6>
                        ) : (
                          <h6 className="text-primary">
                            {tokenBalance === "" ? (
                              <>
                                {/* {"Please Connect Wallet"} */}
                                {/* {" "}
                                <ClipLoader size={12} color={"#F64B1C"} />
                                {"  "}
                                {"fetching Balance"} */}
                              </>
                            ) : (
                              <>balance :{tokenBalance}</>
                            )}
                          </h6>
                        )}
                      </div>
                    </div>
                    <div className="gaps-2x d-md-none"></div>
                  </div>
                  <div className="col-md-4">
                    <div className="payment-bonus">
                      <h6 className="payment-bonus-title">Current Bonus</h6>
                      {saleObj.bonus !== "" ? (
                        <>
                          <span className="payment-bonus-amount">
                            {saleObj.bonus}%{" "}
                            <span>
                              on{" "}
                              {saleObj.type !== "Not Started"
                                ? saleObj.type
                                : ""}
                            </span>
                          </span>
                          <span className="payment-bonus-time">
                            End at - {saleObj.end}
                          </span>
                        </>
                      ) : (
                        <>
                          {" "}
                          <h4 className="PULSE" style={{ color: "#f64b1ca6" }}>
                            Calculating ....
                          </h4>
                          {/* <Skeleton
                       variant='text'
                       width={80}
                       sx={{ bgcolor: '#f64b1ca6' }}
                     />
                     <Skeleton variant='text' width={80}   sx={{ bgcolor: '#f64b1ca6' }}/> */}
                        </>
                      )}
                    </div>
                    <div className="gaps-1x d-md-none"></div>
                  </div>
                  {/* <div className="error">{error}</div> */}
                </div>
                <span className="error">
                  <span className="text-primary">
                    {errors !== "" ? (
                      <>
                        <span className="mr-1">
                          <ErrorIcon />
                        </span>
                        <span>{errors}</span>
                      </>
                    ) : (
                      <></>
                    )}
                  </span>
                </span>
                <div className="gaps-1x"></div>
                <div className="payment-calculator-note">
                  <i className="fas fa-info-circle"></i>The calculator helps you
                  to convert required currency to {rewardToken} tokens.
                </div>
                <div className="gaps-3x"></div>
                <div className="payment-summary">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="payment-summary-item payment-summary-final">
                        <h6 className="payment-summary-title">Final Payment</h6>
                        <div className="payment-summary-info elipse">
                          <span className="payment-summary-amount">
                            {dollar}
                          </span>{" "}
                          <span>{select.name}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="payment-summary-item payment-summary-bonus">
                        <h6 className="payment-summary-title">
                          Received Bonus
                        </h6>
                        <div className="payment-summary-info elipde">
                          <span>+</span>{" "}
                          <span className="payment-summary-amount">
                            {purchase.bonus.toLocaleString()}
                          </span>{" "}
                          <span>{rewardToken}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="payment-summary-item payment-summary-tokens">
                        <h6 className="payment-summary-title">
                          Tokens Received
                        </h6>
                        <div className="payment-summary-info elipse">
                          <span className="payment-summary-amount">
                            {purchase.icoamount.toLocaleString()}
                          </span>{" "}
                          <span>{rewardToken}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {saleObj.price !== "" ? (
                  // walletAddress === "Connect Wallet" ? (
                  //   <div className="d-flex w-40 pl-2 pr-2 mx-auto justify-content-center buytoken-connect">
                  //     <ConnectButton />
                  //   </div>
                  // ) :
                  // (
                  <button
                    className="btn btn-primary payment-btn d-flex w-40 pl-2 pr-2 mx-auto"
                    // disabled={Val <= 3}
                    onClick={
                      Val === 4 || Val === 5 || Val === 1
                        ? handleBtcPurchase
                        : Val === 7
                        ? handleCreditCard
                        : handleStableCoinsPurchase
                    }
                  >
                    <>
                      {" "}
                      {loading ? (
                        <Clip />
                      ) : approveToken === "" ? (
                        <>Approve Token</>
                      ) : (
                        <>
                          {/* {Val <= 3 && walletAddress === "Connect Wallet"
                              ? "Connect Wallet" */}
                          {"Purchase Tokens"}
                        </>
                      )}
                    </>
                  </button>
                ) : (
                  // )
                  <button className="btn btn-primary payment-btn d-flex w-40 pl-2 pr-2 mx-auto">
                    Please Wait ....
                  </button>
                )}
              </form>
            </div>
          </div>
          {btcModal ? (
            <BTCInstruction
              btcModal={btcModal}
              closeBtcModal={closeBtcModal}
              dollar={dollar}
              network={EthbtcFlow.network}
              purchase={purchase}
            />
          ) : (
            <>
              {creditCardModal ? (
                <ModalCredit
                  purchase={purchase}
                  closeCreditModal={closeCreditModal}
                  show={creditCardModal}
                />
              ) : (
                <></>
              )}
            </>
          )}
          {/* {creditCardModal ? (
            <ModalCredit
              purchase={purchase}
              closeCreditModal={closeCreditModal}
              show={creditCardModal}
            />
          ) : (
            <></>
          )} */}
          {loading ? <ModalLoading /> : <></>}
          {showModal ? (
            <ModalPurchase
              data={showModal}
              callbackFunc={handleCloseModal}
              coinPrice={coinPrice.btc}
              purchase={purchase}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <SaleEnded saleType={saleObj.type} />
        </>
      )}
    </>
  );
};

export default BuyTokens;
