import { useEffect, useRef } from "react";
import { sanitize } from "dompurify";
import ReactPlayer from "react-player";

const About = (props) => {
  const { about } = props;
  const videoEl = useRef(null);
  const attemptPlay = () => {
    videoEl && videoEl.current && videoEl.current.play().catch((error) => {});
  };
  useEffect(() => {
    attemptPlay();
  }, []);
  return (
    // <section className="section bg-zinnia-grad-alt pb-0 ov-h" id="about">
    <section className="section pb-5 ov-h about-section" id="about">
      <div className="container">
        <div className="nk-block nk-block-about">
          <div className="row justify-content-center gutter-vr-30px flex-lg-row flex-column-reverse">
            <div className="col-lg-5">
              <div className="section-head section-head-about about-pt desktop-pt">
                <h2 className="title title-xl-s2 title-semibold text-default cpn-desktop">
                  {about.mainhead}
                </h2>
                <div className="fw-4 tc-dark mb-3">
                  {/* <div className="mb-lg-4 mb-3"
                    dangerouslySetInnerHTML={{
                      __html: sanitize(about.description),
                    }}
                  /> */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitize(about.description),
                    }}
                  />
                </div>
                <div className="d-flex justify-content-lg-start justify-content-center">
                  <a
                    className="btn btn-md btn-round btn-outline btn-auto custom-outline"
                    rel="noreferrer"
                    href="https://bettsrecruiting.com"
                  >
                    <span>LEARN MORE</span>
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-8">
              <div className="nk-block-video tc-light ">
                <img src={Video} alt="" />
                <a
                  to={"https://www.youtube.com/watch?v=SSo_EIwHSd4"}
                  className="nk-block-video-play video-popup btn-play btn-play-s3"
                >
                  <svg className="btn-play-icon-s2" viewBox="0 0 24 34">
                    <path
                      fill-rule="evenodd"
                      d="M0.212,32.552 C0.427,32.851 0.769,33.010 1.117,33.010 C1.337,33.010 1.559,32.947 1.752,32.814 L23.521,17.879 C23.816,17.678 23.991,17.350 23.991,16.998 C23.991,16.646 23.816,16.319 23.521,16.115 L1.752,1.181 C1.415,0.950 0.972,0.922 0.606,1.107 C0.240,1.292 0.010,1.661 0.010,2.064 L0.010,22.480 C0.010,23.076 0.506,23.558 1.116,23.558 C1.727,23.558 2.222,23.076 2.222,22.480 L2.222,4.142 L20.963,16.998 L0.479,31.049 C-0.020,31.393 -0.140,32.066 0.212,32.552 Z"
                    />
                  </svg>
                </a>
              </div>
            </div> */}
            {/* <h1>Autoplay example</h1>
            <div>
              <video
                style={{ maxWidth: "100%", width: "800px", margin: "0 auto" }}
                playsInline
                loop
                muted
                controls
                alt="All the devices"
                src={"https://www.youtube.com/watch?v=SSo_EIwHSd4"}
                ref={videoEl}
              />
            </div> */}
            <div className="col-lg-6 offset-lg-1">
              <div className="section-head section-head-about about-pt cpn-mobile text-center my-xl-4 mt-5">
                <h2 className="title title-xl-s2 title-semibold text-default">
                  {about.mainhead}
                </h2>
              </div>
              <div className="tc-light bg-zinnia video-about-player theme-shadow">
                <ReactPlayer
                  style={{ margin: "0 auto" }}
                  // width="100%"
                  url={about.videolink}
                  loop={true}
                  playing={false}
                  controls={true}
                  // thumbnail={about.video}
                  // light={true}
                  src={"https://fast.wistia.com/assets/external/E-v1.js"}
                  playIcon={
                    <>
                      <div className="video-popup btn-play btn-play-s3">
                        <svg className="btn-play-icon-s2" viewBox="0 0 24 34">
                          <path
                            fillRule="evenodd"
                            d="M0.212,32.552 C0.427,32.851 0.769,33.010 1.117,33.010 C1.337,33.010 1.559,32.947 1.752,32.814 L23.521,17.879 C23.816,17.678 23.991,17.350 23.991,16.998 C23.991,16.646 23.816,16.319 23.521,16.115 L1.752,1.181 C1.415,0.950 0.972,0.922 0.606,1.107 C0.240,1.292 0.010,1.661 0.010,2.064 L0.010,22.480 C0.010,23.076 0.506,23.558 1.116,23.558 C1.727,23.558 2.222,23.076 2.222,22.480 L2.222,4.142 L20.963,16.998 L0.479,31.049 C-0.020,31.393 -0.140,32.066 0.212,32.552 Z"
                          />
                        </svg>
                      </div>
                    </>
                  }
                  config={{
                    youtube: {
                      playerVars: { showinfo: 0 },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default About;
