import React from "react";
import CMS from "../../../components/CMS/ContactUs";
const index = () => {
  return (
    <>
      <CMS />
    </>
  );
};
export default index;
