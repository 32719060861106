import Button from "@mui/material/Button";
// import Card from "@material-ui/core/Card";
import AppBar from "@material-ui/core/AppBar";
import useStyles from "../../../assets/CustomeMaterialStyleSheet";
import CloseIcon from "@mui/icons-material/Close";
import renderHTML from "react-render-html";
const Body = (props) => {
  const { handleTemplateClose, template } = props;
  const classes = useStyles();
  return (
    <>
      <div className="table-view-modal">
        <AppBar className={classes.header60}>
          <div
            id="add-user-header"
            className="d-flex align-items-center justify-content-between add-user-css"
          >
            <div>
              <h5 className="user-header-h5 mb-1">View Email Template</h5>
            </div>
            <Button
              id="coin-modal-close"
              className="btn-neutral mx-2 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center text-white border-radius-15px"
              onClick={() => {
                handleTemplateClose();
              }}
            >
              <CloseIcon />
            </Button>
          </div>
        </AppBar>
      </div>
      <div className="email-msg-modal">
        <div className={classes.paper60} id="style-default">
          <div className="d-flex justify-content-between">
            <div className="mx-auto w100">
              <div className="">
                <div className="app orange-email orange-border-1px bg-white">
                  {renderHTML(template ? template : "")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Body;
