import { TokenomicsData } from "../helpers";
// import { sanitize } from "dompurify";
const TokenSale = (props) => {
  const { tokenomics } = props;
  // eslint-disable-next-line
  return (
    <section className="bg-theme section tc-light ov-h" id="tokensale">
      <div className="nk-block nk-block-token mgb-m30">
        <div className="section-head text-center wide-auto">
          <h4 className="title title-semibold">{tokenomics.mainhead}</h4>
        </div>
        <div className="tokdis-list">
          <div className="container">
            <div className="row text-center text-lg-start">
              <div className="col-md">
                <div className="tokdis-item">
                  <span>Tokens Offered</span>
                  <h5>{TokenomicsData.tokensOffered}M</h5>
                </div>
              </div>
              <div className="col-md">
                <div className="tokdis-item">
                  <span>Soft Cap (Public ICO)</span>
                  <h5>${TokenomicsData.softCap}M</h5>
                </div>
              </div>
              <div className="col-md">
                <div className="tokdis-item">
                  <span>Hard Cap</span>
                  <h5>{TokenomicsData.hardCap}M</h5>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="tokdis-item">
                  <span>Tokens Distributed</span>
                  <h5>{TokenomicsData.tokenDistributed}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="toktmln-list pb-lg-5">
          <div className="container">
            <div className="row">
              {TokenomicsData.Sales.map((item, index) => {
                return (
                  <div className="col-lg" key={item.name + index}>
                    <div className="toktmln-item">
                      <div>
                        <span>{item.name}</span>
                        <span>
                          {item.startDate} - {item.endDate}
                        </span>
                      </div>
                      <div>
                        <span>{item.bonus}% Bonus</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="nk-ovm shape-n"></div>
    </section>
  );
};
export default TokenSale;
