import { GetPreSaleUsers } from "../Services/User/Presale/presale";
const { MerkleTree } = require("merkletreejs");
window.Buffer = window.Buffer || require("buffer").Buffer;
const SHA256 = require("keccak256");

export const verifyMerkle = async (walletAddress) => {
  const data = await GetPreSaleUsers();
  const { address } = data.data[0];
  const leaves = address.map((x) => SHA256(x));
  const tree = new MerkleTree(leaves, SHA256, { sortPairs: true });
  const root = tree.getRoot().toString("hex");
  const leaf = SHA256(walletAddress);
  const proofForTx = tree.getHexProof(leaf);
  const status = tree.verify(proofForTx, leaf, root);
  const presaleInfo = {
    proof: proofForTx,
    status: status,
  };
  return presaleInfo;
};
