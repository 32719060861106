const DataNotFound = (props) => {
  return (
    <>
      <tr>
        <td className="px-4 text-center font-size-xs" colSpan={8}>
          {/* <img
          className="data-not-found-image"
          src="images/data-not-found.png"
          alt="Data Not Found!"
        /> */}
          <div className="text-muted font-size-xl text-center pt-3">
            DATA NOT FOUND
          </div>
        </td>
      </tr>
    </>
  );
};
export default DataNotFound;
