import * as Yup from "yup";
export const IcoInitialValues = {
  mainHeading: "",
  videoLink: "",
  description: "",
};
export const CMSIcoFormFields = [
  {
    field: "Main Heading",
    fieldName: "mainhead",
    place: "Enter Main heading",
    type: "text",
  },
  {
    field: "Video Link",
    fieldName: "videolink",
    place: "Enter Video Link",
    type: "text",
  },
  {
    field: "Description",
    fieldName: "description",
    place: "Enter Description",
    type: "description",
  },
];
export const AboutIcoSchema = Yup.object().shape({
  mainhead: Yup.string()
    .min(3, "Heading be at least 3 characters.")
    .required("Heading is required!"),
  videolink: Yup.string().required("Video Link is required!"),
  description: Yup.string().required("Description is required!"),
});
export const getInitialValues = (CurrentUser, cmsId) => {
  return {
    id: cmsId ? cmsId : "",
    mainhead: CurrentUser.mainhead,
    videolink: CurrentUser.videolink,
    description: CurrentUser.description,
  };
};
