import React, { useState } from "react";
import moment from "moment";

import DateRangePicker from "react-bootstrap-daterangepicker";
// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
export default function ResponsiveDateRangePicker({ SetFilterData }) {
  const [fromDate, setFromDate] = useState(
    moment("04-04-2022").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(new Date());
  const range = {
    Today: [moment("2022-04-04").format("YYYY-MM-DD"), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Last Year": [
      moment().subtract(1, "year").startOf("year"),
      moment().subtract(1, "year").endOf("year"),
    ],
  };

  const handleEvent = (event, picker) => {
    setFromDate(picker.startDate._d.toISOString());
    setToDate(picker.endDate._d.toISOString());
    let data = [
      picker.startDate._d.toISOString(),
      picker.endDate._d.toISOString(),
    ];
    SetFilterData(data, "date");
  };
  const Reset = () => {
    setFromDate(moment("2022-04-04").format("YYYY-MM-DD"));
    setToDate(new Date());
    let data = [moment("2022-04-04").toISOString(), new Date().toISOString()];
    SetFilterData(data, "date");
  };
  return (
    <div className="App">
      <DateRangePicker
        startDate={fromDate}
        endDate={toDate}
        ranges={range}
        alwaysShowCalendars={true}
        onEvent={handleEvent}
      >
        <button className="btn  btn-primary mb-2">
          {moment(fromDate).format("LL")} to {moment(toDate).format("LL")}
        </button>
      </DateRangePicker>
      <button onClick={Reset} className="btn  btn-primary">
        <RestartAltIcon />
      </button>
    </div>
  );
}
