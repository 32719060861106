import React from "react";
import Airdrop from "../../../components/AirDrop";
const AirdropPage = () => {
  return (
    <>
      <Airdrop />
    </>
  );
};

export default AirdropPage;
