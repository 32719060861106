import * as Yup from "yup";
export const TeamInitialValues = {
  mainhead: "",
  description: "",
  mainhead2: "",
  description2: "",
};
export const CMSTeamFormFields = [
  {
    field: "Main Heading",
    fieldName: "mainhead",
    place: "Enter Main heading",
    type: "text",
  },
  {
    field: "Description",
    fieldName: "description",
    place: "Enter Description",
    type: "description",
  },
  {
    field: "Main Heading 2",
    fieldName: "mainhead2",
    place: "Enter Main heading 2",
    type: "text",
  },
  {
    field: "Description 2",
    fieldName: "description2",
    place: "Enter Description 2",
    type: "description",
  },
];
export const TeamSchema = Yup.object().shape({
  mainhead: Yup.string()
    .min(3, "Heading be at least 3 characters.")
    .required("Heading is required!"),
  description: Yup.string().required("Description is required!"),
  mainhead2: Yup.string()
    .min(3, "Heading be at least 3 characters.")
    .required("Heading is required!"),
  description2: Yup.string().required("Description is required!"),
});
export const getInitialValues = (CurrentUser, id) => {
  return {
    id: id ? id : "",
    mainhead: CurrentUser ? CurrentUser.mainhead : "",
    description: CurrentUser.description ? CurrentUser.description : "",
    mainhead2: CurrentUser.mainhead2,
    description2: CurrentUser.description2 ? CurrentUser.description2 : "",
  };
};
