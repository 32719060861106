import Switch from "../../../Comps-Utils/Switch";
import ActionGroup from "../../../Comps-Utils/ActionsButtonGroup";
const TableRow = (props) => {
  const {
    updateKycStatus,
    deleteRow,
    row,
    index,
    setActionState,
    handleOpen,
    setUpdatedRow,
  } = props;
  return (
    <>
      <tr key={row.id + index} id={"user-profile-tr-" + index}>
        <td className="px-4 text-left">
          <span>{row.title}</span>
        </td>
        <td className="text-left">
          <span className="mt-2">
            {" "}
            <Switch status={row.status} id={row.id} service={updateKycStatus} />
          </span>
        </td>
        <td className="text-left table-btn-group">
          <ActionGroup
            handleOpen={handleOpen}
            setUpdatedRow={setUpdatedRow}
            setActionState={setActionState}
            row={row}
            deleteRow={deleteRow}
          />
        </td>
      </tr>
    </>
  );
};
export default TableRow;
