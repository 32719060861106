import { useContext, useEffect, useState } from "react";
import { ToasterContext } from "../../App";
import { sanitize } from "dompurify";
import { GetCMS } from "../../Services/Admin/CMS/Banner";
const Roadmap = () => {
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [general, setGeneral] = useState([
    {
      mainhead: "Roadmap",
      description:
        "Our team working hardly to make archive lorem ipsum dolor sit amet, consectetur amet, consectetur adipiscing elit.",
      type: "general",
    },
  ]);
  const GetCMSDataService = () => {
    GetCMS().then((res) => {
      if (res.status === 200) {
        setGeneral(res.data[0].roadmap);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  useEffect(() => {
    GetCMSDataService();
    // eslint-disable-next-line
  }, []);
  return (
    <section className="section bg-theme tc-light ov-h" id="roadmap">
      <div className="container">
        <div className="section-head text-center wide-auto-sm">
          <h4 className="title title-semibold " data-delay=".1">
            {general.mainhead}
          </h4>
          <div className="" data-delay=".2">
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(general.description),
              }}
            />
          </div>
        </div>
        <div className="ico-roadmap">
          <div className="roadmap-plan">
            <div className="roadmap-header">
              <span>2022 Q3</span>
            </div>
            <div className="roadmap-body">
              <span className="d-block mb-lg-3 mb-2">Pre-Sale</span>
              <p>Private and Public Financing</p>
            </div>
          </div>
          <div className="roadmap-plan">
            <div className="roadmap-header">
              <span>2022 Q4</span>
            </div>
            <div className="roadmap-body">
              <span className="d-block mb-lg-3 mb-2">Token Sale</span>
              <p>
                Token Sale ICO Press Tour Open global sales of ICO block token
              </p>
            </div>
          </div>
          <div className="roadmap-plan">
            <div className="roadmap-header">
              <span>2022 Q4</span>
            </div>
            <div className="roadmap-body">
              <span className="d-block mb-lg-3 mb-2">ICO</span>
              <p>Launch smart contracts within Betts Connect Platform</p>
            </div>
          </div>
          <div className="roadmap-plan">
            <div className="roadmap-header">
              <span>2023 Q1</span>
            </div>
            <div className="roadmap-body">
              <span className="d-block mb-lg-3 mb-2">Governence</span>
              <p>Launch Recruit Coin governence model</p>
            </div>
          </div>
          <div className="roadmap-plan">
            <div className="roadmap-header">
              <span>2023 Q2</span>
            </div>
            <div className="roadmap-body">
              <span className="d-block mb-lg-3 mb-2">Training</span>
              <p>
                Launch the ability to buy different training with Recruit Coin
                within Connect
              </p>
            </div>
          </div>
          <div className="roadmap-plan">
            <div className="roadmap-header">
              <span>2023 Q3</span>
            </div>
            <div className="roadmap-body">
              <span className="d-block mb-lg-3 mb-2">Agency</span>
              <p>
                Launch agency model on blockchain allowing anyone to setup their
                own recruiting agency
              </p>
            </div>
          </div>
          <div className="roadmap-plan">
            <div className="roadmap-header">
              <span>2023 Q4</span>
            </div>
            <div className="roadmap-body">
              <span className="d-block mb-lg-3 mb-2">Recruit Coin 2.0</span>
              <p>
                Updates to the coin, the contracts, incresing the ways users can
                earn and spend recruit coin
              </p>
            </div>
          </div>
        </div>
        {/* <div className="nk-block">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <div className="roadmap-all mgb-m50 " data-delay=".3">
                <div className="roadmap-wrap roadmap-wrap-done roadmap-wrap-s1 mb-0">
                  <div className="row g-0">
                    <div className="col-lg">
                      <div className="roadmap roadmap-s1 roadmap-done text-lg-center">
                        <div className="roadmap-step roadmap-step-s1">
                          <div className="roadmap-head roadmap-head-s1">
                            <span className="roadmap-time roadmap-time-s1">
                              2022 Q1
                            </span>
                            <span className="roadmap-title roadmap-title-s1">
                              Concept
                            </span>
                          </div>
                          <ul className="roadmap-step-list roadmap-step-list-s1">
                            <li>Concept Generation</li>
                            <li>Team Assemble</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="roadmap roadmap-s1 roadmap-done text-lg-center">
                        <div className="roadmap-step roadmap-step-s1">
                          <div className="roadmap-head roadmap-head-s1">
                            <span className="roadmap-time roadmap-time-s1">
                              2022 Q2
                            </span>
                            <span className="roadmap-title roadmap-title-s1">
                              Research
                            </span>
                          </div>
                          <ul className="roadmap-step-list roadmap-step-list-s1">
                            <li>Proving the concept can work</li>
                            <li>Strategic Plan</li>
                            <li>White paper conpletion</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="roadmap roadmap-s1 roadmap-done text-lg-center">
                        <div className="roadmap-step roadmap-step-s1">
                          <div className="roadmap-head roadmap-head-s1">
                            <span className="roadmap-time roadmap-time-s1">
                              2022 Q1
                            </span>
                            <span className="roadmap-title roadmap-title-s1">
                              Design
                            </span>
                          </div>
                          <ul className="roadmap-step-list roadmap-step-list-s1">
                            <li>Platform design and technical demonstration</li>
                            <li>Building the MVP</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="roadmap roadmap-s1 roadmap-done text-lg-center">
                        <div className="roadmap-step roadmap-step-s1">
                          <div className="roadmap-head roadmap-head-s1">
                            <span className="roadmap-time roadmap-time-s1">
                              2022 Q2
                            </span>
                            <span className="roadmap-title roadmap-title-s1">
                              Pre-Sale
                            </span>
                          </div>
                          <ul className="roadmap-step-list roadmap-step-list-s1">
                            <li>Public financing &amp; Seed funding raised</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="roadmap-wrap roadmap-wrap-s1 mb-0">
                  <div className="row flex-row-reverse g-0">
                    <div className="col-lg">
                      <div className="roadmap roadmap-current roadmap-s1 text-lg-center">
                        <div className="roadmap-step roadmap-step-s1">
                          <div className="roadmap-head roadmap-head-s1">
                            <span className="roadmap-time roadmap-time-s1">
                              2022 Q3
                            </span>
                            <span className="roadmap-title roadmap-title-s1">
                              App Beta Test
                            </span>
                          </div>
                          <ul className="roadmap-step-list roadmap-step-list-s1">
                            <li>Private closed beta</li>
                            <li>
                              Open beta launched to public and improvement the
                              app
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="roadmap roadmap-s1 text-lg-center">
                        <div className="roadmap-step roadmap-step-s1">
                          <div className="roadmap-head roadmap-head-s1">
                            <span className="roadmap-time roadmap-time-s1">
                              2022 Q4
                            </span>
                            <span className="roadmap-title roadmap-title-s1">
                              Token Sale
                            </span>
                          </div>
                          <ul className="roadmap-step-list roadmap-step-list-s1">
                            <li>ICO Press Tour</li>
                            <li>Open global sales of ICOblock token</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="roadmap roadmap-s1 text-lg-center">
                        <div className="roadmap-step roadmap-step-s1">
                          <div className="roadmap-head roadmap-head-s1">
                            <span className="roadmap-time roadmap-time-s1">
                              2022 Q1
                            </span>
                            <span className="roadmap-title roadmap-title-s1">
                              Alpha Test
                            </span>
                          </div>
                          <ul className="roadmap-step-list roadmap-step-list-s1">
                            <li>In-house testing of functional</li>
                            <li>
                              Prototype published and linked to Ethereum
                              blockchain with real-time scanning
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="roadmap-wrap roadmap-wrap-s1 mb-lg-0">
                  <div className="row g-0">
                    <div className="col-lg">
                      <div className="roadmap roadmap-s1 text-lg-center">
                        <div className="roadmap-step roadmap-step-s1">
                          <div className="roadmap-head roadmap-head-s1">
                            <span className="roadmap-time roadmap-time-s1">
                              2022 Q2
                            </span>
                            <span className="roadmap-title roadmap-title-s1">
                              Crowdfunding Integration
                            </span>
                          </div>
                          <ul className="roadmap-step-list roadmap-step-list-s1">
                            <li>Smart contracts support creators</li>
                            <li>Ethereum tokens support</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="roadmap roadmap-s1 text-lg-center">
                        <div className="roadmap-step roadmap-step-s1">
                          <div className="roadmap-head roadmap-head-s1">
                            <span className="roadmap-time roadmap-time-s1">
                              2022 Q3
                            </span>
                            <span className="roadmap-title roadmap-title-s1">
                              Community Benefits
                            </span>
                          </div>
                          <ul className="roadmap-step-list roadmap-step-list-s1">
                            <li>Establishing global user base</li>
                            <li>US start retailer selection</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="roadmap roadmap-s1 text-lg-center">
                        <div className="roadmap-step roadmap-step-s1">
                          <div className="roadmap-head roadmap-head-s1">
                            <span className="roadmap-time roadmap-time-s1">
                              2022 Q3
                            </span>
                            <span className="roadmap-title roadmap-title-s1">
                              Hardware things
                            </span>
                          </div>
                          <ul className="roadmap-step-list roadmap-step-list-s1">
                            <li>Integration of third party controllers</li>
                            <li>Marketplace cooperative module</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="roadmap roadmap-s1 text-lg-center">
                        <div className="roadmap-step roadmap-step-s1">
                          <div className="roadmap-head roadmap-head-s1">
                            <span className="roadmap-time roadmap-time-s1">
                              2022 Q1
                            </span>
                            <span className="roadmap-title roadmap-title-s1">
                              More Operational
                            </span>
                          </div>
                          <ul className="roadmap-step-list roadmap-step-list-s1">
                            <li>
                              Integration with Private Chains, More Coin in
                              Wallet
                            </li>
                            <li>New services offered by members or business</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/* <div className="nk-ovm shape-n"></div> */}
    </section>
  );
};
export default Roadmap;
