import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assetsUser/css/vendor.bundle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/css/dashmix.css";
import "./assetsUser/css/style.css";
import "../node_modules/react-quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";
import "video-react/dist/video-react.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BrowserRouter } from "react-router-dom";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import "@rainbow-me/rainbowkit/styles.css";
import {
  getDefaultWallets,
  RainbowKitProvider,
  wallet,
} from "@rainbow-me/rainbowkit";
import { configureChains, createClient, WagmiConfig, chain } from "wagmi";
// import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
// import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
// import {
//   bscChainMainnet,
//   bscChainTestnet,
// } from "./Services/User/Wallet/chains";
// import { BooleanSchema } from "yup";

let networkchains = [];

if (process.env.REACT_APP_CHAIN_ID == "137") {
  networkchains.push(chain.polygon);
} else {
  networkchains.push(chain.polygonMumbai);
}

const { chains, provider, webSocketProvider } = configureChains(
  // networkchains,
  [
    //   //chain.mainnet,
    chain.polygon,
    chain.polygonMumbai,
    //   // bscChainTestnet,
    //   // bscChainMainnet,
  ],
  [
    // alchemyProvider({ alchemyId: process.env.ALCHEMY_ID }),
    publicProvider(),
    jsonRpcProvider({ rpc: (chain) => ({ http: chain.rpcUrls.default }) }),
  ]
  // [jsonRpcProvider({ rpc: (chain) => ({ http: chain.rpcUrls.default }) })]
);

const { connectors } = getDefaultWallets({
  appName: "Betts ICO",
  chains,
  wallets: [
    wallet.rainbow({ chains }),
    wallet.walletConnect({ chains }),
    wallet.imToken({ chains }),

    // wallet.brave({
    //   chains,
    // }),
    // wallet.argent({
    //   chains,
    // }),
    // new WalletConnectConnector({
    //   chains,
    //   options: {
    //     qrcode: true,
    //   },
    // }),
  ],
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  // connectorsCustom,
  webSocketProvider,
  provider,
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains}>
          <App />
        </RainbowKitProvider>
      </WagmiConfig>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
