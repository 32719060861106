import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { GetKycUsers } from "../../Services/Admin/Kyc";
import { GetPriceData } from "../../Services/Admin/Web3call/contractCall";
import { AddAdminTokenTransaction } from "../../Services/User/Tokens/token";
import { ToasterContext } from "../../App";
import Clip from "../Comps-Utils/ClipLoader";

const AirdropModal = ({ airdropModalCloseHandle, airdropShow }) => {
  const [showCustomEmail, setShowCustomEmail] = useState(false);
  const [users, setUsers] = useState([]);
  const [prices, setPrices] = useState([]);
  const [vesting, setVesting] = useState(false);
  const vestingDate = [
    { label: "3 months", value: "3 months" },
    { label: "6 months", value: "6 months" },
  ];
  const [loading, setLoading] = useState(false);
  const [tostify, setTostify] = useContext(ToasterContext);
  const [errors, setErrors] = useState({
    saletype: "",
    useremail: "",
    useremailcustom: "",
    amount: "",
    address: "",
    vesting: "",
  });
  // const [purchase, setPurchase] = useState({
  //   userid: "",
  //   username: "",
  //   useremail: "",
  //   tokens: "Recruit",
  //   amount: 0,
  //   from: "",
  //   date: Date.now(),
  //   status: "",
  //   saletype: "",
  //   transactionId: "",
  //   paymentMethod: "",
  //   payabletoken: "",
  //   bonus: 0.0,
  //   icoamount: 0.0,
  //   biddingtokenprice: 0,
  //   claimtokenprice: 0,
  // });
  // const [selectedUser,setSelectedUser] = useState("")
  const [airdropData, setAirdropData] = useState({
    vesting: "12 months",
    tokens_value: 0,
    sale_price: 0,
    userid: "",
    username: "",
    useremail: "",
    tokens: "Recruit",
    amount: 0,
    from: "",
    recevingaddress: "",
    walletAddress: "",
    date: Date.now(),
    status: 4,
    saletype: "",
    transactionId: "",
    paymentMethod: "USD",
    payabletoken: 100,
    bonus: 0.0,
    icoamount: 0.0,
    biddingtokenprice: 1,
    claimtokenprice: 0,
  });

  useEffect(() => {
    fetchUsers();
    fetchPrices();
  }, []);

  const fetchUsers = () => {
    GetKycUsers().then((res) => {
      if (res.status === 200) {
        let users = res.data;
        users = users.filter((user) => user.permissions !== "super");
        // let custom_obj = {email: "Custom Email"}
        // users.splice(0,0,custom_obj)
        setUsers(users);
      }
    });
  };

  const handleChange = (e) => {
    console.log(">>>>>>sasa", e.target.name);
    if (e.target.name == "amount") {
      let tokens_calculated = e.target.value / (airdropData.sale_price / 1e6);
      setAirdropData({
        ...airdropData,
        [e.target.name]: e.target.value,
        tokens_value: tokens_calculated,
        icoamount: e.target.value,
      });
    } else if (e.target.name == "from") {
      setAirdropData({
        ...airdropData,
        [e.target.name]: e.target.value,
        recevingaddress: e.target.value,
      });
    } else {
      setAirdropData({ ...airdropData, [e.target.name]: e.target.value });
    }
  };

  const fetchPrices = () => {
    GetPriceData().then((res) => {
      console.log(res);
      let prices_data = [
        { label: "Pre Sale", value: res.preSalePrice },
        { label: "Public Sale", value: res.publicSalePrice },
      ];
      setPrices(prices_data);
    });
  };

  const handleUserChange = (e) => {
    if (e.email === "Custom Email") {
      setShowCustomEmail(true);
      setAirdropData({
        ...airdropData,
        useremail: "",
        form: "",
        userid: "",
        recevingaddress: "",
      });
    } else {
      setShowCustomEmail(false);
      setAirdropData({
        ...airdropData,
        useremail: e.email,
        from: e.walletAddress,
        recevingaddress: e.walletAddress,
        userid: e._id,
        username: e.username,
      });
      // setAirdropData({ ...airdropData, deposit_address: e.walletAddress });
    }
    // let user_info = users.filter(user => user.email === e.target.value)
    // console.log(">>>>>>>>>>user", user_info)
  };

  const handlePriceChange = (e) => {
    let tokens_calculated = airdropData.amount / (e.value / 1e6);
    setAirdropData({
      ...airdropData,
      tokens_value: tokens_calculated,
      sale_price: e.value,
      saletype: e.label,
      claimtokenprice: e.value / 1e6,
    });
    if (e.label == "Public Sale") {
      setVesting(true);
    } else {
      setVesting(false);
    }
  };

  const handleVestingChange = (e) => {
    setAirdropData({
      ...airdropData,
      vesting: e.value,
    });
  };

  const checkValidation = (e) => {
    let errors = {};
    if (airdropData.saletype === "") {
      errors.saletype = "Sale Type is required";
    }
    if (airdropData.useremail === "") {
      errors.useremail = "User Email is required";
    } else if (airdropData.useremail === "Custom Email") {
      errors.useremailcustom = "Please Enter custom user email";
    }
    if (airdropData.amount == 0) {
      errors.amount = "Amount is required";
    }
    if (airdropData.recevingaddress == "") {
      errors.address = "Deposit address is required";
    }
    if (airdropData.vesting == "") {
      errors.vesting = "Vesting is required";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return false;
    } else {
      setErrors({});
      return true;
    }
  };

  const submitTransactionsDetails = async () => {
    const validate = checkValidation();
    if (validate) {
      setLoading(true);
      const addToken = await AddAdminTokenTransaction(airdropData);

      if (addToken.code === 200) {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "success",
          message: "Transaction Successfully added",
        });
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "error",
          message: addToken.message,
        });
      }
      setLoading(false);
      airdropModalCloseHandle();
    }
  };

  return (
    <>
      <Modal
        className="airdropmodal-wrapper"
        show={airdropShow}
        onHide={airdropModalCloseHandle}
      >
        <Modal.Header closeButton>
          <div className="modal-header-heading text-center">
            <h4>Manual Airdrop</h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="airmodal-content">
            <Row>
              <Col lg="7">
                <div className="airmodal-content-wrapper">
                  <h6>Type of SALE</h6>
                  {/* <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic-two"
                      className="btn-gray"
                    >
                      Sale
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item >pre-sale</Dropdown.Item>
                      <Dropdown.Item >
                        public-sale
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <Select
                    // name="email"
                    // value={airdropData.email}
                    // isSearchable={true}
                    className="btn-gray"
                    options={prices}
                    placeholder={"Select"}
                    onChange={handlePriceChange}
                  />
                  <span className="error1">{errors.saletype}</span>
                </div>
                {vesting && (
                  <>
                    <div className="airmodal-content-wrapper">
                      <h6>Select Vesting</h6>
                      <Select
                        className="btn-gray"
                        options={vestingDate}
                        placeholder={"Select"}
                        onChange={handleVestingChange}
                      />
                      <span className="error1">{errors.vesting}</span>
                    </div>
                  </>
                )}
                <div className="airmodal-content-wrapper">
                  <h6>Select User</h6>
                  {/* <Dropdown onChange={handleChange}>
                    <Dropdown.Toggle id="dropdown-basic" className="btn-gray">
                      Dropdown Users
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="user-dropdown-menu">
                      {users && users.map((user,index) => (
                        <Dropdown.Item>
                        {user.email}
                      </Dropdown.Item>
                      ))  
                      } */}

                  {/* <Dropdown.Item href="#/action-2">
                        action@gmail.com
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        action@gmail.com
                      </Dropdown.Item> */}
                  {/* <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={() => {
                          setShowCustomEmail(!showCustomEmail);
                        }}
                      >
                        Custom Email
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  {/* <select
                  id="dropdown-basic" className="btn-gray"
                  name="user_email"
                  style={{
                    border: "2px solid rgb(255 173 122)",
                    width: "70%",
                    // background: "rgb(234 84 39)",
                    color: "rgb(234 84 39)",
                  }}
                  >
                    {users && users.map((user,index) => (
                      <option>{user.email}</option>
                      ))  
                      }
                  </select> */}
                  <Select
                    // name="email"
                    // value={airdropData.email}
                    // isSearchable={true}
                    className="btn-gray"
                    getOptionLabel={(users) => users.email}
                    getOptionValue={(users) => users.email}
                    options={users}
                    placeholder={"Select"}
                    onChange={handleUserChange}
                  />
                  <span className="error1">{errors.useremail}</span>
                </div>
                <Form>
                  {showCustomEmail && (
                    <>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          name="email"
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <span className="error1">{errors.useremailcustom}</span>
                    </>
                  )}

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Amount in USD</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter the amount"
                      name="amount"
                      onChange={handleChange}
                      value={airdropData.amount}
                    />
                  </Form.Group>
                  <span className="error1">{errors.amount}</span>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Desposit Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter the address"
                      name="from"
                      onChange={handleChange}
                      value={airdropData.from}
                    />
                  </Form.Group>
                  <span className="error1">{errors.address}</span>
                </Form>
              </Col>
              <Col lg="5">
                <div className="airdropmodal-boxe">
                  <div className="airdropmodal-boxe-heading d-flex align-items-center">
                    <h4 className="me-2 mb-0">Details</h4>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="info-icon"
                    />
                  </div>
                  <div className="airdropmodal-boxe-content">
                    <p>
                      <span>Amount: </span> {airdropData.amount}
                    </p>
                    <p>
                      <span>Tokens To Get: </span> {airdropData.tokens_value}
                    </p>
                    <p className="mb-0">
                      <span>User Email: </span> {airdropData.useremail}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="airdropmodal-boxe-buttons d-flex ">
            <Button className="btn-gray me-3" onClick={airdropModalCloseHandle}>
              Cancel
            </Button>
            <Button className=" btn-gray" onClick={submitTransactionsDetails}>
              {loading ? <Clip /> : <>Create</>}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AirdropModal;
