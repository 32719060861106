import * as Yup from "yup";
export const AdminProfileFormFields = [
  {
    field: "First Name",
    fieldName: "firstname",
    place: "Enter Your First Name",
    type: "text",
  },
  {
    field: "Last Name",
    fieldName: "lastname",
    place: "Enter Your Last Name",
    type: "text",
  },
  {
    field: "Phone",
    fieldName: "phone",
    place: "Enter Your Phone Number",
    type: "number",
  },
  {
    field: "Address Line 1",
    fieldName: "address1",
    place: "Enter your Address Line 1",
    type: "text",
  },
  {
    field: "Address Line 2",
    fieldName: "address2",
    place: "Enter your Address Line 2",
    type: "text",
  },
  {
    field: "City",
    fieldName: "city",
    place: "Enter your City",
    type: "text",
  },
  {
    field: "Country",
    fieldName: "country",
    place: "Enter your Country",
    type: "selectCountry",
  },
];
export const ProfileSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, "Name must be at least 3 characters.")
    .required("First Name is required!"),
  lastname: Yup.string()
    .min(3, "Last Name  be at least 3 characters.")
    .required("Last Name is required!"),
  phone: Yup.string().required("Phone Number is required!"),
  address1: Yup.string()
    .min(5, "Address must be at least 5 characters.")
    .required("Address is required!"),
  city: Yup.string()
    .min(3, "City must be at least 5 characters.")
    .required("City is required!"),
  country: Yup.object().required("Please Select Your Nationality!").nullable(),
});
export const getInitialValues = (CurrentUser, id) => {
  return {
    email: CurrentUser ? CurrentUser.email : "",
    username: CurrentUser ? CurrentUser.username : "",
    firstname: CurrentUser ? CurrentUser.firstname : "",
    lastname: CurrentUser ? CurrentUser.lastname : "",
    phone: CurrentUser ? CurrentUser.phone : "",
    address1: CurrentUser ? CurrentUser.address1 : "",
    address2: CurrentUser ? CurrentUser.address2 : "",
    city: CurrentUser ? CurrentUser.city : "",
    country:
      CurrentUser && CurrentUser.nationality
        ? CurrentUser.nationality
        : { code: "PK", label: "Pakistan", phone: "92" },
    profileImgUrl: CurrentUser ? CurrentUser.profileImgUrl : "",
  };
};
