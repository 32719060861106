import * as Yup from "yup";
export const ContactUsInitialValues = {
  mainhead: "",
  description: "",
  mobile: "",
  email: "",
  telegram: "",
};
export const CMSContactUsFormFields = [
  {
    field: "Main Heading",
    fieldName: "mainhead",
    place: "Enter Main heading",
    type: "text",
  },
  {
    field: "Short Description",
    fieldName: "description",
    place: "Enter Short Description",
    type: "text",
  },
  {
    field: "Mobile",
    fieldName: "mobile",
    place: "Enter Mobile",
    type: "text",
  },
  {
    field: "Email",
    fieldName: "email",
    place: "Enter email",
    type: "email",
  },
  {
    field: "Telegram",
    fieldName: "telegram",
    place: "Enter Telegram",
    type: "text",
  },
];
export const ContactUsSchema = Yup.object().shape({
  mainhead: Yup.string().required("Heading is required!"),
  description: Yup.string().required("Short Description is required!"),
  mobile: Yup.string().required("Mobile is required!"),
  email: Yup.string().required("Email is required!"),
  telegram: Yup.string().required("Telegram is required!"),
});
export const getInitialValues = (CurrentUser, id) => {
  return {
    id: id ? id : "",
    mainhead: CurrentUser.mainhead,
    description: CurrentUser.description,
    mobile: CurrentUser.mobile,
    email: CurrentUser.email,
    telegram: CurrentUser.telegram,
  };
};
