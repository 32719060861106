export const getStatusClasses = (status) => {
  let classes = " ";
  if (status === "in progress") {
    return classes + "bg-warning kycstatus-btn";
  } else if (status === "active") {
    return classes + "bg-success kycstatus-btn";
  } else if (status === "blocked") {
    return classes + "bg-danger kycstatus-btn";
  } else if (status === "pending") {
    return classes + "bg-primary-dark-op kycstatus-btn";
  } else {
    return classes + "bg-info kycstatus-btn";
  }
};
export const StatusLabel = {
  active: "Active",
  inactive: "in progress",
  blocked: "Blocked",
  pending: "Pending",
};
