import Card from "@mui/material/Card";
import { useState, useEffect, useContext, useCallback } from "react";
import SiteSettingsForm from "../Comps-Utils/GenericForm";
import Loader from "../Comps-Utils/ClimbingLoader";
import { GetSiteInfo, UpdateSiteInfo } from "../../Services/Admin/Site";
import { ToasterContext } from "../../App";
import {
  SiteSchema,
  getInitialValues,
  AdminSiteFormFields,
  AdminAddressFormFields,
  AdminSocialFormFields,
} from "./helpers";
const SiteSettings = () => {
  const [general, setGeneral] = useState([]);
  const [id, setId] = useState();
  const [loading, setLoading] = useState(true);
  const [reset, setReset] = useState(false);
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  //Get Site Settings Data Handler
  const GetSiteService = useCallback(() => {
    setLoading(true);
    GetSiteInfo().then((res) => {
      if (res.status === 200) {
        setGeneral(res.data[0]);
        setId(res.data[0]?._id);
        setLoading(false);
        setReset(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  }, [tostify, setTostify]);

  const updateSiteService = useCallback(
    (data, actions) => {
      UpdateSiteInfo(data).then((res) => {
        if (res.status === 200) {
          setGeneral(res.data);
          setReset(true);
        }
        actions.setSubmitting(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      });
    },
    [tostify, setTostify]
  );

  useEffect(() => {
    GetSiteService();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Card className="mx-auto w95">
        {loading ? (
          <Loader />
        ) : (
          <SiteSettingsForm
            heading={"Site Settings"}
            general={general}
            id={id}
            reset={reset}
            refresh={GetSiteService}
            getValues={getInitialValues}
            initialValues={""}
            schema={SiteSchema}
            formFields={AdminSiteFormFields}
            formFields2={AdminAddressFormFields}
            headingFormFields2={"Address Details"}
            formFields3={AdminSocialFormFields}
            headingFormFields3={"Social Details"}
            update={updateSiteService}
            tostify={tostify}
          />
        )}
      </Card>
    </>
  );
};
export default SiteSettings;
