import React from "react";
import PasswordChange from "../../../components/PasswordChange";
const PasswordChangePage = () => {
  return (
    <>
      <PasswordChange />
    </>
  );
};

export default PasswordChangePage;
