import React from "react";
// import '../assets/css/dashmix.css'

// import '../assetsUser/css/style.css'
import Navbar from "./navigation/navbar";
function IndexDash() {
  return (
    <>
      <Navbar />
    </>
  );
}

export default IndexDash;
