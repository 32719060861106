import { useState, useEffect } from "react";
import moment from "moment";
const Clock = (props) => {
  const { date, styleprop, clockprop } = props;
  const [endDate, setEndDate] = useState({});
  const HandleCountDown = () => {
    const futureDate = moment(date);
    const today = moment().format("ddd, MMM D, YYYY h:mm:ss A");

    const timeDuration = moment.duration(futureDate.diff(today));
    setEndDate({
      years: timeDuration.years() >= 0 ? timeDuration.years() : 0,
      months: timeDuration.months() >= 0 ? timeDuration.months() : 0,
      days: timeDuration.days() >= 0 ? timeDuration.days() : 0,
      hours: timeDuration.hours() >= 0 ? timeDuration.hours() : 0,
      minuts: timeDuration.minutes() >= 0 ? timeDuration.minutes() : 0,
      seconds: timeDuration.seconds() >= 0 ? timeDuration.seconds() : 0,
    });
  };
  useEffect(() => {
    const timeInterval = setInterval(() => {
      HandleCountDown();
    }, 1000);
    return () => clearInterval(timeInterval);
  });
  return (
    <>
      {endDate.years === 0 &&
      endDate.months === 0 &&
      endDate.days === 0 &&
      endDate.hours === 0 &&
      endDate.minuts === 0 &&
      endDate.seconds === 0 ? (
        <>
          <p className="font-weight-normal remaingin-balance m-0 font-size-sm text-white text-center mt-1">
            Sale Ended
          </p>
        </>
      ) : (
        (endDate.years ||
          endDate.months ||
          endDate.days ||
          endDate.hours ||
          endDate.minuts ||
          endDate.seconds) && (
          <div
            className={`d-flex font-weight-bold font-size-md text-center justify-content-around p-2 ${styleprop}`}
            style={{
              background: "white",
            }}
          >
            {/* {endDate.years === 0 ? null : (
                <div className="d-flex flex-column me-3">
                  <span>{endDate.years}</span>
                  <span>Years</span>
                </div>
              )} */}
            {/* <div className="d-flex flex-column me-3">
                  <span>{endDate.years}</span>
                  <span>Years</span>
                </div> */}

            <div className={`d-flex flex-column ${clockprop}`}>
              <span className="d-color ">{endDate.months} M</span>
            </div>

            <div className={`d-flex flex-column ${clockprop}`}>
              <span className="d-color ">{endDate.days} D</span>
            </div>

            <div className={`d-flex flex-column ${clockprop}`}>
              <span className="d-color ">{endDate.hours} H</span>
            </div>
            <div className={`d-flex flex-column ${clockprop}`}>
              <span className="d-color ">{endDate.minuts} M</span>
            </div>
            <div className={`d-flex flex-column ${clockprop}`}>
              <span className="d-color ">{endDate.seconds} S</span>
            </div>
          </div>
        )
      )}
    </>
  );
};
export default Clock;
