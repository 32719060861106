import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

export default function Tags({ SetFilterData }) {
  const fixedOptions = [top100Films[5]];
  const [value, setValue] = React.useState([top100Films[0]]);
  const handleChange = (newValue) => {
    let items = [...newValue];
    let dummy = [];
    for (let i = 0; i < items.length; i++) {
      dummy.push(items[i].status);
    }
    setValue([
      ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
    ]);

    SetFilterData(dummy, "status");
  };
  return (
    <Stack spacing={2} sx={{ width: 400 }}>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={top100Films}
        getOptionLabel={(option) => option.title}
        onChange={(event, newValue) => handleChange(newValue)}
        value={value}
        renderInput={(params) => (
          <TextField
            className="tag-label"
            {...params}
            label="Select Sale Status"
            // placeholder="Status Type"
          />
        )}
      />
    </Stack>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { title: "All", status: "6" },
  { title: "Tx Not Submit", status: "2" },
  { title: "verified", status: "4" },
  { title: "In Process", status: "3" },
  { title: "Airdropped", status: "5" },
];
