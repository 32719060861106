export const createRows = (rowData) => {
  const rows = rowData.map((i) => {
    return {
      userId: i.id,
      email: i.useremail && i.useremail === "" ? "..." : i.useremail,
      name: i.username === "" ? "..." : i.username,
      invested: i.amount,
      paymentMethod: i.paymentMethod,
      payableToken: i.payabletoken,
      tokens: i.tokens,
      status: i.status.length === 0 ? "In Process" : "Token Sent",
      updatedAt: i.date,
      saleType: i.saletype,
      expectedamount: i.expectedamount,
    };
  });
  return rows;
};
export const rowsPerPage = 5;
export const StableTableHeadings = [
  // {
  //   id: "id",
  //   label: "#",
  //   sort: true,
  //   class: "bg-body-light",
  // },
  {
    id: "name",
    label: "User",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "invested",
    label: "Invested",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "payableToken",
    label: "Payable Token",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "Sale Type",
    label: "Sale Type",
    class: "bg-body-light",
  },
  // {
  //   id: "status",
  //   label: "Status",
  //   class: "bg-body-light",
  // },

  {
    id: "time",
    label: "Time",
    sort: true,
    class: "bg-body-light",
  },
];
