import React, { useEffect, useState, useContext } from "react";
import { AddTokenTransaction } from "../../Services/User/Tokens/token";
// import Clip from '../../components/Comps-Utils/ClipLoader'
import { Modal } from "react-bootstrap";
import { ToasterContext } from "../../App";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getReferralCount } from "../../Services/User/web3Calls/contractCall";

function ModalPurchase({ data, callbackFunc, purchase }) {
  const [show] = useState(data);
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const handleClose = () => callbackFunc();
  // const [loading, setLoading] = useState(false)
  const submitTransactionsDetails = async () => {
    const referrralCount = await getReferralCount(purchase.referralAddress);

    if (referrralCount > purchase.referralCount) {
      purchase.referralEmail = true;
    }
    const addToken = await AddTokenTransaction(purchase);

    if (addToken.code === 200) {
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: "success",
        message: "Transaction Successfully added",
      });
    } else {
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: "error",
        message: addToken.message,
      });
    }
  };
  useEffect(() => {
    submitTransactionsDetails();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Modal
        className="success-payment-popup fade d-flex align-items-center"
        show={show}
        backdrop="static"
        onHide={handleClose}
      >
        <Modal.Header className="align-items-baseline" closeButton>
          <div className="tranx-popup text-center">
            <div className="text-center m-4">
              <CheckCircleIcon className="trans-confirm" />
            </div>
            <h3>Transaction Confirmed </h3>
            <p>We have successfully received your deposits.</p>
            <p>
              View transaction details{" "}
              <strong>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${process.env.REACT_APP_MUMBAI_POLYGON_EXPLORER}/tx/${purchase.transactionId}`}
                >
                  here
                </a>
              </strong>{" "}
            </p>
            <div className="tranx-payment-details">
              {/* <p>
                Transaction is now  
                <strong>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://testnet.bscscan.com/tx/${purchase.transactionId}`}
                  >
                    <strong> Confirmed</strong>
                  </a>
                </strong>{" "}
                
              </p> */}

              {/* <div className="tranx-payment-info">
                <span className="tranx-copy-feedback copy-feedback"></span>
                <em className="fab fa-ethereum"></em>
                <input
                  type="text"
                  className="tranx-payment-address"
                  value={process.env.REACT_APP_ICO_ADDRESS}
                  disabled
                />
                <button
                  className="tranx-payment-copy copy-clipboard-modal"
                  data-clipboard-text={process.env.REACT_APP_ICO_ADDRESS}
                >
                  <em className="ti ti-files"></em>
                </button>
              </div> */}

              <div className="row">
                <div className="col-sm-5"></div>
              </div>
            </div>
          </div>
        </Modal.Header>
      </Modal>

      <div className="footer-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <span className="footer-copyright">
                Copyright 2022, <a href="google.com">ICO Crypto</a>. All Rights
                Reserved.
              </span>
            </div>
            <div className="col-md-5 text-md-end">
              <ul className="footer-links">
                <li>
                  <a href="policy.html">Privacy Policy</a>
                </li>
                <li>
                  <a href="policy.html">Terms of Sales</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalPurchase;
