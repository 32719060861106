import instance from "../../../../Config/axios";
import { ParseError, getAuthHeader } from "../../../../Config/utils";
import { TokenErr } from "../../../../Config/tokenErr";
const UpdateCMSServices = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  const data = {
    id: formData.id,
    mainhead: formData.mainhead,
    description: formData.description,
    card1: {
      heading: formData.heading1,
      description: formData.description1,
    },
    card2: {
      heading: formData.heading2,
      description: formData.description2,
    },
    card3: {
      heading: formData.heading3,
      description: formData.description3,
    },
    card4: {
      heading: formData.heading4,
      description: formData.description4,
    },
  };
  return instance
    .put("/cms/services", { data: data }, headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.data.services,
            message: response.msg,
            code: response.code,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
export { UpdateCMSServices };
