import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ResetPasswordApi } from "../../Services/User/User";
import Clip from "../../components/Comps-Utils/ClipLoader";
import { ToasterContext } from "../../App";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  // const [passPage, setPassPage] = useState(false)
  const checkValidation = (e) => {
    const regExp = new RegExp(
      // eslint-disable-next-line
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    if (email === "") {
      setError("email is required");
      return false;
    } else if (!regExp.test(email)) {
      setError("email is not valid");
      return false;
    } else {
      setError("");
      return true;
    }
  };

  const handleSubmit = async (e) => {
    tostify.dismissAll();
    e.preventDefault();
    const validate = checkValidation();
    if (validate) {
      setLoading(true);
      const send = {
        email: email,
      };
      const res = await ResetPasswordApi(send);
      if (res.data.code === 200) {
        setLoading(false);

        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "success",
          message: res.data.msg,
        });
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "error",
          message: res.data.msg,
        });
      }
    }
    tostify.dismissAll();
  };

  return (
    <>
      <div className="user-ath">
        <div className="user-ath-page">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8 text-center">
                <div className="user-ath-logo logo-width">
                  <Link to={"/"}>
                    <img
                      src="images/logo.png"
                      srcSet="images/logo.png"
                      alt="icon"
                    />
                  </Link>
                </div>
                <div className="user-ath-box">
                  <h4>Request Your Password</h4>
                  <form action="#" className="user-ath-form">
                    {/* <div className="note note-lg note-no-icon note-danger">
                                <p>There is no account with this email.</p>
                            </div>
                            <div className="note note-lg note-no-icon note-success">
                                <p>Password recovery instruction sent to your email, Please check.</p>
                            </div> */}

                    <div className="input-item">
                      <input
                        type="text"
                        placeholder="Your Email"
                        className="input-bordered"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <span className="error">{error}</span>
                    </div>
                    <div className="gaps"></div>
                    <div className="d-flex justify-content-between align-items-center">
                      <button
                        className="btn btn-primary d-flex"
                        onClick={handleSubmit}
                      >
                        {loading ? <Clip /> : <>Reset</>}
                      </button>
                      <span>
                        {" "}
                        <Link to={"/login"} className="simple-link">
                          <em className="ti ti-arrow-right"></em> Login Here
                        </Link>
                      </span>
                    </div>
                  </form>
                </div>
                <div className="gaps-2x"></div>
                <div className="form-note">
                  {" "}
                  Not a member? <Link to={"/signup"}>Sign up now</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
