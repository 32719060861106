import * as Yup from "yup";
export const AdminSiteFormFields = [
  {
    field: "Site Name",
    fieldName: "name",
    place: "Enter Site Name",
    type: "text",
    field1: "Site Title",
    fieldName1: "title",
    place1: "Enter Site Title",
    type1: "text",
  },
  {
    field: "Site Meta Keywords",
    fieldName: "meta_key",
    place: "Enter Site Meta Keywords",
    type: "text",
    field1: "Site Meta Description",
    fieldName1: "meta_description",
    place1: "Enter Site Meta Description",
    type1: "text",
  },
];
export const AdminAddressFormFields = [
  {
    field: "Email",
    fieldName: "email",
    place: "Enter Email",
    type: "email",
    field1: "Phone",
    fieldName1: "phone",
    place1: "Enter Phone",
    type1: "number",
  },
  {
    field: "Address",
    fieldName: "address",
    place: "Enter Address Url",
    type: "text",
  },
];

export const AdminSocialFormFields = [
  {
    field: "Github Url",
    fieldName: "github",
    place: "Enter Github Url",
    type: "text",
    field1: "Facebook Url",
    fieldName1: "facebook",
    place1: "Enter Facebook Url",
    type1: "text",
  },
  {
    field: "Discord Url",
    fieldName: "discord",
    place: "Enter Discord Url",
    type: "text",
    field1: "Telegram Url",
    fieldName1: "telegram",
    place1: "Enter Telegram Url",
    type1: "text",
  },

  {
    field: "Bitcoin Url",
    fieldName: "bitcoin",
    place: "Enter Bitcoin Url",
    type: "text",
    field1: "Youtube Url",
    fieldName1: "youtube",
    place1: "Enter YouTube Url",
    type1: "text",
  },
  {
    field: "Madium Url",
    fieldName: "medium",
    place: "Enter Medium Url",
    type: "text",
    field1: "Twitter Url",
    fieldName1: "twitter",
    place1: "Enter Twitter Url",
    type1: "text",
  },
];

export const SiteSchema = Yup.object().shape({
  name: Yup.string().required("First Name is required!"),
  title: Yup.string().required("Title is required!"),
  meta_key: Yup.string().required("Meta Keyword is required!"),
  meta_description: Yup.string().required("Meta Description is required!"),
});

export const getInitialValues = (CurrentUser, id) => {
  return {
    id: id ? id : "",
    name: CurrentUser.name ? CurrentUser.name : "",
    title: CurrentUser ? CurrentUser.title : "",
    meta_key: CurrentUser ? CurrentUser.meta_key : "",
    meta_description: CurrentUser ? CurrentUser.meta_description : "",
    email: CurrentUser ? CurrentUser.email : "",
    phone: CurrentUser ? CurrentUser.phone : "",
    address: CurrentUser ? CurrentUser.address : "",
    github: CurrentUser ? CurrentUser.github : "",
    facebook: CurrentUser ? CurrentUser.facebook : "",
    discord: CurrentUser ? CurrentUser.discord : "",
    telegram: CurrentUser ? CurrentUser.telegram : "",
    bitcoin: CurrentUser ? CurrentUser.bitcoin : "",
    youtube: CurrentUser ? CurrentUser.youtube : "",
    twitter: CurrentUser ? CurrentUser.twitter : "",
    medium: CurrentUser ? CurrentUser.medium : "",
  };
};
