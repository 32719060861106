// import { TextField } from "@material-ui/core";
import { ErrorMessage } from "formik";
// import { FormControl, FormHelperText } from "@material-ui/core";
import ReactQuill from "react-quill";
// import EditorToolbar, { modules, formats } from "./toolbar";
import "./styles.css";
const TextField50 = (props) => {
  const {
    fieldName,
    // handleChange,
    setFieldValue,
    values,
    touched,
    place,
    errors,
    // type,
    // widthclass
  } = props;
  return (
    <>
      <div className="col-md-9">
        <div style={{ paddingRight: "2px" }}>
          {/* <EditorToolbar /> */}
          <ReactQuill
            theme={"snow"}
            style={{
              border:
                touched[fieldName] && errors[fieldName] ? "red solid 1px" : "",
              borderRadius: "5px",
            }}
            className={
              touched[fieldName] && errors[fieldName] ? "quill-error" : ""
            }
            id={fieldName + "quill"}
            name={fieldName}
            fullWidth
            value={values[fieldName]}
            onChange={(event) => {
              if (event.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
                setFieldValue(fieldName, "");
              } else {
                setFieldValue(fieldName, event);
              }
            }}
            placeholder={place}
            error={touched[fieldName] && errors[fieldName]}
            helperText={touched.belowButtonText && errors.belowButtonText}
            // modules={modules}
            // formats={formats}
          />
        </div>
        <ErrorMessage
          name={fieldName}
          component="div"
          className={"error-text"}
        />
      </div>
    </>
  );
};
export default TextField50;
