/* eslint-disable */
import { useEffect, useState } from "react";
import Switch from "react-ios-switch";
export default function CustomizedSwitches(props) {
  const { status, service, id } = props;
  const [checkedd, setChecked] = useState(false);
  const handleChange = (event) => {
    setChecked(!checkedd);
    if (props.type) {
      service(id, !checkedd, props.type);
    } else if (props.role && props.subtype) {
      service(id, props.subtype, !checkedd, props.role);
    } else {
      service(id, !checkedd);
    }
  };
  useEffect(() => {
    setChecked(status);
  }, [status]);
  return (
    <div className=" d-flex align-items-center">
      <Switch
        className="switch-btn"
        disabled={
          id === "dashboard" ||
          id === "profile" ||
          id === "password" ||
          (props && props.dis === false)
        }
        checked={checkedd}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
      />
    </div>
  );
}
