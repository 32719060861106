import * as Yup from "yup";
export const TokensInitialValues = {
  mainHeading: "",
  subHeading: "",
};
export const CMSTokensFormFields = [
  {
    field: "Main Heading",
    fieldName: "mainhead",
    place: "Enter Main heading",
    type: "text",
  },
];
export const TokensSchema = Yup.object().shape({
  mainhead: Yup.string().required("Heading is required!"),
});
export const getInitialValues = (CurrentUser, cmsId) => {
  return {
    id: cmsId ? cmsId : "",
    mainhead: CurrentUser.mainhead,
  };
};
