import instance from "../../../../Config/axios";
import { ParseError, getAuthHeader } from "../../../../Config/utils";
import { TokenErr } from "../../../../Config/tokenErr";
const UpdateCMSDocument = async (formData) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .put("/cms/document", formData, headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.msg,
            code: response.code,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export { UpdateCMSDocument };
