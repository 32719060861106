import Card from "@mui/material/Card";
import { useState, useEffect, useContext, useCallback } from "react";
import Loader from "../Comps-Utils/ClimbingLoader";
import {
  GetPermissions,
  UpdatePermissions,
  AddPermissions,
  DeletePermissions,
} from "../../Services/Permissions";
import PermissionsTable from "../Comps-Utils/GenericTable";
import DialogBox from "../Comps-Utils/DialougeBox";
import { DialogMessage } from "../../helpers/dialouge";
import TableRows from "./TableRows";
import TableRowCollapse from "./CollapseTableRow";
import { ToasterContext } from "../../App";
import {
  CreatePermissionsTableHeadings,
  createRows,
  PermissionsInitialValues,
  PermissionsSchema,
  setInitialValues,
  PermissionsFormFields,
} from "./helpers";
import {
  MANAGER,
  NEW_S,
  SUCCESS_S,
  ERROR_S,
  UPDATE_ROLE,
  DELETE_ROLE,
  PLEASE_CONFIRM_DELETE_ROLE,
  PERMISSIONS_MODAL_HEADING,
  PERMISSIONS_TABLE_HEADING,
  PERMISSIONS_SEARCH_PLACEHOLDER,
  PLEASE_CONFIRM_UPDATE_ROLE,
} from "../../helpers/constants";
import { MX_AUTO__W95 } from "../../helpers/CLASSES";

const SiteSettings = () => {
  const [general, setGeneral] = useState([]);
  // eslint-disable-next-line
  const [filteredData, setfilteredData] = useState([]);
  const [openProfile, setOpenProfile] = useState(false);
  const [dialog, setDialog] = useState({ visible: false });
  const [role, setRole] = useState(MANAGER);
  // eslint-disable-next-line
  const [id, setId] = useState();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [reset, setReset] = useState(false);
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  //Get Site Settings Data Handler
  const [updatedRow, setUpdatedRow] = useState({});
  const [actionState, setActionState] = useState(NEW_S);
  const [open, setOpen] = useState(false);
  const [headings, setHeadings] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setActionState(NEW_S);
    setUpdatedRow({});
  };
  const GetPermissionService = useCallback(() => {
    //
    setLoading(true);
    GetPermissions().then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
        setHeadings(CreatePermissionsTableHeadings(res.data));
        setId(res.data[0]._id);
        setLoading(false);
        setReset(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? SUCCESS_S : ERROR_S,
          message: res.message,
        });
      }
    });
  }, [tostify, setTostify]);
  const AddPermissionService = (data) => {
    AddPermissions(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
        setHeadings(CreatePermissionsTableHeadings(res.data));
        setLoading(false);
        setReset(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.code === 200 ? SUCCESS_S : ERROR_S,
          message: res.message,
        });
      }
    });
  };
  const handleRoleUpdateConfirmation = useCallback(
    (type, subtype, status, role, actions) => {
      DialogMessage(
        setDialog,
        "",
        UPDATE_ROLE,
        PLEASE_CONFIRM_UPDATE_ROLE,
        () => updatePermissionService(type, subtype, status, role, actions)
      );
      // eslint-disable-next-line
    },
    // eslint-disable-next-line
    []
  );
  const updatePermissionService = useCallback(
    (type, subtype, status, role, actions) => {
      let data = { type: type, subtype: subtype, status: status, role: role };
      tostify.dismissAll();
      UpdatePermissions(data).then((res) => {
        if (res.status === 200 && res.code === 200) {
          setGeneral(res.data);
          setHeadings(CreatePermissionsTableHeadings(res.data));
        }
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.code === 200 ? SUCCESS_S : ERROR_S,
          message: res.message,
        });
      });
      // eslint-disable-next-line
    },
    [tostify, setTostify]
  );
  //Role Delete Confirmation Handler
  const handleRoleDeleteConfirmation = useCallback((data) => {
    DialogMessage(setDialog, "", DELETE_ROLE, PLEASE_CONFIRM_DELETE_ROLE, () =>
      DeletePermissionsService(data)
    );
    // eslint-disable-next-line
  }, []);
  const DeletePermissionsService = (data) => {
    DeletePermissions(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
        setHeadings(CreatePermissionsTableHeadings(res.data));
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? SUCCESS_S : ERROR_S,
        message: res.message,
      });
    });
  };
  useEffect(() => {
    //
    GetPermissionService();
    // eslint-disable-next-line
  }, []);
  const filterPermissionsData = (data, role) => {
    setfilteredData(data.filter((i) => i.role === role));
  };
  useEffect(() => {
    //
    filterPermissionsData(general, role);
    // eslint-disable-next-line
  }, [general, role]);
  return (
    <>
      <DialogBox {...dialog} />
      <Card className={MX_AUTO__W95}>
        {loading ? (
          <Loader />
        ) : (
          <PermissionsTable
            isModal={true}
            modalHeading={PERMISSIONS_MODAL_HEADING}
            tableHeading={PERMISSIONS_TABLE_HEADING}
            searchPlaceHolder={PERMISSIONS_SEARCH_PLACEHOLDER}
            loading={loading}
            data={general}
            actionState={actionState}
            open={open}
            updatedRow={updatedRow}
            setUpdatedRow={setUpdatedRow}
            setActionState={setActionState}
            setLoading={setLoading}
            setGeneral={setGeneral}
            //Table Row Components
            TableRow={TableRows}
            TableRowCollapse={TableRowCollapse}
            //Handlers
            handleOpen={handleOpen}
            handleClose={handleClose}
            //Helpers States &  Function
            createRows={createRows}
            headings={headings}
            initialValues={PermissionsInitialValues}
            schema={PermissionsSchema}
            setInitialValues={setInitialValues}
            formFields={PermissionsFormFields}
            //Services
            updateUserStatus={handleRoleUpdateConfirmation}
            add={AddPermissionService}
            role={role}
            setRole={setRole}
            openProfile={openProfile}
            setOpenProfile={setOpenProfile}
            tostify={tostify}
            deleteRow={handleRoleDeleteConfirmation}
          />
        )}
      </Card>
    </>
  );
};
export default SiteSettings;
