export const createRows = (rowData) => {
  const rows = rowData.map((i) => {
    return {
      userId: i.id,
      email: i.useremail && i.useremail === "" ? "..." : i.useremail,
      name: i.username === "" ? "..." : i.username,
      invested: i.amount,
      paymentMethod: i.paymentMethod,
      payableToken: (
        i.expectedamount *
        i.biddingtokenprice *
        i.claimtokenprice *
        100
      ).toFixed(3),
      tokens: i.tokens,

      updatedAt: i.date,
      expectedamount: i.expectedamount,
      saleType: i.saletype,
      recevingAddress:
        i.recevingaddress === ""
          ? "Not Given"
          : i.recevingaddress.substring(0, 5) +
            "...." +
            i.recevingaddress.substring(36, 40),
    };
  });
  return rows;
};
export const rowsPerPage = 5;
export const EthereumTableHeadings = [
  {
    id: "name",
    label: "User",
    sort: true,
    class: "bg-body-light",
  },
  // {
  //   id: "invested",
  //   label: "Invested",
  //   sort: true,
  //   class: "bg-body-light",
  // },
  {
    id: "payment_info",
    label: "Payment info",
    sort: true,
    class: "bg-body-light",
  },
  // {
  //   id: "Sale Type",
  //   label: "Sale Type",
  //   class: "bg-body-light",
  // },
  // {
  //   id: "Expected Amount",
  //   label: "Amount Received",
  //   class: "bg-body-light",
  // },
  {
    id: "Receving Address",
    label: "Receving Address",
    class: "bg-body-light",
  },
  {
    id: "time",
    label: "Time",
    class: "bg-body-light",
  },
];
