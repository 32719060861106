import { TokenErr } from "../../../Config/tokenErr";
import { getAuthHeader, ParseError } from "../../../Config/utils";
import { API } from "../apiConfig";
export const AddUser = async (formData, upload) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  const data = new FormData();
  var uploadIndex = 0;
  const filesIndex = {
    passportIndex: -1,
    licenseIndex: -1,
    cnicFrontIndex: -1,
    cnicBackIndex: -1,
  };
  if (upload.license) {
    data.append("files", upload.license);
    filesIndex.licenseIndex = uploadIndex;
    uploadIndex = uploadIndex + 1;
  }
  if (upload.passport) {
    data.append("files", upload.passport);
    filesIndex.passportIndex = uploadIndex;
    uploadIndex = uploadIndex + 1;
  }
  if (upload.cnicFront) {
    data.append("files", upload.cnicFront);
    filesIndex.cnicFrontIndex = uploadIndex;
    uploadIndex = uploadIndex + 1;
  }
  if (upload.cnicBack) {
    data.append("files", upload.cnicBack);
    filesIndex.cnicBackIndex = uploadIndex;
  }
  data.append("firstname", formData.firstname);
  data.append("lastname", formData.lastname);
  data.append("email", formData.email);
  data.append("phone", formData.phone);
  data.append("dob", formData.dob);
  data.append("nationality", JSON.stringify(formData.nationality));
  data.append("city", formData.city);
  data.append("zip", formData.zip);
  data.append("address1", formData.address1);
  data.append("address2", formData.address2);
  data.append("telegram", formData.telegram);
  data.append("walletAddress", formData.walletAddress);
  data.append("walletNetwork", formData.walletNetwork);
  data.append("type", formData.type);
  data.append("filesIndex", JSON.stringify(filesIndex));
  return API.post("/users/addKYC", data, headers)
    .then((response) => {
      if (TokenErr(response.data)) {
        if (response.data && response.status === 200) {
          response = response.data;
          return {
            ...responseData,
            status: 200,
            data: response.data,
            code: response.code,
            message: response.msg,
          };
        } else {
          return {
            ...responseData,
            message: ParseError(response.data),
          };
        }
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
