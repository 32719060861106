/* eslint-disable */
export const makePaths = (rights, main, setter) => {
  let pathNames = [];
  let filter = [];

  Object.values(rights).map((item) => {
    if (item.status === true) {
      pathNames.push("/" + item.name);
    }
  });

  pathNames.map((right) => {
    const temp = main.filter((i) => i.match === right);

    if (temp.length > 0) {
      Object.values(temp).map((i) => {
        filter.push(i);
      });
    }
  });

  setter(filter);

  // return pathNames;
};
