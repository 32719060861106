import React, { useContext, useEffect, useState } from "react";
import { ToasterContext, WalletContext } from "../../App";
// import Clip from "../../components/Comps-Utils/ClipLoader";
import {
  GetUserWallet,
  UpdateUserWallet,
  //  UpdateUserWallet
} from "../../Services/User/User/user";
// import { PlusOne } from "@material-ui/icons";
import { BiMinus, BiPlus } from "react-icons/bi";
import Clip from "../../components/Comps-Utils/ClipLoader";
// import { useAccount } from "wagmi";

function Setupwallet() {
  const [tostify, setTostify] = useContext(ToasterContext);
  const [walletAddress, setWalletAddress] = useContext(WalletContext);
  // const [openAbout, setOpenAbout] = useState(false);
  const [openAbouts, setOpenAbouts] = useState(``);

  const [walletInfo, setWalletInfo] = useState({
    walletAddress1: "",
  });
  const [errors, setError] = useState({
    walletAddress1: "",
  });
  const [loading, setLoading] = useState(false);
  const user =
    localStorage.getItem("user") === "undefined"
      ? { email: "", username: "", _id: "" }
      : JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    fetchWalletData();
    // setWalletInfo({
    //   walletAddress1: walletAddress,
    // });
    // if (walletAddress != "Connect Wallet") {
    //   user.walletAddress = walletAddress;
    //   localStorage.setItem("user", JSON.stringify(user));
    // }
    // eslint-disable-next-line
  }, [walletAddress]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setWalletInfo({ ...walletInfo, [name]: value });
  };

  const fetchWalletData = async () => {
    const data = await GetUserWallet();
    setWalletInfo({
      walletAddress1: data.data.walletAddress,
    });
  };

  const checkValidation = () => {
    let errors = {};
    if (walletInfo.walletAddress1 === "") {
      errors.walletAddress1 = "wallet Address is empty";
    }
    const walletRegex = new RegExp(/^0x[a-fA-F0-9]{40}$/g);
    if (!walletRegex.test(walletInfo.walletAddress1)) {
      errors.walletAddress1 = "Invalid  wallet Address";
    }

    if (Object.keys(errors).length > 0) {
      setError(errors);
      return false;
    } else {
      setError({});
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validate = checkValidation();

    if (validate) {
      setLoading(true);
      const wallet = await UpdateUserWallet(walletInfo);

      if (wallet.code === 200) {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "success",
          message: "Successfully Updated",
        });
        user.walletAddress = walletInfo.walletAddress1;
        localStorage.setItem("user", JSON.stringify(user));
        setWalletAddress(user.walletAddress);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "error",
          message: "Error Server/network ",
        });
      }
    } else {
      // console.log("failed");
    }
    // setTostify({
    //   visible: false,
    //   key: "",
    //   type: "",
    //   message: "",
    //   dismissAll: () => tostify.dismiss(),
    // });
  };

  // React state to manage visibility
  const [show, setShow] = useState();

  // function to toggle the boolean value
  // function toggleShow() {
  //   setShow(!show);
  // }
  // var buttonText = show ? <BiMinus/> : <BiPlus/> ;

  function doThis(a) {
    if (openAbouts === a) {
      setOpenAbouts(``);
    } else {
      setOpenAbouts(a);
      setShow(!show);
    }
  }

  return (
    <>
      <div className="user-content">
        <div className="user-panel">
          <h2 className="user-panel-title">How to Set Up Your Wallet</h2>
          <div className="user-panel-content">
            <p>
              You need a software wallet to participate in the RecruitCoin ICO.
              A crypto wallet keeps track of your crypto accounts and allows you
              to approve transfers to and from other accounts. Your wallet needs
              to have access to the{" "}
              <a
                href="https://polygonscan.com/"
                rel="noreferrer"
                target="_blank"
              >
                Polygon network{" "}
              </a>
              (blockchain) and must be compatible with ERC-20 tokens. Once your
              tokens vest, you will claim them to make them available in your
              software wallet. You can hold them long term in your software
              wallet, or transfer them to any compatible wallet or exchange
              account.
            </p>
            <p>
              If you don’t have a crypto wallet, we recommend setting up
              Metamask. Here is a helpful{" "}
              <a
                href="https://financebuzz.com/set-up-crypto-wallet"
                rel="noreferrer"
                target="_blank"
              >
                Metamask user guide{" "}
              </a>
              for setting up your wallet and network configuration. If you have
              questions on how to set up your crypto wallet, this useful guide
              will help!
            </p>
            <p>
              DO NOT use a hardware wallet or an exchange account like Kraken,
              Binance, or Coinbase. When you claim your tokens, you need to pay
              a “gas fee” on the Polygon network using MATIC tokens.
            </p>
            {/* <p>
              You need a wallet to participate in the RecruitCoin ICO. A crypto
              wallet keeps track of your crypto accounts and allows you to
              approve transfers to and from other accounts.
            </p>
            <p>
              RecruitCoins are Ethereum ERC-20 compatible tokens. Your crypto
              wallet must be capable of holding ERC-20 tokens.
            </p>
            <p>
              If you don’t have a crypto wallet, we recommend setting one up on
              Metamask. Here is a helpful{" "}
              <a
                href="https://cryptobriefing.com/metamask-beginner-guide/"
                target="_blank"
                rel="noreferrer"
              >
                Metamask user guide
              </a>{" "}
              for setting up your wallet and network configuration. If you have
              questions on how to set up your crypto wallet, this{" "}
              <a
                href="https://financebuzz.com/set-up-crypto-wallet"
                target="_blank"
                rel="noreferrer"
              >
                useful guide
              </a>{" "}
              will help!
            </p> */}
            <div className="mb-2">
              <div
                onClick={() => doThis(1)}
                className="about-wallet wrapAccordion"
              >
                <h4>About Wallets and Recruit Coin tokens</h4>
                <span className="closePlus">
                  {openAbouts === 1 ? <BiMinus /> : <BiPlus />}
                </span>
              </div>

              {openAbouts === 1 && (
                <>
                  <p>
                    A crypto wallet keeps track of your crypto accounts and
                    allows you to approve transfers to and from other accounts.
                    You need a wallet to participate in the Recruit Coin ICO.
                  </p>
                  <p>
                    Recruit Coins are{" "}
                    <a
                      href="https://ethereum.org/en/developers/docs/standards/tokens/erc-20/"
                      target="_black"
                      rel="noreferrer"
                    >
                      Ethereum ERC-20
                    </a>{" "}
                    compatible tokens. Your crypto wallet must be capable of
                    holding ERC-20 tokens.
                  </p>
                  <p>
                    Recruit Coins are managed on the Polygon block chain.
                    Polygon is a layer 2 chain which is much faster and less
                    expensive than the main Ethereum chain. Layer 2 transactions
                    are rolled-up and stored on the main Ethereum chain for
                    security. If your Crypto wallet does not list the Polygon
                    network, you must add it using these values:
                    <ul className="metamask-info">
                      <li>Name: Polygon Mainnet</li>
                      <li>
                        New PRC URL:{" "}
                        <a
                          href="https://polygon-rpc.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://polygon-rpc.com{" "}
                        </a>
                      </li>
                      <li>Chain ID: 137 </li>
                      <li>Currency Symbol: MATIC</li>
                      <li>
                        Block Explorer URL:{" "}
                        <a
                          href="https://polygonscan.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://polygonscan.com/
                        </a>
                      </li>
                    </ul>
                  </p>
                  <p>
                    You need to connect your wallet to this application so you
                    can do the following:
                    <ul className="metamask-info">
                      <li>
                        Spend your existing tokens to invest in RecruitCoins. If
                        you are using a credit card, this step is not required.
                      </li>
                      <li>
                        Claim your RecruitCoin tokens from Betts after they
                        vest.
                      </li>
                    </ul>
                  </p>
                  <p>
                    Recruit Coins are released to investors based on a vesting
                    schedule. This is typical of ICO’s to help prevent early
                    investors from manipulating the price by controlling a large
                    amount of coins.
                  </p>
                </>
              )}
            </div>
            <div className="mb-2">
              <div
                onClick={() => doThis(2)}
                className="about-wallet wrapAccordion"
              >
                <h4>I Already Have an Ethereum ERC-20 Compatible Wallet</h4>
                <span className="closePlus">
                  {openAbouts === 2 ? <BiMinus /> : <BiPlus />}
                </span>
              </div>
              {openAbouts === 2 && (
                <>
                  <p>
                    <ul>
                      <li className="mb-2">
                        Great, just paste the public key of the account where
                        you want to receive your tokens into the field at the
                        bottom of this page. Optionally, you can click the
                        “Connect Wallet” button in the upper right corner of
                        this page to associate your wallet with this application
                        (known as a dApp - distributed application in crypto
                        speak).
                      </li>
                      <li>
                        Make sure your wallet is pointing to the Polygon Mainnet
                        (main network). You may need to add this to the list of
                        networks your wallet knows about. The values needed are
                        listed above in the “About Wallets and the Recruit Coin
                        tokens” section.
                      </li>
                    </ul>
                  </p>
                </>
              )}
            </div>
            <div className="mb-2">
              <div
                onClick={() => doThis(3)}
                className="about-wallet wrapAccordion"
              >
                <h4>I Don’t have an ERC-20 Compatible Wallet</h4>
                <span className="closePlus">
                  {openAbouts === 3 ? <BiMinus /> : <BiPlus />}
                </span>
              </div>
              {openAbouts === 3 && (
                <>
                  <p>
                    <ul className="list-group">
                      <li>
                        No problem, a wallet is easy to create. We recommend
                        using{" "}
                        <a
                          href="https://metamask.io/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          MetaMask
                        </a>
                        , one of the most popular software wallets. Be
                        absolutely sure you have backed up your Recovery Phrase
                        in a safe, offline, location before using your wallet!
                      </li>
                    </ul>
                    <ol className="metamask-info-num mb-3 mt-0">
                      <li>
                        Your new wallet will have one account by default. You
                        can add additional accounts if you desire.
                      </li>
                      <li>
                        You must configure MetaMask to point to the Polygon
                        Mainnet (main network). There is a dropdown at the top
                        of the MetaMask browser extension showing a list of
                        networks. If Polygon is not listed, you will need to
                        “Add Network”. The values needed are listed above in the
                        “About Wallets and the Recruit Coin tokens” section.
                      </li>
                      <li>
                        Click the “Connect Wallet” button in the upper right
                        corner of this page to associate your wallet with this
                        application (known as a dApp - distributed application
                        in crypto speak).
                      </li>
                    </ol>
                  </p>
                </>
              )}
            </div>
            <div className="input-item input-with-label">
              <label htmlFor="token-address" className="input-item-label">
                Your Address for tokens:
              </label>
              <input
                className="input-bordered"
                type="text"
                id="token-address"
                // value={walletInfo.walletAddress1}
                value={
                  walletAddress != "Connect Wallet"
                    ? walletAddress
                    : walletInfo.walletAddress1
                }
                name="walletAddress1"
                disabled={walletAddress === "Connect Wallet" ? false : true}
                onChange={handleChange}
              />
              <div className="error">{errors.walletAddress1}</div>
              <span className="input-note">
                Note: Address should be ERC-20 compliant.
              </span>
            </div>

            {/* <div className="d-sm-flex justify-content-between align-items-center"> */}
            {walletAddress === "Connect Wallet" && (
              <button
                className="btn btn-primary d-flex justify-content-center align-items-center"
                onClick={handleSubmit}
              >
                {loading ? <Clip /> : <>Update</>}
              </button>
            )}
            {/* <div className="gaps-2x d-sm-none"></div> */}
            {/* <span className='color-success'>
            <em className='ti ti-check-box'></em> Saved your wallet address
          </span> */}
            {/* </div> */}
            {/* <img src="images/setupsuccess.png" alt="human" /> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Setupwallet;
