import { Formik } from "formik";
import { useCallback } from "react";
import Form from "../FormikFormFieldsSingleLine";
import DoubleLineForm from "../FormikFormFieldsDoubleLine";
import PermissionsTabs from "../PermissionsTabsForm";
// import Web3 from "web3";
const FormikForm = (props) => {
  const {
    schema,
    formFields,
    formFields2,
    formFields3,
    headingFormFields2,
    headingFormFields3,
    general,
    id,
    getValues,
    initialValues,
    heading,
    update,
    reset,
    refresh,
    isModal,
    tostify,
    role,
    setRole,
    setSelectedTab,
    setMintFields,
    mintFields,
    setValuesTemp,
    tab,
    setTostify,
    skeltonLoading,
  } = props;
  // const [allValid, setAllValid] = useState(false);
  const SubmitForm = useCallback(
    (data, actions) => {
      let validArr = [];
      if (data) {
        if (
          tab === "Early Minting" ||
          tab === "Minting (Uncapped)" ||
          tab === "Payment Tokens"
        ) {
          Object.keys(data).map((item, index) => {
            let match = item.toLowerCase().includes("status".toLowerCase());
            if (match) {
              validArr.push(data[item]);
            }
            return 0;
          });
        }
      }
      let result = validArr.filter((i) => i === false);
      if (result[0] === false) {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "error",
          message:
            tab === "Early Minting" || tab === "Minting (Uncapped)"
              ? "Please Enter Valid Address"
              : "Please Enter Valid Payment Tokens",
        });
        actions.setSubmitting(false);
      } else {
        update(data, actions);
      }
    },
    // eslint-disable-next-line
    [update]
  );
  return (
    <>
      <Formik
        enableReinitialize={false}
        initialValues={
          heading === "Password" ||
          (heading === "Ico Settings" && tab === "Payment Tokens") ||
          (heading === "Ico Settings" && tab === "Early Minting") ||
          (heading === "Ico Settings" && tab === "Minting (Uncapped)")
            ? initialValues
            : (heading === "Ico Settings" && tab === "bonus") ||
              (heading === "Ico Settings" && tab === "Set Prices") ||
              (heading === "Ico Settings" && tab === "Withdraw Tokens") ||
              (heading === "Ico Settings" && tab === "Ico Dates")
            ? getValues(general, id)
            : heading === "Permissions"
            ? getValues(general[0], role)
            : getValues(general, id)
        }
        onSubmit={(values, actions) => {
          tostify.dismissAll();
          SubmitForm(values, actions);
        }}
        validationSchema={schema}
      >
        {(props) => {
          return (
            <>
              {heading === "Site Settings" ? (
                <DoubleLineForm
                  formFields={formFields}
                  formFields2={formFields2}
                  headingFormFields2={headingFormFields2}
                  formFields3={formFields3}
                  headingFormFields3={headingFormFields3}
                  {...props}
                  isModal={isModal}
                  heading={heading}
                  reset={reset}
                  refresh={refresh}
                />
              ) : heading === "Ico Settings" ? (
                <PermissionsTabs
                  role={role}
                  general={general}
                  tab={tab}
                  setSelectedTab={setSelectedTab}
                  setRole={setRole}
                  formFields={formFields}
                  isModal={isModal}
                  heading={heading}
                  reset={reset}
                  refresh={refresh}
                  mintFields={mintFields}
                  setMintFields={setMintFields}
                  setValuesTemp={setValuesTemp}
                  skeltonLoading={skeltonLoading}
                  {...props}
                />
              ) : (
                <Form
                  formFields={formFields}
                  formFields2={formFields2}
                  headingFormFields2={headingFormFields2}
                  {...props}
                  isModal={isModal}
                  heading={heading}
                  reset={reset}
                  refresh={refresh}
                />
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};
export default FormikForm;
